import { Alert, Container } from "react-bootstrap";
import { useEffect } from "react";

export const NotFound = () => {
  useEffect(() => {
    document.title = "No Encontrado";
  }, []);

  return (
    <div style={{ marginTop: 100, textAlign: "center" }}>
      <Container>
        <Alert key="noAccess" variant="danger">
          El elemento pertenece a una cartera distinta a la actual del usuario.
        </Alert>
      </Container>
    </div>
  );
};
