import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, InputGroup } from "react-bootstrap";

export const InputFormUrl = ({ label, id, hidden, filter, value }) => {
  return (
    <Row className="mb-2" hidden={hidden(filter, label)}>
      <Fragment>
        <Col xs={12} md={5}>
          <Form.Label
            hidden={hidden(filter, label)}
            style={{ fontSize: "0.875rem" }}
          >
            {label}
          </Form.Label>
        </Col>
        <Col xs={12} md={7}>
          <InputGroup>
            <div className="form-control" style={{ minHeight: 35 }}>
              <a target="_blank" rel="noopener noreferrer" id={id} href={value}>
                {value}
              </a>
            </div>
          </InputGroup>
        </Col>
      </Fragment>
    </Row>
  );
};

InputFormUrl.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  hidden: PropTypes.func.isRequired,
  filter: PropTypes.string,
  value: PropTypes.string,
};
