import styled from "styled-components/macro";

export const ButtonTextEditor = styled.button`
  background-color: transparent;
  color: black;
  textdecoration: underline;
  padding: 0;
  border: none;
  background: none;
  margin-left: 0.5rem;
  :hover {
    color: #0a58ca;
  }
`;

export const DangerousHTML = styled.div`
  #danHTMLContainer {
    height: 100px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: #e9ecef;
    padding: 5px;
    overflow-y: auto;
    resize: vertical;
  }
`;
