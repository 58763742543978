import React, { useState, useEffect } from "react";
import { TableFrontEnd } from "../../components/TableFrontEnd";
import { useLoader } from "../../components/Loading";
import { Card, Form } from "react-bootstrap";
import * as S from "./styles";
import * as API from "../../api";
import { useNavigate } from "react-router-dom";
import { useDataContext } from "../../context/DataState";
import PageTitle from "../../components/PageTitle/PageTitle";
import usePermition from "../../constants/usePermition";

export const TablaTemporal = () => {
  const [loader, showLoader, hideLoader] = useLoader();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const {
    state: { appLists, user },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  const defaultSort = [
    {
      id: "creationTime",
      desc: true,
    },
  ];

  useEffect(() => {
    if (PERMISOS_POR_ROL.TablaTemporal.Acceso) {
      document.title = "EC-Light-Tabla Temporal";
      showLoader();
      API.obtenerTablaTemporal()
        .then((res) => {
          showLoader();
          API.obtenerUsuariosList()
            .then((resUsuarios) => {
              let dataAuxList = [];
              let listas = appLists;
              listas.userList = resUsuarios.data;
              res.data.forEach((e) => {
                let dataElement = {};
                dataElement.id = e.id;
                dataElement.prinexId = e.prinexId;
                dataElement.dossierCode = e.dossierCode;
                dataElement.portfolioId = e.portfolioId;
                dataElement.isProcessed = e.isProcessed;
                dataElement.lastModificationTime = e.lastModificationTime;
                dataElement.lastModifierId = e.lastModifierId;

                dataElement.portfolioName = appLists.appPortfolios.find(
                  (element) => element.id === e.portfolioId
                ).name;
                dataElement.code = e.code;
                if (e.creationTime !== null) {
                  dataElement.creationTime = e.creationTime;
                }
                const creator = resUsuarios.data.find(
                  (s) => s.id === e.creatorId
                );
                if (creator !== undefined) {
                  dataElement.creatorId = creator.name;
                }

                dataAuxList.push(dataElement);
              });
              setData(dataAuxList);
            })
            .catch((error) => {
              API.DEVELOP && console.log(error);
            })
            .finally(() => hideLoader());
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    } else {
      navigate("/");
    }
  }, [showLoader, hideLoader, navigate]);

  const filterForm = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (
      <Form>
        <Form.Group className="mb-2">
          <span>
            <input
              className="form-control"
              style={{ width: "60%", marginTop: "0.25rem", padding: "0" }}
              value={filterValue || ""}
              onClick={(e) => {
                if (!e) e = window.event;
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => setFilter(e.target.value)}
            />
          </span>
        </Form.Group>
      </Form>
    );
  };

  const getUser = (id) => {
    let name = "";
    const user = appLists.userList.find((e) => e.id === id);
    if (!!user) {
      name = user.name;
    }
    return name;
  };

  const formatDate = (dateString) => {
    var date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() + -offset * 60000);
    let d = date;
    d = [
      "0" + d.getDate(),
      "0" + (d.getMonth() + 1),
      "" + d.getFullYear(),
      "0" + d.getHours(),
      "0" + d.getMinutes(),
      "0" + d.getSeconds(),
    ].map((component) => {
      if (component.length < 4) {
        return component.slice(-2);
      } else {
        return component;
      }
    });

    return d.slice(0, 3).join("/") + " " + d.slice(3).join(":");
  };

  const defaultColumn = {
    // Let's set up our default Filter UI
    Filter: filterForm,
  };

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      Filter: filterForm,
      width: 60,
    },
    {
      Header: "Id Prinex",
      accessor: "prinexId",
      Filter: filterForm,
      width: 60,
    },
    {
      Header: "Código Producto",
      accessor: "code",
      Filter: filterForm,
      width: 80,
    },
    {
      Header: "Expediente",
      accessor: "dossierCode",
      Filter: filterForm,
      width: 80,
    },
    {
      Header: "Cartera",
      accessor: "portfolioName",
      Filter: filterForm,
    },
    {
      Header: "Usuario Creación",
      accessor: "creatorId",
      Filter: filterForm,
    },
    {
      Header: "Fecha Creación",
      accessor: "creationTime",
      disableFilters: true,
      width: 100,
      Cell: ({ cell }) => (
        <span>{formatDate(cell.row.original.creationTime)}</span>
      ),
    },
    {
      Header: "Usuario última modificación",
      accessor: "lastModifierId",
      disableFilters: true,
      width: 100,
      Cell: ({ cell }) => (
        <span>
          {!!cell.row.original.lastModifierId
            ? getUser(cell.row.original.lastModifierId)
            : ""}
        </span>
      ),
    },
    {
      Header: "Última modificación",
      accessor: "lastModificationTime",
      disableFilters: true,
      width: 100,
      Cell: ({ cell }) => (
        <span>
          {!!cell.row.original.lastModificationTime
            ? formatDate(cell.row.original.lastModificationTime)
            : ""}
        </span>
      ),
    },
    {
      Header: "Procesado",
      accessor: "isProcessed",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) =>
        cell.row.original.isProcessed ? <span>Si</span> : <span>No</span>,
    },
  ];

  return (
    <S.Container>
      {loader}
      <PageTitle title="Tabla Temporal" />
      <Card style={{ marginTop: 20 }}>
        <Card.Body>
          <TableFrontEnd
            columns={columns}
            data={data}
            defaultSort={defaultSort}
            defaultColumn={defaultColumn}
            hiddenColumns={[]}
            pagination={true}
          />
        </Card.Body>
      </Card>
    </S.Container>
  );
};
