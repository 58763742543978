import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";

export const InputModalDataList = ({
  label,
  placeholder,
  id,
  disabled,
  hidden,
  filter,
  list,
  value,
  handleChange,
  required,
  inputBootstrap = true,
}) => {
  if (list === undefined) {
    list = [];
  }
  return (
    <Row className="mb-2">
      {inputBootstrap ? (
        <Col>
          <Form.Label hidden={hidden(filter, label)}>{label}</Form.Label>
        </Col>
      ) : (
        <Form.Label hidden={hidden(filter, label)}>{label}</Form.Label>
      )}
      <Col>
        <input
          required={required}
          className="form-control"
          placeholder={placeholder}
          id={id}
          disabled={disabled()}
          hidden={hidden(filter, label)}
          value={value}
          onChange={(e) => handleChange(id, e.target.value)}
          list="datalistOptions"
        />
        <datalist id="datalistOptions">
          {list.map((field, index) => (
            <option value={field.name} key={index}></option>
          ))}
        </datalist>
        <Form.Control.Feedback type="invalid">
          El {label} es necesario.
        </Form.Control.Feedback>
      </Col>
    </Row>
  );
};

InputModalDataList.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.func.isRequired,
  hidden: PropTypes.func.isRequired,
  filter: PropTypes.string,
  list: PropTypes.object.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  required: PropTypes.bool,
};
