import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

export const ModalOnlyText = ({ message, cancel, text }) => {
  return (
    <Modal
      show={true}
      onHide={cancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
    </Modal>
  );
};

ModalOnlyText.propTypes = {
  message: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};
