import styled from "styled-components/macro";

export const Sidebar = styled.div`
  margin: 0;
  padding: 1rem;
  width: 200px;
  background-color: #ffffff;
  position: fixed;
  max-height: 82vh;
  overflow: auto;

  @media screen and (max-width: 700px) {
    width: 100%;
    height: auto;
    position: relative;
    display: none;
  }
  a {
    float: left;
  }
`;

export const Content = styled.div`
  margin-left: 200px;
  padding: 1rem;

  .openFilters {
    display: none;
  }

  @media screen and (max-width: 700px) {
    margin-left: 0;
    .openFilters {
      display: inline;
      margin-right: 0.5rem;
    }
  }

  .tableButton {
    backgroundcolor: transparent;
    color: #0d6efd;
    textdecoration: underline;
    padding: 0;
    border: none;
    background: none;
  }
  .tableButton:hover {
    color: #0a58ca;
  }
`;
