import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import { Table, Button, ButtonGroup, Form, Row, Col } from "react-bootstrap";
import * as S from "./styles";
import { useDataContext } from "../../context/DataState";
import EmptyData from "../EmptyTable/EmptyData";

export const TableFrontEnd = ({
  columns,
  data,
  defaultSort,
  defaultColumn,
  hiddenColumns,
  pagination,
  regByPage,
  heightSize,
  hideReg,
}) => {
  const {
    dispatch,
    state: { regLength },
  } = useDataContext();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: pagination
          ? !!regByPage
            ? regByPage
            : 50
          : Number.MAX_VALUE,
        sortBy: defaultSort,
        hiddenColumns: hiddenColumns,
      },
      defaultColumn,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <S.Container>
      <S.TableContainer heightSize={heightSize}>
        <Table
          striped
          bordered
          hover
          {...getTableProps()}
          style={{
            border: "solid 1px black",
            whiteSpace: "nowrap",
          }}
        >
          <thead className="bg-light sticky-top top-0">
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    id={column.id + "Header"}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      backgroundColor: "rgb(246, 246, 246)",
                      color: "black",
                      fontWeight: "bold",
                      cursor: "pointer",
                      verticalAlign: "top",
                      border: "thin solid lightgray",
                      maxWidth: column.maxWidth,
                      minWidth: column.minWidth,
                      width: column.width,
                    }}
                    key={i}
                    scope="col"
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        <Fragment>
                          <span
                            id="sortedData"
                            column={column.id}
                            hidden={true}
                          ></span>
                          {column.isSortedDesc ? (
                            <span> ↓ </span>
                          ) : (
                            <span> ↑ </span>
                          )}
                        </Fragment>
                      ) : (
                        ""
                      )}
                    </span>
                    {column.canFilter ? column.render("Filter") : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          background: "white",
                          overflow: "hidden",
                          border: "thin solid lightgray",
                          maxWidth: cell.column.maxWidth,
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                        }}
                        title={cell.value || ""}
                        key={index}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </S.TableContainer>
      {!!data.length ? (
        <Fragment>
          {pagination && (
            <Fragment>
              <Row className="mb-2">
                <Col>
                  <ButtonGroup size="sm">
                    <Button
                      variant="secondary"
                      style={{ marginRight: "3px" }}
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </Button>
                    <Button
                      variant="secondary"
                      style={{ marginRight: "3px" }}
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </Button>
                    <Button
                      variant="secondary"
                      style={{ marginRight: "3px" }}
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      {">"}
                    </Button>
                    <Button
                      variant="secondary"
                      style={{ marginRight: "3px" }}
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={6}>
                  <Form.Select
                    size="sm"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      dispatch({
                        type: "UPDATE_REG_LENGTH",
                        payload: e.target.value,
                      });
                    }}
                    style={{ width: "8rem" }}
                  >
                    {[10, 20, 50, 100, 200].map((pageSize, ind) => (
                      <option key={ind} value={pageSize}>
                        Mostrar {pageSize}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <span>
                    Página{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <span>
                    | Ir a la página:{" "}
                    <input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(page);
                      }}
                      style={{ width: "100px" }}
                      min={1}
                      max={pageOptions.length}
                      onKeyDown={(e) => {
                        if (
                          e.key === "-" ||
                          Number(e.key) > pageOptions.length ||
                          Number(e.key) < 1
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </span>{" "}
                </Col>
              </Row>
            </Fragment>
          )}
          <Row hidden={!!hideReg}>
            <Col>{`${data.length} registro${data.length > 1 ? "s" : ""}`}</Col>
          </Row>
        </Fragment>
      ) : (
        <EmptyData />
      )}
    </S.Container>
  );
};

TableFrontEnd.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  defaultSort: PropTypes.array.isRequired,
  defaultColumn: PropTypes.object,
  hiddenColumns: PropTypes.array.isRequired,
  pagination: PropTypes.bool,
};
