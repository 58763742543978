import PropTypes from "prop-types";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

export const ModalFilterAudit = ({
  message,
  confirm,
  labelConfirm,
  cancel,
  filtersModal,
  comparators,
  values,
  sqlField,
  handleChangeFilters,
}) => {
  return (
    <Modal
      show={true}
      onHide={cancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="formModal" onSubmit={(e) => confirm(e, values)}>
          <input type="hidden" name="sqlField" value={sqlField}></input>
          <Row className="mb-2">
            <Col>
              <Form.Label>Campo</Form.Label>
            </Col>
            <Col>
              <Form.Select
                name="campo"
                onChange={(e) => handleChangeFilters(e.target.value)}
              >
                <option key="emptyField" value=""></option>

                {filtersModal.map((field, index) => (
                  <option key={index} value={field.fieldLabel}>
                    {field.fieldLabel}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          {comparators.length > 0 && (
            <Row className="mb-2">
              <Col>
                <Form.Label>Comparador</Form.Label>
              </Col>
              <Col>
                <Form.Select name="comparador">
                  <option key="emptyComparador" value=""></option>

                  {comparators.map((field, index) => (
                    <option key={index} value={field}>
                      {field}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          )}
          {values !== {} &&
            (values.entityTableType === "System.String" ||
              values.entityTableType === "System.Guid") && (
              <Row className="mb-2">
                <Col>
                  <Form.Label>Valor</Form.Label>
                </Col>
                <Col>
                  {values.values === null && (
                    <Form.Control name="valor" type="text" />
                  )}
                  {values.values !== null && (
                    <Form.Select name="valor">
                      <option key="emptyValue" value=""></option>
                      {values.values.map((field, index) => (
                        <option key={index} value={field.value}>
                          {field.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </Col>
              </Row>
            )}
          {values !== {} &&
            (values.entityTableType === "System.Int64" ||
              values.entityTableType === "System.Int32") && (
              <Row className="mb-2">
                <Col>
                  <Form.Label>Valor</Form.Label>
                </Col>
                <Col>
                  {values.values === null && (
                    <Form.Control name="valor" type="number" />
                  )}
                  {values.values !== null && (
                    <Form.Select name="valor">
                      <option key="emptyValue" value=""></option>
                      {values.values.map((field, index) => (
                        <option key={index} value={field.value}>
                          {field.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </Col>
              </Row>
            )}
          {values !== {} && values.entityTableType === "System.Decimal" && (
            <Row className="mb-2">
              <Col>
                <Form.Label>Valor</Form.Label>
              </Col>
              <Col>
                {values.values === null && (
                  <Form.Control name="valor" type="number" step="any" />
                )}
                {values.values !== null && (
                  <Form.Select name="valor">
                    <option key="emptyValue" value=""></option>
                    {values.values.map((field, index) => (
                      <option key={index} value={field.value}>
                        {field.label}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Col>
            </Row>
          )}
          {values !== {} && values.entityTableType === "System.Boolean" && (
            <Row className="mb-2">
              <Col>
                <Form.Label>Valor</Form.Label>
              </Col>
              <Col>
                {values.values === null && <Form.Check name="valor" />}
                {values.values !== null && (
                  <Form.Select name="valor">
                    <option key="emptyValue" value=""></option>
                    {values.values.map((field, index) => (
                      <option key={index} value={field.value}>
                        {field.label}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Col>
            </Row>
          )}
          {values !== {} && values.entityTableType === "System.DateTime" && (
            <Row className="mb-2">
              <Col>
                <Form.Label>Valor</Form.Label>
              </Col>
              <Col>
                {values.values === null && (
                  <Form.Control name="valor" type="datetime-local" />
                )}
                {values.values !== null && (
                  <Form.Select name="valor">
                    <option key="emptyValue" value=""></option>
                    {values.values.map((field, index) => (
                      <option key={field.value} value={field.value}>
                        {field.label}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="formModal" variant="success">
          <span>
            <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
          </span>
          {labelConfirm}
        </Button>

        {cancel && (
          <Button onClick={cancel} variant="danger">
            <span>
              <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
            </span>
            Cancelar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalFilterAudit.propTypes = {
  message: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  labelConfirm: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  filtersModal: PropTypes.array.isRequired,
  comparators: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  sqlField: PropTypes.string.isRequired,
  handleChangeFilters: PropTypes.func.isRequired,
};
