import { disabledFalse, disabledTrue, hiddenFalse } from "../../constants";

export const METADATA_EDICION_USUARIO = [
  {
    label: "Código Azure",
    name: "id",
    type: "text",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Nombre y apellidos",
    name: "name",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Correo electrónico",
    name: "email",
    type: "text",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Deshabilitado",
    name: "isDeleted",
    type: "checkbox",
    disabled: disabledFalse,
    hidden: hiddenFalse,
  },
];

export const METADATA_CREACION_USUARIO = [
  {
    label: "Código Azure",
    name: "id",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Nombre y apellidos",
    name: "name",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Correo electrónico",
    name: "email",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Deshabilitado",
    name: "isDeleted",
    type: "checkbox",
    disabled: disabledFalse,
    hidden: hiddenFalse,
  },
];

export const METADATA_AUDIT_USUARIO = [
  {
    label: "Usuario Creación",
    name: "creatorId",
  },
  {
    label: "Fecha Creación",
    name: "creationTime",
  },
  {
    label: "Usuario Última Modificación",
    name: "lastModifierId",
  },
  {
    label: "Fecha Última Modificación",
    name: "lastModificationTime",
  },
  {
    label: "Último Acceso",
    name: "lastAccess",
  },
];
