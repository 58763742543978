import styled from "styled-components/macro";

export const AccordionCollapsableWrapper = styled.div`
  height: 20px;
  margin-bottom: 1rem;

  button {
    height: 10px;
  }
`;
