import React from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomeTooltip } from "../CustomeTooltip/CustomeTooltip";

export const InputFormText = ({
  label,
  placeholder,
  id,
  disabled,
  hidden,
  filter,
  required,
  maxLength,
  handleChange,
  value,
  addon,
  tooltip,
  numberSeparator,
}) => {
  const getValue = (value) => {
    if (value || value === 0) {
      if (numberSeparator) {
        return value.toLocaleString("de");
      } else {
        return value;
      }
    } else {
      return "";
    }
  };

  return (
    <Row className="mb-2" hidden={hidden(filter, label)}>
      <Col xs={12} md={5}>
        <Form.Label
          hidden={hidden(filter, label)}
          style={{ fontSize: "0.875rem" }}
          alt={tooltip ? tooltip : ""}
        >
          {label}
          {tooltip && (
            <CustomeTooltip
              tooltip={tooltip}
              children={<FontAwesomeIcon icon={faQuestion} size="xs" />}
            />
          )}
        </Form.Label>
      </Col>
      <Col xs={12} md={7}>
        <InputGroup>
          <Form.Control
            required={required}
            type="text"
            placeholder={placeholder}
            id={id}
            disabled={disabled()}
            maxLength={maxLength}
            value={getValue(value)}
            onChange={(e) => handleChange(id, e.target.value)}
            aria-describedby="addon"
            title={getValue(value)}
            size="sm"
            autoComplete="off"
          />
          {addon && <InputGroup.Text id="addon">{addon}</InputGroup.Text>}
          <Form.Control.Feedback type="invalid">
            El {label} es necesario.
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  );
};
