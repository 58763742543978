import styled from "styled-components/macro";

export const ButtonDownload = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  color: #0d6efd;
`;

export const DetailPage = styled.div`
  margin: 3rem 15px 0 15px;
`;
