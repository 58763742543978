import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination } from "react-table";
import { Table, Button, ButtonGroup, Form, Row, Col } from "react-bootstrap";
import * as S from "./styles";
import EmptyData from "../EmptyTable/EmptyData";

export const TableBackEnd = ({
  columns,
  data,
  defaultSort,
  defaultColumn,
  hiddenColumns,
  pageTable,
  regsPage,
  setPage,
  setRegsPage,
  totalPages,
  numRegisters,
  search,
  order,
  setOrder,
  orderDesc,
  setOrderDesc,
  heightED,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: regsPage,
        hiddenColumns: hiddenColumns,
        sortBy: defaultSort,
      },
      defaultColumn,
    },
    usePagination
  );

  useEffect(() => {
    if (pageTable > 0) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTable, regsPage, order, orderDesc]);

  const changeOrder = (column) => {
    if (!column.disableSortBy) {
      if (column.id === "requestingUserName") {
        column.id = "requestingUserId";
      }
      if (order === column.id && orderDesc === false) {
        setOrderDesc(!orderDesc);
      } else if (order === column.id && orderDesc === true) {
        setOrder("");
        setOrderDesc(!orderDesc);
      } else {
        setOrder(column.id);
        setOrderDesc(false);
      }
    }
  };

  return (
    <S.Container>
      <S.TableContainer height={heightED}>
        <Table
          striped
          bordered
          hover
          {...getTableProps()}
          style={{
            border: "solid 1px black",
            whiteSpace: "nowrap",
          }}
        >
          <thead className="bg-light sticky-top top-0">
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    onClick={() => changeOrder(column)}
                    style={{
                      backgroundColor: "rgb(246, 246, 246)",
                      color: "black",
                      fontWeight: "bold",
                      cursor: "pointer",
                      verticalAlign: "top",
                      border: "thin solid lightgray",
                      maxWidth: column.maxWidth,
                      width: column.width,
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.render("Header")}
                    <span>
                      {column.id === order ? (
                        orderDesc ? (
                          <span> ↑ </span>
                        ) : (
                          <span> ↓ </span>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        background: "white",
                        overflow: "hidden",
                        maxWidth: cell.column.maxWidth,
                        border: "thin solid lightgray",
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                      }}
                      title={cell.value || ""}
                      key={index}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </S.TableContainer>
      {!!data.length ? (
        <Fragment>
          <Row className="mb-2">
            <Col>
              <ButtonGroup size="sm" className="mt-10">
                <Button
                  style={{ marginRight: "3px" }}
                  variant="secondary"
                  onClick={() => {
                    setPage(1);
                  }}
                  disabled={pageTable < 2}
                >
                  {"<<"}
                </Button>
                <Button
                  style={{ marginRight: "3px" }}
                  variant="secondary"
                  onClick={() => {
                    setPage(pageTable - 1);
                  }}
                  disabled={pageTable < 2}
                >
                  {"<"}
                </Button>
                <Button
                  style={{ marginRight: "3px" }}
                  variant="secondary"
                  onClick={() => {
                    setPage(pageTable + 1);
                  }}
                  disabled={pageTable === totalPages || pageTable === 0}
                >
                  {">"}
                </Button>
                <Button
                  style={{ marginRight: "3px" }}
                  variant="secondary"
                  onClick={() => setPage(totalPages)}
                  disabled={pageTable === totalPages || pageTable === 0}
                >
                  {">>"}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={6}>
              <Form.Select
                size="sm"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                  setPage(1);
                  setRegsPage(Number(e.target.value));
                }}
                style={{ width: "8rem" }}
              >
                {[10, 20, 50, 100, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <span>
                Página{" "}
                <strong>
                  {pageTable} de {totalPages}
                </strong>{" "}
              </span>
              <span>
                | Ir a la página:{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) : 0;
                    if (page >= 1 && page < totalPages) {
                      setPage(page);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "-" ||
                      Number(e.key) > totalPages ||
                      Number(e.key) < 1
                    ) {
                      e.preventDefault();
                    }
                  }}
                  min={1}
                  max={totalPages}
                  style={{ width: "100px" }}
                />
              </span>{" "}
            </Col>
          </Row>
          <Row>
            <Col>{`${numRegisters} registro${
              numRegisters > 1 ? "s" : ""
            }`}</Col>
          </Row>
        </Fragment>
      ) : (
        <EmptyData height={heightED} />
      )}
    </S.Container>
  );
};

TableBackEnd.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  defaultSort: PropTypes.array.isRequired,
  defaultColumn: PropTypes.object,
  hiddenColumns: PropTypes.array.isRequired,
  pageTable: PropTypes.number.isRequired,
  regsPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setRegsPage: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  numRegisters: PropTypes.number.isRequired,
  order: PropTypes.string,
  setOrder: PropTypes.func,
  orderDesc: PropTypes.bool,
  setOrderDesc: PropTypes.func,
};
