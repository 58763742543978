import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Title = styled.span`
  background-color: ${COLORES.TITLE_CAMPAIGN_PROMOTION};
  color: #fff;
  margin-left: 2px;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 1.3rem;
`;

export const AssetsSelected = styled.div`
  max-height: 65vh;
  overflow-y: auto;
  margin-top: 1rem;
  button {
    cursor: pointer;
  }
  .tableButton {
    backgroundcolor: transparent;
    color: #0d6efd;
    textdecoration: underline;
    padding: 0;
    border: none;
    background: none;
  }
  .tableButton:hover {
    color: #0a58ca;
  }
`;

export const DetailPage = styled.div`
  margin: 0 15px;
`;

export const HeaderPageWithBack = styled.div`
  display: inline-flex;
  button {
    margin-right: 15px;
  }
`;
