import styled from "styled-components/macro";

export const MenuContainer = styled.div`
  position: ${({ fixed }) => (fixed ? "fixed" : "relative")};
  top: ${({ fixed }) =>
    fixed
      ? "0"
      : navigator.userAgent.toLowerCase().indexOf("firefox") > -1
        ? ""
        : "-100%"};
  left: ${({ fixed }) => (fixed ? "0" : "auto")};
  right: ${({ fixed }) => (fixed ? "0" : "auto")};
  z-index: 999;
  background-color: ${process.env.REACT_APP_BG_COLOR};
  padding: 0 15px;
  transition: top 0.8s ease;
  .container {
    margin: auto;
  }
  #basic-nav-dropdown-tablas {
    color: black;
  }
  #basic-nav-dropdown-admin {
    color: black;
  }
  #basic-nav-dropdown-portfolio {
    color: black;
  }
  #basic-nav-dropdown-user {
    color: black;
  }
  .nav-link.bulkUpload {
    color: black;
    cursor: pointer;
    margin-right: 1rem;
  }

  .version {
    color: black;
  }

  span {
    margin-right: 0.5rem;
  }

  .nav-item {
    margin-right: 1rem;
  }
  .dropdown-menu.show {
    z-index: 9999;
    left: auto;
    right: 0;
  }
`;

export const LabelMenu = styled.label`
  cursor: pointer;
  font-size: small;
  .tooltip-text {
    visibility: hidden;
  }
`;

export const LabelMenuContainer = styled.div`
  display: block;
`;
