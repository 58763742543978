import styled from "styled-components/macro";

export const TooltipSpan = styled.span`
  position: absolute;
  background-color: #818589;
  z-index: 99999;
  color: white;
  padding: 7px;
  border-radius: 7px;
  margin-left: -134px;
  margin-top: 26px;
  transition-duration: 500ms;
  right: 0;
  left: auto;
`;
export const CustomeItemTooltip = styled.span`
  cursor: pointer;
`;
