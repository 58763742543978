import {
  disabledFalse,
  disabledTrue,
  hiddenFalse,
  hiddenTrue,
} from "../../constants";

export const METADATA_EDICION_SOCIEDAD = [
  {
    label: "Id",
    name: "id",
    type: "text",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Id Sociedad",
    name: "prinexId",
    type: "text",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Nombre",
    name: "name",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Cartera",
    name: "portfolioId",
    type: "text",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "CIF",
    name: "cif",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Domicilio",
    name: "address",
    type: "text",
    required: true,
    disabled: disabledFalse,
    hidden: hiddenFalse,
  },
  {
    label: "Municipio",
    name: "municipalityName",
    type: "datalist",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    listName: "appMunicipalities",
    required: true,
  },
  {
    label: "Excent",
    name: "isExcent",
    type: "checkbox",
    disabled: disabledFalse,
    hidden: hiddenTrue,
  },
  {
    label: "Sareb",
    name: "isSareb",
    type: "checkbox",
    disabled: disabledFalse,
    hidden: hiddenTrue,
  },
];

export const METADATA_CREACION_SOCIEDAD = [
  {
    label: "Id",
    name: "id",
    type: "text",
    required: true,
    disabled: disabledTrue,
    hidden: hiddenFalse,
  },
  {
    label: "Id Sociedad",
    name: "prinexId",
    type: "number",
    required: true,
    disabled: disabledFalse,
    hidden: hiddenFalse,
    min: 1,
  },
  {
    label: "Nombre",
    name: "name",
    type: "text",
    required: true,
    disabled: disabledFalse,
    hidden: hiddenFalse,
  },
  {
    label: "Cartera",
    name: "portfolioId",
    type: "listWithChildren",
    typeList: "Normal",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    listName: "appPortfolios",
    required: true,
  },
  {
    label: "CIF",
    name: "cif",
    type: "text",
    required: true,
    disabled: disabledFalse,
    hidden: hiddenFalse,
  },
  {
    label: "Domicilio",
    name: "address",
    type: "text",
    required: true,
    disabled: disabledFalse,
    hidden: hiddenFalse,
  },
  {
    label: "Municipio",
    name: "municipalityName",
    type: "datalist",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    listName: "appMunicipalities",
    required: true,
  },
  {
    label: "Excent",
    name: "isExcent",
    type: "checkbox",
    disabled: disabledFalse,
    hidden: hiddenTrue,
  },
  {
    label: "Sareb",
    name: "isSareb",
    type: "checkbox",
    disabled: disabledFalse,
    hidden: hiddenTrue,
  },
];

export const METADATA_AUDIT_SOCIEDAD = [
  {
    label: "Usuario Creación",
    name: "creatorId",
  },
  {
    label: "Fecha Creación",
    name: "creationTime",
  },
  {
    label: "Usuario Última Modificación",
    name: "lastModifierId",
  },
  {
    label: "Fecha Última Modificación",
    name: "lastModificationTime",
  },
];
