import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { loginRequest } from "./authConfig";
import { Layout } from "./pages/Layout";
import { RoutesApp } from "./routes";
import { GlobalStyle } from "./styles/global";
import "bootstrap/dist/css/bootstrap.min.css";
import { configureAxios } from "./api";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";

export default function App() {
  const { instance: msalInstance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [triggerGetAccess, setTriggerGetAccess] = useState(false);

  useEffect(() => {
    if (inProgress === "none" && accounts.length > 0) {
      configureAxios(msalInstance);
      if (localStorage.getItem("token")) {
        const currentTime = Date.now();
        const msalTokenExpiresOn = Number(
          localStorage.getItem("msalTokenExpiresOn")
        );
        if (currentTime < msalTokenExpiresOn) {
          setTriggerGetAccess(true);
        }
      }
    }
  }, [inProgress, accounts, msalInstance]);

  useEffect(() => {
    const handleRedirect = () => {
      msalInstance
        .handleRedirectPromise()
        .then((res) => {
          if (res) {
            msalInstance.setActiveAccount(res.account);
          }

          const currentTime = Date.now();
          if (res?.accessToken) {
            localStorage.setItem("token", res.accessToken);
            setTriggerGetAccess(true);

            const msalTokenExpiresOn = Number(res.expiresOn);
            const msalTokenExtExpiresOn = Number(res.extExpiresOn);

            localStorage.setItem(
              "msalTokenExpiresOn",
              msalTokenExpiresOn.toString()
            );
            localStorage.setItem(
              "msalTokenExtExpiresOn",
              msalTokenExtExpiresOn.toString()
            );

            if (currentTime > msalTokenExtExpiresOn) {
              msalInstance
                .loginRedirect(loginRequest)
                .then((res) => {})
                .catch((error) => {
                  console.log("[App] loginRedirect->ERROR", error);
                });
            } else if (currentTime > msalTokenExpiresOn) {
              msalInstance
                .acquireTokenSilent(loginRequest)
                .then((res) => {})
                .catch((error) => {
                  console.log("[App] acquireTokenSilent(1)->error", error);
                });
            }
          } else {
            if (isAuthenticated) {
              const msalTokenExpiresOn = Number(
                localStorage.getItem("msalTokenExpiresOn")
              );
              const msalTokenExtExpiresOn = Number(
                localStorage.getItem("msalTokenExtExpiresOn")
              );

              if (currentTime > msalTokenExtExpiresOn) {
                console.log(
                  "%c[App-isAuthenticated] msalTokenExt expirado => loginRedirect",
                  "font-weight: bold"
                );
                // localStorage.removeItem("token");
                msalInstance
                  .loginRedirect(loginRequest)
                  .then((res) => {
                    console.log(
                      "[App-isAuthenticated] loginRedirect->then",
                      res
                    );
                  })
                  .catch((error) => {
                    console.log(
                      "[App-isAuthenticated] loginRedirect->ERROR",
                      error
                    );
                  });
              } else if (currentTime > msalTokenExpiresOn) {
                console.log(
                  "%c[App-isAuthenticated] msalToken expirado | msalTokenExt vigente => acquireTokenSilent",
                  "font-weight: bold"
                );
                msalInstance
                  .acquireTokenSilent(loginRequest)
                  .then((res) => {
                    console.log(
                      "[App-isAuthenticated] acquireTokenSilent->then",
                      res
                    );
                    setTriggerGetAccess(true);
                  })
                  .catch((error) => {
                    console.log(
                      "[App-isAuthenticated] acquireTokenSilent->error",
                      error
                    );
                  });
              }
            } else {
              console.log(
                "%c[App-!isAuthenticated] => loginRedirect",
                "font-weight: bold"
              );
              // localStorage.removeItem("token");
              msalInstance
                .loginRedirect(loginRequest)
                .then((res) => {})
                .catch((error) =>
                  console.log(
                    "[App] loginRedirect(!isAuthenticated)->error",
                    error
                  )
                );
            }
          }
        })
        .catch((error) => {
          console.log("[App] handleRedirectPromise->error", error);
        });
    };

    if (inProgress === "none") handleRedirect();
  }, [inProgress, accounts, msalInstance, isAuthenticated]);

  return (
    <AuthenticatedTemplate>
      <Router>
        <Layout
          triggerGetAccess={triggerGetAccess}
          setTriggerGetAccess={setTriggerGetAccess}
        >
          <RoutesApp />
        </Layout>
        <GlobalStyle />
      </Router>
    </AuthenticatedTemplate>
  );
}
