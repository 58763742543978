import React, { useState, useEffect, useMemo, Fragment } from "react";
import { TableBuscador } from "../../components/TableBuscador";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTasks } from "@fortawesome/free-solid-svg-icons";
import { Card, Button, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./styles";
import * as API from "../../api";
import { useLoader } from "../../components/Loading";
import { ModalValidarCreacionProducto } from "../../components/ModalValidarCreacionProducto";
import { useDataContext } from "../../context/DataState";
import PageTitle from "../../components/PageTitle/PageTitle";
import { toast } from "react-toastify";
import { portfolios } from "../../constants";
import usePermition from "../../constants/usePermition";
import FilterButtons from "../../components/SearchButtons/FilterButtons";

export const Buscador = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [dataActivosSinProducto, setDataActivosSinProducto] = useState([]);
  const [dataActivosConProducto, setDataActivosConProducto] = useState([]);
  const [dataValidacionCreacionProducto, setDataValidacionCreacionProducto] =
    useState({});
  const [showingFilters, setShowingFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [loader, showLoader, hideLoader] = useLoader();
  const [page, setPage] = useState(0);
  const [regsPage, setRegsPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [numRegisters, setNumRegisters] = useState(0);
  const [order, setOrder] = useState("");
  const [orderDesc, setOrderDesc] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [modalValidarCreacionProducto, setModalValidarCreacionProducto] =
    useState(false);
  const {
    dispatch,
    state: { appLists, user },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  const currentCartera = user.currentPortfolioID;

  useEffect(() => {
    document.title = "EC-Light-Buscador";
    let isMounted = true;
    let listas = appLists;
    if (isMounted) {
      if (appLists !== null) {
        listas.appMunicipalitiesAux = listas.appMunicipalities;
        dispatch({
          type: "UPDATE_APP_LIST",
          payload: listas,
        });
      }
      const {
        id,
        code,
        assetType,
        businessLine,
        isPublishable,
        status,
        dossierCode,
        promotionCode,
        responsibleTertiaryId,
        provinceId,
        municipalityId,
        registralFarm,
        withProduct,
        isValidToFormProduct,
        sarebPromotionID,
        propertyCode,
        skip,
        take,
        orderField,
        descField,
      } = Object.fromEntries(searchParams);
      let tmpFilters = { ...filters };
      if (id !== undefined && id !== null) {
        tmpFilters.id = id;
      }
      if (!!code) {
        tmpFilters.code = code;
      }
      if (!!assetType) {
        tmpFilters.assetType = parseInt(assetType);
      }
      if (!!businessLine) {
        tmpFilters.businessLine = parseInt(businessLine);
      }
      if (isPublishable !== undefined && isPublishable !== null) {
        tmpFilters.isPublishable = isPublishable;
      }
      if (status !== undefined && status !== null) {
        tmpFilters.status = status;
      }
      if (dossierCode !== undefined && dossierCode !== null) {
        tmpFilters.dossierCode = dossierCode;
      }
      if (promotionCode !== undefined && promotionCode !== null) {
        tmpFilters.promotionCode = promotionCode;
      }
      if (
        responsibleTertiaryId !== undefined &&
        responsibleTertiaryId !== null
      ) {
        tmpFilters.responsibleTertiaryId = responsibleTertiaryId;
      }
      if (provinceId !== undefined && provinceId !== null) {
        tmpFilters.provinceId = provinceId;
        listas.appMunicipalitiesAux = appLists.appMunicipalities.filter(
          (e) => e.provinceId === provinceId
        );
        dispatch({
          type: "UPDATE_APP_LIST",
          payload: listas,
        });
      }
      if (municipalityId !== undefined && municipalityId !== null) {
        tmpFilters.municipalityId = municipalityId;
      }
      if (registralFarm !== undefined && registralFarm !== null) {
        tmpFilters.registralFarm = registralFarm;
      }
      if (withProduct !== undefined && withProduct !== null) {
        tmpFilters.withProduct = withProduct;
      }
      if (isValidToFormProduct !== undefined && isValidToFormProduct !== null) {
        tmpFilters.isValidToFormProduct = isValidToFormProduct;
      }
      if (sarebPromotionID !== undefined && sarebPromotionID !== null) {
        tmpFilters.sarebPromotionID = sarebPromotionID;
      }
      if (propertyCode !== undefined && propertyCode !== null) {
        tmpFilters.propertyCode = propertyCode;
      }
      setFilters(tmpFilters);
      if (
        skip !== undefined &&
        skip !== null &&
        take !== undefined &&
        take !== null
      ) {
        setPage(Number(skip) + 1);
        setRegsPage(Number(take));
        if (orderField !== null && orderField !== undefined) {
          setOrder(orderField);
          if (descField !== null && descField !== undefined) {
            setOrderDesc(!Boolean(descField));
          }
        }
        busquedaActivos();
      }
    }
    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeNumericals = (prop, value, maxLength) => {
    let tmpFilters = { ...filters };

    if (value === "") {
      delete tmpFilters[prop];
    }

    const regex = /[^0-9]/g;
    const newValue = value.replace(regex, "");
    if (newValue !== undefined && newValue !== null && newValue !== "") {
      if (newValue.length <= maxLength) {
        tmpFilters[prop] = parseInt(newValue);
      } else {
        const fixLengthValue = newValue.slice(0, maxLength);
        tmpFilters[prop] = parseInt(fixLengthValue);
        toast.error("El máximo admitido es " + maxLength + " dígitos");
      }
    }

    setFilters(tmpFilters);
  };

  const handleChange = (prop, value) => {
    let tmpFilters = { ...filters };
    if (
      value !== "Tipo de Activo" &&
      value !== "Línea de Negocio" &&
      value !== "Publicado: Todos" &&
      value !== "Incidentado: Todos" &&
      value !== "Responsable" &&
      value !== "Municipio" &&
      value !== "Activos: Todos" &&
      value !== "Apto Crear Producto: Todos"
    ) {
      if (prop === "assetType" || prop === "businessLine") {
        value = parseInt(value);
      }
      if (
        (prop !== "dossierCode" &&
          prop !== "promotionCode" &&
          prop !== "propertyCode" &&
          prop !== "registralFarm" &&
          prop !== "sarebGroupingCode" &&
          prop !== "sarebPromotionID" &&
          value < 1) ||
        value === ""
      ) {
        delete tmpFilters[prop];
      } else {
        tmpFilters[prop] = value;
      }
    } else {
      if (tmpFilters[prop]) delete tmpFilters[prop];
    }
    setFilters(tmpFilters);
  };

  const handleChangeProvince = (prop, value) => {
    const tmpFilters = { ...filters };
    const tmpLists = { ...appLists };
    if (value !== "Provincia") {
      tmpFilters[prop] = value;
      delete tmpFilters.municipalityId;
      tmpLists.appMunicipalitiesAux = tmpLists.appMunicipalities.filter(
        (x) => x.provinceId === value
      );
      dispatch({
        type: "UPDATE_APP_LIST",
        payload: tmpLists,
      });
    } else {
      if (tmpFilters[prop]) delete tmpFilters[prop];
      delete tmpFilters.municipalityId;
      tmpLists.appMunicipalitiesAux = tmpLists.appMunicipalities;
      dispatch({
        type: "UPDATE_APP_LIST",
        payload: tmpLists,
      });
    }
    setFilters(tmpFilters);
  };

  const busquedaActivos = () => {
    if (page > 0) {
      let data = {};
      let filtersAux = {};
      if (filters.code !== null && filters.code !== "")
        filtersAux.code = filters.code;
      if (filters.prinexId !== null && filters.prinexId !== "")
        filtersAux.prinexId = filters.prinexId;
      if (!!filters.code) filtersAux.code = filters.code;
      if (filters.assetType !== null && filters.assetType !== 0)
        filtersAux.assetType = filters.assetType;
      if (filters.businessLine !== null && filters.businessLine !== 0)
        filtersAux.businessLine = filters.businessLine;
      if (
        filters.isPublishable !== undefined &&
        filters.isPublishable !== null &&
        filters.isPublishable !== 0
      ) {
        filtersAux.isPublishable = filters.isPublishable === "true";
      }
      if (filters.status !== null && filters.status !== 0)
        filtersAux.status = filters.status;
      if (filters.dossierCode !== null && filters.dossierCode !== "")
        filtersAux.dossierCode = filters.dossierCode;
      if (filters.promotionCode !== null && filters.promotionCode !== "")
        filtersAux.promotionCode = filters.promotionCode;
      if (
        filters.responsibleTertiaryId !== null &&
        filters.responsibleTertiaryId !== 0
      )
        filtersAux.responsibleTertiaryId = filters.responsibleTertiaryId;
      if (filters.provinceId !== null && filters.provinceId !== 0)
        filtersAux.provinceId = filters.provinceId;
      if (filters.municipalityId !== null && filters.municipalityId !== 0)
        filtersAux.municipalityId = filters.municipalityId;
      if (filters.registralFarm !== null && filters.registralFarm !== "")
        filtersAux.registralFarm = filters.registralFarm;
      if (
        filters.withProduct !== undefined &&
        filters.withProduct !== null &&
        filters.withProduct !== 0
      ) {
        filtersAux.withProduct = filters.withProduct === "true";
      }
      if (
        filters.isValidToFormProduct !== undefined &&
        filters.isValidToFormProduct !== null &&
        filters.isValidToFormProduct !== 0
      ) {
        filtersAux.isValidToFormProduct =
          filters.isValidToFormProduct === "true";
      }
      if (filters.sarebPromotionID !== null && filters.sarebPromotionID !== "")
        filtersAux.sarebPromotionID = filters.sarebPromotionID;
      if (
        filters.sarebGroupingCode !== null &&
        filters.sarebGroupingCode !== ""
      )
        filtersAux.sarebGroupingCode = filters.sarebGroupingCode;
      if (filters.propertyCode !== null && filters.propertyCode !== "")
        filtersAux.propertyCode = filters.propertyCode;
      data.page = page;
      data.regsPage = regsPage;
      data.filters = filtersAux;
      let tmpFilters = { ...filters };
      if (!!!tmpFilters.code) delete tmpFilters.code;
      if (order !== null && order !== "") {
        data.orderBy = order;
        data.isAsc = orderDesc;
        tmpFilters.orderField = order;
        tmpFilters.descField = !orderDesc;
      } else {
        data.orderBy = "id";
        data.isAsc = true;
      }

      tmpFilters.skip = page - 1;
      tmpFilters.take = regsPage;
      let newUrl = "/search-asset?";
      navigate(newUrl + new URLSearchParams(tmpFilters).toString());
      showLoader();
      API.busquedaActivos(data)
        .then((res) => {
          setDataActivosSinProducto(
            res.data.collection.filter((s) => s.productId === null)
          );
          setDataActivosConProducto(
            res.data.collection.filter((s) => s.productId !== null)
          );
          setTotalPages(res.data.maxPages);
          setNumRegisters(res.data.numRegisters);
        })
        .catch((error) => API.DEVELOP && console.log(error))
        .finally(() => hideLoader());
    }
  };

  const search = () => {
    busquedaActivos();
  };

  const columns = useMemo(
    () =>
      PERMISOS_POR_ROL.Buscador.SuperAdmin
        ? [
            {
              label: "",
              id: "empty",
              disableSortBy: true,
            },
            {
              label: "",
              id: "checkValidate",
              disableSortBy: true,
            },
            {
              label: "",
              id: "detail",
              disableSortBy: true,
            },
            {
              label: "Código Producto",
              id: "code",
            },
            {
              label: "Id Prínex",
              id: "prinexId",
              disableSortBy: true,
            },
            {
              label: "Id Promoción Prinex",
              id: "promotionCode",
              disableSortBy: true,
            },
            {
              label: "Nombre",
              id: "name",
              maxWidth: 70,
              minWidth: 50,
              width: 60,
              disableSortBy: true,
            },
            {
              label: "Expediente",
              id: "dossierCode",
              disableSortBy: true,
            },
            {
              label: "Estado Comercial",
              id: "commercialStatus",
              disableSortBy: true,
            },
            {
              label: "L. de Negocio",
              id: "businessLine",
              disableSortBy: true,
            },
            {
              label: "Tipo",
              id: "assetType",
              disableSortBy: true,
            },
            {
              label: "Descripción",
              id: "description",
              disableSortBy: true,
            },
          ]
        : [
            {
              label: "",
              id: "empty",
              disableSortBy: true,
            },
            {
              label: "",
              id: "checkValidate",
              disableSortBy: true,
            },
            {
              label: "",
              id: "detail",
              disableSortBy: true,
            },
            {
              label: "Código Producto",
              id: "code",
            },
            {
              label: "Id Prínex",
              id: "prinexId",
              disableSortBy: true,
            },
            {
              label: "Id Promoción Prinex",
              id: "promotionCode",
              disableSortBy: true,
            },
            {
              label: "Nombre",
              id: "name",
              disableSortBy: true,
            },
            {
              label: "Expediente",
              id: "dossierCode",
              disableSortBy: true,
            },
            {
              label: "L. de Negocio",
              id: "businessLine",
              disableSortBy: true,
            },
            {
              label: "Tipo",
              id: "assetType",
              disableSortBy: true,
            },
            {
              label: "Descripción",
              id: "description",
              disableSortBy: true,
            },
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const goToCreateProduct = () => {
    navigate("/create-product", {
      state: {
        activos: selectedIds,
      },
    });
  };

  const validateCreateProduct = () => {
    const selectedIdsNumbers = selectedIds.map((str) => parseInt(str, 10));
    let data = {
      iDs: selectedIdsNumbers,
    };
    showLoader();
    API.validarCreacionProductos(data)
      .then((res) => {
        setDataValidacionCreacionProducto(res.data);
        setModalValidarCreacionProducto(true);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => hideLoader());
  };

  const cleanFilters = () => {
    setFilters({});
    navigate("/search-asset");
  };

  const showFilters = (showing) => {
    if (showing) {
      document.getElementsByClassName("filters")[0].style.display = "none";
      setShowingFilters(false);
    } else {
      document.getElementsByClassName("filters")[0].style.display = "block";
      setShowingFilters(true);
    }
  };

  const navigateTo = () => {
    if (currentCartera === "CAR-PARTI") {
      navigate("/particular-asset");
    } else {
      navigate("/create-terce-asset");
    }
  };

  return (
    <Fragment>
      {loader}
      {modalValidarCreacionProducto && (
        <ModalValidarCreacionProducto
          message="Validar activos para formar producto"
          cancel={() => setModalValidarCreacionProducto(false)}
          data={dataValidacionCreacionProducto}
          selectedIds={selectedIds}
        />
      )}
      <S.Sidebar className="filters">
        <FilterButtons
          onSearch={() => {
            setPage(1);
            search();
          }}
          onClean={cleanFilters}
        />
        <Form.Group className="mb-2">
          <Form.Control
            type="text"
            autoComplete="off"
            id="code"
            placeholder="Código de Producto"
            onChange={(e) => handleChangeNumericals("code", e.target.value, 10)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            value={filters.code || filters.code >= 0 ? filters.code : ""}
            style={{ height: "2rem", fontSize: "0.9rem" }}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Control
            type="text"
            autoComplete="off"
            id="dossierCode"
            placeholder="Expediente"
            maxLength="20"
            onChange={(e) => handleChange("dossierCode", e.target.value, 10)}
            value={filters.dossierCode ? filters.dossierCode : ""}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Control
            type="text"
            id="prinexId"
            placeholder="Id Prínex"
            onChange={(e) =>
              handleChangeNumericals("prinexId", e.target.value, 10)
            }
            value={
              filters.prinexId || filters.prinexId >= 0 ? filters.prinexId : ""
            }
            // min={0}
            // max={9999999999}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Select
            id="assetType"
            onChange={(e) => handleChange("assetType", e.target.value)}
            value={filters.assetType ? filters.assetType : 0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
          >
            {/* <option>Tipo de Activo</option> */}
            {appLists["assetType"] !== undefined &&
              appLists["assetType"].map((e, i) => (
                <option value={e.Id} key={i}>
                  {!!e?.translation ? e.translation : "Tipo de Activo"}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Select
            id="businessLine"
            onChange={(e) => handleChange("businessLine", e.target.value)}
            value={filters.businessLine ? filters.businessLine : 0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
          >
            <option>Línea de Negocio</option>
            {appLists["businessLines"] !== undefined &&
              appLists["businessLines"].map((e, i) => (
                <option value={e.Id} key={i}>
                  {e.translation}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Select
            id="isPublishable"
            onChange={(e) => handleChange("isPublishable", e.target.value)}
            value={filters.isPublishable ? filters.isPublishable : 0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
          >
            <option>Publicado: Todos</option>
            <option value="true">Publicado</option>
            <option value="false">No Publicado</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Control
            type="text"
            id="promotionCode"
            placeholder="ID Promoción Prínex"
            maxLength="20"
            onChange={(e) => handleChange("promotionCode", e.target.value)}
            value={filters.promotionCode ? filters.promotionCode : ""}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Select
            id="provinceId"
            onChange={(e) => handleChangeProvince("provinceId", e.target.value)}
            value={filters.provinceId ? filters.provinceId : 0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
          >
            <option>Provincia</option>
            {appLists["appProvinces"] !== undefined &&
              appLists["appProvinces"].map((e, i) => (
                <option value={e.id} key={i}>
                  {e.name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        {filters.provinceId && (
          <Form.Group className="mb-2">
            <Form.Select
              id="municipalityId"
              onChange={(e) => handleChange("municipalityId", e.target.value)}
              value={filters.municipalityId ? filters.municipalityId : 0}
              style={{ height: "2rem", fontSize: "0.9rem" }}
            >
              <option>Municipio</option>
              {appLists["appMunicipalitiesAux"] !== undefined &&
                appLists["appMunicipalitiesAux"].map((e, i) => (
                  <option value={e.id} key={i}>
                    {e.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        )}
        <Form.Group className="mb-2">
          <Form.Control
            type="text"
            id="registralFarm"
            placeholder="Finca Registral"
            // maxLength="18"
            onChange={(e) => handleChange("registralFarm", e.target.value)}
            value={filters.registralFarm ? filters.registralFarm : ""}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Select
            id="withProduct"
            onChange={(e) => handleChange("withProduct", e.target.value)}
            value={filters.withProduct ? filters.withProduct : 0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
          >
            <option>Activos: Todos</option>
            <option value="false">Sin Productos</option>
            <option value="true">Con Productos</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Select
            id="isValidToFormProduct"
            onChange={(e) =>
              handleChange("isValidToFormProduct", e.target.value)
            }
            value={
              filters.isValidToFormProduct ? filters.isValidToFormProduct : 0
            }
            style={{ height: "2rem", fontSize: "0.9rem" }}
          >
            <option>Apto Crear Producto: Todos</option>
            <option value="true">Apto</option>
            <option value="false">No Apto</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Control
            type="text"
            id="propertyCode"
            placeholder="Inmueble"
            maxLength="50"
            onChange={(e) => handleChange("propertyCode", e.target.value)}
            value={filters.propertyCode ? filters.propertyCode : ""}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        {user.currentPortfolioID === portfolios.name.sareb && (
          <Fragment>
            <Form.Group className="mb-2">
              <Form.Control
                type="text"
                id="sarebPromotionID"
                placeholder="ID Promoción Sareb"
                onChange={(e) =>
                  handleChange("sarebPromotionID", e.target.value)
                }
                value={filters.sarebPromotionID ? filters.sarebPromotionID : ""}
                style={{ height: "2rem", fontSize: "0.9rem" }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setPage(1);
                    search();
                  }
                }}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Control
                type="text"
                id="sarebGroupingCode"
                placeholder="Código de agrupación Sareb"
                maxLength="18"
                onChange={(e) =>
                  handleChange("sarebGroupingCode", e.target.value)
                }
                value={
                  filters.sarebGroupingCode ? filters.sarebGroupingCode : ""
                }
                style={{ height: "2rem", fontSize: "0.9rem" }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setPage(1);
                    search();
                  }
                }}
                autoComplete="off"
              />
            </Form.Group>
          </Fragment>
        )}
      </S.Sidebar>
      <S.Content>
        <Card>
          <Card.Header
            style={{
              paddingLeft: "2rem",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <S.CardHeaderContainer>
              <PageTitle title="Buscador" />
              <div>
                {showingFilters && (
                  <Button
                    className="openFilters"
                    onClick={() => showFilters(showingFilters)}
                    variant="danger"
                  >
                    Esconder Busqueda
                  </Button>
                )}
                {!showingFilters && (
                  <Button
                    className="openFilters"
                    onClick={() => showFilters(showingFilters)}
                    variant="danger"
                  >
                    Desplegar Busqueda
                  </Button>
                )}
                <Button
                  variant="success"
                  style={{ marginRight: 16 }}
                  onClick={() => navigateTo()}
                  hidden={!PERMISOS_POR_ROL.Buscador.CrearActivo}
                >
                  <span>
                    <FontAwesomeIcon icon={faPlus} size="lg" fixedWidth />
                  </span>
                  Crear Activo
                </Button>

                <Button
                  variant="success"
                  onClick={goToCreateProduct}
                  disabled={selectedIds.length === 0}
                  hidden={!PERMISOS_POR_ROL.Buscador.CrearProducto}
                >
                  <span>
                    <FontAwesomeIcon icon={faPlus} size="lg" fixedWidth />
                  </span>
                  Crear Producto
                </Button>
                <Button
                  variant="success"
                  onClick={() => validateCreateProduct()}
                  disabled={selectedIds.length === 0}
                  style={{ marginLeft: "1rem" }}
                  hidden={!PERMISOS_POR_ROL.Buscador.ValidarCreacionProducto}
                >
                  <span>
                    <FontAwesomeIcon icon={faTasks} size="lg" fixedWidth />
                  </span>
                  Validar creación de Producto
                </Button>
                <Button
                  variant="success"
                  onClick={() => navigate("/search-deleted-products")}
                  hidden={!PERMISOS_POR_ROL.Buscador.BuscarProductosBorrados}
                  style={{ marginLeft: "1rem" }}
                >
                  <span>
                    <FontAwesomeIcon icon={faTasks} size="lg" fixedWidth />
                  </span>
                  Buscar Productos Borrados
                </Button>
              </div>
            </S.CardHeaderContainer>
          </Card.Header>
          <Card.Body>
            <TableBuscador
              columns={columns}
              dataActivosSinProducto={dataActivosSinProducto}
              dataActivosConProducto={dataActivosConProducto}
              pageTable={page}
              regsPage={regsPage}
              setPage={setPage}
              setRegsPage={setRegsPage}
              search={search}
              totalPages={totalPages}
              numRegisters={numRegisters}
              order={order}
              setOrder={setOrder}
              orderDesc={orderDesc}
              setOrderDesc={setOrderDesc}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
          </Card.Body>
        </Card>
      </S.Content>
    </Fragment>
  );
};
