import React, { useState, useEffect, Fragment, useRef } from "react";
import { TableFrontEnd } from "../../components/TableFrontEnd";
import { Dialog } from "../../components/Dialog";
import { ModalCreateDelegation } from "../../components/ModalCreateDelegation";
import { ModalEditDelegation } from "../../components/ModalEditDelegation";
import { useLoader } from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faPlus,
  faTrashAlt,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Button, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as S from "./styles";
import * as API from "../../api";
import {
  METADATA_EDICION_DELEGACION,
  METADATA_CREACION_DELEGACION,
  METADATA_AUDIT_DELEGACION,
  METADATA_RANGE_PRICE,
  METADATA_RANGE_PRICE_EDIT,
} from "./constants";
import { ModalAudit } from "../../components/ModalAudit";
import { useDataContext } from "../../context/DataState";
import { CardHeaderContainer } from "../Buscador/styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import usePermition from "../../constants/usePermition";
import { hiddenFalse, hiddenTrue } from "../../constants";

export const Delegaciones = () => {
  const [values, setValues] = useState({ default: true });
  const [valuesAudit, setValuesAudit] = useState({});
  const [dialogEditarDelegacionVisible, setDialogEditarDelegacionVisible] =
    useState(false);
  const [dialogCrearDelegacionVisible, setDialogCrearDelegacionVisible] =
    useState(false);
  const [dialogBorrarDelegacionVisible, setDialogBorrarDelegacionVisible] =
    useState(false);
  const [dialogAuditDelegacionVisible, setDialogAuditDelegacionVisible] =
    useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [valueDelete, setValueDelete] = useState("");
  const [metadataCreate, setMetadataCreate] = useState(
    METADATA_CREACION_DELEGACION
  );
  const [metadataRangePrice, setMetadataRangePrice] = useState([
    METADATA_RANGE_PRICE,
  ]);
  const [metadataRangePriceEdit, setMetadataRangePriceEdit] = useState([
    METADATA_RANGE_PRICE_EDIT,
  ]);
  const [valuesRangePrice, setValuesRangePrice] = useState([{}]);
  const [valuesRangePriceEdit, setValuesRangePriceEdit] = useState([{}]);
  const [metadataEdit, setMetadataEdit] = useState(METADATA_EDICION_DELEGACION);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const {
    dispatch,
    state: { appLists, user },
  } = useDataContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { PERMISOS_POR_ROL } = usePermition({ user });
  const initialFormRefDataPriceRange = useRef();
  const formDataRangePriceRefs = useRef([initialFormRefDataPriceRange]);

  useEffect(() => {
    document.title = "EC-Light-Delegaciones";
    if (!PERMISOS_POR_ROL.Delegaciones.Acceso) {
      navigate("/");
    }
    if (!data.length) {
      obtenerDelegaciones();
      showLoader();
      API.obtenerUsuariosList()
        .then((res) => {
          setUsers(res.data);
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const obtenerDelegaciones = () => {
    showLoader();
    API.obtenerDelegaciones()
      .then((resDelegacion) => {
        showLoader();
        API.obtenerTerritoriales()
          .then((resTerritorial) => {
            let dataAuxList = [];
            let listas = appLists;
            if (listas !== null) {
              listas["territorials"] = resTerritorial.data;
              listas["territorialsAux"] = resTerritorial.data;
              const priceRangeFromList = listas["delegationPriceRangesFrom"];
              const priceRangeToList = listas["delegationPriceRangesTo"];
              const newListFrom = priceRangeFromList.map((item) => ({
                Id: item?.Id,
                name: item?.translation,
              }));
              const newListTo = priceRangeToList.map((item) => ({
                Id: item?.Id,
                name: item?.translation,
              }));
              listas["delegationPriceRangesFrom_"] = newListFrom;
              listas["delegationPriceRangesTo_"] = newListTo;
              dispatch({
                type: "UPDATE_APP_LIST",
                payload: listas,
              });
              resDelegacion.data.forEach((e) => {
                const dataAux = {};
                dataAux.id = e.id;
                dataAux.crmId = e.crmId;
                dataAux.name = e.name;
                dataAux.numAssets = e.numAssets;
                dataAux.numMunicipalities = e.numMunicipalities;
                dataAux.numUsers = e.numUsers;
                dataAux.readableConfig = e.readableConfig;
                const businessLine = listas.businessLines.find(
                  (s) => s.Id === e.businessLine
                );
                if (businessLine !== null) {
                  dataAux.businessLine = businessLine.Id;
                  dataAux.businessLineName = businessLine.translation;
                }
                const portfolio = listas.appPortfolios.find(
                  (s) => s.id === e.portfolioId
                );
                if (portfolio !== null) {
                  dataAux.portfolioId = portfolio.id;
                  dataAux.portfolioName = portfolio.name;
                }
                const territorial = resTerritorial.data.find(
                  (s) => s.id === e.territorialId
                );
                if (territorial !== null) {
                  dataAux.territorialId = territorial.id;
                  dataAux.territorialName = territorial.name;
                }
                dataAux.creatorId = e.creatorId;
                if (e.creationTime !== null)
                  dataAux.creationTime = formatDate(e.creationTime);
                dataAux.lastModifierId = e.lastModifierId;
                if (e.lastModificationTime !== null)
                  dataAux.lastModificationTime = formatDate(
                    e.lastModificationTime
                  );
                dataAuxList.push(dataAux);
              });
            }
            setData(dataAuxList);
            setFilteredData(dataAuxList);
          })
          .catch((error) => {
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const updateHiddenProperty = (value, index) => {
    const desdeLista = ["Sea mayor o igual que", "Sea mayor que", "-Infinito"];
    const hastaLista = ["Sea menor o igual que", "Sea menor que", "+Infinito"];

    if (!desdeLista.includes(value) && !hastaLista.includes(value)) return;

    const keyObject = desdeLista.includes(value)
      ? "desdeNumber"
      : "hastaNumber";
    const updatedMetadata = [...metadataRangePrice[index]];

    const indexInputToChange = updatedMetadata.findIndex(
      (item) => item.name === keyObject
    );

    updatedMetadata[indexInputToChange] = {
      ...updatedMetadata[indexInputToChange],
      hidden:
        value === "-Infinito" || value === "+Infinito"
          ? hiddenTrue
          : hiddenFalse,
    };
    setMetadataRangePrice((prevState) => {
      return prevState.map((item, idx) => {
        if (idx === index) {
          return updatedMetadata;
        }
        return item;
      });
    });
  };

  const convertToOriginalFormat = (priceRanges) => {
    return priceRanges.map((range) => {
      let desdeListId = null;
      if (range.from.closeLimit === true) {
        desdeListId = "Sea mayor o igual que";
      } else if (range.from.closeLimit === false) {
        desdeListId = "Sea mayor que";
      } else if (range.from.closeLimit === null) {
        desdeListId = "-Infinito";
      }

      let hastaListId = null;
      if (range.to.closeLimit === true) {
        hastaListId = "Sea menor o igual que";
      } else if (range.to.closeLimit === false) {
        hastaListId = "Sea menor que";
      } else if (range.to.closeLimit === null) {
        hastaListId = "+Infinito";
      }

      return {
        desdeListId: desdeListId,
        desdeNumber: range.from.price,
        hastaListId: hastaListId,
        hastaNumber: range.to.price,
      };
    });
  };

  const obtenerDataDelegacion = (id) => {
    showLoader();
    API.obtenerDelegacion(id)
      .then((res) => {
        setValues(res?.data);
        if (!res?.data?.isDefault) {
          const priceRangesEdit = convertToOriginalFormat(
            res?.data?.config?.priceRanges
          );
          setValuesRangePriceEdit(priceRangesEdit);
        }
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  /**
   * manejar los cambios de modal de crear
   * @param {*} dataValues
   */
  const handleDataCreateChange = (dataValues) => {
    let MD = metadataCreate;

    let listas = { ...appLists };
    if (!!dataValues.portfolioId) {
      listas.territorialsAux = listas.territorials.filter(
        (e) => e.portfolioId === dataValues.portfolioId
      );
    } else {
      listas.territorialsAux = listas.territorials;
    }
    dispatch({
      type: "UPDATE_APP_LIST",
      payload: listas,
    });

    if (dataValues) {
      setMetadataCreate(MD);
    }
    setValues(dataValues);
  };

  const formatDate = (dateString) => {
    var date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() + -offset * 60000);
    let d = date;
    d = [
      "0" + d.getDate(),
      "0" + (d.getMonth() + 1),
      "" + d.getFullYear(),
      "0" + d.getHours(),
      "0" + d.getMinutes(),
      "0" + d.getSeconds(),
    ].map((component) => {
      if (component.length < 4) {
        return component.slice(-2);
      } else {
        return component;
      }
    });

    return d.slice(0, 3).join("/") + " " + d.slice(3).join(":");
  };

  const FilterForm = ({ column }) => {
    const { filterValue, setFilter } = column;

    useEffect(() => {
      const value = searchParams.get(
        "filter" + column.Header.split(" ").join("").split(".").join("")
      );
      if (value) {
        setFilter(value);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Form>
        <Form.Group className="mb-2">
          <span>
            <input
              id={
                "filter" + column.Header.split(" ").join("").split(".").join("")
              }
              className="form-control"
              style={{ width: "60%", marginTop: "0.25rem", padding: "0" }}
              value={filterValue || ""}
              onClick={(e) => {
                if (!e) e = window.event;
                e.stopPropagation();
              }}
              onChange={(e) => setFilter(e.target.value)}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              autoComplete="off"
            />
          </span>
        </Form.Group>
      </Form>
    );
  };

  const handleNavigateDetail = (id) => {
    const valueDelegationId =
      document.getElementById("filterIdDelegación").value;
    const valueDelegation = document.getElementById("filterDelegación").value;
    const valueLNegocio = document.getElementById("filterLNegocio").value;
    const valueTerritorial = document.getElementById("filterTerritorial").value;
    const valueCartera = document.getElementById("filterCartera").value;

    const url = `/view-delegations?${
      valueDelegationId !== "" ? `filterIdDelegación=${valueDelegationId}` : ""
    }${
      valueDelegation !== ""
        ? valueDelegationId !== ""
          ? `&filterDelegación=${valueDelegation}`
          : `filterDelegación=${valueDelegation}`
        : ""
    }${
      valueLNegocio !== ""
        ? valueDelegationId !== "" || valueDelegation !== ""
          ? `&filterLNegocio=${valueLNegocio}`
          : `filterLNegocio=${valueLNegocio}`
        : ""
    }${
      valueTerritorial !== ""
        ? valueDelegationId !== "" ||
          valueDelegation !== "" ||
          valueLNegocio !== ""
        : ""
    }${
      valueCartera !== ""
        ? `${
            valueDelegationId !== "" ||
            valueDelegation !== "" ||
            valueLNegocio !== "" ||
            valueTerritorial !== ""
              ? `&filterCartera=${valueCartera}`
              : `filterCartera=${valueCartera}`
          }`
        : ""
    }`;

    navigate(url);

    navigate("/delegation-detail/" + id);
  };

  const defaultColumn = {
    Filter: FilterForm,
  };

  const defaultSort = [
    {
      id: "id",
      desc: false,
    },
  ];

  const columns = [
    {
      Header: "",
      accessor: "detail",
      disableFilters: true,
      disableSortBy: true,
      width: 60,
      Cell: ({ cell }) => (
        <Fragment>
          <FontAwesomeIcon
            onClick={() => handleNavigateDetail(cell.row.original.id)}
            style={{ color: "black", cursor: "pointer" }}
            icon={faEye}
            size="lg"
            fixedWidth
          />
          <button
            style={{ marginLeft: "0.5rem" }}
            className="tableButton"
            onClick={() => showModalAudit(cell)}
            hidden={!PERMISOS_POR_ROL.Delegaciones.Auditoria}
          >
            <span title="Auditoría">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faPrint}
                size="lg"
                fixedWidth
              />
            </span>
          </button>
        </Fragment>
      ),
    },
    {
      Header: "",
      accessor: "edit",
      disableFilters: true,
      disableSortBy: true,
      width: 40,
      Cell: ({ cell }) => (
        <button
          className="tableButton"
          onClick={() => showModalEdit(cell, appLists)}
        >
          <span title="Editar">
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={faEdit}
              size="lg"
              fixedWidth
            />
          </span>
        </button>
      ),
    },
    {
      Header: "Id Delegación",
      accessor: "id",
      Filter: FilterForm,
      maxWidth: 100,
      Cell: ({ cell }) => (
        <span>{data.find((e) => e.id === cell.row.original.id)?.id}</span>
      ),
    },
    {
      Header: "Delegación",
      accessor: "name",
      Cell: ({ cell }) => (
        <span>{data.find((e) => e.id === cell.row.original.id)?.name}</span>
      ),
    },
    {
      Header: "Crm Id",
      accessor: "crmId",
      Cell: ({ cell }) => (
        <span>{data.find((e) => e.id === cell.row.original.id)?.crmId}</span>
      ),
    },
    {
      Header: "L. Negocio",
      accessor: "businessLine",
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id)?.businessLine}
        </span>
      ),
    },
    {
      Header: "L. Negocio",
      accessor: "businessLineName",
      maxWidth: 100,
      Filter: FilterForm,
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id)?.businessLineName}
        </span>
      ),
    },
    {
      Header: "Territorial",
      accessor: "territorialId",
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id)?.territorialId}
        </span>
      ),
    },
    {
      Header: "Territorial",
      accessor: "territorialName",
      Filter: FilterForm,
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id)?.territorialName}
        </span>
      ),
    },
    {
      Header: "Cartera",
      accessor: "portfolioId",
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id)?.portfolioId}
        </span>
      ),
    },
    {
      Header: "Cartera",
      accessor: "portfolioName",
      maxWidth: 100,
      Filter: FilterForm,
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id)?.portfolioName}
        </span>
      ),
    },
    {
      Header: "Configuración",
      accessor: "readableConfig",
      Filter: FilterForm,
      disableFilters: true,
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id)?.readableConfig}
        </span>
      ),
    },
    {
      Header: "Nº usuarios",
      accessor: "numUsers",
      Filter: FilterForm,
      disableFilters: true,
      Cell: ({ cell }) => (
        <span>{data.find((e) => e.id === cell.row.original.id)?.numUsers}</span>
      ),
    },
    {
      Header: "Nº activos",
      accessor: "numAssets",
      Filter: FilterForm,
      disableFilters: true,
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id)?.numAssets}
        </span>
      ),
    },
    {
      Header: "Nº municipios",
      accessor: "numMunicipalities",
      Filter: FilterForm,
      disableFilters: true,
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id)?.numMunicipalities}
        </span>
      ),
    },
    {
      Header: "Usuario Creación",
      accessor: "creatorId",
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id)?.creatorId}
        </span>
      ),
    },
    {
      Header: "Fecha Creación",
      accessor: "creationTime",
      disableFilters: true,
      Cell: ({ cell }) => (
        <span>
          {formatDate(
            data.find((e) => e.id === cell.row.original.id)?.creationTime
          )}
        </span>
      ),
    },
    {
      Header: "Usuario Última Modificación",
      accessor: "lastModifierId",
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id)?.lastModifierId}
        </span>
      ),
    },
    {
      Header: "Fecha Última Modificación",
      accessor: "lastModificationTime",
      Cell: ({ cell }) => (
        <span>
          {
            data.find((e) => e.id === cell.row.original.id)
              ?.lastModificationTime
          }
        </span>
      ),
    },
    {
      Header: "",
      accessor: "delete",
      disableFilters: true,
      disableSortBy: true,
      with: 40,
      Cell: ({ cell }) => (
        <button
          className="tableButton"
          onClick={() => showModalDelete(cell.row.original.id)}
        >
          <span title="Borrar">
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={faTrashAlt}
              size="lg"
              fixedWidth
            />
          </span>
        </button>
      ),
    },
  ];

  /**
   * Abrir el model de crear delegacion y reiniciar los valores de la data del modal
   */
  const showModalCreate = () => {
    let listas = { ...appLists };
    listas.territorialsAux = listas.territorials;
    dispatch({
      type: "UPDATE_APP_LIST",
      payload: listas,
    });
    setValues({ default: true });
    setValuesRangePrice([{}]);
    setMetadataCreate(METADATA_CREACION_DELEGACION);
    setMetadataRangePrice([METADATA_RANGE_PRICE]);
    setDialogCrearDelegacionVisible(true);
  };

  const showModalEdit = (cell) => {
    const idDelegation = cell?.row?.original?.id;
    let auxMD = [...metadataEdit];
    setMetadataEdit(auxMD);
    obtenerDataDelegacion(idDelegation);
    setOpenModalEdit(true);
  };

  useEffect(() => {
    if (valuesRangePriceEdit.length > 1) {
      const newMetadata = Array(valuesRangePriceEdit.length).fill(
        METADATA_RANGE_PRICE_EDIT
      );
      setMetadataRangePriceEdit([...newMetadata]);
    }
  }, [valuesRangePriceEdit]);

  useEffect(() => {
    if (
      metadataRangePriceEdit.length === valuesRangePriceEdit.length &&
      openModalEdit
    ) {
      setDialogEditarDelegacionVisible(true);
      setOpenModalEdit(false);
    }
  }, [metadataRangePriceEdit, openModalEdit, valuesRangePriceEdit]);

  const showModalDelete = (id) => {
    setValueDelete(id);
    setDialogBorrarDelegacionVisible(true);
  };

  const showModalAudit = (cell) => {
    let cellValues = { ...cell.row.values };
    const creator = users.find((s) => s.id === cellValues.creatorId);
    if (creator !== undefined) {
      cellValues.creatorId = creator.name;
    }
    const modifier = users.find((s) => s.id === cellValues.lastModifierId);
    if (modifier !== undefined) {
      cellValues.lastModifierId = modifier.name;
    }

    const valueDelegationId =
      document.getElementById("filterIdDelegación").value;
    const valueDelegation = document.getElementById("filterDelegación").value;
    const valueLNegocio = document.getElementById("filterLNegocio").value;
    const valueLPresupuestaria = document.getElementById(
      "filterLPresupuestaria"
    ).value;
    const valueTerritorial = document.getElementById("filterTerritorial").value;
    const valueCartera = document.getElementById("filterCartera").value;

    const url = `/view-delegations?${
      valueDelegationId !== "" ? `filterIdDelegación=${valueDelegationId}` : ""
    }${
      valueDelegation !== ""
        ? valueDelegationId !== ""
          ? `&filterDelegación=${valueDelegation}`
          : `filterDelegación=${valueDelegation}`
        : ""
    }${
      valueLNegocio !== ""
        ? valueDelegationId !== "" || valueDelegation !== ""
          ? `&filterLNegocio=${valueLNegocio}`
          : `filterLNegocio=${valueLNegocio}`
        : ""
    }${
      valueLPresupuestaria !== ""
        ? valueDelegationId !== "" ||
          valueDelegation !== "" ||
          valueLNegocio !== ""
          ? `&filterLPresupuestaria=${valueLPresupuestaria}`
          : `filterLPresupuestaria=${valueLPresupuestaria}`
        : ""
    }${
      valueTerritorial !== ""
        ? valueDelegationId !== "" ||
          valueDelegation !== "" ||
          valueLNegocio !== "" ||
          valueLPresupuestaria !== ""
          ? `&filterTerritorial=${valueTerritorial}`
          : `filterTerritorial=${valueTerritorial}`
        : ""
    }${
      valueCartera !== ""
        ? `${
            valueDelegationId !== "" ||
            valueDelegation !== "" ||
            valueLNegocio !== "" ||
            valueLPresupuestaria !== "" ||
            valueTerritorial !== ""
              ? `&filterCartera=${valueCartera}`
              : `filterCartera=${valueCartera}`
          }`
        : ""
    }`;

    navigate(url);

    setValuesAudit(cellValues);
    setDialogAuditDelegacionVisible(true);
  };

  const confirmEdit = (values) => {
    setDialogEditarDelegacionVisible(false);
    if (values["businessLine"])
      values["businessLine"] = Number(values["businessLine"]);
    showLoader();
    API.editarDelegacion(values)
      .then((res) => {
        obtenerDelegaciones();

        toast.success("Delegación editada correctamente");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const convertDataRangePrices = (data) => {
    return data.map((item) => {
      let fromCloseLimit = null;
      let fromPrice = null;

      if (item.desdeListId === "Sea mayor o igual que") {
        fromCloseLimit = true;
        fromPrice = parseFloat(item.desdeNumber);
      } else if (item.desdeListId === "Sea mayor que") {
        fromCloseLimit = false;
        fromPrice = parseFloat(item.desdeNumber);
      }

      let toCloseLimit = null;
      let toPrice = null;

      if (item.hastaListId === "Sea menor o igual que") {
        toCloseLimit = true;
        toPrice = parseFloat(item.hastaNumber);
      } else if (item.hastaListId === "Sea menor que") {
        toCloseLimit = false;
        toPrice = parseFloat(item.hastaNumber);
      }

      return {
        from: {
          closeLimit: fromCloseLimit,
          price: fromPrice,
        },
        to: {
          closeLimit: toCloseLimit,
          price: toPrice,
        },
      };
    });
  };

  const confirmCreate = (values) => {
    showLoader();
    setDialogCrearDelegacionVisible(false);

    const priceRanges = convertDataRangePrices(valuesRangePrice);

    const dataToSend = {
      name: values?.name,
      crmId: values?.crmId,
      portfolioId: values?.portfolioId,
      territorialId: values?.territorialId,
      businessLine: Number(values?.businessLine),
      config: values?.default ? null : { priceRanges: priceRanges },
    };

    API.crearDelegacion(dataToSend)
      .then((res) => {
        let dataAux = res.data;
        const portfolio = appLists["appPortfolios"].find(
          (s) => s.id === dataAux.portfolioId
        );
        if (portfolio) {
          dataAux.portfolioId = portfolio.id;
          dataAux.portfolioName = portfolio.name;
        }
        const businessLine = appLists.businessLines.find(
          (s) => s.Id === res.data.businessLine
        );
        if (businessLine !== null) {
          dataAux.businessLine = businessLine.Id;
          dataAux.businessLineName = businessLine.translation;
        }
        const territorial = appLists.territorialsAux.find(
          (s) => s.id === res.data.territorialId
        );
        if (territorial) {
          dataAux.territorialId = territorial.id;
          dataAux.territorialName = territorial.name;
        }
        if (res.data.creationTime !== null)
          dataAux.creationTime = formatDate(res.data.creationTime);
        dataAux.lastModifierId = res.data.lastModifierId;
        if (res.data.lastModificationTime !== null)
          dataAux.lastModificationTime = formatDate(
            res.data.lastModificationTime
          );
        dataAux.numAssets = 0;
        dataAux.numMunicipalities = 0;
        dataAux.numUsers = 0;

        setData([...data, dataAux]);
        setFilteredData([...filteredData, dataAux]);
        toast.success("Delegación creada correctamente");
        formDataRangePriceRefs.current = [initialFormRefDataPriceRange];
        setMetadataCreate(METADATA_CREACION_DELEGACION);
        setMetadataRangePrice([METADATA_RANGE_PRICE]);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const confirmDelete = (id) => {
    showLoader();
    setDialogBorrarDelegacionVisible(false);
    API.borrarDelegacion(id)
      .then((res) => {
        obtenerDelegaciones();

        toast.success("Delegación borrada correctamente");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const addNewRangePrice = () => {
    setValuesRangePrice([...valuesRangePrice, {}]);
    formDataRangePriceRefs.current.push(React.createRef());

    setMetadataRangePrice((prevState) => [...prevState, METADATA_RANGE_PRICE]);
  };

  return (
    <S.Container>
      {loader}
      {dialogEditarDelegacionVisible && (
        <ModalEditDelegation
          metadata={metadataEdit}
          message="Datos de Delegación"
          values={values}
          setValues={setValues}
          cancel={() => setDialogEditarDelegacionVisible(false)}
          confirm={confirmEdit}
          lists={appLists}
          setLists={appLists}
          metadata2={metadataRangePriceEdit}
          valuesRangePrice={valuesRangePriceEdit}
        />
      )}
      {dialogCrearDelegacionVisible && (
        <ModalCreateDelegation
          metadata={metadataCreate}
          message="Datos de Delegación"
          values={values}
          setValues={handleDataCreateChange}
          cancel={() => setDialogCrearDelegacionVisible(false)}
          confirm={confirmCreate}
          lists={appLists}
          setLists={appLists}
          metadata2={metadataRangePrice}
          valuesRangePrice={valuesRangePrice}
          setValuesRangePrice={setValuesRangePrice}
          formRangePriceRangeRef={formDataRangePriceRefs}
          onClickNewRange={addNewRangePrice}
          setMetadataRangePrice={setMetadataRangePrice}
          updateHiddenProperty={updateHiddenProperty}
        />
      )}
      {dialogBorrarDelegacionVisible && (
        <Dialog
          type="noFooter"
          message="¿Desea eliminar la delegación?"
          labelConfirm="Eliminar"
          confirm={() => confirmDelete(valueDelete)}
          cancel={() => setDialogBorrarDelegacionVisible(false)}
          value={valueDelete}
        />
      )}
      {dialogAuditDelegacionVisible && (
        <ModalAudit
          metadata={METADATA_AUDIT_DELEGACION}
          message="Auditoría"
          values={valuesAudit}
          cancel={() => setDialogAuditDelegacionVisible(false)}
          entityName="Delegation"
        />
      )}
      <Card>
        <Card.Header
          style={{
            paddingLeft: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <CardHeaderContainer>
            <PageTitle title="Delegaciones" />
            <Button
              variant="success"
              onClick={() => showModalCreate()}
              hidden={!PERMISOS_POR_ROL.Delegaciones.Crud}
            >
              <span>
                <FontAwesomeIcon icon={faPlus} size="lg" fixedWidth />
              </span>
              Crear Delegación
            </Button>
          </CardHeaderContainer>
        </Card.Header>
        <Card.Body>
          <TableFrontEnd
            columns={columns}
            data={filteredData}
            defaultSort={defaultSort}
            defaultColumn={defaultColumn}
            hiddenColumns={
              PERMISOS_POR_ROL.Delegaciones.Crud
                ? [
                    "portfolioId",
                    "territorialId",
                    "businessLine",
                    "crmId",
                    "creatorId",
                    "creationTime",
                    "lastModifierId",
                    "lastModificationTime",
                  ]
                : [
                    "portfolioId",
                    "edit",
                    "territorialId",
                    "businessLine",
                    "crmId",
                    "creatorId",
                    "creationTime",
                    "lastModifierId",
                    "lastModificationTime",
                    "delete",
                  ]
            }
            pagination={true}
          />
        </Card.Body>
      </Card>
    </S.Container>
  );
};
