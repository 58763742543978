import React, { useState, useEffect, Fragment } from "react";
import { Tab, Row, Col, Card, Button, Form, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoader } from "../../components/Loading";
import {
  faArrowLeft,
  faSave,
  faPrint,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import * as API from "../../api";
import {
  METADATA_EDICION_CAMPANA_1,
  METADATA_EDICION_CAMPANA_2,
  buildFormDataEdit,
  buildFormDataCreate,
} from "./constants";
import { CardForm } from "../../components/CardForm";
import { TableFrontEnd } from "../../components/TableFrontEnd";
import { useParams } from "react-router";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as S from "./styles";
import { Dialog } from "../../components/Dialog";
import { useDataContext } from "../../context/DataState";
import { ModalAddAssets } from "../../components/ModalAddAsset/ModalAddAssets";
import usePermition from "../../constants/usePermition";
import SelectLanguage from "../../components/SelectLanguage/SelectLanguage";

export const DetalleCampana = ({ sourcePath }) => {
  const [valueFilter, setValueFilter] = useState("");
  const [language, setLanguage] = useState("ES");
  const [data, setData] = useState({});
  const [dataActivos, setDataActivos] = useState([]);
  const [dataPromociones, setDataPromociones] = useState([]);
  const [validated, setValidated] = useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  const navigate = useNavigate();
  const { id } = useParams();
  const [campaignsSelected, setCampaignsSelected] = useState([]);
  const [dialogDesasignarActivosCampana, setDialogDesasignarActivosCampana] =
    useState(false);
  const [modalAssignCampaigns, setModalAssignCampaigns] = useState(false);
  const {
    dispatch,
    state: { appLists, user },
  } = useDataContext();
  const [mdEdition1, setMDEdition1] = useState(METADATA_EDICION_CAMPANA_1);
  const [mdEdition2, setMDEdition2] = useState(METADATA_EDICION_CAMPANA_2);
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    document.title = "EC-Light-Campaña";
    let accs = document.getElementsByClassName("accordion-button");

    let isMounted = true;
    if (isMounted && id) {
      for (let i = 0, len = accs.length; i < len; i++) {
        accs[i].click();
      }
      let auxMDEd2 = [...mdEdition2];
      auxMDEd2[2].collapsable = true;
      setMDEdition2(auxMDEd2);
      let listsAux = appLists;

      getCampaign();
      showLoader();
      API.obtenerUsuariosList()
        .then((res) => {
          listsAux.users = res.data;
          dispatch({
            type: "UPDATE_APP_LIST",
            payload: listsAux,
          });
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    } else {
      let auxMDEd2 = [...mdEdition2];
      auxMDEd2[2].collapsable = false;
      setMDEdition2(auxMDEd2);
      for (let j = 0, len = accs.length - 1; j < len; j++) {
        accs[j].click();
      }
    }
    return () => (isMounted = false);
  }, [showLoader, hideLoader, id]);

  const getCampaign = () => {
    showLoader();
    API.obtenerCampana(id)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasDataChanged && !id) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasDataChanged]);

  /**
   * Verificar si hay cambios antes de salir en la vista de crear
   */
  const goBack = () => {
    if (hasDataChanged && !id) {
      if (window.confirm("Existen cambios sin guardar. ¿Desea salir?")) {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  };

  const obtenerActivos = () => {
    showLoader();
    API.obtenerActivosCampana(id)
      .then((res) => {
        let dataAuxList = [];
        res.data.forEach((e) => {
          const dataAux = {};
          dataAux.id = e.id;
          dataAux.prinexId = e.prinexId;
          dataAux.productId = e.productId;
          dataAux.name = e.name;
          const portfolio = appLists.appPortfolios.find(
            (s) => s.id === e.portfolioId
          );
          if (portfolio !== null) {
            dataAux.portfolioName = portfolio.name;
          }
          const businessLine = appLists.businessLines.find(
            (s) => s.Id === e.businessLine
          );
          if (businessLine !== null) {
            dataAux.businessLineName = businessLine.translation;
          }
          dataAux.dossierCode = e.dossierCode;
          dataAux.promotionCode = e.promotionCode;
          const assetType = appLists.assetType.find((s) => s.Id === e.type);
          if (assetType !== null) {
            dataAux.type = assetType.translation;
          }
          if (e.isPrincipal === true) {
            dataAux.isPrincipal = "Si";
          } else if (e.isPrincipal === false) {
            dataAux.isPrincipal = "No";
          } else {
            dataAux.isPrincipal = "";
          }
          dataAuxList.push(dataAux);
        });
        setDataActivos(dataAuxList);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const obtenerPromociones = () => {
    showLoader();
    API.obtenerPromocionesCampana(id)
      .then((res) => {
        let dataAuxList = [];
        res.data.forEach((e) => {
          const dataAux = {};
          dataAux.id = e.id;
          dataAux.code = e.code;
          dataAux.promotionName = e.promotionName;
          if (e.isPublishable === true) {
            dataAux.isPublishable = "Si";
          } else if (e.isPublishable === false) {
            dataAux.isPublishable = "No";
          } else {
            dataAux.isPublishable = "";
          }
          const municipality = appLists.appMunicipalities.find(
            (s) => s.id === e.municipalityId
          );
          if (municipality) {
            dataAux.municipality = municipality.name;
          }
          if (e.provinceId) {
            const province = appLists.appProvinces.find(
              (s) => s.id === e.provinceId
            );
            if (province) {
              dataAux.province = province.name;
            }
          } else {
            const provinceId = appLists.appMunicipalities.find(
              (el) => el.id === e.municipalityId
            ).provinceId;
            dataAux.province = appLists.appProvinces.find(
              (element) => element.id === provinceId
            ).name;
          }
          const portfolio = appLists.appPortfolios.find(
            (s) => s.id === e.portfolioId
          );
          if (portfolio) {
            dataAux.portfolio = portfolio.name;
          }

          dataAuxList.push(dataAux);
        });
        setDataPromociones(dataAuxList);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  /**
   * Crear campaña si no hay id y si hay editarla
   * @param {*} event
   */
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (id) {
        let formData = buildFormDataEdit(data);
        if (formData.endDate === "") {
          delete formData.endDate;
        }
        showLoader();
        API.editarCampana(formData)
          .then((res) => {
            toast.success("Campaña editada correctamente");
            getCampaign();
          })
          .catch((error) => {
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
      } else {
        let formData = buildFormDataCreate(data);
        if (formData.endDate === "") {
          delete formData.endDate;
        }
        showLoader();
        API.crearCampana(formData)
          .then((res) => {
            toast.success("Campaña creada correctamente");
            navigate("/campaign-detail/" + res.data.id);
          })
          .catch((error) => {
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
      }
    }
  };

  const confirmDesasignarActivosCampana = (value) => {
    showLoader();
    setDialogDesasignarActivosCampana(false);
    let data = {};
    data.isAdd = false;
    data.iDs = value;
    API.cambiarActivosCampana(id, data)
      .then((res) => {
        const auxAssets = dataActivos.filter((e) => !value.includes(e.id));
        setDataActivos(auxAssets);
        toast.success("Activos desasignados correctamente");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const confirmAsignarActivosCampana = (value) => {
    showLoader();
    setModalAssignCampaigns(false);
    let data = {};
    data.isAdd = true;
    data.iDs = value;
    API.cambiarActivosCampana(id, data)
      .then((res) => {
        toast.success("Activos asignados correctamente");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
        obtenerActivos();
      });
  };

  const onLanguageChange = ({ target: { value } }) => {
    setLanguage(value);
  };

  const handleChange = (id, checked, type) => {
    if (type === "Campaigns") {
      if (checked) {
        const tmpValues = [...campaignsSelected];
        tmpValues.push(id);
        setCampaignsSelected(tmpValues);
      } else {
        const tmpValues = [...campaignsSelected];
        var ic = tmpValues.indexOf(id);
        tmpValues.splice(ic, 1);
        if (ic > -1) setCampaignsSelected(tmpValues);
      }
    }
  };

  const getURLAsset = (id) => {
    const asset = dataActivos.find((e) => e.id === id);
    const url = !!asset.productId
      ? "/product-detail/" + asset.productId + "/" + id
      : "/asset-detail/" + id;
    return url;
  };

  const handleChangeAllCampaign = (checked) => {
    if (checked) {
      setCampaignsSelected(dataActivos.map((s) => s.id));
    } else {
      setCampaignsSelected([]);
    }
  };

  const defaultSortActivos = [
    {
      id: "id",
      desc: false,
    },
  ];

  const defaultSortModalAssets = [
    {
      id: "prinexId",
      desc: false,
    },
  ];

  const columnsActivos = [
    {
      Header: (
        <Form.Check
          onChange={(e) => {
            handleChangeAllCampaign(e.target.checked);
          }}
          checked={campaignsSelected.length === dataActivos.length}
        />
      ),
      accessor: "select",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Form.Check
          onChange={(e) => {
            handleChange(cell.row.original.id, e.target.checked, "Campaigns");
          }}
          checked={campaignsSelected.includes(cell.row.original.id)}
        />
      ),
    },
    {
      Header: "",
      accessor: "detail",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Fragment>
          <Link to={getURLAsset(cell.row.original.id)}>
            <span title="Ver detalle">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEye}
                size="lg"
                fixedWidth
              />
            </span>
          </Link>
        </Fragment>
      ),
    },
    {
      Header: "Id Prínex",
      accessor: "prinexId",
      disableFilters: true,
    },
    {
      Header: "Nombre",
      accessor: "name",
      disableFilters: true,
    },
    {
      Header: "Cartera",
      accessor: "portfolioName",
      disableFilters: true,
    },
    {
      Header: "Negocio",
      accessor: "businessLineName",
      disableFilters: true,
    },
    {
      Header: "Expediente",
      accessor: "dossierCode",
      disableFilters: true,
    },
    {
      Header: "Id Promoción Prinex",
      accessor: "promotionCode",
      disableFilters: true,
    },
    {
      Header: "Subtipo",
      accessor: "type",
      disableFilters: true,
    },
    {
      Header: "Principal",
      accessor: "isPrincipal",
      disableFilters: true,
    },
  ];

  const defaultSortPromociones = [
    {
      id: "id",
      desc: false,
    },
  ];

  const columnsPromociones = [
    {
      Header: "",
      accessor: "detail",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Fragment>
          <Link to={`/promotion-detail/${cell.row.original.id}`}>
            <span title="Ver detalle">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEye}
                size="lg"
                fixedWidth
              />
            </span>
          </Link>
        </Fragment>
      ),
    },
    {
      Header: "Id Promoción",
      accessor: "id",
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "Código",
      accessor: "code",
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "Nombre",
      accessor: "promotionName",
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "Es Publicable",
      accessor: "isPublishable",
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "Municipio",
      accessor: "municipality",
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "Provincia",
      accessor: "province",
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "Cartera",
      accessor: "portfolio",
      disableFilters: true,
      disableSortBy: false,
    },
  ];

  const handleAuditLink = () => {
    navigate(`/auditing?EntityName=Campaign&EntityId=${data.id}`);
  };

  const handleDataChange = (values) => {
    if (!!values.campaignName) {
      setHasDataChanged(true);
    } else {
      setHasDataChanged(false);
    }
    let auxMD1 = [...mdEdition1];
    if (!!values.startDate) {
      auxMD1[0].metadata.find((e) => e.name === "endDate").min =
        values.startDate;
      setMDEdition1(auxMD1);

      if (values.startDate > values.endDate || !values.endDate) {
        values.endDate = values.startDate;
      }
    } else {
      values.endDate = "";
    }
    setData(values);
  };

  return (
    <S.ContainerElement>
      {loader}
      {dialogDesasignarActivosCampana && (
        <Dialog
          type="noFooter"
          message="¿Desea desasignar los activos?"
          labelConfirm="Eliminar"
          confirm={confirmDesasignarActivosCampana}
          cancel={() => setDialogDesasignarActivosCampana(false)}
          value={campaignsSelected}
        />
      )}
      {modalAssignCampaigns && (
        <ModalAddAssets
          id={id}
          message="Seleccionar activos"
          lists={appLists}
          defaultSort={defaultSortModalAssets}
          hiddenColumns={["id"]}
          labelConfirm="Guardar"
          cancel={() => setModalAssignCampaigns(false)}
          confirm={confirmAsignarActivosCampana}
          showLoader={showLoader}
          hideLoader={hideLoader}
          owner="campaign"
        />
      )}
      <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Col>
          <S.HeaderPageWithBack>
            <Button variant="danger" onClick={goBack}>
              <span>
                <FontAwesomeIcon icon={faArrowLeft} size="lg" fixedWidth />
              </span>
            </Button>
            <S.Title>Campaña {id}</S.Title>
          </S.HeaderPageWithBack>
        </Col>
      </Row>
      <Row>
        <Tab.Container
          defaultActiveKey="detalleCampana"
          id="uncontrolled-tab-example"
          className="mb-3"
          fill
        >
          <Row style={{ marginLeft: "0rem" }}>
            {id && (
              <Nav variant="pills">
                <Col sm={4}>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="detalleCampana"
                      style={{ textAlign: "left" }}
                    >
                      Detalle de la Campaña
                    </Nav.Link>
                  </Nav.Item>
                </Col>
                <Col sm={4}>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        if (!dataActivos.length) {
                          obtenerActivos();
                        }
                      }}
                      style={{ textAlign: "center" }}
                      eventKey="activosCampana"
                    >
                      Activos de la Campaña
                    </Nav.Link>
                  </Nav.Item>
                </Col>
                <Col sm={4}>
                  <Nav.Item>
                    <Nav.Link
                      onClick={obtenerPromociones}
                      eventKey="promocionesCampana"
                      style={{ textAlign: "right" }}
                    >
                      Promociones de la Campaña
                    </Nav.Link>
                  </Nav.Item>
                </Col>
              </Nav>
            )}
          </Row>
          <Tab.Content>
            <Tab.Pane eventKey="detalleCampana" title="Detalle de la Campaña">
              <Card style={{ marginTop: "1rem" }}>
                <Card.Header>
                  <Row>
                    <Col xs={3}>
                      <Form.Control
                        type="text"
                        placeholder="Filtrar campos"
                        onChange={(e) => {
                          setValueFilter(e.target.value);
                        }}
                      />
                    </Col>
                    <Col
                      xs={9}
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                      <div
                        key={`inline-radio`}
                        className="mb-1 mt-1"
                        style={{ display: "flex" }}
                      >
                        <div
                          id={
                            PERMISOS_POR_ROL.Campanas.Auditoria ||
                            PERMISOS_POR_ROL.Campanas.CrudCampana
                              ? "idiomsContainer"
                              : ""
                          }
                        >
                          <SelectLanguage
                            language={language}
                            onLanguageChange={onLanguageChange}
                          />
                        </div>
                        <Button
                          style={{ marginRight: "1rem" }}
                          variant="success"
                          hidden={!PERMISOS_POR_ROL.Campanas.Auditoria}
                          onClick={handleAuditLink}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faPrint}
                              size="lg"
                              fixedWidth
                            />
                          </span>
                          Auditoría
                        </Button>
                        <Button
                          type="submit"
                          form="editCampaign"
                          variant="success"
                          hidden={!PERMISOS_POR_ROL.Campanas.CrudCampana}
                          disabled={!hasDataChanged}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faSave}
                              size="lg"
                              fixedWidth
                            />
                          </span>
                          Guardar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Form
                    noValidate
                    id="editCampaign"
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col>
                        {mdEdition1.map((field, i) => (
                          <CardForm
                            key={i}
                            metadata={field.metadata}
                            label={field.label}
                            collapsable={field.collapsable}
                            filter={valueFilter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            lists={appLists}
                            data={data}
                            setData={handleDataChange}
                          />
                        ))}
                      </Col>
                      <Col>
                        {mdEdition2.map((field, i) => (
                          <CardForm
                            metadata={field.metadata}
                            label={field.label}
                            collapsable={field.collapsable}
                            filter={valueFilter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            lists={appLists}
                            data={data}
                            setData={handleDataChange}
                          />
                        ))}
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Tab.Pane>
            {id && (
              <Fragment>
                <Tab.Pane
                  eventKey="activosCampana"
                  title="Activos de la Campaña"
                >
                  <Card style={{ marginTop: "1rem", padding: "1rem" }}>
                    <Row>
                      <div className="assets-btn-container">
                        <Button
                          onClick={() =>
                            setDialogDesasignarActivosCampana(true)
                          }
                          disabled={
                            campaignsSelected.length === 0 ||
                            dataActivos.length === 0
                          }
                          variant="danger"
                          hidden={!PERMISOS_POR_ROL.Campanas.CrudCampana}
                        >
                          Desasignar Activos a la Campaña
                        </Button>
                        <Button
                          variant="success"
                          onClick={() => setModalAssignCampaigns(true)}
                          hidden={!PERMISOS_POR_ROL.Campanas.CrudCampana}
                        >
                          Asignar Activos a la Campaña
                        </Button>
                      </div>
                    </Row>
                    <Row>
                      <TableFrontEnd
                        columns={columnsActivos}
                        data={dataActivos}
                        defaultSort={defaultSortActivos}
                        hiddenColumns={
                          PERMISOS_POR_ROL.Campanas.CrudCampana
                            ? []
                            : ["select"]
                        }
                        pagination={false}
                      />
                    </Row>
                  </Card>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="promocionesCampana"
                  title="Promociones de la Campaña"
                >
                  <Card
                    style={{
                      marginTop: "1rem",
                      padding: "1rem",
                    }}
                  >
                    <TableFrontEnd
                      columns={columnsPromociones}
                      data={dataPromociones}
                      defaultSort={defaultSortPromociones}
                      hiddenColumns={[]}
                      pagination={false}
                    />
                  </Card>
                </Tab.Pane>
              </Fragment>
            )}
          </Tab.Content>
        </Tab.Container>
      </Row>
    </S.ContainerElement>
  );
};
