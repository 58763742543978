import axios from "axios";
import { toast } from "react-toastify";
import { loginRequest } from "../authConfig";

const { REACT_APP_BASE_API_URL, NODE_ENV } = process.env;

export const DEVELOP = NODE_ENV === "development";

const BASE_API_URL = REACT_APP_BASE_API_URL;

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});

let requestInterceptorId;
let responseInterceptorId;

export const configureAxios = (msalInstance) => {
  if (requestInterceptorId !== undefined)
    axiosInstance.interceptors.request.eject(requestInterceptorId);

  requestInterceptorId = axiosInstance.interceptors.request.use(
    async (config) => {
      const currentTime = Date.now();
      const token = localStorage.getItem("token");
      const msalTokenExpiresOn = Number(
        localStorage.getItem("msalTokenExpiresOn")
      );
      const msalTokenExtExpiresOn = Number(
        localStorage.getItem("msalTokenExtExpiresOn")
      );

      if (currentTime > msalTokenExtExpiresOn) {
        msalInstance
          .loginRedirect(loginRequest)
          .then((res) => {})
          .catch((error) => {
            console.log("[IReq] loginRedirect->ERROR", error);
          });
      } else if (currentTime > msalTokenExpiresOn) {
        const account = msalInstance.getActiveAccount();
        const accessTokenRequest = { ...loginRequest, account };
        try {
          const msalResponse =
            await msalInstance.acquireTokenSilent(accessTokenRequest);
          const msalToken = msalResponse.accessToken;
          const msalTokenExpiresOn = Number(msalResponse.expiresOn);
          const msalTokenExtExpiresOn = Number(msalResponse.extExpiresOn);
          localStorage.setItem("token", msalToken);
          localStorage.setItem(
            "msalTokenExpiresOn",
            msalTokenExpiresOn.toString()
          );
          localStorage.setItem(
            "msalTokenExtExpiresOn",
            msalTokenExtExpiresOn.toString()
          );

          config.headers.Authorization = `Bearer ${msalToken}`;

          return config;
        } catch (error) {
          console.log("[configureAxios] acquireTokenSilent->error", error);
        }
      }

      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      console.log("interceptors.request->error", error);
      return Promise.reject(error);
    }
  );

  if (responseInterceptorId !== undefined)
    axiosInstance.interceptors.response.eject(responseInterceptorId);

  responseInterceptorId = axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        if (error.config.url === "/Users/Access") {
          console.log("%c[IRes] /Users/Access 401 => /NoAccess", "color: red");
          window.location.href = `${process.env.REACT_APP_BASE_URL}/NoAccess`;
        } else {
          throw error;
        }
      } else if (error.message === "Network Error") {
        toast.error("Error de conexión");
      } else if (
        error.response?.status === 400 &&
        error.response?.data?.message
      ) {
        toast.error(error.response?.data.message);
      }
    }
  );

  axiosInstance.defaults.headers.common["Content-Type"] = "application/json";
};

const get = async (url, config) => {
  try {
    const response = await axiosInstance.get(url, config);
    if (config?.responseType === "blob") return response ? response : null;
    return response?.data ? response.data : null;
  } catch (error) {
    if (error.response) {
      const res = {
        code: error.response.status,
        msg: error.response.data,
        error: error,
      };
      throw res;
    } else {
      const res_1 = { code: 500, msg: error.message, error: error };
      throw res_1;
    }
  }
};

const post = async (url, data, config) => {
  try {
    const response = await axiosInstance.post(url, data, config);
    if (config?.responseType === "blob") return response ? response : null;
    return response?.data ? response.data : null;
  } catch (error) {
    if (axios.isCancel(error)) {
      // console.log("POST Request Cancelada");
    } else if (error.response) {
      const res = {
        code: error.response.status,
        msg: error.response.data,
        error: error,
      };
      throw res;
    } else {
      const res_1 = { code: 500, msg: error.message, error: error };
      throw res_1;
    }
  }
};

const put = async (url, data, config) => {
  try {
    const response = await axiosInstance.put(url, data, config);
    if (config?.responseType === "blob") return response ? response : null;
    return response.data ? response.data : null;
  } catch (error) {
    if (axios.isCancel(error)) {
      // console.log("POST Request Cancelada");
    } else if (error.response) {
      const res = {
        code: error.response.status,
        msg: error.response.data,
        error: error,
      };
      throw res;
    } else {
      const res_1 = { code: 500, msg: error.message, error: error };
      throw res_1;
    }
  }
};

const deleteAxios = async (url, config) => {
  try {
    const response = await axiosInstance.delete(url, config);
    if (config?.responseType === "blob") return response ? response : null;
    return response.data ? response.data : null;
  } catch (error) {
    if (error.response) {
      const res = {
        code: error.response.status,
        msg: error.response.data,
        error: error,
      };
      throw res;
    } else {
      const res_1 = { code: 500, msg: error.message, error: error };
      throw res_1;
    }
  }
};

// ACTIVOS

export const obtenerActivo = (data) => {
  const url = "/Assets/Asset/List";
  return post(url, data);
};

export const busquedaActivos = (data) => {
  const url = "/Assets/Filter";
  return post(url, data);
};

export const validarCreacionProductos = (data) => {
  const url = "/Assets/Validation";
  return post(url, data);
};

export const validarCreacionProductosDownloadDocument = (data) => {
  const url = "/Assets/ValidationMap";
  return post(url, data, { responseType: "blob" });
};

export const busquedaActivosCampanaPaginada = (id, data) => {
  const url = "/Assets/Campaign/" + id + "/Filter/Pagination";
  return post(url, data);
};

export const busquedaActivosCampanaTodos = (id, data) => {
  const url = "/Assets/Campaign/" + id + "/Filter/All";
  return post(url, data);
};

export const busquedaActivosProductoPaginada = (id, data) => {
  const url = "/Assets/Product/" + id + "/Filter/Pagination";
  return post(url, data);
};

export const busquedaActivosProductoTodos = (id, data) => {
  const url = "/Assets/Product/" + id + "/Filter/All";
  return post(url, data);
};

export const busquedaActivosCrearProductoPaginada = (data) => {
  const url = "/Assets/Product/Assets/Filter/Pagination";
  return post(url, data);
};

export const busquedaActivosCrearProductoTodos = (data) => {
  const url = "/Assets/Product/Assets/Filter/All";
  return post(url, data);
};

export const busquedaActivosPromocionPaginada = (id, data) => {
  const url = "/Assets/Promotion/" + id + "/Filter/Pagination";
  return post(url, data);
};

export const busquedaActivosPromocionTodos = (id, data) => {
  const url = "/Assets/Promotion/" + id + "/Filter/All";
  return post(url, data);
};

export const busquedaEditorContenidoActivos = (data) => {
  const url = "/Assets/ContentEditor";
  return post(url, data);
};

export const editarActivo = (data) => {
  const url = "/Assets/Asset";
  return put(url, data);
};

export const editarActivoParticular = (data) => {
  const url = "/Assets/Asset/portfolios/car-parti";
  return put(url, data);
};

export const crearActivoParticulares = (data) => {
  const url = "/Assets/Asset/portfolios/car-parti";
  return post(url, data);
};

export const crearVentaEspecial = (data) => {
  const url = "/SpecialSales/SpecialSale";
  return post(url, data);
};

export const enviarTablaTemporalActivo = (id, data) => {
  const url = "/Assets/Asset/" + id + "/Reprocess";
  return post(url, data);
};

export const obtenerActivosProducto = (data) => {
  const url = "/Assets/Product/Assets";
  return post(url, data);
};

export const obtenerVentasEspeciales = () => {
  const url = "/SpecialSales/All";
  return get(url);
};

export const obtenerVentasEspecialesLista = () => {
  const url = "/SpecialSales/All/Resume";
  return get(url);
};

export const obtenerVentaEspecial = (id) => {
  const url = "/SpecialSales/SpecialSale/" + id;
  return get(url);
};

export const editarVentaEspecial = (data) => {
  const url = "/SpecialSales/SpecialSale";
  return put(url, data);
};

export const crearActivoTerceros = (data) => {
  const url = "/Assets/Asset/portfolios/car-terce";
  return post(url, data);
};

export const editarActivoTerceros = (data) => {
  const url = "/Assets/Asset/portfolios/car-terce";
  return put(url, data);
};

export const activosForzadosMunicipios = (id, data) => {
  const req = {
    data,
  };
  const url = "/Delegations/Delegation/" + id + "/Municipalities/Assets/Forced";
  return post(url, req);
};

export const activosForzadosProvincias = (id, data) => {
  const req = {
    data,
  };
  const url = "/Delegations/Delegation/" + id + "/Provinces/Assets/Forced";
  return post(url, req);
};

// PRODUCTOS

export const busquedaEditorContenidoProductos = (data) => {
  const url = "/Products/ContentEditor";
  return post(url, data);
};

export const busquedaProductosBorrados = (data) => {
  const url = "/Products/Erased";
  return post(url, data);
};

export const obtenerProducto = (id) => {
  const url = "/Products/Product/" + id;
  return get(url);
};

export const validarProducto = (id) => {
  const url = "/Products/Product/" + id + "/Validation";
  return get(url);
};

export const editarProducto = (data) => {
  const url = "/Products/Product";
  return put(url, data);
};

export const editarProductoParticulares = (data) => {
  const url = "/Products/Product/portfolios/car-parti";
  return put(url, data);
};

export const crearProducto = (data) => {
  const url = "/Products/Product";
  return post(url, data);
};

export const crearProductoParticulares = (data) => {
  const url = "/Products/Product/portfolios/car-parti";
  return post(url, data);
};

export const crearProductoTerceros = (data) => {
  const url = "/Products/Product/portfolios/car-terce";
  return post(url, data);
};

export const editarProductoTerceros = (data) => {
  const url = "/Products/Product/portfolios/car-terce";
  return put(url, data);
};

export const desagruparProducto = (data, grouping) => {
  const url = "/Products/Product?grouping=" + grouping;
  return post(url, data);
};

export const desagruparProductoParticulares = (data, grouping) => {
  const url = "/Products/Product/portfolios/car-parti?grouping=" + grouping;
  return post(url, data);
};

export const desagruparProductoTerceros = (data, grouping) => {
  const url = "/Products/Product/portfolios/car-terce?grouping=" + grouping;
  return post(url, data);
};

export const enviarTablaTemporalProducto = (id, data) => {
  const url = "/Products/Product/" + id + "/Reprocess";
  return post(url, data);
};

export const validarDetalleProductosDownloadDocument = (id) => {
  const url = "/Products/Product/" + id + "/ValidationMap";
  return get(url, { responseType: "blob" });
};

// PROMOCIONES

export const busquedaPromociones = (data) => {
  const url = "/Promotions/Filter";
  return post(url, data);
};

export const obtenerPromociones = () => {
  const url = "/Promotions/All/Resume";
  return get(url);
};

export const obtenerPromocion = (id) => {
  const url = "/Promotions/Promotion/" + id;
  return get(url);
};

export const obtenerActivosPromocion = (id) => {
  const url = "/Promotions/Promotion/" + id + "/Assets";
  return get(url);
};

export const cambiarActivosPromocion = (id, data) => {
  const url = "/Promotions/Promotion/" + id + "/Assets";
  return put(url, data);
};

export const enviarTablaTemporalPromocion = (id, data) => {
  const url = "/Promotions/Promotion/" + id + "/Reprocess";
  return post(url, data);
};

export const borrarPromocion = (id) => {
  const url = "/Promotions/Promotion/" + id;
  return deleteAxios(url);
};

export const crearPromocion = (data) => {
  const url = "/Promotions/Promotion";
  return post(url, data);
};

export const editarPromocion = (data) => {
  const url = "/Promotions/Promotion";
  return put(url, data);
};

// CAMPANAS

export const obtenerCampanas = () => {
  const url = "/Campaigns/All/Resume";
  return get(url);
};

export const obtenerCampana = (id) => {
  const url = "/Campaigns/Campaign/" + id;
  return get(url);
};

export const obtenerActivosCampana = (id) => {
  const url = "/Campaigns/Campaign/" + id + "/Assets";
  return get(url);
};

export const cambiarActivosCampana = (id, data) => {
  const url = "/Campaigns/Campaign/" + id + "/Assets";
  return put(url, data);
};

export const obtenerPromocionesCampana = (id) => {
  const url = "/Campaigns/Campaign/" + id + "/Promotions";
  return get(url);
};

export const busquedaCampanas = (data) => {
  const url = "/Campaigns/Filter";
  return post(url, data);
};

export const crearCampana = (data) => {
  const url = "/Campaigns/Campaign";
  return post(url, data);
};

export const editarCampana = (data) => {
  const url = "/Campaigns/Campaign";
  return put(url, data);
};

export const borrarCampana = (id) => {
  const url = "/Campaigns/Campaign/" + id;
  return deleteAxios(url);
};

// CARGAS MASIVAS

export const generarPlantilla = (type, data) => {
  const url = "/Bulkloads/Templates/" + type;
  if (
    type === "Create/Products" ||
    type === "Update/Products" ||
    type === "Create/Products/portfolios/car-terce" ||
    type === "Update/Assets" ||
    type === "Update/Promotions"
  ) {
    return get(url, { responseType: "blob" });
  } else {
    return post(url, data, { responseType: "blob" });
  }
};

export const cargaPlantilla = (type, data) => {
  const url = "/Bulkloads/Loads/" + type;
  return post(url, data);
};

export const busquedaHistorico = (data) => {
  const url = "/Bulkloads/Filter";
  return post(url, data);
};

export const descargaHistorico = (id) => {
  const url = "/Bulkloads/Bulkload/" + id;
  return get(url, { responseType: "blob" });
};

// DELEGACIONES

export const obtenerDelegaciones = () => {
  const url = "/Delegations/All";
  return get(url);
};

export const obtenerDelegacion = (id) => {
  const url = "/Delegations/Delegation/" + id;
  return get(url);
};

export const crearDelegacion = (data) => {
  const url = "/Delegations/Delegation";
  return post(url, data);
};

export const editarDelegacion = (data) => {
  const url = "/Delegations/Delegation";
  return put(url, data);
};

export const borrarDelegacion = (id) => {
  const url = "/Delegations/Delegation/" + id;
  return deleteAxios(url);
};

export const editarDelegacionMunicipios = (id, data) => {
  const url = "/Delegations/Delegation/" + id + "/Municipalities";
  return put(url, data);
};

export const editarDelegacionProvincias = (id, data) => {
  const url = "/Delegations/Delegation/" + id + "/Provinces";
  return put(url, data);
};

// SOCIEDADES GESTORAS

export const obtenerSociedadesGestoras = () => {
  const url = "/ManagementCompanies/All";
  return get(url);
};

export const crearSociedadGestora = (data) => {
  const url = "/ManagementCompanies/ManagementCompany";
  return post(url, data);
};

export const editarSociedadGestora = (data) => {
  const url = "/ManagementCompanies/ManagementCompany";
  return put(url, data);
};

export const borrarSociedadGestora = (id) => {
  const url = "/ManagementCompanies/ManagementCompany/" + id;
  return deleteAxios(url);
};

// TERRITORIALES

export const obtenerTerritoriales = () => {
  const url = "/Territorials/All";
  return get(url);
};

export const crearTerritorial = (data) => {
  const url = "/Territorials/Territorial";
  return post(url, data);
};

export const editarTerritorial = (data) => {
  const url = "/Territorials/Territorial";
  return put(url, data);
};

export const borrarTerritorial = (id) => {
  const url = "/Territorials/Territorial/" + id;
  return deleteAxios(url);
};

// USUARIOS

export const obtenerUsuarios = () => {
  const url = "/Users/All";
  return get(url);
};

export const obtenerUsuariosList = () => {
  const url = "/Users/All/Resume";
  return get(url);
};

export const accesoUsuario = () => {
  const url = "/Users/Access";
  return get(url);
};

export const cambiarCartera = (portfolio) => {
  const url = "/Users/Portfolios/" + portfolio;
  return put(url, {});
};

export const crearUsuario = (data) => {
  const url = "/Users/User";
  return post(url, data);
};

export const editarUsuario = (data) => {
  const url = "/Users/User";
  return put(url, data);
};

export const editarUsuarioRoles = (data) => {
  const url = "/Users/User/Roles";
  return put(url, data);
};

export const editarUsuarioDelegaciones = (data) => {
  const url = "/Users/User/Delegations";
  return put(url, data);
};

// TAREAS

export const obtenerTareas = () => {
  const url = "/Queues/All";
  return get(url);
};

// TABLA TEMPORAL

export const obtenerTablaTemporal = () => {
  const url = "/Assets/Reprocess";
  return get(url);
};

// AUDITORIAS

export const obtenerFiltrosAuditoria = () => {
  const url = "/Audits/Filter";
  return get(url);
};

export const obtenerAuditorias = (data) => {
  const url = "/Audits/Filter";
  return post(url, data);
};

export const getAudit = (id) => {
  const url = `/Audits/Audit/${id}`;
  return get(url);
};

export const obtenerCambios = (id) => {
  const url = `/Audits/AuditEntity/${id}`;
  return get(url);
};

export const getEnqueuedUploads = () => {
  const url = "Bulkloads/EnqueuedByUser";
  return get(url);
};
