import React, { useState, useEffect, Fragment } from "react";
import { TableBackEnd } from "../../components/TableBackEnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "../../components/Dialog";
import { faEye, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Card, Button, Form } from "react-bootstrap";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { useLoader } from "../../components/Loading";
import { useDataContext } from "../../context/DataState";
import { CardHeaderContainer } from "../Buscador/styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import usePermition from "../../constants/usePermition";
import FilterButtons from "../../components/SearchButtons/FilterButtons";

export const Campanas = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [dialogBorrarCampanaVisible, setDialogBorrarCampanaVisible] =
    useState(false);
  const [data, setData] = useState([]);
  const [showingFilters, setShowingFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [loader, showLoader, hideLoader] = useLoader();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [numRegisters, setNumRegisters] = useState(0);
  const [order, setOrder] = useState("");
  const [orderDesc, setOrderDesc] = useState(true);
  const [valueDelete, setValueDelete] = useState("");
  const [regLength, setRegLength] = useState(50);
  const {
    state: { appLists, user },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    document.title = "EC-Light-Campañas";
    let isMounted = true;
    if (isMounted) {
      const {
        id,
        campaignName,
        url,
        requestingUserId,
        isDeleted,
        skip,
        take,
        orderField,
        descField,
      } = Object.fromEntries(searchParams);
      let tmpFilters = { ...filters };
      if (id !== undefined && id !== null) {
        tmpFilters["id"] = id;
      }
      if (campaignName !== undefined && campaignName !== null) {
        tmpFilters["campaignName"] = campaignName;
      }
      if (url !== undefined && url !== null) {
        tmpFilters["url"] = url;
      }
      if (requestingUserId !== undefined && requestingUserId !== null) {
        tmpFilters["requestingUserId"] = requestingUserId;
      }
      if (isDeleted !== undefined && isDeleted !== null) {
        tmpFilters["isDeleted"] = isDeleted;
      }
      setFilters(tmpFilters);
      if (
        skip !== undefined &&
        skip !== null &&
        take !== undefined &&
        take !== null
      ) {
        setPage(Number(skip) + 1);
        setRegLength(Number(take));
        if (orderField !== null && orderField !== undefined) {
          setOrder(orderField);
          if (descField !== null && descField !== undefined) {
            setOrderDesc(!Boolean(descField));
          }
        }
        busquedaCampanas();
      }
    }
    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (prop, value) => {
    const tmpFilters = { ...filters };
    if (value !== "Solicitada Por" && value !== "Eliminación: Todos") {
      if (prop === "id") {
        value = parseInt(value);
      }
      if (value < 1) {
        delete tmpFilters[prop];
      } else {
        tmpFilters[prop] = value;
      }
    } else {
      if (tmpFilters[prop]) delete tmpFilters[prop];
    }
    setFilters(tmpFilters);
  };

  const busquedaCampanas = () => {
    if (page > 0) {
      let data = {};
      let collection = [];
      let filtersAux = {};
      if (filters.id !== null && filters.id !== 0) filtersAux.id = filters.id;
      if (filters.campaignName !== null && filters.campaignName !== "")
        filtersAux.campaignName = filters.campaignName;
      if (filters.url !== null && filters.url !== "")
        filtersAux.url = filters.url;
      if (filters.requestingUserId !== null && filters.requestingUserId !== "")
        filtersAux.requestingUserId = filters.requestingUserId;
      if (
        filters.isDeleted !== undefined &&
        filters.isDeleted !== null &&
        filters.isDeleted !== ""
      ) {
        filtersAux.isDeleted = filters.isDeleted === "true";
      }
      data.page = page;
      data.regsPage = regLength;
      data.filters = filtersAux;
      let tmpFilters = { ...filters };
      if (tmpFilters.id === 0) delete tmpFilters.id;
      if (order !== null && order !== "") {
        data.orderBy = order;
        data.isAsc = orderDesc;
        tmpFilters["orderField"] = order;
        tmpFilters["descField"] = !orderDesc;
      } else {
        data.orderBy = "id";
        data.isAsc = true;
      }

      tmpFilters["skip"] = page - 1;
      tmpFilters["take"] = regLength;
      let newUrl = "/search-campaign?";
      navigate(newUrl + new URLSearchParams(tmpFilters).toString());
      showLoader();
      API.busquedaCampanas(data)
        .then((res) => {
          const names = appLists.appRequestingUsers;
          res.data.collection.forEach((e) => {
            let collectionElement = {};
            collectionElement.id = e.id;
            collectionElement.isDeleted = e.isDeleted;
            collectionElement.campaignName = e.campaignName;
            collectionElement.requestingUserId = e.requestingUserId;
            if (e.requestingUserId) {
              collectionElement.requestingUserName = names.find(
                (el) => el.id === e.requestingUserId
              ).name;
            } else {
              collectionElement.requestingUserName = "";
            }
            if (e.startDate !== null)
              collectionElement.startDate = e.startDate.split("T")[0];
            if (e.endDate !== null)
              collectionElement.endDate = e.endDate.split("T")[0];
            collection.push(collectionElement);
          });
          setData(collection);
          setTotalPages(res.data.maxPages);
          setNumRegisters(res.data.numRegisters);
        })
        .catch((error) => API.DEVELOP && console.log(error))
        .finally(() => hideLoader());
    }
  };

  const defaultSort = [
    {
      id: "id",
      desc: false,
    },
  ];

  const search = () => {
    busquedaCampanas();
  };

  const columns = [
    {
      Header: "",
      accessor: "detail",
      disableFilters: true,
      disableSortBy: true,
      maxWidth: 40,
      Cell: ({ cell }) => (
        <Fragment>
          <Link
            to={"/campaign-detail/" + cell.row.original.id}
            state={{ sourcePath: location.pathname + location.search }}
          >
            <span title="Ver detalle">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEye}
                size="lg"
                fixedWidth
              />
            </span>
          </Link>
        </Fragment>
      ),
    },
    {
      Header: "Id Campaña",
      accessor: "id",
      disableFilters: true,
      maxWidth: 80,
      width: 60,
    },
    {
      Header: "Nombre",
      accessor: "campaignName",
      disableFilters: true,
      Cell: ({ cell }) => (
        <Fragment>
          {cell.row.original.isDeleted ? (
            <span style={{ textDecoration: "line-through" }}>
              {cell.row.original.campaignName}
            </span>
          ) : (
            cell.row.original.campaignName
          )}
        </Fragment>
      ),
    },
    {
      Header: "Solicitada Por",
      accessor: "requestingUserName",
      disableFilters: true,
    },
    {
      Header: "Fecha Inicio",
      accessor: "startDate",
      disableFilters: true,
      maxWidth: 100,
    },
    {
      Header: "Fecha Fin",
      accessor: "endDate",
      disableFilters: true,
      maxWidth: 100,
    },
    {
      Header: "",
      accessor: "delete",
      disableFilters: true,
      disableSortBy: true,
      maxWidth: 40,
      Cell: ({ cell }) => (
        <Fragment>
          {!cell.row.original.isDeleted && (
            <button
              className="tableButton"
              onClick={() => showModalDelete(cell.row.original.id)}
              hidden={!PERMISOS_POR_ROL.Campanas.CrudCampana}
            >
              <span>
                <FontAwesomeIcon
                  style={{ color: "black" }}
                  icon={faTrashAlt}
                  size="lg"
                  fixedWidth
                />
              </span>
            </button>
          )}
        </Fragment>
      ),
    },
  ];

  const confirmDelete = (id) => {
    setDialogBorrarCampanaVisible(false);
    showLoader();
    API.borrarCampana(id)
      .then((res) => {
        toast.success("Campaña borrada correctamente");
        const tmpData = [...data];
        const dataDeleted = tmpData.find((e) => e.id === id);
        if (dataDeleted !== null) {
          dataDeleted.isDeleted = true;
        }
        setData(tmpData);
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const cleanFilters = () => {
    setFilters({});
    navigate("/search-campaign");
  };

  const handleChangeNumericals = (prop, value, maxLength) => {
    let tmpFilters = { ...filters };

    if (value === "") {
      delete tmpFilters[prop];
    }

    const regex = /[^0-9,]/g;
    const newValue = value.replace(regex, "");
    if (newValue !== undefined && newValue !== null && newValue !== "") {
      if (newValue.length <= maxLength) {
        tmpFilters[prop] = parseInt(newValue);
      } else {
        const fixLengthValue = newValue.slice(0, maxLength);
        tmpFilters[prop] = parseInt(fixLengthValue);
        toast.error("El máximo admitido es " + maxLength + " dígitos");
      }
    }

    setFilters(tmpFilters);
  };

  const showFilters = (showing) => {
    if (showing) {
      document.getElementsByClassName("filters")[0].style.display = "none";
      setShowingFilters(false);
    } else {
      document.getElementsByClassName("filters")[0].style.display = "block";
      setShowingFilters(true);
    }
  };

  const showModalDelete = (id) => {
    setValueDelete(id);
    setDialogBorrarCampanaVisible(true);
  };

  const handleChangePagination = (value) => {
    setRegLength(value);
  };

  return (
    <Fragment>
      {loader}
      {dialogBorrarCampanaVisible && (
        <Dialog
          type="noFooter"
          message="¿Desea eliminar la campaña?"
          labelConfirm="Eliminar"
          confirm={confirmDelete}
          cancel={() => setDialogBorrarCampanaVisible(false)}
          value={valueDelete}
        />
      )}
      <S.Sidebar className="filters">
        <FilterButtons
          onSearch={() => {
            setPage(1);
            search();
          }}
          onClean={cleanFilters}
        />
        <Form.Group className="mb-2">
          <Form.Control
            type="text"
            onChange={(e) => handleChangeNumericals("id", e.target.value, 10)}
            id="id"
            placeholder="Id Campaña"
            value={filters.id || filters.id >= 0 ? filters.id : ""}
            min={0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Control
            onChange={(e) => handleChange("campaignName", e.target.value)}
            id="campaignName"
            placeholder="Nombre Campaña"
            value={filters.campaignName ? filters.campaignName : ""}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Control
            onChange={(e) => handleChange("url", e.target.value)}
            id="url"
            placeholder="Url"
            value={filters.url ? filters.url : ""}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Select
            id="requestingUserId"
            onChange={(e) => handleChange("requestingUserId", e.target.value)}
            value={filters.requestingUserId ? filters.requestingUserId : 0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
          >
            <option>Solicitada Por</option>
            {appLists.appRequestingUsers !== undefined &&
              appLists.appRequestingUsers.map((e, i) => (
                <option value={e.id} key={i}>
                  {e.name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Select
            id="isDeleted"
            onChange={(e) => handleChange("isDeleted", e.target.value)}
            value={filters.isDeleted ? filters.isDeleted : 0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
          >
            <option>Eliminación: Todos</option>
            <option value="true">Eliminados</option>
            <option value="false">No Eliminados</option>
          </Form.Select>
        </Form.Group>
      </S.Sidebar>
      <S.Content>
        <Card>
          <Card.Header
            style={{
              paddingLeft: "2rem",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <CardHeaderContainer>
              <PageTitle title="Campañas" />
              <div>
                {showingFilters && (
                  <Button
                    className="openFilters"
                    onClick={() => showFilters(showingFilters)}
                    variant="danger"
                  >
                    Esconder Busqueda
                  </Button>
                )}
                {!showingFilters && (
                  <Button
                    className="openFilters"
                    onClick={() => showFilters(showingFilters)}
                    variant="danger"
                  >
                    Desplegar Busqueda
                  </Button>
                )}
                <Button
                  variant="success"
                  onClick={() => navigate("/campaign-detail")}
                  hidden={!PERMISOS_POR_ROL.Campanas.CrudCampana}
                >
                  <span>
                    <FontAwesomeIcon icon={faPlus} size="lg" fixedWidth />
                  </span>
                  Crear Campaña
                </Button>
              </div>
            </CardHeaderContainer>
          </Card.Header>
          <Card.Body>
            <TableBackEnd
              columns={columns}
              data={data}
              defaultSort={defaultSort}
              hiddenColumns={[]}
              pageTable={page}
              regsPage={regLength}
              setPage={setPage}
              setRegsPage={handleChangePagination}
              search={search}
              totalPages={totalPages}
              numRegisters={numRegisters}
              order={order}
              setOrder={setOrder}
              orderDesc={orderDesc}
              setOrderDesc={setOrderDesc}
            />
          </Card.Body>
        </Card>
      </S.Content>
    </Fragment>
  );
};
