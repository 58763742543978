import React, { useState, useEffect } from "react";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import * as API from "../../api";
import * as S from "./styles";
import { Dialog } from "../Dialog";
import { Fragment } from "react";
import EmptyData from "../EmptyTable/EmptyData";

export const ModalAssetCreateProduct = ({
  ids,
  message,
  lists,
  labelConfirm,
  confirm,
  cancel,
  defaultSort,
  defaultColumn,
  showLoader,
  hideLoader,
}) => {
  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState("");
  const [orderDesc, setOrderDesc] = useState(true);
  const [pageTable, setPage] = useState(0);
  const [regsPage, setRegsPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [numRegisters, setNumRegisters] = useState(0);
  const [dialogTooMuchAssets, setDialogTooMuchAssets] = useState(false);

  useEffect(() => {
    if (pageTable > 0) {
      busquedaAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTable, regsPage, order, orderDesc]);

  const columns = [
    {
      Header: (
        <Form.Check
          onChange={(e) => {
            setSelectedAllAssets(e.target.checked);
          }}
          disabled={numRegisters === 0}
          checked={selected.length === numRegisters}
        />
      ),
      accessor: "select",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Form.Check
          onChange={(e) => {
            handleChangeSelectedAssets(
              parseInt(cell.row.original.id),
              parseInt(cell.row.original.prinexId),
              e.target.checked
            );
          }}
          checked={selected.find((e) => e.id === cell.row.original.id)}
        />
      ),
    },
    {
      Header: "Id Prínex",
      accessor: "prinexId",
      disableFilters: true,
    },
    {
      Header: "Código Producto",
      accessor: "code",
      disableFilters: true,
    },
    {
      Header: "Nombre",
      accessor: "name",
      disableFilters: true,
    },
    {
      Header: "Línea Negocio",
      accessor: "businessLine",
      disableFilters: true,
    },

    {
      Header: "Expediente",
      accessor: "dossierCode",
      disableFilters: true,
    },
    {
      Header: "Id Promoción Prinex",
      accessor: "promotionCode",
      disableFilters: true,
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: defaultSort,
      },
      autoResetHiddenColumns: false,
      defaultColumn,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const handleConfirm = () => {
    let ids = selected.map((e) => {
      return e.id;
    });
    confirm(ids);
  };

  const changeOrder = (column) => {
    if (!column.disableSortBy) {
      if (order === column.id && orderDesc === false) {
        setOrderDesc(!orderDesc);
      } else if (order === column.id && orderDesc === true) {
        setOrder("");
        setOrderDesc(!orderDesc);
      } else {
        setOrder(column.id);
        setOrderDesc(false);
      }
    }
  };

  const handleChange = (prop, value) => {
    const tmpFilters = { ...filters };
    tmpFilters[prop] = value;
    setFilters(tmpFilters);
  };

  const handleChangeSelectedAssets = (id, prinexId, checked) => {
    let tmpSelected = [...selected];
    if (checked) {
      let tmpSelectedItem = {
        id,
        prinexId,
      };
      tmpSelected.push(tmpSelectedItem);
      setSelected(tmpSelected);
    } else {
      tmpSelected = tmpSelected.filter((e) => e.id !== id);
      setSelected(tmpSelected);
    }
  };

  const deleteSelected = (id) => {
    let tmpSelected = [...selected];
    tmpSelected = tmpSelected.filter((e) => e.id !== id);
    setSelected(tmpSelected);
  };

  const busquedaAssets = () => {
    let dataSend = {};
    let collection = [];
    let filtersAux = {};
    const idsToNumber = ids.map((str) => parseInt(str, 10));
    if (filters.prinexId !== null && filters.prinexId !== "")
      filtersAux.prinexId = Number(filters.prinexId);
    if (filters.code !== null && filters.code !== "")
      filtersAux.code = Number(filters.code);
    if (filters.dossierCode !== null && filters.dossierCode !== "")
      filtersAux.dossierCode = filters.dossierCode;
    if (filters.promotionCode !== null && filters.promotionCode !== "")
      filtersAux.promotionCode = filters.promotionCode;
    dataSend.page = pageTable;
    dataSend.regsPage = regsPage;
    dataSend.filters = filtersAux;
    if (order !== null && order !== "") {
      dataSend.orderBy = order;
      dataSend.isAsc = orderDesc;
    } else {
      dataSend.orderBy = "code";
      dataSend.isAsc = true;
    }
    dataSend.iDs = idsToNumber;
    showLoader();
    API.busquedaActivosCrearProductoPaginada(dataSend)
      .then((res) => {
        res.data.collection.forEach((e) => {
          e.assets.forEach((f) => {
            let collectionElement = {};
            collectionElement.id = f.id;
            collectionElement.prinexId = f.prinexId;
            collectionElement.code = e.code;
            collectionElement.name = f.name;
            const businessLine = lists.businessLines.find(
              (s) => s.Id === f.businessLine
            );
            if (businessLine !== null) {
              collectionElement.businessLine = businessLine.translation;
            }
            collectionElement.dossierCode = f.dossierCode;
            collectionElement.promotionCode = f.promotionCode;
            collection.push(collectionElement);
          });
        });
        setData(collection);
        setTotalPages(res.data.maxPages);
        setNumRegisters(res.data.numRegisters);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => hideLoader());
  };

  const setSelectedAllAssets = (checked) => {
    if (checked) {
      if (numRegisters <= 200) {
        getAllIds();
      } else {
        setDialogTooMuchAssets(true);
      }
    } else {
      setSelected([]);
    }
  };

  const getAllIds = () => {
    if (dialogTooMuchAssets) setDialogTooMuchAssets(false);
    let dataSend = {};
    const idsToNumber = ids.map((str) => parseInt(str, 10));
    if (filters.prinexId !== null && filters.prinexId !== "")
      dataSend.prinexId = filters.prinexId;
    if (filters.code !== null && filters.code !== "")
      dataSend.code = filters.code;
    if (filters.dossierCode !== null && filters.dossierCode !== "")
      dataSend.dossierCode = filters.dossierCode;
    if (filters.promotionCode !== null && filters.promotionCode !== "")
      dataSend.promotionCode = filters.promotionCode;
    data.iDs = idsToNumber;
    showLoader();
    API.busquedaActivosCrearProductoTodos(dataSend)
      .then((res) => {
        let assetIds = [];
        res.data.forEach((element) => {
          element.assets.forEach((e) => {
            let asset = {
              id: e.id,
              prinexId: e.prinexId,
            };
            assetIds.push(asset);
          });
        });
        setSelected(assetIds);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => hideLoader());
  };

  const cleanFilters = () => {
    setFilters({});
  };

  return (
    <Fragment>
      {dialogTooMuchAssets && (
        <Dialog
          type="noFooter"
          message="Se seleccionarán más de 200 activos. Puede que la aplicación no funcione correctamente, ¿desea continuar?"
          labelConfirm="Continuar"
          confirm={getAllIds}
          cancel={() => setDialogTooMuchAssets(false)}
          value={{}}
        />
      )}
      {!dialogTooMuchAssets && (
        <Modal show={true} onHide={cancel} size="xl">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {message}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={3}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    id="prinexId"
                    min={0}
                    placeholder="Id Prínex"
                    value={filters.prinexId ? filters.prinexId : ""}
                    onChange={(e) => handleChange("prinexId", e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    min={0}
                    id="code"
                    value={filters.code ? filters.code : ""}
                    placeholder="Código"
                    onChange={(e) => handleChange("code", e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="dossierCode"
                    placeholder="Expediente"
                    value={filters.dossierCode ? filters.dossierCode : ""}
                    onChange={(e) =>
                      handleChange("dossierCode", e.target.value)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="promotionCode"
                    placeholder="Código Promoción"
                    value={filters.promotionCode ? filters.promotionCode : ""}
                    onChange={(e) =>
                      handleChange("promotionCode", e.target.value)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={3}>
                <Button onClick={() => cleanFilters()} variant="danger">
                  <span>
                    <FontAwesomeIcon icon={faTrash} size="lg" fixedWidth />
                  </span>
                  Limpiar Filtros
                </Button>
              </Col>
              <Col xs={9}>
                <Button
                  onClick={() => {
                    setPage(1);
                    busquedaAssets();
                  }}
                  variant="success"
                >
                  Buscar
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <S.AssetsSelected>
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    style={{
                      border: "thin solid lightgray",
                      whiteSpace: "nowrap",
                      maxHeight: "80vh",
                      overflowY: "auto",
                    }}
                  >
                    <thead className="sticky-top">
                      <tr>
                        <th style={{ backgroundColor: "rgb(246, 246, 246)" }}>
                          <button
                            className="tableButton"
                            onClick={() => setSelected([])}
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                size="lg"
                                fixedWidth
                              />
                            </span>
                          </button>
                        </th>
                        <th style={{ backgroundColor: "rgb(246, 246, 246)" }}>
                          Id Prínex ({selected.length})
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selected.map((a, index) => (
                        <tr key={index}>
                          <td>
                            <button
                              className="tableButton"
                              onClick={() => deleteSelected(a.id)}
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  size="lg"
                                  fixedWidth
                                />
                              </span>
                            </button>
                          </td>
                          <td>{a.prinexId}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </S.AssetsSelected>
              </Col>
              <Col xs={9}>
                <div
                  style={{
                    height: !!data.length ? "45vh" : "5vh",
                    overflow: !!data.length ? "auto" : "hidden",
                  }}
                >
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    {...getTableProps()}
                    style={{
                      border: "thin solid lightgray",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <thead className="sticky-top">
                      {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                          {headerGroup.headers.map((column, i) => (
                            <th
                              key={i}
                              onClick={() => changeOrder(column)}
                              style={{
                                backgroundColor: "rgb(246, 246, 246)",
                                color: "black",
                                fontWeight: "bold",
                                cursor: "pointer",
                                verticalAlign: "top",
                                border: "thin solid lightgray",
                              }}
                            >
                              {column.render("Header")}
                              <span>
                                {column.id === order ? (
                                  orderDesc ? (
                                    <span> ↓ </span>
                                  ) : (
                                    <span> ↑ </span>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} key={i}>
                            {row.cells.map((cell, ind) => (
                              <td
                                {...cell.getCellProps()}
                                style={{
                                  padding: "10px",
                                  border: "thin solid lightgray",
                                  background: "white",
                                  overflow: "hidden",
                                  maxWidth: "100px",
                                }}
                                title={cell.value}
                                key={ind}
                              >
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                {!!data.length ? (
                  <Fragment>
                    <Row className="mb-2" style={{ marginTop: 10 }}>
                      <Col>
                        <ButtonGroup size="sm">
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => {
                              setPage(1);
                            }}
                            disabled={pageTable < 2}
                          >
                            {"<<"}
                          </Button>
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => {
                              setPage(pageTable - 1);
                            }}
                            disabled={pageTable < 2}
                          >
                            {"<"}
                          </Button>
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => {
                              setPage(pageTable + 1);
                            }}
                            disabled={
                              pageTable === totalPages || pageTable === 0
                            }
                          >
                            {">"}
                          </Button>
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => setPage(totalPages)}
                            disabled={
                              pageTable === totalPages || pageTable === 0
                            }
                          >
                            {">>"}
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={6}>
                        <Form.Select
                          size="sm"
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(Number(e.target.value));
                            setPage(1);
                            setRegsPage(Number(e.target.value));
                          }}
                          style={{ width: "8rem" }}
                        >
                          {[10, 20, 50, 100, 200].map((pageSize, indexOp) => (
                            <option key={indexOp} value={pageSize}>
                              Mostrar {pageSize}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <span>
                          Página{" "}
                          <strong>
                            {pageTable} de {totalPages}
                          </strong>{" "}
                        </span>
                        <span>
                          | Ir a la página:{" "}
                          <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                              const page = e.target.value
                                ? Number(e.target.value)
                                : 0;
                              setPage(page);
                            }}
                            style={{ width: "100px" }}
                          />
                        </span>{" "}
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <div style={{ border: "thin solid lightgray" }}>
                    <EmptyData />
                  </div>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={handleConfirm}
              disabled={selected.length === 0}
              variant="success"
            >
              <span>
                <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
              </span>
              {labelConfirm}
            </Button>

            {cancel && (
              <Button onClick={cancel} variant="danger">
                <span>
                  <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
                </span>
                Cancelar
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
};
