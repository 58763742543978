import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePermition from "../../constants/usePermition";
import { useDataContext } from "../../context/DataState";
import { useLoader } from "../../components/Loading";
import * as API from "../../api";
import { generateRandomId, orderAlphabetic, portfolios } from "../../constants";
import { toast } from "react-toastify";

const useSpecialSaleDetail = () => {
  const {
    state: { user, appLists },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useLoader();
  const [managementCompanies, setManagmentCompanies] = useState([]);
  const [filtedredManagementCompanies, setFilteredManagmentCompanies] =
    useState([]);
  const [portFolioList, setPortfolioList] = useState([]);
  const [data, setData] = useState({});
  const [actualPublication, setActualPublication] = useState({
    portfolioId: null,
    managementCompanyId: null,
    isPublishable: null,
  });
  const [active, setActive] = useState(null);
  const [filteredFields, setFilteredFields] = useState(null);
  const [originalFields, setOriginalFields] = useState(null);

  useEffect(() => {
    document.title = "Venta Especial";
    if (!PERMISOS_POR_ROL.VentaEspecial.Acceso) {
      navigate("/");
    } else {
      setPortfolioList(
        appLists.appPortfolios.filter(
          (e) =>
            e.id !== portfolios.name.particulares &&
            e.id !== portfolios.name.terceros
        )
      );
      loadManagmentCompanies();

      setFilteredFields(appLists.appSpecialSalesBusinessFields);
      setOriginalFields(appLists.appSpecialSalesBusinessFields);

      if (id) {
        getSpecialSale();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Cargar las reglas en caso de la vista ser de edicion
   */
  const getSpecialSale = () => {
    showLoader();
    API.obtenerVentaEspecial(id)
      .then((res) => {
        let VE = res.data;
        VE.publication = VE.publication.map((e) => {
          e.id = generateRandomId(7);
          return e;
        });
        setData(VE);
      })
      .catch((e) => API.DEVELOP && console.error(e))
      .finally(hideLoader);
  };

  /**
   * Cambiar el prinexId de la sociedad por el id para que se vea en el select
   */
  useEffect(() => {
    if (!!data?.publication?.length && !!managementCompanies.length) {
      let ve = { ...data };
      ve.publication.forEach((pub) => {
        const mc = managementCompanies.find(
          (e) =>
            e.portfolioId === pub.portfolioId &&
            e.prinexId === pub.managementCompanyId
        );
        if (mc) {
          pub.managementCompanyId = mc.id;
        }
      });
    }
  }, [data, managementCompanies]);

  /**
   * Cargar Sociedades gedtoras
   */
  const loadManagmentCompanies = () => {
    showLoader();
    API.obtenerSociedadesGestoras()
      .then((res) => {
        const orderedList = orderAlphabetic(res.data, "name");
        setManagmentCompanies(orderedList);
        setFilteredManagmentCompanies(orderedList);
      })
      .catch((e) => console.log(e))
      .finally(hideLoader);
  };

  /**
   * Manejar los cambios de la regla que se esta llenando
   * @param {*} prop
   * @param {*} value
   */
  const handleDataChange = (prop, value) => {
    const tmpData = { ...data };
    tmpData[prop] = value;
    setData(tmpData);
  };

  /**
   * Cambio de data de la publicación a añadir
   * @param {*} prop
   * @param {*} value
   */
  const handleActualChange = (prop, value) => {
    const tmpData = { ...actualPublication };
    if (prop === "portfolioId") {
      delete tmpData.managementCompanyId;
      let auxMC = [];
      if (value !== "") {
        auxMC = managementCompanies.filter((e) => e.portfolioId === value);
      } else {
        auxMC = managementCompanies;
      }
      setFilteredManagmentCompanies(auxMC);
    }
    if (value === "") {
      delete tmpData[prop];
    } else {
      if (prop === "managementCompanyId") {
        value = Number(value);
      }
      tmpData[prop] = value;
    }
    setActualPublication(tmpData);
  };

  /**
   * Añadir una regla a la lista de reglas
   */
  const handleAddRule = () => {
    let newRule = { ...actualPublication };
    if (!!!newRule.isPublishable) {
      newRule.isPublishable = false;
    }
    const newId = generateRandomId(7);
    newRule.id = newId;
    newRule.configuration = [];
    let auxData = { ...data };
    auxData.publication = !!data?.publication?.length
      ? [...data.publication, newRule]
      : [newRule];
    setData(auxData);
    setActualPublication({
      portfolioId: null,
      managementCompanyId: null,
      isPublishable: null,
    });
    setFilteredManagmentCompanies(managementCompanies);
  };

  /**
   * Eliminar una regla
   * @param {*} idRule
   */
  const removeRule = (idRule) => {
    let auxData = { ...data };
    auxData.publication = data.publication.filter((e) => e.id !== idRule);
    setData(auxData);
    if (active === idRule) {
      setActive(null);
    }
  };

  /**
   *
   * @returns true/false si ya existe una ragla existente para la combinacion CARTERA/SG/publicable
   */
  const existRule = () => {
    const auxRule = data?.publication?.find(
      (e) =>
        e.portfolioId === actualPublication.portfolioId &&
        e.managementCompanyId === actualPublication.managementCompanyId
    );
    const found = auxRule ? true : false;
    return found;
  };

  /**
   * Ordenar los campos poniendo los seleccionados primero
   * @param {*} id
   */
  const orderFields = (id) => {
    let auxFields = { ...originalFields };
    const pub = data.publication.find((e) => e.id === id);
    Object.entries(auxFields).forEach((tag) => {
      let tagName = tag[0];
      let checkedFields = pub.configuration.filter((field) =>
        field.startsWith(tagName + ".")
      );
      const fixedCheckedFields = checkedFields.map((e) =>
        e.replace(tagName + ".", "")
      );
      auxFields[tagName] = [...new Set([...fixedCheckedFields, ...tag[1]])];
    });
    setFilteredFields(auxFields);
    setOriginalFields(auxFields);
  };

  /**
   * Seleccionar una regla para ver sus campos
   * @param {*} idRule
   */
  const handleSelectRule = (idRule) => {
    setActive(idRule);
    orderFields(idRule);
  };

  /**
   * Añadir un campo a la configuración de la regla seleccionada
   * @param {*} prop
   * @param {*} val
   */
  const handleAddField = (prop, val, fieldList) => {
    let auxData = { ...data };
    let auxActiveRule = auxData.publication.find((e) => e.id === active);
    if (val) {
      if (prop !== "all") {
        auxActiveRule.configuration.push(fieldList + "." + prop);
      } else {
        const all = originalFields[fieldList].map((e) => fieldList + "." + e);
        const resultArray = [
          ...new Set(auxActiveRule.configuration.concat(all)),
        ];
        auxActiveRule.configuration = resultArray;
      }
    } else {
      if (prop !== "all") {
        auxActiveRule.configuration = auxActiveRule.configuration.filter(
          (e) => e !== fieldList + "." + prop
        );
      } else {
        auxActiveRule.configuration = auxActiveRule.configuration.filter(
          (e) => !e.includes(fieldList + ".")
        );
      }
    }
    setData(auxData);
  };

  /**
   * filtrado de tabla
   * @param {*} value
   * @param {*} list
   */
  const filterTable = (value, list) => {
    let auxFiltered = { ...filteredFields };
    auxFiltered[list] = originalFields[list].filter((e) =>
      e.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredFields(auxFiltered);
  };

  /**
   * Navegar a la auditoria de la venta especial
   */
  const goToAudit = () => {
    const url = `/auditing?EntityName=SpecialSale&EntityId=${id}`;
    navigate(url);
  };

  /**
   * Guardar. Editar si hay id y si no hay crear
   * antes de enviar la peticion se cambia en cada publicacion el id de la sociedad por el prinexId
   */
  const save = () => {
    let sendData = { ...data };
    sendData?.publication?.forEach((pub) => {
      if (!!pub.managementCompanyId) {
        const mc = managementCompanies.find(
          (e) => e.id === pub.managementCompanyId
        );
        pub.managementCompanyId = mc.prinexId;
      }
    });
    if (id) {
      showLoader();
      API.editarVentaEspecial(sendData)
        .then((res) => {
          toast.success("Venta especial guardada con éxito.");
          let VE = res.data;
          VE.publication = VE.publication.map((e, index) => {
            e.id = data.publication[index].id;
            return e;
          });
          setData(VE);
        })
        .catch((e) => {
          API.DEVELOP && console.log(e);
        })
        .finally(hideLoader);
    } else {
      showLoader();
      API.crearVentaEspecial(sendData)
        .then((res) => {
          toast.success("Venta especial guardada con éxito");
          let VE = res.data;
          VE.publication = VE.publication.map((e, index) => {
            e.id = data.publication[index].id;
            return e;
          });
          setData(VE);
          navigate(`/special-sale/${VE.id}`);
        })
        .catch((error) => API.DEVELOP && console.error(error))
        .finally(hideLoader);
    }
  };

  return {
    PERMISOS_POR_ROL,
    filtedredManagementCompanies,
    portFolioList,
    id,
    loader,
    handleDataChange,
    data,
    navigate,
    actualPublication,
    handleActualChange,
    handleAddRule,
    active,
    handleSelectRule,
    appLists,
    managementCompanies,
    removeRule,
    existRule,
    filteredFields,
    handleAddField,
    filterTable,
    goToAudit,
    save,
  };
};

export default useSpecialSaleDetail;
