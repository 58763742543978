// const BUSCADOR = {
//   key: "search-asset",
//   label: "Buscador",
// };
const PROMOCIONES = {
  key: "search-promotion",
  label: "Promociones",
};
const CAMPANAS = {
  key: "search-campaign",
  label: "Campañas",
};
export const MENUS_TABLAS = [PROMOCIONES, CAMPANAS];

const TERRITORIALES = {
  key: "view-territorials",
  label: "Territoriales",
};
const DELEGACIONES = {
  key: "view-delegations",
  label: "Delegaciones",
};
const SOCIEDADES_GESTORAS = {
  key: "view-managements",
  label: "Sociedades Gestoras",
};
const USUARIOS = {
  key: "users",
  label: "Usuarios",
};
const TAREAS = {
  key: "queues",
  label: "Tareas",
};
const TABLA_TEMPORAL = {
  key: "temp-table",
  label: "Tabla Temporal",
};
const AUDITORIA = {
  key: "auditing",
  label: "Auditoría",
};
const VENTA_ESPECIAL = {
  key: "special-sales",
  label: "Ventas Especiales",
};
export const MENUS_ADMIN = [
  TERRITORIALES,
  DELEGACIONES,
  SOCIEDADES_GESTORAS,
  USUARIOS,
  TAREAS,
  TABLA_TEMPORAL,
  VENTA_ESPECIAL,
  AUDITORIA,
];
