import PropTypes from "prop-types";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import {
  Button,
  Modal,
  Table,
  Row,
  Col,
  ButtonGroup,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";

export const ModalTable = ({
  message,
  labelConfirm,
  confirm,
  cancel,
  columns,
  data,
  defaultSort,
  hiddenColumns,
  defaultColumn,
  pagination,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: pagination ? 10 : Number.MAX_VALUE,
        sortBy: defaultSort,
        hiddenColumns: hiddenColumns,
      },
      defaultColumn,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ maxHeight: "550px", overflow: "auto" }}>
          <Table
            striped
            bordered
            hover
            {...getTableProps()}
            style={{
              border: "solid 1px black",
              whiteSpace: "nowrap",
            }}
          >
            <thead className="bg-light sticky-top top-0">
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        border: "thin solid lightgray",
                        backgroundColor: "rgb(246, 246, 246)",
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        verticalAlign: "top",
                        maxWidth: column.maxWidth,
                      }}
                      key={index}
                    >
                      {column.render("Header")}
                      <span>
                        {i !== 0 && column.isSorted ? (
                          column.isSortedDesc ? (
                            <span> ↓ </span>
                          ) : (
                            <span> ↑ </span>
                          )
                        ) : (
                          ""
                        )}
                      </span>
                      {column.canFilter ? column.render("Filter") : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "thin solid lightgray",
                          background: "white",
                          overflow: "hidden",
                          maxWidth: cell.column.maxWidth,
                        }}
                        title={cell.value}
                        key={index}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        {pagination && (
          <Fragment>
            <Row className="mb-2">
              <Col>
                <ButtonGroup size="sm">
                  <Button
                    style={{ marginRight: "3px" }}
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </Button>{" "}
                  <Button
                    style={{ marginRight: "3px" }}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    {"<"}
                  </Button>{" "}
                  <Button
                    style={{ marginRight: "3px" }}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    {">"}
                  </Button>{" "}
                  <Button
                    style={{ marginRight: "3px" }}
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </Button>{" "}
                </ButtonGroup>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={6}>
                <Form.Select
                  size="sm"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  style={{ width: "8rem" }}
                >
                  {[10, 20, 50, 100, 200].map((pageSize, i) => (
                    <option key={i} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <span>
                  Página{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <span>
                  | Ir a la página:{" "}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{ width: "100px" }}
                  />
                </span>{" "}
              </Col>
            </Row>
          </Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginRight: "1rem" }}
          onClick={() => confirm()}
          variant="success"
        >
          <span>
            <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
          </span>
          {labelConfirm}
        </Button>

        {cancel && (
          <Button onClick={cancel} variant="danger">
            <span>
              <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
            </span>
            Cancelar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalTable.propTypes = {
  message: PropTypes.string.isRequired,
  labelConfirm: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  defaultSort: PropTypes.array.isRequired,
  defaultColumn: PropTypes.object.isRequired,
  hiddenColumns: PropTypes.array.isRequired,
  pagination: PropTypes.bool.isRequired,
};
