import React from "react";
import * as S from "./styles";
import { InputModalText } from "../../InputModalText";
import { InputModalCheckBox } from "../../InputModalCheckBox";
import { InputModalList } from "../../InputModalList";
import { InputModalDataList } from "../../InputModalDataList";
import { InputModalNumber } from "../../InputModalNumber/InputModalNumber";
import { useDataContext } from "../../../context/DataState";

export const RenderInputs = ({
  metadata,
  numColumns,
  values,
  setValues,
  index,
  updateHiddenProperty,
}) => {
  const {
    dispatch,
    state: { appLists },
  } = useDataContext();

  const handleChange = (prop, value) => {
    if (typeof index === "number") {
      setValues((prevState) => {
        const newState = [...prevState];
        const newValues = { ...newState[index], [prop]: value };
        newState[index] = newValues;
        return newState;
      });
      updateHiddenProperty(value, index);
    } else {
      const tmpValues = { ...values, [prop]: value };
      setValues(tmpValues);
    }
  };

  const handleChangeListWithChildren = (prop, value) => {
    const tmpValues = { ...values };
    const tmpLists = { ...appLists };
    tmpValues[prop] = value;
    setValues(tmpValues);
    tmpLists["territorials"] = tmpLists["territorialsAux"].filter(
      (x) => x.portfolioId === value
    );
    dispatch({
      type: "UPDATE_APP_LIST",
      payload: tmpLists,
    });
  };

  const handleChangeCheckBox = (prop, value) => {
    const tmpValues = { ...values };
    if (value) {
      tmpValues[prop] = true;
    } else {
      tmpValues[prop] = false;
    }
    setValues(tmpValues);
  };

  return (
    <S.Fields $numColumns={numColumns}>
      {metadata.map((field, index) => {
        switch (field.type) {
          case "text":
            return (
              <InputModalText
                key={index}
                id={field?.name}
                label={field?.label}
                value={values[field.name] ? values[field.name].toString() : ""}
                disabled={field?.disabled}
                hidden={field?.hidden}
                handleChange={handleChange}
                required={field?.required}
                maxLength={field.maxLength}
                inputBootstrap={field?.inputBootstrap}
              />
            );
          case "checkbox":
            return (
              <InputModalCheckBox
                key={index}
                id={field.name}
                label={field.label}
                value={values[field.name] || false}
                disabled={field.disabled}
                hidden={field.hidden}
                handleChange={handleChangeCheckBox}
                inputBootstrap={field?.inputBootstrap}
              />
            );
          case "list":
            return (
              <InputModalList
                key={index}
                id={field.name}
                label={field.label}
                value={values[field.name] || ""}
                disabled={field.disabled}
                hidden={field.hidden}
                handleChange={handleChange}
                list={appLists[field.listName]}
                typeList={field.typeList}
                required={field.required}
                inputBootstrap={field?.inputBootstrap}
              />
            );
          case "listWithChildren":
            return (
              <InputModalList
                key={index}
                id={field.name}
                label={field.label}
                value={values[field.name] || ""}
                disabled={field.disabled}
                hidden={field.hidden}
                handleChange={handleChangeListWithChildren}
                list={appLists[field.listName]}
                typeList={field.typeList}
                required={field.required}
                inputBootstrap={field?.inputBootstrap}
              />
            );
          case "datalist":
            return (
              <InputModalDataList
                key={index}
                id={field.name}
                label={field.label}
                value={values[field.name] || ""}
                disabled={field.disabled}
                hidden={field.hidden}
                handleChange={handleChange}
                list={appLists[field.listName]}
                required={field.required}
                inputBootstrap={field?.inputBootstrap}
              />
            );
          case "number":
            return (
              <InputModalNumber
                key={index}
                id={field.name}
                label={field.label}
                value={values[field.name] || 0}
                disabled={field.disabled}
                hidden={field.hidden}
                handleChange={handleChange}
                list={appLists[field.listName]}
                required={field.required}
                min={field.min}
                max={field.max}
                typeNumber={field.typeNumber}
                inputBootstrap={field?.inputBootstrap}
                isEmpty={!values[field.name] && values[field.name] !== 0}
              />
            );
          default:
            return null;
        }
      })}
    </S.Fields>
  );
};
