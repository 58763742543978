import React from "react";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import * as S from "./styles";
import { Dialog } from "../Dialog";
import useModalAddAsset from "../../hooks/useModalAddAsset";
import { Fragment } from "react";
import EmptyData from "../EmptyTable/EmptyData";

export const ModalAddAssets = ({
  id,
  message,
  lists,
  labelConfirm,
  confirm,
  cancel,
  defaultSort,
  defaultColumn,
  showLoader,
  hideLoader,
  owner,
}) => {
  const {
    filters,
    order,
    orderDesc,
    pageTable,
    totalPages,
    data,
    selected,
    dialogTooMuchAssets,
    columns,
    numRegisters,
    busquedaAssets,
    handleConfirm,
    changeOrder,
    handleChange,
    deleteSelected,
    getAllIds,
    cleanFilters,
    setDialogTooMuchAssets,
    setPage,
    setSelected,
    setRegsPage,
  } = useModalAddAsset({
    id,
    lists,
    confirm,
    showLoader,
    hideLoader,
    owner,
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: owner !== "promotion" ? 50 : 200,
        sortBy: defaultSort,
      },
      autoResetHiddenColumns: false,
      defaultColumn,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <Fragment>
      {dialogTooMuchAssets && (
        <Dialog
          type="noFooter"
          message="Se seleccionarán más de 200 activos. Puede que la aplicación no funcione correctamente, ¿desea continuar?"
          labelConfirm="Continuar"
          confirm={getAllIds}
          cancel={() => setDialogTooMuchAssets(false)}
          value={{}}
        />
      )}
      {!dialogTooMuchAssets && (
        <Modal show={true} onHide={cancel} size="xl">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {message}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={2}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    min={0}
                    id="prinexId"
                    placeholder="Id Prínex"
                    value={filters.prinexId ? filters.prinexId : ""}
                    onChange={(e) => handleChange("prinexId", e.target.value)}
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    id="code"
                    placeholder="Código"
                    min={0}
                    value={filters.code ? filters.code : ""}
                    onChange={(e) => handleChange("code", e.target.value)}
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="dossierCode"
                    placeholder="Expediente"
                    value={filters.dossierCode ? filters.dossierCode : ""}
                    onChange={(e) =>
                      handleChange("dossierCode", e.target.value)
                    }
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="promotionCode"
                    placeholder="Código Promoción"
                    value={filters.promotionCode ? filters.promotionCode : ""}
                    onChange={(e) =>
                      handleChange("promotionCode", e.target.value)
                    }
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="propertyCode"
                    placeholder="Inmueble"
                    value={filters.propertyCode || ""}
                    onChange={(e) =>
                      handleChange("propertyCode", e.target.value)
                    }
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={3}>
                <Button onClick={() => cleanFilters()} variant="danger">
                  <span>
                    <FontAwesomeIcon icon={faTrash} size="lg" fixedWidth />
                  </span>
                  Limpiar Filtros
                </Button>
              </Col>
              <Col xs={9}>
                <Button
                  onClick={() => {
                    setPage(1);
                    busquedaAssets();
                  }}
                  variant="success"
                >
                  Buscar
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <S.AssetsSelected>
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      border: "thin solid lightgray",
                      whiteSpace: "nowrap",
                      maxHeight: "80vh",
                      overflowY: "auto",
                    }}
                  >
                    <thead className="bg-light sticky-top top-0">
                      <tr>
                        <th style={{ backgroundColor: "rgb(246, 246, 246)" }}>
                          <button
                            className="tableButton"
                            onClick={() => setSelected([])}
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                size="lg"
                                fixedWidth
                              />
                            </span>
                          </button>
                        </th>
                        <th style={{ backgroundColor: "rgb(246, 246, 246)" }}>
                          Id Prínex ({selected.length})
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selected.map((a, index) => (
                        <tr key={index}>
                          <td>
                            <button
                              className="tableButton"
                              onClick={() => deleteSelected(a.id)}
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  size="lg"
                                  fixedWidth
                                />
                              </span>
                            </button>
                          </td>
                          <td>{a.prinexId}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </S.AssetsSelected>
              </Col>
              <Col xs={9}>
                <div
                  style={{
                    height: !!data.length ? "45vh" : "5vh",
                    overflow: !!data.length ? "auto" : "hidden",
                  }}
                >
                  <Table
                    striped
                    bordered
                    hover
                    {...getTableProps()}
                    style={{
                      border: "thin solid lightgray",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <thead
                      className="bg-light sticky-top top-0"
                      style={{ zIndex: "auto" }}
                    >
                      {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                          {headerGroup.headers.map((column, i) => (
                            <th
                              key={i}
                              onClick={() => changeOrder(column)}
                              style={{
                                backgroundColor: "rgb(246, 246, 246)",
                                color: "black",
                                fontWeight: "bold",
                                cursor: "pointer",
                                verticalAlign: "top",
                                border: "thin solid lightgray",
                                width: column.width,
                                maxWidth: column.maxWidth,
                                minWidth: column.minWidth,
                              }}
                            >
                              {column.render("Header")}
                              <span>
                                {column.id === order ? (
                                  orderDesc ? (
                                    <span> ↑ </span>
                                  ) : (
                                    <span> ↓ </span>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, index) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} key={index}>
                            {row.cells.map((cell, i) => (
                              <td
                                {...cell.getCellProps()}
                                style={{
                                  padding: "10px",
                                  border: "thin solid lightgray",
                                  background: "white",
                                  overflow: "hidden",
                                  maxWidth: cell.column.maxWidth,
                                  minWidth: cell.column.minWidth,
                                  width: cell.column.width,
                                }}
                                title={cell.value}
                                key={i}
                              >
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                {!!data.length ? (
                  <Fragment>
                    <Row className="mb-2" style={{ marginTop: 10 }}>
                      <Col>
                        <ButtonGroup size="sm">
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => {
                              setPage(1);
                            }}
                            disabled={pageTable < 2}
                          >
                            {"<<"}
                          </Button>
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => {
                              setPage(pageTable - 1);
                            }}
                            disabled={pageTable < 2}
                          >
                            {"<"}
                          </Button>
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => {
                              setPage(pageTable + 1);
                            }}
                            disabled={
                              pageTable === totalPages || pageTable === 0
                            }
                          >
                            {">"}
                          </Button>
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => setPage(totalPages)}
                            disabled={
                              pageTable === totalPages || pageTable === 0
                            }
                          >
                            {">>"}
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={6}>
                        <Form.Select
                          size="sm"
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(Number(e.target.value));
                            setPage(1);
                            setRegsPage(Number(e.target.value));
                          }}
                          style={{ width: "8rem" }}
                        >
                          {[10, 20, 50, 100, 200].map((pageSize, index) => (
                            <option key={index} value={pageSize}>
                              Mostrar {pageSize}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      {numRegisters > 0 && (
                        <Col>
                          {`${numRegisters} registro${
                            numRegisters > 1 ? "s" : ""
                          }`}
                        </Col>
                      )}
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <span>
                          Página{" "}
                          <strong>
                            {pageTable} de {totalPages}
                          </strong>{" "}
                        </span>
                        <span>
                          | Ir a la página:{" "}
                          <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                              const page = e.target.value
                                ? Number(e.target.value)
                                : 0;
                              setPage(page);
                            }}
                            style={{ width: "100px" }}
                          />
                        </span>{" "}
                      </Col>
                    </Row>{" "}
                  </Fragment>
                ) : (
                  <div style={{ border: "thin solid lightgray" }}>
                    <EmptyData />
                  </div>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={handleConfirm}
              disabled={selected.length === 0}
              variant="success"
            >
              <span>
                <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
              </span>
              {labelConfirm}
            </Button>

            {cancel && (
              <Button onClick={cancel} variant="danger">
                <span>
                  <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
                </span>
                Cancelar
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
};
