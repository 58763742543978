import React, { Fragment } from "react";
import { useState } from "react";
import { CustomeItemTooltip, TooltipSpan } from "./styles";

export const CustomeTooltip = ({ tooltip, children }) => {
  const [hidden, setHidden] = useState(true);
  return (
    <Fragment>
      <TooltipSpan hidden={hidden}>{tooltip}</TooltipSpan>
      <CustomeItemTooltip
        onMouseEnter={() => setHidden(false)}
        onMouseLeave={() => setHidden(true)}
      >
        {children}
      </CustomeItemTooltip>
    </Fragment>
  );
};
