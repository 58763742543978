import styled from "styled-components/macro";

export const StyledInputLink = styled.div`
  background-color: #e9ecef;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  border: 1px solid #ced4da;
`;
