import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Title = styled.span`
  background-color: ${COLORES.TITLE_CAMPAIGN_PROMOTION};
  color: #fff;
  margin-left: 2px;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 1.3rem;
`;

export const NumActivos = styled.span`
  background-color: #00a65a;
  color: #fff;
  margin-left: 2px;
  padding: 3px 8px;
  border-radius: 3px;
`;

export const DetailPage = styled.div`
  margin: 0 15px;
  input[type="checkbox"] {
    cursor: pointer;
  }
`;
