import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";

export const InputFormTextArea = ({
  label,
  placeholder,
  id,
  disabled,
  hidden,
  filter,
  maxLength,
  required,
  value,
  handleChange,
}) => {
  return (
    <Row className="mb-2" hidden={hidden(filter, label)}>
      <Fragment>
        <Col xs={12} md={5}>
          <Form.Label
            hidden={hidden(filter, label)}
            style={{ fontSize: "0.875rem" }}
          >
            {label}
          </Form.Label>
        </Col>
        <Col xs={12} md={7}>
          <Form.Control
            as="textarea"
            required={required}
            placeholder={placeholder}
            id={id}
            disabled={disabled()}
            maxLength={maxLength}
            value={value ? value : ""}
            onChange={(e) => handleChange(id, e.target.value)}
            size="sm"
          />
          <Form.Control.Feedback type="invalid">
            El {label} es necesario.
          </Form.Control.Feedback>
        </Col>
      </Fragment>
    </Row>
  );
};

InputFormTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.func.isRequired,
  hidden: PropTypes.func.isRequired,
  filter: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  handleChange: PropTypes.func,
  value: PropTypes.string,
};
