import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import { ModalTextEditor } from "../../components/ModalTextEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as S from "./styles";

export const InputFormTextEditor = ({
  label,
  placeholder,
  id,
  disabled,
  hidden,
  filter,
  maxLength,
  required,
  value,
  data,
  setData,
  handleChange,
}) => {
  const [showModalTextEditor, setShowModalTextEditor] = useState(false);
  const [valueEdit, setValueEdit] = useState("");

  const showModal = () => {
    setShowModalTextEditor(true);
    setValueEdit(value);
  };

  const updateValue = () => {
    let tmpData = { ...data };
    tmpData[id] = valueEdit;
    setData(tmpData);
    setShowModalTextEditor(false);
  };

  return (
    <Fragment>
      {showModalTextEditor && (
        <ModalTextEditor
          message={label}
          cancel={() => setShowModalTextEditor(false)}
          value={valueEdit}
          setValue={setValueEdit}
          confirm={updateValue}
        />
      )}
      <Row className="mb-2" hidden={hidden(filter, label)}>
        <Col xs={12} md={5}>
          <Form.Label
            hidden={hidden(filter, label)}
            style={{ fontSize: "0.875rem" }}
          >
            {label}
          </Form.Label>
          {disabled() && (
            <S.ButtonTextEditor type="button" onClick={() => showModal()}>
              <span>
                <FontAwesomeIcon icon={faEdit} size="lg" fixedWidth />
              </span>
            </S.ButtonTextEditor>
          )}
        </Col>
        <Col xs={12} md={7}>
          <S.DangerousHTML>
            <div
              id="danHTMLContainer"
              dangerouslySetInnerHTML={{ __html: value }}
            ></div>
          </S.DangerousHTML>

          <Form.Control.Feedback type="invalid">
            El {label} es necesario.
          </Form.Control.Feedback>
        </Col>
      </Row>
    </Fragment>
  );
};

InputFormTextEditor.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.func.isRequired,
  hidden: PropTypes.func.isRequired,
  filter: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  handleChange: PropTypes.func,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  value: PropTypes.string,
};
