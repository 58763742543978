import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";

export const InputModalNumber = ({
  id,
  label,
  value,
  placeholder,
  handleChange,
  disabled,
  hidden,
  filter,
  required,
  maxLength,
  min = -Infinity,
  max = +Infinity,
  typeNumber,
  inputBootstrap = true,
  isEmpty,
}) => {
  const handleValueChange = (id, val) => {
    if (val < min) val = min;
    handleChange(id, val);
  };

  const preventE = (e) => {
    if (e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  };

  const step = typeNumber === "decimal" ? 0.01 : 1;

  return (
    <Row className="mb-2">
      {inputBootstrap ? (
        <Col>
          <Form.Label hidden={hidden(filter, label)}>{label}</Form.Label>
        </Col>
      ) : (
        <Form.Label hidden={hidden(filter, label)}>{label}</Form.Label>
      )}
      <Col>
        <Form.Control
          required={required}
          type="number"
          placeholder={placeholder}
          value={isEmpty ? "" : value}
          id={id}
          disabled={disabled()}
          hidden={hidden(filter, label)}
          onChange={(e) => handleValueChange(id, e.target.value)}
          onKeyDown={preventE}
          maxLength={maxLength}
          min={min}
          max={max}
          step={step}
          autoComplete="off"
        />
        <Form.Control.Feedback type="invalid">
          El {label} es necesario.
        </Form.Control.Feedback>
      </Col>
    </Row>
  );
};

InputModalNumber.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.func.isRequired,
  hidden: PropTypes.func.isRequired,
  required: PropTypes.bool,
  min: PropTypes.number,
};
