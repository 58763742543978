import { disabledFalse, disabledTrue, hiddenFalse } from "../../constants";

export const METADATA_EDICION_DELEGACION = [
  {
    label: "Id",
    name: "id",
    type: "text",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Nombre",
    name: "name",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Crm Id",
    name: "crmId",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "L. Negocio",
    name: "businessLine",
    type: "list",
    typeList: "Translation",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    listName: "businessLines",
    required: true,
  },
  {
    label: "Cartera",
    name: "portfolioId",
    type: "list",
    typeList: "Normal",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    listName: "appPortfolios",
    required: true,
  },
  {
    label: "Territorial",
    name: "territorialId",
    type: "list",
    typeList: "Normal",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    listName: "territorialsAux",
    required: true,
  },
  {
    label: "Por defecto",
    name: "isDefault",
    type: "checkbox",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    languageSensitive: false,
  },
];

export const METADATA_CREACION_DELEGACION = [
  {
    label: "Id",
    name: "id",
    type: "text",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Nombre",
    name: "name",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Crm Id",
    name: "crmId",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "L. Negocio",
    name: "businessLine",
    type: "list",
    typeList: "Translation",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    listName: "businessLines",
    required: true,
  },
  {
    label: "Cartera",
    name: "portfolioId",
    type: "list",
    typeList: "Normal",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    listName: "appPortfolios",
    required: true,
  },
  {
    label: "Territorial",
    name: "territorialId",
    type: "list",
    typeList: "Normal",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    listName: "territorialsAux",
    required: true,
  },
  {
    label: "Por defecto",
    name: "default",
    type: "checkbox",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    languageSensitive: false,
  },
];

export const METADATA_RANGE_PRICE = [
  {
    label: "Desde",
    name: "desdeListId",
    type: "list",
    typeList: "Normal",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    listName: "delegationPriceRangesFrom_",
    required: true,
    inputBootstrap: false,
  },
  {
    label: "Precio",
    name: "desdeNumber",
    type: "number",
    typeNumber: "decimal",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
    inputBootstrap: false,
  },
  {
    label: "Hasta",
    name: "hastaListId",
    type: "list",
    typeList: "Normal",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    listName: "delegationPriceRangesTo_",
    required: true,
    inputBootstrap: false,
  },
  {
    label: "Precio",
    name: "hastaNumber",
    type: "number",
    typeNumber: "decimal",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
    inputBootstrap: false,
  },
];

export const METADATA_RANGE_PRICE_EDIT = [
  {
    label: "Desde",
    name: "desdeListId",
    type: "list",
    typeList: "Normal",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    listName: "delegationPriceRangesFrom_",
    required: true,
    inputBootstrap: false,
  },
  {
    label: "Precio",
    name: "desdeNumber",
    type: "text",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    required: true,
    inputBootstrap: false,
  },
  {
    label: "Hasta",
    name: "hastaListId",
    type: "list",
    typeList: "Normal",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    listName: "delegationPriceRangesTo_",
    required: true,
    inputBootstrap: false,
  },
  {
    label: "Precio",
    name: "hastaNumber",
    type: "text",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    required: true,
    inputBootstrap: false,
  },
];

export const METADATA_AUDIT_DELEGACION = [
  {
    label: "Usuario Creación",
    name: "creatorId",
  },
  {
    label: "Fecha Creación",
    name: "creationTime",
  },
  {
    label: "Usuario Última Modificación",
    name: "lastModifierId",
  },
  {
    label: "Fecha Última Modificación",
    name: "lastModificationTime",
  },
];
