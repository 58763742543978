import {
  disabledFalse,
  disabledTrue,
  hiddenFilter,
  portfolios,
  userRoles,
} from "../../constants";

const isSuperAdmin = () => {
  const auxRoles = JSON.parse(localStorage.getItem("userRoles"));
  if (
    auxRoles.filter(
      (s) =>
        s.name.toLowerCase() === userRoles.name.roleSuperAdmin.toLowerCase()
    ).length > 0
  ) {
    return true;
  } else {
    return false;
  }
};

const notPermissionFieldsForm = () => {
  const auxRoles = JSON.parse(localStorage.getItem("userRoles"));
  if (
    auxRoles.filter(
      (s) =>
        s.name.toLowerCase() === userRoles.name.roleAdmin ||
        s.name.toLowerCase() === userRoles.name.roleSuperAdmin ||
        s.name.toLowerCase() === userRoles.name.roleParticular
    ).length > 0
  ) {
    return false;
  } else {
    return true;
  }
};

export const buildFormDataEditParticularAsset = (data) => {
  let formData = {};
  //Datos Generales
  formData.id = data.id;
  formData.businessLine = data.businessLine;

  //Particulares
  if (data.okPBC === "true" || data.okPBC === true) {
    formData.okPBC = true;
  } else if (data.okPBC === "false" || data.okPBC === false) {
    formData.okPBC = false;
  } else {
    formData.okPBC = null;
  }
  if (data.okRegistral === "true" || data.okRegistral === true) {
    formData.okRegistral = true;
  } else if (data.okRegistral === "false" || data.okRegistral === false) {
    formData.okRegistral = false;
  } else {
    formData.okRegistral = null;
  }
  if (!!data.mandateDateSign) {
    const array = data.mandateDateSign.split("T");
    formData.mandateDateSign = !!array[1]
      ? `${array[0]}T${array[1]}Z`
      : `${array[0]}T00:00:00.000Z`;
  } else {
    formData.mandateDateSign = null;
  }
  if (!!data.mandateModality) {
    formData.mandateModality = data.mandateModality;
  } else {
    formData.mandateModality = null;
  }
  if (!!data.mandateDuration) {
    formData.mandateDuration = parseInt(data.mandateDuration);
  } else {
    formData.mandateDuration = null;
  }
  if (
    data.isBrandNewConstruction === "true" ||
    data.isBrandNewConstruction === true
  ) {
    formData.isBrandNewConstruction = true;
  } else if (
    data.isBrandNewConstruction === "false" ||
    data.isBrandNewConstruction === false
  ) {
    formData.isBrandNewConstruction = false;
  } else {
    formData.isBrandNewConstruction = null;
  }

  //Localizacion
  if (!!data.typeOfRoad) {
    formData.typeOfRoad = data.typeOfRoad;
  } else {
    formData.typeOfRoad = "AVENIDA";
  }
  formData.road = data.road;
  formData.number = data.number;
  if (!!data.floor) {
    formData.floor = data.floor;
  } else {
    formData.floor = null;
  }
  formData.door = data.door;
  if (!!data.stair) {
    formData.stair = data.stair;
  } else {
    formData.stair = null;
  }

  if (!!data.block) {
    formData.block = data.block;
  } else {
    formData.block = null;
  }
  formData.postalCode = data.postalCode;
  formData.municipalityId = data.municipalityId;
  formData.latitude = data.latitude;
  formData.longitude = data.longitude;
  if (!!data.district) {
    formData.district = data.district;
  } else {
    formData.district = null;
  }
  formData.hideNumber = data.hideNumber;
  formData.hideAddress = data.hideAddress;
  if (!!data.commuterTrain) {
    formData.commuterTrain = data.commuterTrain;
  } else {
    formData.commuterTrain = data.commuterTrain;
  }
  if (!!data.bus) {
    formData.bus = data.bus;
  } else {
    formData.bus = null;
  }
  if (!!data.highway) {
    formData.highway = data.highway;
  } else {
    formData.highway = null;
  }

  //Características del inmueble
  if (!!data.description) {
    formData.description = data.description;
  } else {
    formData.description = null;
  }
  if (!!data.descriptionEN) {
    formData.descriptionEN = data.descriptionEN;
  } else {
    formData.descriptionEN = null;
  }
  if (!!data.descriptionFR) {
    formData.descriptionFR = data.descriptionFR;
  } else {
    formData.descriptionFR = null;
  }
  formData.delegationId = data.delegationId;
  formData.commercialNetType = parseInt(data.commercialNetType);
  formData.subTypeId = data.subTypeId;
  if (!!data.crmPrice || data.crmPrice === 0) {
    if (data.crmPrice !== 0) {
      let newVal = data.crmPrice.toString();
      formData.crmPrice = parseFloat(newVal.replace(",", "."));
    } else {
      formData.crmPrice = 0;
    }
  } else {
    formData.crmPrice = null;
  }
  formData.bathrooms = parseInt(data.bathrooms);
  formData.bedrooms = parseInt(data.bedrooms);
  formData.builtM2 = data.builtM2.split(".").join(",");
  formData.builtInWardrobe = data.builtInWardrobe;

  if (data.hasStorage === "true" || data.hasStorage === true) {
    formData.hasStorage = true;
  } else if (data.hasStorage === "false" || data.hasStorage === false) {
    formData.hasStorage = false;
  } else {
    formData.hasStorage = null;
  }

  if (data.hasGarage === "true" || data.hasGarage === true) {
    formData.hasGarage = true;
  } else if (data.hasGarage === "false" || data.hasGarage === false) {
    formData.hasGarage = false;
  } else {
    formData.hasGarage = null;
  }

  formData.hasFurnishedKitchen = data.hasFurnishedKitchen;
  formData.hasElevator = data.hasElevator;
  formData.hasAirConditioning = data.hasAirConditioning;
  formData.hasHeating = data.hasHeating;
  formData.hasTerrace = data.hasTerrace;
  // formData.terraceSurface = parseFloat(data.terraceSurface);
  if (!!data.terraceSurface || data.terraceSurface === 0) {
    if (data.terraceSurface !== 0) {
      formData.terraceSurface = parseFloat(
        data.terraceSurface.replace(",", ".")
      );
    } else {
      formData.terraceSurface = 0;
    }
  } else {
    formData.terraceSurface = null;
  }
  formData.hasCommunityGarden = data.hasCommunityGarden;
  formData.hasOwnGarden = data.hasOwnGarden;
  formData.hasCommunityPool = data.hasCommunityPool;
  formData.hasOwnPool = data.hasOwnPool;
  formData.hasIntercom = data.hasIntercom;
  formData.hasPorter = data.hasPorter;
  formData.garageType = parseInt(data.garageType);
  formData.managementCompanyId = data.managementCompanyPrinexId;
  if (!!data.outDoorCarpentry) {
    formData.outDoorCarpentry = data.outDoorCarpentry;
  } else {
    formData.outDoorCarpentry = null;
  }
  if (!!data.insideCarpentry) {
    formData.insideCarpentry = data.insideCarpentry;
  } else {
    formData.insideCarpentry = null;
  }
  if (!!data.furnishingStatus) {
    formData.furnishingStatus = data.furnishingStatus;
  } else {
    formData.furnishingStatus = null;
  }
  formData.isExterior = data.isExterior;
  if (!!data.floorEDC) {
    formData.floorEDC = data.floorEDC;
  } else {
    formData.floorEDC = null;
  }
  formData.hasBalconyEDC = data.hasBalconyEDC;
  if (!!data.districtEDC) {
    formData.districtEDC = data.districtEDC;
  } else {
    formData.districtEDC = null;
  }
  formData.statusEDC = data.statusEDC;
  formData.isFirstSeaLineEDC = data.isFirstSeaLineEDC;
  formData.isCityCenterEDC = data.isCityCenterEDC;
  if (!!data.heatingType) {
    formData.heatingType = data.heatingType;
  } else {
    formData.heatingType = null;
  }
  if (!!data.buildingYear) {
    formData.buildingYear = parseInt(data.buildingYear);
  } else {
    formData.buildingYear = null;
  }
  if (!!data.heatingEmissions || data.heatingEmissions === 0) {
    if (data.heatingEmissions !== 0) {
      formData.heatingEmissions = parseFloat(
        data.heatingEmissions.replace(",", ".")
      );
    } else {
      formData.heatingEmissions = 0;
    }
  } else {
    formData.heatingEmissions = null;
  }
  if (!!data.emissionsCertification) {
    formData.emissionsCertification = data.emissionsCertification;
  } else {
    formData.emissionsCertification = null;
  }
  if (!!data.buildingOrientation) {
    formData.buildingOrientation = data.buildingOrientation;
  } else {
    formData.buildingOrientation = null;
  }
  if (!!data.certificationCategory) {
    formData.certificationCategory = data.certificationCategory;
  } else {
    formData.certificationCategory = null;
  }

  //Referencia catastral
  if (!!data.cadastralReference) {
    formData.cadastralReference = data.cadastralReference;
  } else {
    formData.cadastralReference = null;
  }

  //Material gráfico
  if (!!data.urlYoutube) {
    formData.urlYoutube = data.urlYoutube;
  } else {
    formData.urlYoutube = null;
  }
  if (!!data.cloudVideo) {
    formData.cloudVideo = data.cloudVideo;
  } else {
    formData.cloudVideo = null;
  }
  return formData;
};

export const buildFormDataEditTercerosAsset = (data) => {
  let crmPriceToChange = data.crmPrice.toString();
  const formData = {
    id: Number(data?.id),
    //GENERAL DATA
    managementCompanyId: data?.managementCompanyId,
    promotionName: data?.promotionName,
    //LOCALIZACION
    typeOfRoad: data?.typeOfRoad,
    road: data?.road,
    number: data?.number,
    floor: data?.floor,
    door: data?.door,
    stair: data?.stair,
    block: data?.block,
    postalCode: data?.postalCode,
    municipalityId: data?.municipalityId,
    latitude: data?.latitude,
    longitude: data?.longitude,
    district: data?.district,
    hideNumber: data?.hideNumber ? data?.hideNumber : false,
    hideAddress: data?.hideAddress ? data?.hideAddress : false,
    commuterTrain: data?.commuterTrain,
    bus: data?.bus,
    highway: data?.highway,
    //CARACTERISTICAS INMUEBLE
    description: data?.description,
    descriptionEN: data?.descriptionEN,
    descriptionFR: data?.descriptionFR,
    delegationId: Number(data?.delegationId),
    commercialNetType: Number(data?.commercialNetType),
    subTypeId: data?.subTypeId,
    crmPrice: parseFloat(crmPriceToChange.replace(",", ".")),
    bathrooms: Number(data?.bathrooms),
    bedrooms: Number(data?.bedrooms),
    builtM2: data?.builtM2.toString(),
    builtInWardrobe: data?.builtInWardrobe ? data?.builtInWardrobe : false,
    hasStorage: data?.hasStorage === "true" ? true : false,
    hasFurnishedKitchen: data?.hasFurnishedKitchen
      ? data?.hasFurnishedKitchen
      : false,
    hasElevator: data?.hasElevator ? data?.hasElevator : false,
    hasAirConditioning: data?.hasAirConditioning
      ? data?.hasAirConditioning
      : false,
    hasHeating: data?.hasHeating ? data?.hasHeating : false,
    hasTerrace: data?.hasTerrace ? data?.hasTerrace : false,
    terraceSurface: Number(data?.terraceSurface),
    hasCommunityGarden: data?.hasCommunityGarden
      ? data?.hasCommunityGarden
      : false,
    hasOwnGarden: data?.hasOwnGarden ? data?.hasOwnGarden : false,
    hasCommunityPool: data?.hasCommunityPool ? data?.hasCommunityPool : false,
    hasOwnPool: data?.hasOwnPool ? data?.hasOwnPool : false,
    hasIntercom: data?.hasIntercom ? data?.hasIntercom : false,
    hasPorter: data?.hasPorter ? data?.hasPorter : false,
    hasGarage: data?.hasGarage === "true" ? true : false,
    garageType: Number(data?.garageType),
    outDoorCarpentry: data?.outDoorCarpentry,
    insideCarpentry: data?.insideCarpentry,
    furnishingStatus: data?.furnishingStatus,
    isExterior: data?.isExterior ? data?.isExterior : false,
    floorEDC: data?.floorEDC,
    hasBalconyEDC: data?.hasBalconyEDC ? data?.hasBalconyEDC : false,
    districtEDC: data?.districtEDC,
    statusEDC: data?.statusEDC,
    isFirstSeaLineEDC: data?.isFirstSeaLineEDC
      ? data?.isFirstSeaLineEDC
      : false,
    isCityCenterEDC: data?.isCityCenterEDC ? data?.isCityCenterEDC : false,
    heatingType: data?.heatingType,
    buildingYear: Number(data?.buildingYear),
    heatingEmissions: Number(data?.heatingEmissions),
    emissionsCertification: data?.emissionsCertification,
    buildingOrientation: data?.buildingOrientation,
    certificationCategory: data?.certificationCategory,
    isBrandNewConstruction:
      data?.isBrandNewConstruction === "true" ? true : false,
    //REFERENCIA CATASTRAL
    cadastralReference: data?.cadastralReference,
    //MATERIAL GRAFICO
    urlYoutube: data?.urlYoutube,
    cloudVideo: data?.cloudVideo,
    urlDossier: data?.dossier,
    //MARKETING
    isHighlighted: data?.isHighlighted,
  };

  return formData;
};

/**
 * Valid required fields
 * @param {*} data
 * @returns
 */
export const validateRequiredFields = (data, id) => {
  let emptyRequredFields = [];
  if (!data.managementCompanyId) {
    emptyRequredFields.push({
      name: "managementCompanyId",
      label: "Sociedad Propietaria",
    });
  }
  if (!data.typeOfRoad || data.typeOfRoad === "-Seleccionar-") {
    emptyRequredFields.push({
      name: "typeOfRoad",
      label: "Tipo de vía",
    });
  }
  if (!data.road) {
    emptyRequredFields.push({
      name: "road",
      label: "Vía",
    });
  }
  if (!data.number) {
    emptyRequredFields.push({
      name: "number",
      label: "Número",
    });
  }
  if (!data.floor) {
    emptyRequredFields.push({
      name: "floor",
      label: "Piso",
    });
  }
  if (!data.door) {
    emptyRequredFields.push({
      name: "door",
      label: "Puerta",
    });
  }
  if (!data.postalCode) {
    emptyRequredFields.push({
      name: "postalCode",
      label: "Código postal",
    });
  }
  if (!data.municipalityId) {
    emptyRequredFields.push({
      name: "municipalityId",
      label: "Municipio",
    });
  }
  if (!data.latitude) {
    emptyRequredFields.push({
      name: "latitude",
      label: "Latitud",
    });
  }
  if (!data.longitude) {
    emptyRequredFields.push({
      name: "longitude",
      label: "Longitud",
    });
  }
  if (!data.commercialNetType) {
    emptyRequredFields.push({
      name: "commercialNetType",
      label: "Tipo de red comercial",
    });
  }
  if (!data.subTypeId) {
    emptyRequredFields.push({
      name: "subTypeId",
      label: "Subtipo",
    });
  }
  if (!data.crmPrice) {
    emptyRequredFields.push({
      name: "crmPrice",
      label: "Precio",
    });
  }
  if (!data.bathrooms && data.bathrooms !== 0) {
    emptyRequredFields.push({
      name: "bathrooms",
      label: "Número de baños",
    });
  }
  if (!data.bedrooms && data.bedrooms !== 0) {
    emptyRequredFields.push({
      name: "bedrooms",
      label: "Número de dormitorios",
    });
  }
  if (!data.builtM2) {
    emptyRequredFields.push({
      name: "builtM2",
      label: "m² construídos",
    });
  }
  if (!data.statusEDC) {
    emptyRequredFields.push({
      name: "statusEDC",
      label: "Estado",
    });
  }
  if (id && !data.delegationId) {
    emptyRequredFields.push({
      name: "delegationId",
      label: "Delegación",
    });
  }
  if (data.hasStorage === "" || data.hasStorage === undefined) {
    emptyRequredFields.push({
      name: "hasStorage",
      label: "Trastero",
    });
  }
  if (data.hasGarage === "" || data.hasGarage === undefined) {
    emptyRequredFields.push({
      name: "hasGarage",
      label: "Garaje",
    });
  }

  return emptyRequredFields;
};

export const getMetadataAsset = (id) => {
  return [
    {
      label: "Datos generales del activo",
      id: "generalData",
      collapsable: true,
      metadata: [
        {
          label: "Id (interno)",
          name: "id",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Id Prínex",
          name: "prinexId",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Expediente",
          name: "dossierCode",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Inmueble",
          name: "propertyCode",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Id Promoción Prínex",
          name: "promotionCode",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Nombre de Promoción Prínex",
          name: "promotionName",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Línea Negocio",
          name: "businessLine",
          type: "textFromList",
          typeList: "Normal",
          listName: "businessLines",
          propertyList: "translation",
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Sociedad Propietaria Id",
          name: "managementCompanyPrinexId",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Sociedad Propietaria Nombre",
          name: "managementCompany",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Sociedad Propietaria NIF",
          name: "managementCompanyNif",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Sociedad Propietaria Domi",
          name: "managementCompanyAddress",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Estado Comercial",
          name: "commercialStatusId",
          type: "textFromList",
          typeList: "Normal",
          listName: "appCommercialStatus",
          propertyList: "name",
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Particulares",
      id: "privates",
      collapsable: true,
      metadata: [
        {
          label: "OK PBC",
          name: "okPBC",
          type: "list",
          typeList: "Normal",
          listName: "nulleableList",
          propertyId: "value",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "OK Registral",
          name: "okRegistral",
          type: "list",
          typeList: "Normal",
          listName: "nulleableList",
          propertyId: "value",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Fecha firma del mandato",
          name: "mandateDateSign",
          type: "date",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          visibleFor: [
            portfolios.name.quasar,
            portfolios.name.ns,
            portfolios.name.anticipa,
          ],
        },
        {
          label: "Modalidad de mandato",
          name: "mandateModality",
          type: "sampleList",
          listName: "mandateModality",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Duración del mandato",
          name: "mandateDuration",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Localización",
      id: "localization",
      collapsable: true,
      metadata: [
        {
          label: "Tipo de vía*",
          name: "typeOfRoad",
          required: true,
          type: "sampleList",
          listName: "typeOfRoads",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Vía*",
          name: "road",
          required: true,
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Número*",
          name: "number",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          min: 0,
          languageSensitive: false,
        },
        {
          label: "Piso*",
          name: "floor",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Puerta*",
          name: "door",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Escalera",
          name: "stair",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Bloque",
          name: "block",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Código postal*",
          name: "postalCode",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Dirección completa",
          name: "fullAddress",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Provincia INE*",
          name: "provinceId",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "appProvinces",
          propertyId: "id",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Municipio INE*",
          name: "municipalityId",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "auxMunicipalities",
          propertyId: "id",
          propertyName: "name",
          disabled: id ? disabledFalse : disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Latitud*",
          name: "latitude",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Longitud*",
          name: "longitude",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Mapa",
          name: "map",
          type: "map",
          lat: "latitude",
          lng: "longitude",
        },
        {
          label: "Distrito",
          name: "district",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Ocultar número",
          name: "hideNumber",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Ocultar dirección",
          name: "hideAddress",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Cercanías",
          name: "commuterTrain",
          type: "text",
          disabled: notPermissionFieldsForm,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Autobús",
          name: "bus",
          type: "text",
          disabled: notPermissionFieldsForm,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Carretera",
          name: "highway",
          type: "text",
          disabled: notPermissionFieldsForm,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Características del inmueble",
      id: "features",
      collapsable: true,
      metadata: [
        {
          label: "Activo",
          name: "name",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Descripción activo",
          name: "description",
          type: "texteditor",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: true,
        },
        {
          label: "Delegación",
          name: "delegationId",
          type: "list",
          typeList: "Normal",
          listName: "delegationList",
          propertyId: "id",
          propertyName: "name",
          disabled: isSuperAdmin && id ? disabledFalse : disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Forzado",
          name: "delegationForced",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Tipo de red comercial*",
          name: "commercialNetType",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "commercialNetType",
          propertyId: "Id",
          propertyName: "translation",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Tipo*",
          name: "typeId",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "assetType",
          propertyId: "Id",
          propertyName: "translation",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Subtipo*",
          name: "subTypeId",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "auxAssetSubTypes",
          propertyId: "id",
          propertyName: "name",
          disabled: id ? disabledFalse : disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Precio*",
          name: "crmPrice",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          addon: "€",
        },
        {
          label: "Número de baños*",
          name: "bathrooms",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "Número de dormitorios*",
          name: "bedrooms",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "m² construídos*",
          name: "builtM2",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Armarios empotrados",
          name: "builtInWardrobe",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "Trastero*",
          name: "hasStorage",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "nulleableList",
          propertyId: "value",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Cocina amueblada",
          name: "hasFurnishedKitchen",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Ascensor",
          name: "hasElevator",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Aire acondicionado",
          name: "hasAirConditioning",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Calefacción",
          name: "hasHeating",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Terraza",
          name: "hasTerrace",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Superficie de la terraza",
          name: "terraceSurface",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Jardín comunitario",
          name: "hasCommunityGarden",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Jardín propio",
          name: "hasOwnGarden",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Piscina comunitaria",
          name: "hasCommunityPool",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Piscina propia",
          name: "hasOwnPool",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Portero automático",
          name: "hasIntercom",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Portero físico",
          name: "hasPorter",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Garaje*",
          name: "hasGarage",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "nulleableList",
          propertyId: "value",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Tipo de Garaje",
          name: "garageType",
          type: "list",
          typeList: "Normal",
          listName: "garageType",
          propertyId: "Id",
          propertyName: "translation",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Carpintería exterior",
          name: "outDoorCarpentry",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Carpintería interior",
          name: "insideCarpentry",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Estado de mobiliario (amueblamiento)",
          name: "furnishingStatus",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Exterior",
          name: "isExterior",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Planta",
          name: "floorEDC",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Balcón",
          name: "hasBalconyEDC",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Barrio",
          name: "districtEDC",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Estado*",
          name: "statusEDC",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Primera línea de mar",
          name: "isFirstSeaLineEDC",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Centro ciudad",
          name: "isCityCenterEDC",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Tipo de calefacción",
          name: "heatingType",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Año de construcción",
          name: "buildingYear",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "Emisiones co2/m2",
          name: "heatingEmissions",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "Etiqueta de emisiones",
          name: "emissionsCertification",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Orientación del activo",
          name: "buildingOrientation",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Letra Energética",
          name: "certificationCategory",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "A estrenar obra nueva",
          name: "isBrandNewConstruction",
          type: "list",
          typeList: "Normal",
          listName: "nulleableList",
          propertyId: "value",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Ref. Catastral",
      id: "catastralRef",
      collapsable: true,
      metadata: [
        {
          label: "Ref. Catastral",
          name: "cadastralReference",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Material gráfico",
      id: "graphicData",
      collapsable: true,
      metadata: [
        {
          label: "URL Youtube",
          name: "urlYoutube",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Video Cloud",
          name: "cloudVideo",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "URL TourVirtual",
          name: "urlVirtualTour",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Plano",
          name: "hasPlans",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Decorview",
          name: "decorview",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Fotos Decorview",
          name: "decorviewImages",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,

          languageSensitive: false,
        },
        {
          label: "Número de Fotos",
          name: "numberOfPhotos",
          type: "number",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "Letras de las fotos",
          name: "photoLetters",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Fotos Plano",
          name: "schemeImages",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Dossier",
          name: "dossier",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Validación fotos",
          name: "areValidPhotos",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Observaciones fotos",
          name: "photoDetails",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Comentarios fotos",
          name: "photoComments",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Fecha última modificación Floorfy",
          name: "lastFloorfyModificationDate",
          type: "dateText",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Auditoría",
      id: "audit",
      collapsable: true,
      metadata: [
        {
          label: "Fecha Creación",
          name: "creationTime",
          type: "dateText",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Usuario Creación",
          name: "creatorId",
          type: "textFromList",
          typeList: "Normal",
          listName: "userList",
          propertyList: "name",
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Fecha Última Modificación",
          name: "lastModificationTime",
          type: "dateText",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Usuario Modificación",
          name: "lastModifierId",
          type: "textFromList",
          typeList: "Normal",
          listName: "userList",
          propertyList: "name",
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Fecha Borrado",
          name: "deletionTime",
          type: "dateText",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: true,
        },
        {
          label: "Usuario Borrado",
          name: "deleterId",
          type: "textFromList",
          typeList: "Normal",
          listName: "userList",
          propertyList: "name",
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
  ];
};

export const getMetadataNewActivoTerceros = (id) => {
  return [
    {
      label: "Datos generales del activo",
      id: "generalData",
      collapsable: true,
      metadata: [
        {
          label: "Id (interno)",
          name: "id",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Id Prínex",
          name: "prinexId",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Expediente",
          name: "dossierCode",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Inmueble",
          name: "propertyCode",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Id Promoción Prínex",
          name: "promotionCode",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Nombre de Promoción Prínex",
          name: "promotionName",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Línea Negocio",
          name: "businessLine",
          type: "textFromList",
          typeList: "Normal",
          listName: "businessLines",
          propertyList: "translation",
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Sociedad Propietaria Id",
          name: "managementCompanyId",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "managmentCompanyList",
          propertyId: "Id",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Sociedad Propietaria Nombre",
          name: "managementCompany",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Sociedad Propietaria NIF",
          name: "managementCompanyNif",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Sociedad Propietaria Domi",
          name: "managementCompanyAddress",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Estado Comercial",
          name: "commercialStatusId",
          type: "textFromList",
          typeList: "Normal",
          listName: "appCommercialStatus",
          propertyList: "name",
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Localización",
      id: "localization",
      collapsable: true,
      metadata: [
        {
          label: "Tipo de vía*",
          name: "typeOfRoad",
          required: true,
          type: "sampleList",
          listName: "typeOfRoads",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Vía*",
          name: "road",
          required: true,
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Número*",
          name: "number",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          min: 0,
          languageSensitive: false,
        },
        {
          label: "Piso*",
          name: "floor",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Puerta*",
          name: "door",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Escalera",
          name: "stair",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Bloque",
          name: "block",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Código postal*",
          name: "postalCode",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Dirección completa",
          name: "fullAddress",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Provincia INE*",
          name: "provinceId",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "appProvinces",
          propertyId: "id",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Municipio INE*",
          name: "municipalityId",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "auxMunicipalities",
          propertyId: "id",
          propertyName: "name",
          disabled: id ? disabledFalse : disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Latitud*",
          name: "latitude",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Longitud*",
          name: "longitude",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Mapa",
          name: "map",
          type: "map",
          lat: "latitude",
          lng: "longitude",
        },
        {
          label: "Distrito",
          name: "district",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Ocultar número",
          name: "hideNumber",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Ocultar dirección",
          name: "hideAddress",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Cercanías",
          name: "commuterTrain",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Autobús",
          name: "bus",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Carretera",
          name: "highway",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Características del inmueble",
      id: "features",
      collapsable: true,
      metadata: [
        {
          label: "Activo",
          name: "name",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Descripción activo",
          name: "description",
          type: "texteditor",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: true,
        },
        {
          label: "Delegación",
          name: "delegationId",
          type: "list",
          typeList: "Normal",
          listName: "delegationList",
          propertyId: "id",
          propertyName: "name",
          disabled: isSuperAdmin && id ? disabledFalse : disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Forzado",
          name: "delegationForced",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Tipo de red comercial*",
          name: "commercialNetType",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "commercialNetType",
          propertyId: "Id",
          propertyName: "translation",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Tipo*",
          name: "typeId",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "assetType",
          propertyId: "Id",
          propertyName: "translation",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Subtipo*",
          name: "subTypeId",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "auxAssetSubTypes",
          propertyId: "id",
          propertyName: "name",
          disabled: id ? disabledFalse : disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Precio*",
          name: "crmPrice",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          addon: "€",
        },
        {
          label: "Número de baños*",
          name: "bathrooms",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "Número de dormitorios*",
          name: "bedrooms",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "m² construídos*",
          name: "builtM2",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Armarios empotrados",
          name: "builtInWardrobe",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "Trastero*",
          name: "hasStorage",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "nulleableList",
          propertyId: "value",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Cocina amueblada",
          name: "hasFurnishedKitchen",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Ascensor",
          name: "hasElevator",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Aire acondicionado",
          name: "hasAirConditioning",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Calefacción",
          name: "hasHeating",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Terraza",
          name: "hasTerrace",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Superficie de la terraza",
          name: "terraceSurface",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Jardín comunitario",
          name: "hasCommunityGarden",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Jardín propio",
          name: "hasOwnGarden",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Piscina comunitaria",
          name: "hasCommunityPool",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Piscina propia",
          name: "hasOwnPool",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Portero automático",
          name: "hasIntercom",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Portero físico",
          name: "hasPorter",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Garaje*",
          name: "hasGarage",
          required: true,
          type: "list",
          typeList: "Normal",
          listName: "nulleableList",
          propertyId: "value",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Tipo de Garaje",
          name: "garageType",
          type: "list",
          typeList: "Normal",
          listName: "garageType",
          propertyId: "Id",
          propertyName: "translation",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Carpintería exterior",
          name: "outDoorCarpentry",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Carpintería interior",
          name: "insideCarpentry",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Estado de mobiliario (amueblamiento)",
          name: "furnishingStatus",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Exterior",
          name: "isExterior",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Planta",
          name: "floorEDC",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Balcón",
          name: "hasBalconyEDC",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Barrio",
          name: "districtEDC",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Estado*",
          name: "statusEDC",
          required: true,
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Primera línea de mar",
          name: "isFirstSeaLineEDC",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Centro ciudad",
          name: "isCityCenterEDC",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Tipo de calefacción",
          name: "heatingType",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Año de construcción",
          name: "buildingYear",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "Emisiones co2/m2",
          name: "heatingEmissions",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "Etiqueta de emisiones",
          name: "emissionsCertification",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Orientación del activo",
          name: "buildingOrientation",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Letra Energética",
          name: "certificationCategory",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "A estrenar obra nueva",
          name: "isBrandNewConstruction",
          type: "list",
          typeList: "Normal",
          listName: "nulleableList",
          propertyId: "value",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Ref. Catastral",
      id: "catastralRef",
      collapsable: true,
      metadata: [
        {
          label: "Ref. Catastral",
          name: "cadastralReference",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Material gráfico",
      id: "graphicData",
      collapsable: true,
      metadata: [
        {
          label: "URL Youtube",
          name: "urlYoutube",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Video Cloud",
          name: "cloudVideo",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "URL TourVirtual",
          name: "urlVirtualTour",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Plano",
          name: "hasPlans",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Decorview",
          name: "decorview",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Fotos Decorview",
          name: "decorviewImages",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,

          languageSensitive: false,
        },
        {
          label: "Número de Fotos",
          name: "numberOfPhotos",
          type: "number",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
          min: 0,
        },
        {
          label: "Letras de las fotos",
          name: "photoLetters",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Fotos Plano",
          name: "schemeImages",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          // hidden: id ? hiddenFilter : hiddenTrue,
          languageSensitive: false,
        },
        {
          label: "Dossier",
          name: "dossier",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Marketing",
      id: "marketing",
      collapsable: true,
      metadata: [
        {
          label: "Destacado Promoción",
          name: "isHighlighted",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Auditoría",
      id: "audit",
      collapsable: true,
      metadata: [
        {
          label: "Fecha Creación",
          name: "creationTime",
          type: "dateText",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Usuario Creación",
          name: "creatorId",
          type: "textFromList",
          typeList: "Normal",
          listName: "userList",
          propertyList: "name",
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Fecha Última Modificación",
          name: "lastModificationTime",
          type: "dateText",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Usuario Modificación",
          name: "lastModifierId",
          type: "textFromList",
          typeList: "Normal",
          listName: "userList",
          propertyList: "name",
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Fecha Borrado",
          name: "deletionTime",
          type: "dateText",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: true,
        },
        {
          label: "Usuario Borrado",
          name: "deleterId",
          type: "textFromList",
          typeList: "Normal",
          listName: "userList",
          propertyList: "name",
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
  ];
};
