import React, { Fragment, useState } from "react";
import * as API from "../../api/index";
import PropTypes from "prop-types";
import { Button, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faMinus,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useLoader } from "../Loading";
import { toast } from "react-toastify";

export const ModalValidarCreacionProducto = ({
  message,
  cancel,
  data,
  selectedIds,
  idProduct,
}) => {
  const [openValidations, setOpenValidations] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();

  const handleChangeOpenValidation = (validation) => {
    if (openValidations.includes(validation)) {
      let tmpOpenValidations = [...openValidations];
      var ic = tmpOpenValidations.indexOf(validation);
      tmpOpenValidations.splice(ic, 1);
      if (ic > -1) setOpenValidations(tmpOpenValidations);
    } else {
      let tmpOpenValidations = [...openValidations];
      tmpOpenValidations.push(validation);
      setOpenValidations(tmpOpenValidations);
    }
  };

  const selectServiceDownloadDocument = () => {
    if (selectedIds?.length > 0) {
      downloadDocumentBuscadorValidacionProducto();
    } else {
      downloadDocumentDetalleValidacionProducto();
    }
  };

  const downloadDocumentBuscadorValidacionProducto = () => {
    const selectedIdsNumbers = selectedIds.map((str) => parseInt(str, 10));
    let data = {
      iDs: selectedIdsNumbers,
    };
    showLoader();
    API.validarCreacionProductosDownloadDocument(data)
      .then((res) => {
        const contentType =
          res.headers["content-type"] || "application/octet-stream";
        const url = URL.createObjectURL(
          new Blob([res.data], { type: contentType })
        );
        let fileName = "Plantilla de creación de producto";
        const contentDisposition = res.headers["content-disposition"];
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (fileNameMatch != null && fileNameMatch[1]) {
            fileName = fileNameMatch[1].replace(/['"]/g, "");
          }
        }
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = decodeURIComponent(fileName);
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const downloadDocumentDetalleValidacionProducto = () => {
    showLoader();
    API.validarDetalleProductosDownloadDocument(idProduct)
      .then((res) => {
        const contentType =
          res.headers["content-type"] || "application/octet-stream";
        const url = URL.createObjectURL(
          new Blob([res.data], { type: contentType })
        );
        let fileName = "Plantilla de creación de producto";
        const contentDisposition = res.headers["content-disposition"];
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (fileNameMatch != null && fileNameMatch[1]) {
            fileName = fileNameMatch[1].replace(/['"]/g, "");
          }
        }
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = decodeURIComponent(fileName);
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  return (
    <Modal show={true} onHide={cancel} size="xl">
      {loader}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table
          bordered
          hover
          style={{
            border: "thin solid lightgray",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          striped
        >
          <thead className="bg-light sticky-top top-0">
            <tr>
              <th></th>
              <th>Validación</th>
              <th>Resultado</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([key, value], index) => (
              <Fragment>
                <tr key={index}>
                  {value.errors.length > 0 && openValidations.includes(key) && (
                    <td
                      style={{ cursor: "pointer", width: "2rem" }}
                      onClick={() => handleChangeOpenValidation(key)}
                    >
                      <FontAwesomeIcon
                        style={{ height: "1rem" }}
                        icon={faMinus}
                        size="lg"
                        fixedWidth
                      />
                    </td>
                  )}
                  {value.errors.length > 0 &&
                    !openValidations.includes(key) && (
                      <td
                        style={{ cursor: "pointer", width: "2rem" }}
                        onClick={() => handleChangeOpenValidation(key)}
                        key={index}
                      >
                        <FontAwesomeIcon
                          style={{ height: "1rem" }}
                          icon={faPlus}
                          size="lg"
                          fixedWidth
                        />
                      </td>
                    )}
                  {value.errors.length === 0 && <td></td>}
                  <td>{key}</td>
                  <td>
                    {value.isValid === true
                      ? "Válido (true)"
                      : "Inválido (false)"}
                  </td>
                </tr>
                {openValidations.includes(key) && (
                  <tr>
                    <td colspan="3">
                      <Table style={{ width: "100%" }}>
                        <tr>
                          <th>Errores:</th>
                        </tr>
                        {value.errors.map((error, i) => {
                          return (
                            <tr key={i}>
                              <td>{error}</td>
                            </tr>
                          );
                        })}
                      </Table>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => selectServiceDownloadDocument()}
          variant="success"
        >
          <span>
            <FontAwesomeIcon icon={faDownload} size="lg" fixedWidth />
          </span>
          Descargar
        </Button>
        {cancel && (
          <Button onClick={cancel} variant="danger">
            <span>
              <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
            </span>
            Cerrar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalValidarCreacionProducto.propTypes = {
  message: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
