import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Table, Form } from "react-bootstrap";
import { faLayerGroup, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ModalDesagruparProducto = ({
  message,
  cancel,
  data,
  defaultAsset,
  confirmDesagrupar,
  confirmDesagruparTodos,
}) => {
  const [selected, setSelected] = useState([]);

  const handleChange = (rowId, checked) => {
    let tmpSelected = [...selected];
    if (checked) {
      tmpSelected.push(rowId);
      setSelected(tmpSelected);
    } else {
      var ic = tmpSelected.indexOf(rowId);
      tmpSelected.splice(ic, 1);
      if (ic > -1) setSelected(tmpSelected);
    }
  };

  return (
    <Modal show={true} onHide={cancel} size="xl">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            maxHeight: 550,
            overflowY: "auto",
          }}
        >
          <Table
            striped
            bordered
            hover
            style={{
              border: "thin solid lightgray",
              whiteSpace: "nowrap",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <thead className="bg-light sticky-top top-0">
              <tr>
                <th>Extraer</th>
                <th>Activo Prínex</th>
                <th>Nombre Activo</th>
                <th>Inmueble</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, indexRow) => (
                <tr key={indexRow}>
                  <td>
                    <Form.Check
                      onChange={(e) => {
                        handleChange(row.id, e.target.checked);
                      }}
                      checked={selected.includes(row.id)}
                    />
                  </td>
                  <td>{row.prinexId}</td>
                  <td>{row.name}</td>
                  <td>{row.propertyCode}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginRight: "1rem" }}
          onClick={() => confirmDesagruparTodos()}
          variant="success"
        >
          <span>
            <FontAwesomeIcon icon={faLayerGroup} size="lg" fixedWidth />
          </span>
          Desagrupar todos
        </Button>
        <Button
          style={{ marginRight: "1rem" }}
          disabled={selected.length === 0}
          onClick={() => confirmDesagrupar(selected)}
          variant="success"
        >
          <span>
            <FontAwesomeIcon icon={faLayerGroup} size="lg" fixedWidth />
          </span>
          Desagrupar seleccionados
        </Button>
        {cancel && (
          <Button onClick={cancel} variant="danger">
            <span>
              <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
            </span>
            Cancelar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalDesagruparProducto.propTypes = {
  message: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  defaultAsset: PropTypes.number.isRequired,
  confirmDesagrupar: PropTypes.func.isRequired,
  confirmDesagruparTodos: PropTypes.func.isRequired,
};
