import React, { useState, useEffect, Fragment } from "react";
import { TableFrontEnd } from "../../components/TableFrontEnd";
import { useLoader } from "../../components/Loading";
import { Card, Form } from "react-bootstrap";
import * as S from "./styles";
import * as API from "../../api";
import { useNavigate } from "react-router-dom";
import { useDataContext } from "../../context/DataState";
import PageTitle from "../../components/PageTitle/PageTitle";
import usePermition from "../../constants/usePermition";

export const Tareas = () => {
  const [loader, showLoader, hideLoader] = useLoader();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const {
    state: { appLists, user },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    if (PERMISOS_POR_ROL.Tareas.Acceso) {
      document.title = "EC-Light-Tareas";
      showLoader();
      API.obtenerTareas()
        .then((res) => {
          showLoader();
          API.obtenerUsuariosList()
            .then((resUsuarios) => {
              let dataAuxList = [];
              let listas = appLists;
              listas["userList"] = resUsuarios.data;
              res.data.forEach((e) => {
                let dataElement = {};
                dataElement.id = e.id;
                if (e.type !== null) {
                  const type = listas["queueType"].find((s) => s.Id === e.type);
                  if (type !== undefined) {
                    dataElement.type = type.translation;
                  }
                }
                if (e.creationTime !== null)
                  dataElement.creationTime = e.creationTime;
                const creator = resUsuarios.data.find(
                  (s) => s.id === e.creatorId
                );
                if (creator !== undefined) {
                  dataElement.creatorId = creator.name;
                }
                if (e.lastModificationTime !== null)
                  dataElement.lastModificationTime = e.lastModificationTime;
                if (e.lastModifierId) {
                  const lastModificator = resUsuarios.data.find(
                    (s) => s.id === e.lastModifierId
                  );
                  if (lastModificator !== undefined) {
                    dataElement.lastModificationId = lastModificator.name;
                  }
                }
                dataElement.inProgress = e.inProgress;
                dataElement.errors = e.errors;

                dataAuxList.push(dataElement);
              });
              setData(dataAuxList);
            })
            .catch((error) => {
              API.DEVELOP && console.log(error);
            })
            .finally(() => hideLoader());
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    } else {
      navigate("/");
    }
  }, [showLoader, hideLoader, navigate]);

  const filterForm = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (
      <Form>
        <Form.Group className="mb-2">
          <span>
            <input
              className="form-control"
              style={{ width: "60%", marginTop: "0.25rem", padding: "0" }}
              value={filterValue || ""}
              onClick={(e) => {
                if (!e) e = window.event;
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => setFilter(e.target.value)}
            />
          </span>
        </Form.Group>
      </Form>
    );
  };

  const formatDate = (dateString) => {
    var date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() + -offset * 60000);
    let d = date;
    d = [
      "0" + d.getDate(),
      "0" + (d.getMonth() + 1),
      "" + d.getFullYear(),
      "0" + d.getHours(),
      "0" + d.getMinutes(),
      "0" + d.getSeconds(),
    ].map((component) => {
      if (component.length < 4) {
        return component.slice(-2);
      } else {
        return component;
      }
    });

    return d.slice(0, 3).join("/") + " " + d.slice(3).join(":");
  };

  const defaultColumn = {
    // Let's set up our default Filter UI
    Filter: filterForm,
  };

  const defaultSort = [
    {
      id: "creationTime",
      desc: true,
    },
  ];

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      Filter: filterForm,
      maxWidth: 50,
    },
    {
      Header: "Tipo",
      accessor: "type",
      Filter: filterForm,
      maxWidth: 90,
    },
    {
      Header: "Fecha Creación",
      accessor: "creationTime",
      Filter: filterForm,
      disableFilters: true,
      maxWidth: 140,
      Cell: ({ cell }) => (
        <span>
          {cell.row.original.creationTime
            ? formatDate(cell.row.original.creationTime)
            : ""}
        </span>
      ),
    },
    {
      Header: "Usuario Creación",
      accessor: "creatorId",
      Filter: filterForm,
    },
    {
      Header: "Fecha Última Modificación",
      accessor: "lastModificationTime",
      disableFilters: true,
      maxWidth: 160,
      Cell: ({ cell }) => (
        <span>
          {cell.row.original.lastModificationTime
            ? formatDate(cell.row.original.lastModificationTime)
            : ""}
        </span>
      ),
    },
    {
      Header: "Usuario Última Modificación",
      accessor: "lastModificationId",
      maxWidth: 200,
    },
    {
      Header: "Progreso",
      accessor: "inProgress",
      disableFilters: true,
      maxWidth: 80,
      Cell: ({ cell }) => (
        <Fragment>
          {cell.row.original.inProgress !== null
            ? !!cell.row.original.inProgress
              ? "En curso"
              : "En espera"
            : "Error"}
        </Fragment>
      ),
    },
    {
      Header: "Error",
      accessor: "errors",
      disableFilters: true,
    },
  ];

  return (
    <S.Container>
      <PageTitle title="Tareas" />
      {loader}
      <Card>
        <Card.Body>
          <TableFrontEnd
            columns={columns}
            data={data}
            defaultSort={defaultSort}
            defaultColumn={defaultColumn}
            hiddenColumns={[]}
            pagination={true}
          />
        </Card.Body>
      </Card>
    </S.Container>
  );
};
