import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Title = styled.span`
  background-color: ${COLORES.TITLE_CAMPAIGN_PROMOTION};
  color: #fff;
  margin-left: 2px;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 1.3rem;
`;

export const ContainerElement = styled.div`
  margin: 0 15px;
  padding: 1rem;
  input[type="checkbox"],
  input[type="radio"] {
    cursor: pointer;
  }

  #idiomsContainer {
    margin-right: 1rem;
  }

  .assets-btn-container {
    margin: 0 0 1rem 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const HeaderPageWithBack = styled.div`
  display: inline-flex;
  button {
    margin-right: 15px;
  }
`;
