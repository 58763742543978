import React, { useState, useEffect, Fragment } from "react";
import { TableFrontEnd } from "../../components/TableFrontEnd";
import { ModalForm } from "../../components/ModalForm";
import { Dialog } from "../../components/Dialog";
import { useLoader } from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEdit,
  faPlus,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Button, Form } from "react-bootstrap";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import {
  METADATA_EDICION_SOCIEDAD,
  METADATA_CREACION_SOCIEDAD,
  METADATA_AUDIT_SOCIEDAD,
} from "./constants";
import { ModalAudit } from "../../components/ModalAudit";
import { useDataContext } from "../../context/DataState";
import { CardHeaderContainer } from "../Buscador/styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import { hiddenFalse, hiddenTrue, portfolios } from "../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePermition from "../../constants/usePermition";

export const SociedadesGestoras = () => {
  const [values, setValues] = useState({});
  const [valuesAudit, setValuesAudit] = useState({});
  const [dialogEditarSociedadVisible, setDialogEditarSociedadVisible] =
    useState(false);
  const [dialogCrearSociedadVisible, setDialogCrearSociedadVisible] =
    useState(false);
  const [dialogBorrarSociedadVisible, setDialogBorrarSociedadVisible] =
    useState(false);
  const [dialogAuditSociedadVisible, setDialogAuditSociedadVisible] =
    useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [users, setUsers] = useState([]);
  const [valueDelete, setValueDelete] = useState("");
  const {
    state: { appLists, user },
  } = useDataContext();
  const [mdCreate, setMDCreate] = useState(METADATA_CREACION_SOCIEDAD);
  const [mdEdit, setMDEdit] = useState(METADATA_EDICION_SOCIEDAD);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    document.title = "EC-Light-Sociedades Gestoras";

    if (!PERMISOS_POR_ROL.SociedadesGestoras.Acceso) {
      navigate("/");
    }
    if (!data.length) {
      showLoader();
      API.obtenerUsuariosList()
        .then((res) => {
          setUsers(res.data);
          obtenerSociedadesGestoras();
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    }
  }, []);

  const obtenerSociedadesGestoras = () => {
    showLoader();
    API.obtenerSociedadesGestoras()
      .then((res) => {
        let dataAuxList = [];
        res.data.forEach((e) => {
          const dataAux = {};
          dataAux.id = e.id;
          dataAux.prinexId = e.prinexId;
          dataAux.name = e.name;
          dataAux.portfolioId = e.portfolioId;
          dataAux.portfolioName = appLists.appPortfolios.find(
            (element) => element.id === e.portfolioId
          ).name;
          dataAux.cif = e.cif;
          dataAux.numAssets = e.numAssets;
          dataAux.address = e.address;
          dataAux.municipalityName = e.municipalityName;
          dataAux.isExcent = e.isExcent;
          dataAux.isSareb = e.isSareb;
          dataAux.creatorId = e.creatorId;
          if (e.creationTime !== null) dataAux.creationTime = e.creationTime;
          dataAux.lastModifierId = e.lastModifierId;
          dataAux.lastModificationTime = e.lastModificationTime;
          dataAuxList.push(dataAux);
        });
        setData(dataAuxList);
        setFilteredData(dataAuxList);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const formatDate = (dateString) => {
    var date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() + -offset * 60000);
    let d = date;
    d = [
      "0" + d.getDate(),
      "0" + (d.getMonth() + 1),
      "" + d.getFullYear(),
      "0" + d.getHours(),
      "0" + d.getMinutes(),
      "0" + d.getSeconds(),
    ].map((component) => {
      if (component.length < 4) {
        return component.slice(-2);
      } else {
        return component;
      }
    });

    return d.slice(0, 3).join("/") + " " + d.slice(3).join(":");
  };

  const FilterForm = ({ column }) => {
    const { filterValue, setFilter } = column;

    useEffect(() => {
      const value = searchParams.get(
        "filter" + column.Header.split(" ").join("")
      );
      if (value) {
        setFilter(value);
      }
    }, []);

    return (
      <Form>
        <Form.Group className="mb-2">
          <span>
            <input
              id={"filter" + column.Header.split(" ").join("")}
              className="form-control"
              style={{ width: "60%", marginTop: "0.25rem", padding: "0" }}
              value={filterValue || ""}
              onClick={(e) => {
                if (!e) e = window.event;
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => setFilter(e.target.value)}
              autoComplete="off"
            />
          </span>
        </Form.Group>
      </Form>
    );
  };

  const defaultColumn = {
    // Let's set up our default Filter UI
    Filter: FilterForm,
  };

  const defaultSort = [
    {
      id: "id",
      desc: false,
    },
  ];

  const columns = [
    {
      Header: "",
      accessor: "edit",
      disableFilters: true,
      disableSortBy: true,
      maxWidth: 50,
      Cell: ({ cell }) => (
        <Fragment>
          <button
            className="tableButton"
            onClick={() => showModalEdit(cell)}
            hidden={!PERMISOS_POR_ROL.SociedadesGestoras.Crud}
          >
            <span title="Editar">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEdit}
                size="lg"
                fixedWidth
              />
            </span>
          </button>
          <button
            style={{ marginLeft: "0.5rem" }}
            className="tableButton"
            onClick={() => showModalAudit(cell)}
            hidden={!PERMISOS_POR_ROL.SociedadesGestoras.Auditoria}
          >
            <span title="Auditoría">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faPrint}
                size="lg"
                fixedWidth
              />
            </span>
          </button>
        </Fragment>
      ),
    },
    {
      Header: "Id",
      accessor: "id",
      maxWidth: 80,
      Filter: FilterForm,
    },
    {
      Header: "Id Sociedad",
      accessor: "prinexId",
      maxWidth: 80,
      Filter: FilterForm,
    },
    {
      Header: "Sociedad",
      accessor: "name",
      Filter: FilterForm,
    },
    {
      Header: "Id de Cartera",
      accessor: "portfolioId",
      Filter: FilterForm,
    },
    {
      Header: "Cartera",
      accessor: "portfolioName",
      Filter: FilterForm,
      maxWidth: 100,
    },
    {
      Header: "CIF",
      accessor: "cif",
      Filter: FilterForm,
      maxWidth: 140,
    },
    {
      Header: "Nº activos",
      accessor: "numAssets",
      Filter: FilterForm,
      maxWidth: 80,
    },
    {
      Header: "Domicilio",
      accessor: "address",
    },
    {
      Header: "Municipio",
      accessor: "municipalityName",
    },
    {
      Header: "Excent",
      accessor: "isExcent",
    },
    {
      Header: "Sareb",
      accessor: "isSareb",
    },
    {
      Header: "Usuario Creación",
      accessor: "creatorId",
    },
    {
      Header: "Fecha Creación",
      accessor: "creationTime",
      disableFilters: true,
      Cell: ({ cell }) => (
        <span>{formatDate(cell.row.original.creationTime)}</span>
      ),
    },
    {
      Header: "Usuario Última Modificación",
      accessor: "lastModifierId",
    },
    {
      Header: "Fecha Última Modificación",
      accessor: "lastModificationTime",
    },
    {
      Header: "",
      accessor: "delete",
      disableFilters: true,
      disableSortBy: true,
      maxWidth: 40,
      Cell: ({ cell }) => (
        <button
          className="tableButton"
          onClick={() => showModalDelete(cell.row.original.id)}
        >
          <span title="Borrar">
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={faTrashAlt}
              size="lg"
              fixedWidth
            />
          </span>
        </button>
      ),
    },
  ];

  const showModalCreate = () => {
    setValues({});
    setDialogCrearSociedadVisible(true);
  };

  const showModalEdit = (cell) => {
    let auxMD = [...mdEdit];
    let dataValues = data.find((e) => e.id === cell.row.values.id);
    setValues(dataValues);
    if (cell.row.values.portfolioId === portfolios.name.quasar) {
      auxMD.find((e) => e.label === "Excent").hidden = hiddenFalse;
    } else {
      auxMD.find((e) => e.label === "Excent").hidden = hiddenTrue;
    }
    if (cell.row.values.portfolioId === portfolios.name.sareb) {
      auxMD.find((e) => e.label === "Sareb").hidden = hiddenFalse;
    } else {
      auxMD.find((e) => e.label === "Sareb").hidden = hiddenTrue;
    }
    setDialogEditarSociedadVisible(true);
    setMDEdit(auxMD);
  };

  const showModalDelete = (id) => {
    setValueDelete(id);
    setDialogBorrarSociedadVisible(true);
  };

  const showModalAudit = (cell) => {
    let cellValues = { ...cell.row.values };
    cellValues.creationTime = formatDate(cellValues.creationTime);
    cellValues.lastModificationTime = formatDate(
      cellValues.lastModificationTime
    );
    const creator = users.find((s) => s.id === cellValues.creatorId);
    if (creator !== undefined) {
      cellValues.creatorId = creator.name;
    }
    const modifier = users.find((s) => s.id === cellValues.lastModifierId);
    if (modifier !== undefined) {
      cellValues.lastModifierId = modifier.name;
    }

    //change url & add filters

    const valueId = document.getElementById("filterIdSociedad").value;
    const valueIdSociety = document.getElementById("filterIdSociedad").value;
    const valueSociety = document.getElementById("filterSociedad").value;
    const valueCartera = document.getElementById("filterCartera").value;
    const valueCIF = document.getElementById("filterCIF").value;
    const valueNoAssets = document.getElementById("filterNºactivos").value;

    const url = `/view-managements?${
      valueId !== "" ? `filterId=${valueId}` : ""
    }${
      valueIdSociety !== ""
        ? valueId !== ""
          ? `&filterIdSociedad=${valueIdSociety}`
          : `filterIdSociedad=${valueIdSociety}`
        : ""
    }${
      valueSociety !== ""
        ? valueId !== "" || valueIdSociety !== ""
          ? `&filterSociedad=${valueSociety}`
          : `filterSociedad=${valueSociety}`
        : ""
    }${
      valueCartera !== ""
        ? valueId !== "" || valueIdSociety !== "" || valueSociety !== ""
          ? `&filterCartera=${valueCartera}`
          : `filterCartera=${valueCartera}`
        : ""
    }${
      valueCIF !== ""
        ? valueId !== "" ||
          valueIdSociety !== "" ||
          valueSociety !== "" ||
          valueCartera !== ""
          ? `&filterCIF=${valueCIF}`
          : `filterCIF=${valueCIF}`
        : ""
    }${
      valueNoAssets !== ""
        ? `${
            valueId !== "" ||
            valueIdSociety !== "" ||
            valueSociety !== "" ||
            valueCartera !== "" ||
            valueCIF !== ""
              ? `&filterNºactivos=${valueNoAssets}`
              : `filterNºactivos=${valueNoAssets}`
          }`
        : ""
    }`;

    navigate(url);

    setValuesAudit(cellValues);
    setDialogAuditSociedadVisible(true);
  };

  const confirmEdit = (values) => {
    showLoader();
    setDialogEditarSociedadVisible(false);
    API.editarSociedadGestora(values)
      .then((res) => {
        let dataAux = res.data;
        dataAux.portfolio = appLists.appPortfolios.find(
          (element) => element.id === res.data.portfolioId
        ).name;

        let dataAuxList = [...data];
        let index = dataAuxList.findIndex((e) => e.id === res.data.id);
        dataAuxList[index] = dataAux;
        setData(dataAuxList);

        const idTd = document.querySelector(`td[title="${res.data.id}"]`);

        const siblingName = idTd.parentElement.childNodes[3];
        siblingName.setAttribute("title", dataAux.name);
        siblingName.innerHTML = dataAux.name;

        const siblingCif = idTd.parentElement.childNodes[5];
        siblingCif.setAttribute("title", dataAux.cif);
        siblingCif.innerHTML = dataAux.cif;
        toast.success("Sociedad editada correctamente");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const confirmCreate = (values) => {
    showLoader();
    setDialogCrearSociedadVisible(false);
    API.crearSociedadGestora(values)
      .then((res) => {
        let dataAux = res.data;
        dataAux.portfolioName = appLists.appPortfolios.find(
          (element) => element.id === res.data.portfolioId
        ).name;
        dataAux.numAssets = 0;

        setData([...data, dataAux]);
        setFilteredData([...filteredData, dataAux]);
        toast.success("Sociedad creada correctamente");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const confirmDelete = (id) => {
    showLoader();
    setDialogBorrarSociedadVisible(false);
    API.borrarSociedadGestora(id)
      .then((res) => {
        const td = document.querySelector(`td[title="${id}"]`);
        const row = td.parentElement;
        row.remove();

        const auxData = data.filter((e) => e.id !== id);
        setData(auxData);
        toast.success("Sociedad borrada correctamente");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleCreateChangeValues = (values) => {
    let auxMD = [...mdCreate];
    if (values.portfolioId === portfolios.name.quasar) {
      auxMD.find((e) => e.label === "Excent").hidden = hiddenFalse;
    } else {
      auxMD.find((e) => e.label === "Excent").hidden = hiddenTrue;
      delete values.isExcent;
    }
    if (values.portfolioId === portfolios.name.sareb) {
      auxMD.find((e) => e.label === "Sareb").hidden = hiddenFalse;
    } else {
      auxMD.find((e) => e.label === "Sareb").hidden = hiddenTrue;
      delete values.isSareb;
    }
    setValues(values);
    setMDCreate(auxMD);
  };

  return (
    <S.Container>
      {loader}
      {dialogEditarSociedadVisible && (
        <ModalForm
          metadata={mdEdit}
          message="Datos de sociedad gestora"
          values={values}
          setValues={setValues}
          cancel={() => setDialogEditarSociedadVisible(false)}
          confirm={confirmEdit}
        />
      )}
      {dialogCrearSociedadVisible && (
        <ModalForm
          metadata={mdCreate}
          message="Datos de sociedad gestora"
          values={values}
          setValues={handleCreateChangeValues}
          cancel={() => setDialogCrearSociedadVisible(false)}
          confirm={confirmCreate}
        />
      )}
      {dialogBorrarSociedadVisible && (
        <Dialog
          type="noFooter"
          message="¿Desea eliminar la sociedad?"
          labelConfirm="Eliminar"
          confirm={confirmDelete}
          cancel={() => setDialogBorrarSociedadVisible(false)}
          value={valueDelete}
        />
      )}
      {dialogAuditSociedadVisible && (
        <ModalAudit
          metadata={METADATA_AUDIT_SOCIEDAD}
          message="Auditoría"
          values={valuesAudit}
          cancel={() => setDialogAuditSociedadVisible(false)}
          entityName="ManagementCompany"
        />
      )}
      <Card>
        <Card.Header
          style={{
            paddingLeft: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <CardHeaderContainer>
            <PageTitle title="Sociedades Gestoras" />
            <Button
              variant="success"
              onClick={() => showModalCreate()}
              hidden={!PERMISOS_POR_ROL.SociedadesGestoras.Crud}
            >
              <span>
                <FontAwesomeIcon icon={faPlus} size="lg" fixedWidth />
              </span>
              Crear Sociedad
            </Button>
          </CardHeaderContainer>
        </Card.Header>
        <Card.Body>
          <TableFrontEnd
            columns={columns}
            data={filteredData}
            defaultSort={defaultSort}
            defaultColumn={defaultColumn}
            hiddenColumns={
              PERMISOS_POR_ROL.SociedadesGestoras.Crud
                ? [
                    "isExcent",
                    "isSareb",
                    "address",
                    "municipalityName",
                    "creatorId",
                    "creationTime",
                    "lastModifierId",
                    "lastModificationTime",
                    "portfolioId",
                  ]
                : [
                    "isExcent",
                    "isSareb",
                    "address",
                    "municipalityName",
                    "creatorId",
                    "creationTime",
                    "lastModifierId",
                    "lastModificationTime",
                    "portfolioId",
                    "delete",
                  ]
            }
            pagination={true}
          />
        </Card.Body>
      </Card>
    </S.Container>
  );
};
