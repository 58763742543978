import { Col, Form, Row } from "react-bootstrap";

const InputFormSampleList = ({
  label,
  placeholder,
  id,
  disabled,
  hidden,
  filter,
  list,
  value,
  handleChange,
}) => {
  return (
    <Row className="mb-2" hidden={hidden(filter, label)}>
      <Col xs={12} md={5}>
        <Form.Label
          hidden={hidden(filter, label)}
          style={{ fontSize: "0.875rem" }}
        >
          {label}
        </Form.Label>
      </Col>
      <Col xs={12} md={7}>
        <Form.Select
          placeholder={placeholder}
          id={id}
          disabled={disabled()}
          value={value}
          hidden={hidden(filter, label)}
          onChange={(e) => handleChange(id, e.target.value)}
          size="sm"
        >
          {list !== undefined &&
            list.length > 0 &&
            list.map((field, index) => (
              <option key={index} value={field}>
                {!!field ? field : "-Seleccionar-"}
              </option>
            ))}
        </Form.Select>
      </Col>
    </Row>
  );
};

export default InputFormSampleList;
