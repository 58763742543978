import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Card, Button, Form, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoader } from "../../components/Loading";
import {
  faArrowLeft,
  faSave,
  faPrint,
  faStar,
  faClone,
  faPlus,
  faFileImport,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import * as API from "../../api";
import {
  METADATA_EDICION_PRODUCTO_1,
  buildFormDataEditProduct,
  buildFormDataEditProductParticular,
  getMetadataParticularProduct,
} from "./constants";
import { buildFormDataEdit } from "../DetalleActivoSinProducto/constants";
import { CardForm } from "../../components/CardForm";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as S from "./styles";
import { ModalValidarCreacionProducto } from "../../components/ModalValidarCreacionProducto";
import { ModalAssetProduct } from "../../components/ModalAssetProduct";
import { Dialog } from "../../components/Dialog";
import { ModalDesagruparProducto } from "../../components/ModalDesagruparProducto";
import {
  disabledFalse,
  disabledTrue,
  hiddenFilter,
  hiddenTrue,
  portfolios,
} from "../../constants";
import { METADATA_EDICION_ACTIVO_1 } from "../DetalleActivoSinProducto/constants";
import { useDataContext } from "../../context/DataState";
import {
  buildFormDataEditParticularAsset,
  buildFormDataEditTercerosAsset,
  getMetadataAsset,
  getMetadataNewActivoTerceros,
  validateRequiredFields,
} from "../DetalleActivoParticular/constants";
import usePermition from "../../constants/usePermition";
import SelectLanguage from "../../components/SelectLanguage/SelectLanguage";

export const DetalleProducto = () => {
  const [valueFilterProduct, setValueFilterProduct] = useState("");
  const [valueFilterAsset, setValueFilterAsset] = useState("");
  const [language, setLanguage] = useState("ES");
  const [data, setData] = useState({});
  const [initData, setInitData] = useState({});
  const [oldProductData, setOldProductData] = useState({});
  const [dataActivos, setDataActivos] = useState([]);
  const [dataActivoIds, setDataActivoIds] = useState([]);
  const [dataActivosInfo, setDataActivosInfo] = useState([]);
  const [dataSelectedActivo, setDataSelectedActivo] = useState({});
  const [dataValidacionProducto, setDataValidacionProducto] = useState({});
  const [codeProduct, setCodeProduct] = useState("");
  const [activoActual, setActivoActual] = useState("0");
  const [activoPrincipal, setActivoPrincipal] = useState("");
  const [validated, setValidated] = useState(false);
  const [isEditableCommercialStatus, setIsEditableCommercialStatus] =
    useState(true);
  const [isForcedActual, setIsForcedActual] = useState(false);
  const [isBlockedActual, setIsBlockedActual] = useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  const [modalValidarProducto, setModalValidarProducto] = useState(false);
  const [modalAnadirActivos, setModalAnadirActivos] = useState(false);
  const [modalDesagruparProducto, setModalDesagruparProducto] = useState(false);
  const [modalEnviarTablaTemporal, setModalEnviarTablaTemporal] =
    useState(false);
  const [modalCambiarActivoPrincipal, setModalCambiarActivoPrincipal] =
    useState(false);
  const navigate = useNavigate();
  const { idProduct, idAsset } = useParams();
  const [metadataProd, setMetadataProd] = useState(METADATA_EDICION_PRODUCTO_1);
  const [disabledSaveAsset, setDisabledSaveAsset] = useState(false);
  const [disabledSaveProd, setDisabledSaveProd] = useState(false);
  const [prinexIDs, setPrinexIds] = useState([]);
  const [sarebCompetitiveStartDate, setSarebCompetitiveStartDate] =
    useState("");
  const [sarebCompetitiveEndDate, setSarebCompetitiveEndDate] = useState("");

  const [METADATA_ACTIVO, setMDAsset] = useState([
    ...METADATA_EDICION_ACTIVO_1,
  ]);
  const {
    dispatch,
    state: { appLists, user },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    document.title = "EC-Light-Producto";
    loadProduct(idProduct, idAsset, true, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProduct = (idProductAux, idAssetAux, isInit) => {
    let defaultAsset;
    if (idProductAux && idAssetAux) {
      showLoader();
      API.obtenerProducto(idProductAux)
        .then((res) => {
          const prod = res.data;
          setCodeProduct(res.data.code);
          setActivoActual(Number(idAssetAux));
          const assetIds = res.data.assetIDs;
          setDataActivoIds(assetIds);
          const defaultAssetId = res.data.defaultAssetId;
          setActivoPrincipal(res.data.defaultAssetId);
          setIsForcedActual(res.data.isForced);
          setIsBlockedActual(res.data.isBlocked);

          //cargar activos
          loadAssets(defaultAssetId, defaultAsset, isInit, prod);
        })
        .catch((error) => {
          if (error.code >= 400 && error.code < 500)
            toast.error(error.msg?.message);
          if (error.code === 401) {
            navigate("/404");
          } else {
            API.DEVELOP && console.log(error);
          }
        })
        .finally(() => hideLoader());
    }
  };

  /**
   * Cargar activos del producto
   * @param {*} defaultAssetId
   * @param {*} defaultAsset
   * @param {*} isInit
   * @param {*} prod
   */
  const loadAssets = (defaultAssetId, defaultAsset, isInit, prod) => {
    showLoader();
    const idsToNumber = prod.assetIDs.map((str) => parseInt(str, 10));
    let data = {
      iDs: idsToNumber,
    };
    API.obtenerActivo(data)
      .then((resAssets) => {
        let listsAux = { ...appLists };
        let tmpMDA2 = [...METADATA_ACTIVO];
        let auxMDFloor = tmpMDA2.find((e) => e.id === "suelo");
        if (auxMDFloor) {
          let auxSegField = auxMDFloor.metadata.find(
            (md) => md.name === "segmentation"
          );
          if (auxSegField) {
            auxSegField.disabled = disabledTrue;
          }
        }

        let tempMD = [...metadataProd];
        defaultAsset = resAssets.data.filter((e) => e.id === defaultAssetId)[0];
        prod.segmentationPrimary = defaultAsset.segmentationPrimary;
        prod.segmentationSecondary = defaultAsset.segmentationSecondary;
        prod.segmentationPrimaryType = defaultAsset.segmentationPrimaryType;
        prod.segmentationSecondaryType = defaultAsset.segmentationSecondaryType;

        // if (prod.garagesAmount === 0) {
        //   prod.garagesAmount = "0";
        // }
        setData(prod);
        setOldProductData(prod);
        setInitData(prod);

        //Mostrar las razones de bloqueo concatenenando las generales con las de la cartera del usuario
        let listReasonBlock = [
          ...listsAux.reasonBlock.All,
          ...listsAux.reasonBlock[defaultAsset.portfolioId],
        ];
        listsAux.reasonBlockList = listReasonBlock;

        //Llenar la lista de tipo de red comercial con las opciones generales y las específicas de la cartera
        let listCommercialNT = JSON.parse(
          localStorage.getItem("commercialNetType")
        );
        let listCommercialType = [];
        listCommercialType = [
          ...listCommercialNT.All,
          ...listCommercialNT[defaultAsset.portfolioId],
        ];
        listsAux.commercialNetType = listCommercialType;

        dispatch({
          type: "UPDATE_APP_LIST",
          payload: listsAux,
        });

        let pIds = [];
        prod.assetIDs.forEach((id) => {
          let PI = {};
          PI.prinexId = resAssets.data.find((e) => e.id === id).prinexId;
          PI.id = id;
          pIds.push(PI);
        });
        setPrinexIds(pIds);

        //permissionForced
        if (
          user.currentPortfolioID !== portfolios.name.particulares &&
          user.currentPortfolioID !== portfolios.name.terceros
        ) {
          if (
            prod &&
            (PERMISOS_POR_ROL.SuperAdmin || PERMISOS_POR_ROL.Admin) &&
            (!prod.isAvailable || (prod.isAvailable && prod.isForced))
          ) {
            tempMD
              .find((e) => e.id === "product")
              .metadata.find((e) => e.name === "isForced").disabled =
              disabledFalse;
          } else {
            tempMD
              .find((e) => e.id === "product")
              .metadata.find((e) => e.name === "isForced").disabled =
              disabledTrue;
          }
        }

        //permissionManualHidePvPAPICRM
        if (
          ((defaultAsset.portfolioId === portfolios.name.ns ||
            defaultAsset.portfolioId === portfolios.name.quasar) &&
            (PERMISOS_POR_ROL.AnyAdmin ||
              ((defaultAsset.businessLine === 1 ||
                defaultAsset.businessLine === 2) &&
                PERMISOS_POR_ROL.ComercialDelegate &&
                defaultAsset !== null &&
                defaultAsset.crmPrice <= 1000000))) ||
          (defaultAsset.portfolioId === portfolios.name.sareb &&
            PERMISOS_POR_ROL.AnyAdmin) ||
          (defaultAsset.portfolioId === portfolios.name.anticipa &&
            PERMISOS_POR_ROL.AnyAdmin)
        ) {
          tempMD
            .find((e) => e.id === "product")
            .metadata.find((e) => e.name === "manualHidePvPAPICRM").disabled =
            disabledFalse;
        } else {
          tempMD
            .find((e) => e.id === "product")
            .metadata.find((e) => e.name === "manualHidePvPAPICRM").disabled =
            disabledTrue;
        }

        //permissionCommercialInterest
        let auxMdProdCI = tempMD.find((e) => e.id === "commercialInterest");
        let auxMDScore = auxMdProdCI.metadata.find(
          (e) => e.name === "commercialInterestScore"
        );
        let auxMDCIDesc = auxMdProdCI.metadata.find(
          (e) => e.name === "commercialInterestDescription"
        );
        if (PERMISOS_POR_ROL.SuperAdmin || PERMISOS_POR_ROL.ComercialDelegate) {
          if (auxMdProdCI) {
            if (auxMDScore) {
              auxMDScore.disabled = disabledFalse;
            }
            if (auxMDCIDesc) {
              auxMDCIDesc.disabled = disabledFalse;
            }
          }
        } else {
          if (auxMdProdCI) {
            if (auxMDScore) {
              auxMDScore.disabled = disabledTrue;
            }
            if (auxMDCIDesc) {
              auxMDCIDesc.disabled = disabledTrue;
            }
          }
        }

        //permissionCheckBlocked
        let auxMDProd = tempMD.find((e) => e.id === "product");
        let auxMDIsBlocked = auxMDProd.metadata.find(
          (e) => e.name === "isBlocked"
        );
        let permissionCheckBlocked = false;
        if (
          ((defaultAsset.portfolioId === portfolios.name.ns ||
            defaultAsset.portfolioId === portfolios.name.quasar) &&
            (PERMISOS_POR_ROL.AnyAdmin ||
              ((defaultAsset.businessLine === 1 ||
                defaultAsset.businessLine === 2) &&
                PERMISOS_POR_ROL.ComercialDelegate))) ||
          (defaultAsset.portfolioId === portfolios.name.sareb &&
            PERMISOS_POR_ROL.AnyAdmin) ||
          (defaultAsset.portfolioId === portfolios.name.anticipa &&
            PERMISOS_POR_ROL.AnyAdmin)
        ) {
          if (auxMDProd) {
            auxMDIsBlocked.disabled = disabledFalse;
          }
          permissionCheckBlocked = true;
        } else {
          if (auxMDProd) {
            auxMDIsBlocked.disabled = disabledTrue;
          }
        }

        //permissionReasonBlocked
        if (
          ((defaultAsset.portfolioId === portfolios.name.ns ||
            defaultAsset.portfolioId === portfolios.name.quasar) &&
            (PERMISOS_POR_ROL.AnyAdmin ||
              ((defaultAsset.businessLine === 1 ||
                defaultAsset.businessLine === 2) &&
                PERMISOS_POR_ROL.ComercialDelegate))) ||
          (defaultAsset.portfolioId === portfolios.name.sareb &&
            PERMISOS_POR_ROL.AnyAdmin)
        ) {
          if (permissionCheckBlocked && prod.isBlocked) {
            if (auxMDIsBlocked) {
              tempMD
                .find((e) => e.id === "product")
                .metadata.find((e) => e.name === "reasonBlock").disabled =
                prod.reasonBlock === 13 ? disabledTrue : disabledFalse;
            }
          }
        } else {
          if (auxMDIsBlocked) {
            tempMD
              .find((e) => e.id === "product")
              .metadata.find((e) => e.name === "reasonBlock").disabled =
              disabledTrue;
          }
        }

        //delegationId
        if (
          user.currentPortfolioID !== portfolios.name.particulares &&
          user.currentPortfolioID !== portfolios.name.terceros
        ) {
          if (
            PERMISOS_POR_ROL.AnyAdmin &&
            !!defaultAsset.commercialPromotionId
          ) {
            let mdDelDG = tmpMDA2
              .find((e) => e.id === "generalData")
              .metadata.find((e) => e.name === "delegationId");
            if (mdDelDG) {
              mdDelDG.disabled = disabledTrue;
            }
          } else if (
            PERMISOS_POR_ROL.AnyAdmin &&
            !defaultAsset.commercialPromotionId
          ) {
            let mdDelDG = tmpMDA2
              .find((e) => e.id === "generalData")
              .metadata.find((e) => e.name === "delegationId");
            if (mdDelDG) {
              mdDelDG.disabled = disabledFalse;
            }
          }

          let currentPort = user.currentPortfolioID;

          // hidde fields by portfolio
          let headToRemove = [];
          tmpMDA2.forEach((head) => {
            if (
              !!head.visibleFor?.length &&
              !head.visibleFor?.includes(currentPort)
            ) {
              headToRemove.push(head.label);
            } else {
              head.metadata.forEach((field) => {
                if (!!field.visibleFor?.length) {
                  if (!field.visibleFor.includes(currentPort)) {
                    field.hidden = hiddenTrue;
                  }
                }
              });
            }
          });

          tmpMDA2 = tmpMDA2.filter((e) => !headToRemove.includes(e.label));

          if (!PERMISOS_POR_ROL.SuperAdmin) {
            tmpMDA2 = tmpMDA2.filter((e) => e.id !== "audit");
          }
        }

        if (user.currentPortfolioID === portfolios.name.particulares) {
          tmpMDA2 = getMetadataAsset(prod.defaultAssetId);
        }

        if (user?.currentPortfolioID === portfolios.name.terceros) {
          tmpMDA2 = getMetadataNewActivoTerceros(prod.defaultAssetId);
        }

        // hidde/show anticipaPosesion for portfolio anticipa
        let generalData = tmpMDA2.find((e) => e.id === "generalData");
        let posessionField = null;
        if (generalData) {
          posessionField = generalData.metadata.find(
            (e) => e.name === "anticipaPosesion"
          );
        }

        if (user.currentPortfolioID !== portfolios.name.anticipa) {
          if (posessionField) {
            posessionField.hidden = hiddenTrue;
          }
        } else {
          if (posessionField) {
            posessionField.hidden = hiddenFilter;
          }
        }

        // enble/disable hideNumber
        let locSection = tmpMDA2.find((e) => e.id === "localization");
        if (
          user.currentPortfolioID !== portfolios.name.particulares &&
          user.currentPortfolioID !== portfolios.name.terceros
        ) {
          if (PERMISOS_POR_ROL.DetalleProducto.OcultarNumero) {
            if (locSection) {
              locSection.metadata.find(
                (e) => e.name === "hideNumber"
              ).disabled = disabledFalse;
            }
          } else {
            if (locSection) {
              locSection.metadata.find(
                (e) => e.name === "hideNumber"
              ).disabled = disabledTrue;
            }
          }
        }

        const userRoles = user.roles.map((rol) => rol.name.toUpperCase());
        let granularData = tmpMDA2.find((e) => e.id === "granular");
        let energyCertificationData = tmpMDA2.find(
          (e) => e.id === "energyCertification"
        );
        let graphycMaterialData = tmpMDA2.find(
          (e) => e.id === "graphycMaterial"
        );
        let basicFeaturesData = tmpMDA2.find((e) => e.id === "basicFeatures");
        if (
          userRoles.includes("CONTENTEDITOR") &&
          (user.currentPortfolioID === portfolios.name.quasar ||
            user.currentPortfolioID === portfolios.name.sareb ||
            user.currentPortfolioID === portfolios.name.anticipa)
        ) {
          if (granularData) {
            let hasBalconyEDC = granularData.metadata.find(
              (e) => e.name === "hasBalconyEDC"
            );
            hasBalconyEDC.disabled = disabledFalse;

            let heatingType = granularData.metadata.find(
              (e) => e.name === "heatingType"
            );
            heatingType.disabled = disabledFalse;

            let buildingYear = granularData.metadata.find(
              (e) => e.name === "buildingYear"
            );
            buildingYear.disabled = disabledFalse;

            let builtInWardrobe = granularData.metadata.find(
              (e) => e.name === "builtInWardrobe"
            );
            builtInWardrobe.disabled = disabledFalse;

            let buildingStatus = granularData.metadata.find(
              (e) => e.name === "buildingStatus"
            );
            buildingStatus.disabled = disabledFalse;

            let buildingOrientation = granularData.metadata.find(
              (e) => e.name === "buildingOrientation"
            );
            buildingOrientation.disabled = disabledFalse;
          }
          if (energyCertificationData) {
            let heatingEmissions = energyCertificationData.metadata.find(
              (e) => e.name === "heatingEmissions"
            );
            heatingEmissions.disabled = disabledFalse;

            let energyCertification = energyCertificationData.metadata.find(
              (e) => e.name === "energyCertification"
            );
            energyCertification.disabled = disabledFalse;

            let energyConsumption = energyCertificationData.metadata.find(
              (e) => e.name === "energyConsumption"
            );
            energyConsumption.disabled = disabledFalse;

            let emissionsCertification = energyCertificationData.metadata.find(
              (e) => e.name === "emissionsCertification"
            );
            emissionsCertification.disabled = disabledFalse;
          }
          if (graphycMaterialData) {
            let areValidPhotos = graphycMaterialData.metadata.find(
              (e) => e.name === "areValidPhotos"
            );
            areValidPhotos.disabled = disabledFalse;

            let photoDetails = graphycMaterialData.metadata.find(
              (e) => e.name === "photoDetails"
            );
            photoDetails.disabled = disabledFalse;

            let photoComments = graphycMaterialData.metadata.find(
              (e) => e.name === "photoComments"
            );
            photoComments.disabled = disabledFalse;

            let photoDetails2 = graphycMaterialData.metadata.find(
              (e) => e.name === "photoDetails2"
            );
            photoDetails2.disabled = disabledFalse;

            let photoValidation2 = graphycMaterialData.metadata.find(
              (e) => e.name === "photoValidation2"
            );
            photoValidation2.disabled = disabledFalse;

            let tourDetails2 = graphycMaterialData.metadata.find(
              (e) => e.name === "tourDetails2"
            );
            tourDetails2.disabled = disabledFalse;

            let tourValidation = graphycMaterialData.metadata.find(
              (e) => e.name === "tourValidation"
            );
            tourValidation.disabled = disabledFalse;

            let descriptionDetails = graphycMaterialData.metadata.find(
              (e) => e.name === "descriptionDetails"
            );
            descriptionDetails.disabled = disabledFalse;

            let descriptionValidation = graphycMaterialData.metadata.find(
              (e) => e.name === "descriptionValidation"
            );
            descriptionValidation.disabled = disabledFalse;

            let agreeTour = graphycMaterialData.metadata.find(
              (e) => e.name === "agreeTour"
            );
            agreeTour.disabled = disabledFalse;
          }
        }

        if (
          userRoles.includes("CONTENTEDITOR") ||
          userRoles.includes("MARKETING") ||
          userRoles.includes("SUPERADMIN") ||
          userRoles.includes("ADMIN")
        ) {
          if (basicFeaturesData) {
            let isNew = basicFeaturesData.metadata.find(
              (e) => e.name === "isNew"
            );
            isNew.disabled = disabledFalse;
          }
        }

        if (user.currentPortfolioID === portfolios.name.sareb) {
          let floorSec = tmpMDA2.find((e) => e.id === "suelo");
          let basicFeaturesSec = tmpMDA2.find((e) => e.id === "basicFeatures");
          if (basicFeaturesSec) {
            let curentUseField = basicFeaturesSec.metadata.find(
              (e) => e.name === "currentUse"
            );
            curentUseField.disabled = disabledTrue;
          }
          if (floorSec) {
            let plotAreaField = floorSec.metadata.find(
              (e) => e.name === "plotArea"
            );
            let buildableVolumeField = floorSec.metadata.find(
              (e) => e.name === "buildableVolume"
            );
            plotAreaField.disabled = disabledTrue;
            buildableVolumeField.disabled = disabledTrue;
          }
        }

        setMDAsset(tmpMDA2);

        //Check commercial status editable
        const commercialStatusId = appLists.appNotEditableCommercialStatus.find(
          (e) => e === resAssets.data[0].commercialStatusId
        );
        if (commercialStatusId) {
          setIsEditableCommercialStatus(false);
        }
        //Check para mostrar Suelo
        if (defaultAsset.businessLine !== 1) {
          tempMD = tempMD.filter((s) => s.id !== "floor");
        }
        //Check para mostrar Auditoria
        if (!PERMISOS_POR_ROL.SuperAdmin) {
          tempMD = tempMD.filter((s) => s.id !== "audit");
        }

        //Click accordions
        if (isInit) {
          let accs = document.getElementsByClassName("accordion-button");
          for (var i = 0, len = accs.length; i < len; i++) {
            if (
              accs[i].innerText === "Suelo" &&
              defaultAsset.businessLine === 1
            ) {
              accs[i].click();
            } else if (
              accs[i].innerText === "Terciario" &&
              defaultAsset.businessLine === 2
            ) {
              accs[i].click();
            } else if (
              accs[i].innerText === "Granular" &&
              defaultAsset.businessLine === 3
            ) {
              accs[i].click();
            } else {
              if (
                accs[i].innerText !== "Suelo" &&
                accs[i].innerText !== "Terciario" &&
                accs[i].innerText !== "Granular"
              ) {
                accs[i].click();
              }
            }
          }
        }

        let manualHidePvPAPICRMAccess =
          PERMISOS_POR_ROL.Private || PERMISOS_POR_ROL.AnyAdmin;

        if (
          user.currentPortfolioID === portfolios.name.particulares ||
          user.currentPortfolioID === portfolios.name.terceros
        ) {
          tempMD = getMetadataParticularProduct(
            PERMISOS_POR_ROL.SuperAdmin,
            PERMISOS_POR_ROL.AnyAdmin,
            manualHidePvPAPICRMAccess,
            defaultAsset.crmPrice,
            prod.isBlocked,
            prod.reasonBlock === 13
          );
        }

        // habilitar el chek inSarebCompetitiveProcess si es true y si teine los permisos por rol
        if (user.currentPortfolioID === portfolios.name.sareb) {
          let sarebSection = tempMD.find((e) => e.id === "sareb");
          if (sarebSection) {
            let inProcessField = sarebSection.metadata.find(
              (e) => e.name === "inSarebCompetitiveProcess"
            );
            if (inProcessField) {
              if (
                !!prod.inSarebCompetitiveProcess &&
                PERMISOS_POR_ROL.DetalleProducto.ProcesoTransparencia
              ) {
                inProcessField.disabled = disabledFalse;
              } else {
                inProcessField.disabled = disabledTrue;
              }
            }
          }
        }
        //Update MD
        let headToRemoveDetalleProducto = [];
        let currentPortfolio = user.currentPortfolioID;
        tempMD.forEach((head) => {
          if (
            !!head.visibleFor?.length &&
            !head.visibleFor?.includes(currentPortfolio)
          ) {
            headToRemoveDetalleProducto.push(head.label);
          } else {
            head.metadata.forEach((field) => {
              if (!!field.visibleFor?.length) {
                if (!field.visibleFor.includes(currentPortfolio)) {
                  field.hidden = hiddenTrue;
                }
              }
            });
          }
        });
        setMetadataProd(tempMD);

        let selectedAsset = resAssets.data.find(
          (s) => s.id === Number(idAsset)
        );

        // Si el activo actual no existe (se desagrupó) pone como actual el por defecto
        if (!selectedAsset) {
          selectedAsset = resAssets.data.find(
            (s) => s.id === Number(defaultAssetId)
          );
        }
        setActivoActual(selectedAsset.id);

        let auxAssetsData = [...resAssets.data];

        auxAssetsData.forEach((elem) => {
          const munId = elem.municipalityId;
          if (elem?.photoDetails) {
            const formattedText = elem?.photoDetails.replace(/\|/g, "\n");
            elem.photoDetails = formattedText;
          }
          if (munId) {
            elem.provinceId = appLists.appMunicipalities.find(
              (e) => e.id === munId
            ).provinceId;
          }
          if (elem.registralMunicipalityId) {
            let municipality = listsAux.appMunicipalities.find(
              (s) => s.id === elem.registralMunicipalityId
            );
            if (municipality) {
              elem.registralProvinceId = municipality.provinceId;
            }
          }

          if (elem.subTypeId) {
            elem.typeId = appLists.appAssetSubTypes.find(
              (e) => e.id === elem.subTypeId
            ).type;
          }

          if (elem.subTypeModId) {
            elem.typeModId = appLists.appAssetSubTypes.find(
              (e) => e.id === elem.subTypeModId
            ).type;
          }
        });

        if (
          user.currentPortfolioID === portfolios.name.particulares ||
          user.currentPortfolioID === portfolios.name.terceros
        ) {
          selectedAsset.managementCompany = selectedAsset.managementCompanyId;
          if (selectedAsset.municipalityId) {
            selectedAsset.provinceId = appLists.appMunicipalities.find(
              (e) => e.id === selectedAsset.municipalityId
            ).provinceId;
            listsAux.auxMunicipalities = listsAux.appMunicipalities.filter(
              (e) => e.provinceId === selectedAsset.provinceId
            );
          }
          selectedAsset.typeId = appLists.appAssetSubTypes.find(
            (e) => e.id === selectedAsset.subTypeId
          ).type;

          //fill array assets
          console.log(auxAssetsData);
          auxAssetsData.forEach((as) => {
            as.managementCompany = as.managementCompanyId;
            if (as?.photoDetails) {
              const formattedText = as?.photoDetails.replace(/\|/g, "\n");
              as.photoDetails = formattedText;
            }
            if (
              !isInit &&
              (user.currentPortfolioID === portfolios.name.particulares ||
                user.currentPortfolioID === portfolios.name.terceros)
            ) {
              const auxCurrentAsset = dataActivos.find((e) => e.id === as.id);
              if (auxCurrentAsset) {
                as.managementCompanyPrinexId =
                  auxCurrentAsset.managementCompanyPrinexId;
              }
            }
            if (as.municipalityId) {
              as.provinceId = appLists.appMunicipalities.find(
                (e) => e.id === as.municipalityId
              ).provinceId;
            }
            as.typeId = appLists.appAssetSubTypes.find(
              (e) => e.id === as.subTypeId
            ).type;

            //strngs replace . by ,
            as.crmPrice = as.crmPrice.toString().replace(".", ",");
            if (as.heatingEmissions) {
              as.heatingEmissions = as.heatingEmissions
                .toString()
                .replace(".", ",");
            }

            if (as.terraceSurface) {
              as.terraceSurface = as.terraceSurface
                .toString()
                .replace(".", ",");
            }
          });
        }

        setDataSelectedActivo(selectedAsset);
        setDataActivos(auxAssetsData);
        setDataActivosInfo(
          resAssets.data.map((s) => ({
            id: s.id,
            prinexId: s.prinexId,
            name: s.name,
            propertyCode: s.propertyCode,
          }))
        );

        //filter subtypes
        let filteredSubTypes = appLists.appAssetSubTypes.filter(
          (el) => el.type === parseInt(selectedAsset.typeId)
        );

        filteredSubTypes = filteredSubTypes
          .sort((a, b) => a.type - b.type)
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.name === value.name)
          );

        let auxSub = appLists.appAssetSubTypes.find(
          (e) => e.id === selectedAsset.subTypeId
        );

        filteredSubTypes = filteredSubTypes.filter(
          (e) => e.name !== auxSub.name
        );
        filteredSubTypes.push(auxSub);

        listsAux.auxAssetSubTypes = filteredSubTypes;

        //load users
        if (isInit) {
          loadUsers(
            listsAux,
            defaultAsset.businessLine,
            defaultAsset.portfolioId
          );

          //Llamara a todos las delegaciones
          loadDelegations(
            defaultAsset.businessLine,
            listsAux,
            defaultAsset.portfolioId,
            defaultAsset.delegationId,
            defaultAsset.businessLine,
            defaultAsset.budgetLine,
            auxAssetsData
          );
        }
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        if (error.code === 401) {
          navigate("/404");
        } else {
          API.DEVELOP && console.log(error);
        }
      })
      .finally(() => hideLoader());
  };

  /**
   * Cargar usuarios
   * @param {*} listsAux
   * @param {*} idBL
   * @param {*} portfolioId
   */
  const loadUsers = (listsAux, idBL, portfolioId) => {
    showLoader();
    API.obtenerUsuariosList()
      .then((res) => {
        let list = listsAux;
        list.users = res.data;
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  useEffect(() => {
    if (data.isBlocked) {
      if ((data.reasonBlock !== null) & (data.reasonBlock !== "")) {
        setDisabledSaveProd(false);
      } else {
        setDisabledSaveProd(true);
      }
    } else {
      setDisabledSaveProd(false);
    }
  }, [data]);

  /**
   * Activacion/desactivacion del boton guardar cambios del activo
   */
  useEffect(() => {
    if (user.currentPortfolioID !== portfolios.name.particulares) {
      if (
        dataSelectedActivo.segmentationPrimary === "Dotacional" ||
        dataSelectedActivo.segmentationPrimary === "Residencial" ||
        dataSelectedActivo.segmentationPrimary === "Rústico"
      ) {
        if (
          dataSelectedActivo.segmentationPrimaryType !== undefined &&
          dataSelectedActivo.segmentationPrimaryType !== null
        ) {
          setDisabledSaveAsset(false);
        } else {
          setDisabledSaveAsset(true);
        }
      } else {
        setDisabledSaveAsset(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelectedActivo]);

  /**
   * Actualiza la data y bloquea/desbloquea el campo reazonBlock segun el valor de isBlocked
   * @param {*} data
   */
  const changeData = (values) => {
    let tempMD = metadataProd;
    let tmpData = { ...data };
    tmpData.isBlocked = values.isBlocked;
    tmpData.reasonBlock = values.reasonBlock;

    if (values.isBlocked) {
      if (tmpData.reasonBlock !== null && tmpData.reasonBlock !== "") {
        setDisabledSaveProd(false);
      } else {
        setDisabledSaveProd(true);
      }
    } else {
      tmpData.reasonBlock = "";
    }
    let MDReason = tempMD
      .find((el) => el.id === "product")
      .metadata.find((e) => e.name === "reasonBlock");
    if (values.isBlocked !== isBlockedActual) {
      if (values.isBlocked) {
        MDReason.disabled = disabledFalse;
        MDReason.required = true;
        if (values.reasonBlock !== null && values.reasonBlock !== "") {
          setDisabledSaveProd(false);
        } else {
          setDisabledSaveProd(true);
        }
        tmpData.isPublishable = false;
      } else {
        MDReason.disabled = disabledTrue;
        MDReason.required = false;
        setDisabledSaveProd(false);
        tmpData.reasonBlock = "";
      }
      setIsBlockedActual(values.isBlocked);
    }

    setMetadataProd(tempMD);
    setData(tmpData);
  };

  /**
   * Cargar las delegaciones y poner en las listas las que son de la linea de negocio del activo y las que puede ver el usuario
   * @param {*} businessLine id de linea de negocio
   */
  const loadDelegations = (
    idBL,
    auxList,
    portfolioId,
    delegationId,
    businessLine,
    budgetLine,
    auxAssetsData
  ) => {
    showLoader();
    API.obtenerDelegaciones()
      .then((res) => {
        const businessDelegations = res.data.filter(
          (e) => e.businessLine === idBL
        );
        const userDelegations = user.delegations;
        const delegationsUserList = businessDelegations.filter((e) =>
          userDelegations.includes(e.id)
        );

        const assetDelegation = res.data.find((e) => e.id === delegationId);

        ///
        const delegationsForList = delegationsUserList
          .filter(
            (e) =>
              e.portfolioId === portfolioId &&
              e.businessLine === businessLine &&
              (!e.budgetLine || e.budgetLine === budgetLine)
          )
          // se filtra por territorial (excepto para la cartera ANTICIPA)
          .filter(
            (s) =>
              user.currentPortfolioID === "CAR-ANTICI" ||
              s.territorialId === assetDelegation.territorialId
          );
        let tempLists = auxList;
        tempLists.delegationList = delegationsForList;

        if (
          user.currentPortfolioID === portfolios.name.particulares ||
          user.currentPortfolioID === portfolios.name.terceros
        ) {
          getAllManagmentCompanies(auxList, auxAssetsData);
        } else {
          dispatch({
            type: "UPDATE_APP_LIST",
            payload: tempLists,
          });
        }
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  /**
   * Fill managment company list
   */
  const getAllManagmentCompanies = (list, auxAssetsData) => {
    showLoader();
    API.obtenerSociedadesGestoras()
      .then((res) => {
        let auxList = { ...list };
        const currentCartera = user.currentPortfolioID;
        let auxManagmentCompanyList = res.data.filter(
          (e) => e.portfolioId === currentCartera
        );
        const sociedadesGestorasList = auxManagmentCompanyList.map((item) => ({
          Id: item.prinexId,
          name: item.name,
        }));

        auxList.managmentCompanyList = sociedadesGestorasList || [];
        let auxDataAssets = [...auxAssetsData];
        if (currentCartera === portfolios.name.particulares) {
          auxDataAssets.forEach((e) => {
            e.managementCompanyPrinexId = auxManagmentCompanyList[0]?.prinexId;
            e.managementCompanyId = auxManagmentCompanyList[0]?.id;
            e.managementCompany = auxManagmentCompanyList[0]?.name;
            e.managementCompanyNif = auxManagmentCompanyList[0]?.cif;
            e.managementCompanyAddress = auxManagmentCompanyList[0]?.address;
          });
        } else {
          auxDataAssets.forEach((e) => {
            e.managementCompanyPrinexId = auxManagmentCompanyList[0]?.prinexId;
            e.managementCompanyId = auxManagmentCompanyList[0]?.prinexId;
            e.managementCompany = auxManagmentCompanyList[0]?.name;
            e.managementCompanyNif = auxManagmentCompanyList[0]?.cif;
            e.managementCompanyAddress = auxManagmentCompanyList[0]?.address;
          });
        }

        setDataActivos(auxDataAssets);

        dispatch({
          type: "UPDATE_APP_LIST",
          payload: auxList,
        });
      })
      .catch((e) => console.log(e))
      .finally(hideLoader);
  };

  /**
   * Actualizar producto
   * @param {*} event
   */
  const handleSubmitEditProduct = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    const currentCartera = user.currentPortfolioID;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (idProduct) {
        if (
          user.currentPortfolioID !== portfolios.name.particulares &&
          user.currentPortfolioID !== portfolios.name.terceros
        ) {
          let formData = buildFormDataEditProduct(
            data,
            Number(activoPrincipal)
          );
          if (!formData.tradeName) {
            formData.tradeName = null;
          }
          if (!formData.easyReferenceBusiness) {
            formData.easyReferenceBusiness = null;
          }
          if (!formData.isForced) {
            formData.isForced = false;
          }
          if (!formData.terciaryWebId) {
            formData.terciaryWebId = null;
          }
          if (!formData.checkGiai) {
            formData.checkGiai = false;
          }
          if (!formData.storagesAmount) {
            formData.storagesAmount = 0;
          }
          if (!formData.commercialInterestDescription) {
            formData.commercialInterestDescription = null;
          }
          if (!formData.garagesAmount) {
            formData.garagesAmount = 0;
          }
          if (!formData.commercialInterestScore) {
            formData.commercialInterestScore = 0;
          }
          if (!formData.segmentationPrimary) {
            formData.segmentationPrimary = null;
          }
          if (!formData.segmentationSecondary) {
            formData.segmentationSecondary = null;
          }
          if (!formData.segmentationPrimaryType) {
            formData.segmentationPrimaryType = null;
          }
          if (!formData.segmentationSecondaryType) {
            formData.segmentationSecondaryType = null;
          }
          if (
            !formData.webBookingAvailable &&
            !formData.webBookingAvailable !== false
          ) {
            formData.webBookingAvailable = null;
          }
          if (!formData.webBookingAvailableURL) {
            formData.webBookingAvailableURL = null;
          }
          if (!formData.ignoreMinimalPriceValidation) {
            formData.ignoreMinimalPriceValidation = false;
          }
          showLoader();
          API.editarProducto(formData)
            .then((res) => {
              toast.success("Producto editado correctamente");
              const prod = res.data;
              const defaultAssetId = prod.defaultAssetId;
              setCodeProduct(prod.code);
              setActivoPrincipal(prod.defaultAssetId);
              setIsForcedActual(prod.isForced);
              setIsBlockedActual(prod.isBlocked);

              //cargar activos
              loadAssets(defaultAssetId, {}, false, prod);
            })
            .catch((error) => {
              console.log(error);
              if (error.code >= 400 && error.code < 500)
                toast.error(error.msg?.message);
              API.DEVELOP && console.log(error);
            })
            .finally(() => hideLoader());
        } else {
          let formData = buildFormDataEditProductParticular(data);
          formData.defaultAssetId = Number(activoPrincipal);
          showLoader();
          const serviceForEditarProducto =
            currentCartera === "CAR-PARTI"
              ? API.editarProductoParticulares
              : API.editarProductoTerceros;
          serviceForEditarProducto(formData)
            .then((result) => {
              toast.success("Producto editado correctamente");
              const prod = result.data;
              const defaultAssetId = result.data.defaultAssetId;
              setCodeProduct(prod.code);
              setActivoPrincipal(defaultAssetId);
              setIsForcedActual(prod.isForced);
              setIsBlockedActual(prod.isBlocked);

              //cargar activos
              loadAssets(defaultAssetId, {}, false, prod);
            })
            .catch((e) => API.DEVELOP && console.log(e))
            .finally(hideLoader);
        }
      }
    }
  };

  /**
   * Change valuse by forced
   * @param {*} values
   */
  const handleChangeForced = (values) => {
    let tmpData = { ...data };
    tmpData.isForced = values.isForced;
    let auxMD = [...metadataProd];
    //isForced
    if (values.isForced === isForcedActual) {
      //como estaba
      tmpData.isAvailable = oldProductData.isAvailable;
      tmpData.status = oldProductData.status;
      tmpData.isBlocked = oldProductData.isBlocked;
      tmpData.reasonBlock = oldProductData.reasonBlock;
      tmpData.comments = oldProductData.comments;
    } else if (values.isForced !== isForcedActual && values.isForced) {
      // setOldProductData({ ...tmpData });
      tmpData.isAvailable = true;
      tmpData.status = 0;
      tmpData.isBlocked = true;
      tmpData.reasonBlock = 10;
      tmpData.comments = "";
    }

    let MDPAux = auxMD.find((e) => e.id === "product");
    let mdRBAux = MDPAux.metadata.find((e) => e.name === "reasonBlock");
    if (tmpData.isBlocked) {
      if (MDPAux) {
        if (mdRBAux) {
          mdRBAux.disabled = disabledFalse;
        }
      }
    } else {
      mdRBAux.disabled = disabledTrue;
    }
    if (tmpData.reasonBlock === null) {
      tmpData.reasonBlock = "";
    }
    setData(tmpData);
    setMetadataProd(auxMD);
  };

  /**
   * Actualizar Activo
   * @param {*} event
   */
  const handleSubmitEditAsset = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const currentCartera = user.currentPortfolioID;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (activoActual) {
        if (
          user.currentPortfolioID !== portfolios.name.particulares &&
          user.currentPortfolioID !== portfolios.name.terceros
        ) {
          let formData = buildFormDataEdit(dataSelectedActivo);
          formData.id = activoActual;
          if (formData.delegationId)
            formData.delegationId = parseInt(formData.delegationId);
          else if (formData.delegationId === "") {
            toast.error("La delegación es obligatoria");
            return false;
          }
          if (formData.commercialNetType)
            formData.commercialNetType = parseInt(formData.commercialNetType);
          else if (formData.commercialNetType === "")
            formData.commercialNetType = null;
          if (formData.garageType)
            formData.garageType = parseInt(formData.garageType);
          else if (formData.garageType === "") formData.garageType = null;
          showLoader();
          API.editarActivo(formData)
            .then((res) => {
              toast.success("Activo editado correctamente");
              loadProduct(idProduct, idAsset, false);
            })
            .catch((error) => {
              if (error.code >= 400 && error.code < 500)
                toast.error(error.msg?.message);
              API.DEVELOP && console.log(error);
            })
            .finally(() => hideLoader());
        } else {
          const emptyReguiredFields = validateRequiredFields(
            dataSelectedActivo,
            activoActual
          );
          if (!emptyReguiredFields.length) {
            const formdata =
              currentCartera === "CAR-PARTI"
                ? buildFormDataEditParticularAsset(dataSelectedActivo)
                : buildFormDataEditTercerosAsset(dataSelectedActivo);
            showLoader();
            const serviceForEditarActivo =
              currentCartera === "CAR-PARTI"
                ? API.editarActivoParticular
                : API.editarActivoTerceros;
            serviceForEditarActivo(formdata)
              .then((res) => {
                toast.success("Activo guardado correctamente");
                loadProduct(idProduct, idAsset, false);
              })
              .catch((e) => API.DEVELOP && console.log(e))
              .finally(() => hideLoader());
          } else {
            const singular = emptyReguiredFields.length === 1;
            const errorMsg = `${singular ? "El" : "Los"} campo${
              singular ? "" : "s "
            }${
              singular
                ? ` ${emptyReguiredFields[0].label} `
                : `[${emptyReguiredFields.map((e) => `${e.label}`)}]`
            }${singular ? " es" : " son"} obligatorio${singular ? "" : "s"}`;
            toast.error(errorMsg.replaceAll(",", ", "));
          }
        }
      }
    }
  };

  const onLanguageChange = ({ target: { value } }) => {
    setLanguage(value);
  };

  const validateProduct = () => {
    showLoader();
    API.validarProducto(idProduct)
      .then((res) => {
        setDataValidacionProducto(res.data);
        setModalValidarProducto(true);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => hideLoader());
  };

  /**
   * Actualizar Producto añadiendole activos
   * @param {*} value
   */
  const confirmAnadirActivosProducto = (value) => {
    setModalAnadirActivos(false);
    const currentCartera = user.currentPortfolioID;
    let dataSend = {};
    dataSend.defaultAssetId = Number(activoPrincipal);
    dataSend.assetIDs = dataActivoIds.concat(value);
    if (user.currentPortfolioID !== portfolios.name.particulares) {
      dataSend.segmentationPrimary = data.segmentationPrimary;
      dataSend.segmentationSecondary = data.segmentationSecondary;
      dataSend.segmentationPrimaryType = data.segmentationPrimaryType;
      dataSend.segmentationSecondaryType = data.segmentationSecondaryType;
      showLoader();
      API.crearProducto(dataSend)
        .then((res) => {
          toast.success("Activos añadidos correctamente");
          navigate(
            "/product-detail/" + res.data.id + "/" + res.data.defaultAssetId
          );
          const prod = res.data;
          const defaultAssetId = res.data.defaultAssetId;
          const assetIds = res.data.assetIDs;
          setDataActivoIds(assetIds);
          setCodeProduct(prod.code);

          //cargar activos
          loadAssets(defaultAssetId, {}, false, prod);
          // loadProduct(res.data.id, res.data.defaultAssetId, false);
        })
        .catch((error) => {
          if (error.code >= 400 && error.code < 500)
            toast.error(error.msg?.message);
          API.DEVELOP && console.log(error);
        })
        .finally(hideLoader);
    } else {
      showLoader();
      const serviceForCrearProducto =
        currentCartera === "CAR-PARTI"
          ? API.crearProductoParticulares
          : API.crearProductoTerceros;
      serviceForCrearProducto(dataSend)
        .then((res) => {
          toast.success("Activos añadidos correctamente");
          navigate(
            "/product-detail/" + res.data.id + "/" + res.data.defaultAssetId
          );
          const prod = res.data;
          const defaultAssetId = res.data.defaultAssetId;
          const assetIds = res.data.assetIDs;
          setDataActivoIds(assetIds);
          setCodeProduct(prod.code);

          //cargar activos
          loadAssets(defaultAssetId, {}, false, prod);
          // loadProduct(res.data.id, res.data.defaultAssetId, false);
        })
        .catch((error) => {
          if (error.code >= 400 && error.code < 500)
            toast.error(error.msg?.message);
          API.DEVELOP && console.log(error);
        })
        .finally(hideLoader);
    }
  };

  const confirmEnviarTablaTemporal = () => {
    showLoader();
    setModalEnviarTablaTemporal(false);
    let data = {};
    API.enviarTablaTemporalProducto(idProduct, data)
      .then((res) => {
        toast.success("Producto enviado a tabla temporal");
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const confirmCambiarActivoPrincipal = () => {
    const form = document.getElementById("editProduct");
    setModalCambiarActivoPrincipal(false);
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (idProduct) {
        if (
          user.currentPortfolioID !== portfolios.name.particulares &&
          user.currentPortfolioID !== portfolios.name.terceros
        ) {
          let formData = buildFormDataEditProduct(
            initData,
            Number(activoPrincipal)
          );
          formData.defaultAssetId = parseInt(activoActual);
          showLoader();
          API.editarProducto(formData)
            .then((res) => {
              toast.success("Producto editado correctamente");
              const prod = res.data;
              const defaultAssetId = res.data.defaultAssetId;
              setActivoPrincipal(defaultAssetId);
              const assetIds = res.data.assetIDs;
              setDataActivoIds(assetIds);
              setCodeProduct(prod.code);

              //cargar activos
              loadAssets(defaultAssetId, {}, false, prod);
            })
            .catch((error) => {
              if (error.code >= 400 && error.code < 500)
                toast.error(error.msg?.message);
              API.DEVELOP && console.log(error);
            })
            .finally(() => hideLoader());
        } else {
          let formData = buildFormDataEditProductParticular(data);
          formData.defaultAssetId = parseInt(activoActual);
          setModalCambiarActivoPrincipal(false);
          showLoader();
          API.editarProductoParticulares(formData)
            .then((result) => {
              toast.success("Producto editado correctamente");
              const prod = result.data;
              const defaultAssetId = result.data.defaultAssetId;
              setActivoPrincipal(defaultAssetId);
              const assetIds = result.data.assetIDs;
              setDataActivoIds(assetIds);
              setCodeProduct(prod.code);

              //cargar activos
              loadAssets(defaultAssetId, {}, false, prod);
            })
            .catch((e) => API.DEVELOP && console.log(e))
            .finally(hideLoader);
        }
      }
    }
  };

  const confirmDesagrupar = (selected) => {
    setModalDesagruparProducto(false);
    const currentCartera = user.currentPortfolioID;
    let dataSend = {};
    dataSend.defaultAssetId = Number(activoPrincipal);
    dataSend.assetIDs = dataActivoIds.filter((s) => !selected.includes(s));
    data.assetIDs = dataSend.assetIDs;
    if (
      user.currentPortfolioID !== portfolios.name.particulares &&
      user.currentPortfolioID !== portfolios.name.terceros
    ) {
      dataSend.segmentationPrimary = data.segmentationPrimary;
      dataSend.segmentationSecondary = data.segmentationSecondary;
      dataSend.segmentationPrimaryType = data.segmentationPrimaryType;
      dataSend.segmentationSecondaryType = data.segmentationSecondaryType;
      showLoader();
      API.desagruparProducto(data, "true")
        .then((res) => {
          toast.success("Producto desagrupado correctamente");
          navigate(
            "/product-detail/" + res.data.id + "/" + res.data.defaultAssetId
          );
          const prod = res.data;
          const defaultAssetId = res.data.defaultAssetId;
          const assetIds = res.data.assetIDs;
          setDataActivoIds(assetIds);
          setCodeProduct(prod.code);

          //cargar activos
          loadAssets(defaultAssetId, {}, false, prod);
          // loadProduct(res.data.id, res.data.defaultAssetId, false);
        })
        .catch((error) => {
          if (error.code >= 400 && error.code < 500)
            toast.error(error.msg?.message);
          API.DEVELOP && console.log(error);
        })
        .finally(() => {
          hideLoader();
        });
    } else {
      showLoader();
      const serviceForDesagrupar =
        currentCartera === "CAR-PARTI"
          ? API.desagruparProductoParticulares
          : API.desagruparProductoTerceros;
      serviceForDesagrupar(dataSend, "true")
        .then((result) => {
          toast.success("Producto desagrupado correctamente");
          navigate(
            "/product-detail/" +
              result.data.id +
              "/" +
              result.data.defaultAssetId
          );
          const prod = result.data;
          const defaultAssetId = result.data.defaultAssetId;
          const assetIds = result.data.assetIDs;
          setDataActivoIds(assetIds);
          setCodeProduct(prod.code);

          //cargar activos
          loadAssets(defaultAssetId, {}, false, prod);
          // loadProduct(result.data.id, result.data.defaultAssetId, false);
        })
        .catch((e) => API.DEVELOP && console.log(e))
        .finally(hideLoader);
    }
  };

  const confirmDesagruparTodos = () => {
    setModalDesagruparProducto(false);
    const currentCartera = user.currentPortfolioID;
    let dataSend = {};
    dataSend.defaultAssetId = Number(activoPrincipal);
    dataSend.assetIDs = [Number(activoPrincipal)];
    data.assetIDs = dataSend.assetIDs;
    if (
      user.currentPortfolioID !== portfolios.name.particulares &&
      user.currentPortfolioID !== portfolios.name.terceros
    ) {
      dataSend.segmentationPrimary = data.segmentationPrimary;
      dataSend.segmentationSecondary = data.segmentationSecondary;
      dataSend.segmentationPrimaryType = data.segmentationPrimaryType;
      dataSend.segmentationSecondaryType = data.segmentationSecondaryType;
      showLoader();
      API.desagruparProducto(data, "false")
        .then((res) => {
          toast.success("Producto desagrupado correctamente");
          navigate(
            "/product-detail/" + res.data.id + "/" + res.data.defaultAssetId
          );
          const prod = res.data;
          const defaultAssetId = res.data.defaultAssetId;
          const assetIds = res.data.assetIDs;
          setDataActivoIds(assetIds);
          setCodeProduct(prod.code);

          //cargar activos
          loadAssets(defaultAssetId, {}, false, prod);
          // loadProduct(res.data.id, res.data.defaultAssetId, false);
        })
        .catch((error) => {
          if (error.code >= 400 && error.code < 500)
            toast.error(error.msg?.message);
          API.DEVELOP && console.log(error);
        })
        .finally(() => {
          hideLoader();
        });
    } else {
      showLoader();
      const serviceForDesagruparTodos =
        currentCartera === "CAR-PARTI"
          ? API.desagruparProductoParticulares
          : API.desagruparProductoTerceros;
      serviceForDesagruparTodos(dataSend, "false")
        .then((result) => {
          toast.success("Producto desagrupado correctamente");
          navigate(
            "/product-detail/" +
              result.data.id +
              "/" +
              result.data.defaultAssetId
          );
          const prod = result.data;
          const defaultAssetId = result.data.defaultAssetId;
          const assetIds = result.data.assetIDs;
          setDataActivoIds(assetIds);
          setCodeProduct(prod.code);

          //cargar activos
          loadAssets(defaultAssetId, {}, false, prod);
          // loadProduct(result.data.id, result.data.defaultAssetId, false);
        })
        .catch((e) => API.DEVELOP && console.log(e))
        .finally(hideLoader);
    }
  };

  const defaultSortActivos = [
    {
      id: "prinexId",
      desc: false,
    },
  ];

  /**
   * Gestionar el cambio de datos de los activos
   * @param {*} formData
   */
  const handleSelectedAssetDataChange = (formData) => {
    const urlPattern =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!-.?+=&%!-]))?/;
    if (
      formData.urlVirtualTour !== null &&
      formData.urlVirtualTour !== "" &&
      !!urlPattern.test(formData.urlVirtualTour)
    ) {
      formData.hasPlans = true;
    } else {
      formData.hasPlans = false;
    }
    setDataSelectedActivo(formData);
  };

  const handleDataPARTChange = (values) => {
    if (!!values.garagesAmount) {
      const newValue = values.garagesAmount.toString().replace(/[^0-9]/g, "");
      values.garagesAmount = newValue;
    }
    if (!!values.storagesAmount) {
      const newValue = values.storagesAmount.toString().replace(/[^0-9]/g, "");
      values.storagesAmount = newValue;
    }

    setData(values);
  };

  const handleParticularAssetDataChange = (values) => {
    let auxLists = { ...appLists };

    //actualizar lista de municipios
    let municipalities = appLists.appMunicipalities;
    if (!!values.provinceId) {
      auxLists.auxMunicipalities = municipalities.filter(
        (mun) => mun.provinceId === values.provinceId
      );
    } else {
      // values.municipalityId = "";
      auxLists.auxMunicipalities = municipalities;
    }

    //Replace
    const regex = /[^0-9,]/g;
    if (!!values.crmPrice) {
      const stringPrice = values.crmPrice.toString();
      const newNVal = stringPrice.replace(".", ",");
      const newValue = newNVal.replace(regex, "");
      values.crmPrice = newValue;
    }
    if (!!values.latitude) {
      const newValue = values.latitude.replace(/[^0-9,-]/g, "");
      values.latitude = newValue;
    }
    if (!!values.longitude) {
      const newValue = values.longitude.replace(/[^0-9,-]/g, "");
      values.longitude = newValue;
    }
    if (!!values.builtM2) {
      const newValue = values.builtM2.replace(regex, "");
      values.builtM2 = newValue;
    }
    if (!!values.terraceSurface) {
      const newNVal = values.terraceSurface.toString().replace(".", ",");
      const newValue = newNVal.replace(regex, "");
      values.terraceSurface = newValue;
    }
    if (!!values.mandateDuration) {
      const mandateDuration = values.mandateDuration.toString();
      const newValue = mandateDuration.replace(/[^0-9,]/g, "");
      if (newValue.length <= 9) {
        values.mandateDuration = newValue;
      } else {
        values.mandateDuration = newValue.slice(0, 9);
      }
    }
    if (!!values.bathrooms) {
      const newValue = values.bathrooms.toString().replace(/[^0-9]/g, "");
      values.bathrooms = newValue;
    }
    if (!!values.bedrooms) {
      const newValue = values.bedrooms.toString().replace(/[^0-9]/g, "");
      values.bedrooms = newValue;
    }
    if (!!values.heatingEmissions) {
      const newNVal = values.heatingEmissions.toString().replace(".", ",");
      const newValue = newNVal.replace(regex, "");
      values.heatingEmissions = newValue;
    }
    if (!!values.buildingYear) {
      const newValue = values.buildingYear.toString().replace(regex, "");
      if (newValue.length <= 4) {
        values.buildingYear = newValue;
      } else {
        values.buildingYear = newValue.slice(0, 4);
      }
    }
    //Finsh

    //full direction
    if (
      !!values.road &&
      values.typeOfRoad !== "-Seleccionar-" &&
      !!values.number
    ) {
      values.fullAddress =
        values.typeOfRoad + ": " + values.road + ", " + values.number;
    } else {
      delete values.fullAddress;
    }

    //handle type/subTypeif (!!values.typeId) {
    let auxMD = [...METADATA_ACTIVO];
    let mdSubType = auxMD
      .find((header) => header.id === "features")
      .metadata.find((md) => md.name === "subTypeId");
    if (!!values.typeId) {
      mdSubType.disabled = disabledFalse;
      const filteredSubTypes = appLists.appAssetSubTypes.filter(
        (el) => el.type === parseInt(values.typeId)
      );

      auxLists.auxAssetSubTypes = filteredSubTypes
        .sort((a, b) => a.type - b.type)
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.name === value.name)
        );
      mdSubType.hidden = hiddenFilter;
    } else {
      values.subTypeId = "";
      mdSubType.disabled = disabledTrue;
    }

    dispatch({
      type: "UPDATE_APP_LIST",
      payload: auxLists,
    });

    setMDAsset(auxMD);

    setDataSelectedActivo(values);
  };

  const handleAuditLink = (entityName) => {
    navigate(
      `/auditing?EntityName=${entityName}&EntityId=${
        entityName === "Asset" ? activoActual : data.id
      }`
    );
  };

  const orderActivos = (activos, activoPrincipal) => {
    return activos.sort((a, b) => {
      if (Number(a.id) === activoPrincipal) return -1;
      if (Number(b.id) === activoPrincipal) return 1;
      return 0;
    });
  };

  const orderedPrinexIDs = orderActivos(prinexIDs, activoPrincipal);

  /**
   * blanquear fechas/rellenar al desmarcar/marcar check inSarebCompetitiveProcess
   * @param {*} values
   */
  const handleChangeInProcess = (values) => {
    let auxData = { ...data };
    if (!values.inSarebCompetitiveProcess) {
      setSarebCompetitiveStartDate(values.sarebCompetitiveStartDate);
      setSarebCompetitiveEndDate(values.sarebCompetitiveEndDate);
      auxData.sarebCompetitiveStartDate = "";
      auxData.sarebCompetitiveEndDate = "";
      auxData.inSarebCompetitiveProcess = false;
    } else {
      auxData.sarebCompetitiveStartDate = sarebCompetitiveStartDate;
      auxData.sarebCompetitiveEndDate = sarebCompetitiveEndDate;
      auxData.inSarebCompetitiveProcess = true;
    }
    setData(auxData);
  };

  return (
    <S.DetailPage>
      {loader}
      {modalEnviarTablaTemporal && (
        <Dialog
          type="noFooter"
          message="Se enviará el producto a una tabla temporal, ¿desea continuar?"
          labelConfirm="Continuar"
          confirm={confirmEnviarTablaTemporal}
          cancel={() => setModalEnviarTablaTemporal(false)}
          value={{}}
        />
      )}
      {modalCambiarActivoPrincipal && (
        <Dialog
          type="noFooter"
          message="Se cambiará el activo principal, ¿desea continuar?"
          labelConfirm="Continuar"
          confirm={confirmCambiarActivoPrincipal}
          cancel={() => setModalCambiarActivoPrincipal(false)}
          value={{}}
        />
      )}
      {modalValidarProducto && (
        <ModalValidarCreacionProducto
          message="Validar producto"
          cancel={() => setModalValidarProducto(false)}
          data={dataValidacionProducto}
          idProduct={idProduct}
        />
      )}
      {modalAnadirActivos && (
        <ModalAssetProduct
          id={idProduct}
          message="Añadir activos al producto"
          lists={appLists}
          defaultSort={defaultSortActivos}
          hiddenColumns={["id"]}
          labelConfirm="Guardar"
          cancel={() => setModalAnadirActivos(false)}
          confirm={confirmAnadirActivosProducto}
          showLoader={showLoader}
          hideLoader={hideLoader}
        />
      )}
      {modalDesagruparProducto && (
        <ModalDesagruparProducto
          message="Seleccionar activos"
          cancel={() => setModalDesagruparProducto(false)}
          data={dataActivosInfo.filter((s) => activoPrincipal !== s.id)}
          defaultAsset={activoPrincipal}
          confirmDesagrupar={confirmDesagrupar}
          confirmDesagruparTodos={confirmDesagruparTodos}
        />
      )}
      <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Col>
          <S.Title>Código de Producto {codeProduct}</S.Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <Form noValidate id="editProduct" validated={validated}>
                <Card>
                  <Card.Header>
                    <h4>Producto</h4>
                    <Row className="mb-3">
                      <Col xs={2}>
                        <Button
                          variant="danger"
                          onClick={() => navigate(-1)}
                          style={{ marginRight: "1rem" }}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faArrowLeft}
                              size="sm"
                              fixedWidth
                            />
                          </span>
                        </Button>
                      </Col>
                      <Col xs={9}>
                        <Form.Control
                          type="text"
                          placeholder="Filtrar campos"
                          onChange={(e) => {
                            setValueFilterProduct(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Button
                          style={{ marginRight: "1rem" }}
                          variant="success"
                          hidden={!PERMISOS_POR_ROL.DetalleProducto.Auditoria}
                          onClick={() => handleAuditLink("Product")}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faPrint}
                              size="sm"
                              fixedWidth
                            />
                          </span>
                          Auditoría
                        </Button>
                        <Button
                          style={{ marginRight: "1rem" }}
                          variant="success"
                          onClick={() => validateProduct()}
                          hidden={
                            !PERMISOS_POR_ROL.DetalleProducto.ValidarProducto
                          }
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="sm"
                              fixedWidth
                            />
                          </span>
                          Validar
                        </Button>
                        <Button
                          type="button"
                          form="editProduct"
                          variant="success"
                          hidden={
                            !isEditableCommercialStatus ||
                            !PERMISOS_POR_ROL.DetalleProducto.Guardar
                          }
                          disabled={disabledSaveProd}
                          onClick={handleSubmitEditProduct}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faSave}
                              size="sm"
                              fixedWidth
                            />
                          </span>
                          Guardar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    {metadataProd.map((field, i) => (
                      <CardForm
                        key={i}
                        metadata={field.metadata}
                        label={field.label}
                        id={field.id}
                        collapsable={field.collapsable}
                        filter={valueFilterProduct}
                        language={language}
                        languageSensitive={field.languageSensitive}
                        lists={appLists}
                        data={data}
                        setData={(values, prop) => {
                          if (prop === "isForced") {
                            handleChangeForced(values);
                          } else if (prop === "isBlocked") {
                            changeData(values);
                          } else if (
                            user.currentPortfolioID ===
                            portfolios.name.particulares
                          ) {
                            handleDataPARTChange(values);
                          } else if (prop === "inSarebCompetitiveProcess") {
                            handleChangeInProcess(values);
                          } else {
                            setData(values);
                          }
                        }}
                      />
                    ))}
                  </Card.Body>
                </Card>
              </Form>
            </Col>
            <Col>
              <Form noValidate id="editAsset" validated={validated}>
                <Card>
                  <Card.Header>
                    <h4>
                      Activos <S.NumActivos>{dataActivos.length}</S.NumActivos>
                    </h4>
                    <Row className="mt-3 mb-3">
                      <Col xs={6}>
                        <Form.Control
                          type="text"
                          placeholder="Filtrar campos"
                          onChange={(e) => {
                            setValueFilterAsset(e.target.value);
                          }}
                        />
                      </Col>
                      <Col xs={6}>
                        <SelectLanguage
                          language={language}
                          onLanguageChange={onLanguageChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            style={{ marginRight: "1rem" }}
                            variant="danger"
                            hidden={
                              !isEditableCommercialStatus ||
                              !PERMISOS_POR_ROL.DetalleProducto.Guardar
                            }
                            disabled={!(dataActivoIds.length > 1)}
                            onClick={() => setModalDesagruparProducto(true)}
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faClone}
                                size="sm"
                                fixedWidth
                              />
                            </span>
                            Desagrupar Producto
                          </Button>
                          <Button
                            style={{ marginRight: "1rem" }}
                            variant="success"
                            onClick={() => setModalAnadirActivos(true)}
                            hidden={
                              !isEditableCommercialStatus ||
                              !PERMISOS_POR_ROL.DetalleProducto.Guardar
                            }
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faPlus}
                                size="sm"
                                fixedWidth
                              />
                            </span>
                            Añadir Activos
                          </Button>
                          <Button
                            variant="success"
                            onClick={() => setModalEnviarTablaTemporal(true)}
                            hidden={
                              !isEditableCommercialStatus ||
                              !PERMISOS_POR_ROL.DetalleProducto
                                .EnviarTablaTemporal
                            }
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faFileImport}
                                size="sm"
                                fixedWidth
                              />
                            </span>
                            Enviar a Tabla Temporal
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xs={2}>
                        <ListGroup>
                          {orderedPrinexIDs.map((activo, i) => (
                            <Fragment key={i}>
                              {Number(activo.id) === activoPrincipal ? (
                                <ListGroup.Item
                                  key={i}
                                  onClick={() => {
                                    const dataAsset = dataActivos.find(
                                      (s) => s.id === Number(activo.id)
                                    );

                                    if (
                                      user.currentPortfolioID ===
                                      portfolios.name.particulares
                                    ) {
                                      let auxLists = { ...appLists };
                                      auxLists.auxMunicipalities =
                                        auxLists.appMunicipalities.filter(
                                          (e) =>
                                            e.provinceId ===
                                            dataAsset.provinceId
                                        );

                                      auxLists.auxAssetSubTypes =
                                        auxLists.appAssetSubTypes.filter(
                                          (e) => e.typeId === dataAsset.type
                                        );

                                      dispatch({
                                        type: "UPDATE_APP_LIST",
                                        payload: auxLists,
                                      });
                                    }

                                    setDataSelectedActivo(dataAsset);
                                    setActivoActual(activo.id);
                                  }}
                                  style={
                                    Number(activoActual) === Number(activo.id)
                                      ? {
                                          color: "#3c8dbc",
                                          cursor: "pointer",
                                          border: "1px solid #ff0e49",
                                          borderRight: "0",
                                          backgroundColor: "#fff",
                                          borderTopRightRadius: "0",
                                          borderBottomRightRadius: "0",
                                          borderTopLeftRadius: "0",
                                          borderBottomLeftRadius: "0",
                                        }
                                      : {
                                          color: "#3c8dbc",
                                          cursor: "pointer",
                                        }
                                  }
                                >
                                  <span>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      fixedWidth
                                      size="sm"
                                    />
                                  </span>
                                  {activo.prinexId}
                                </ListGroup.Item>
                              ) : (
                                <ListGroup.Item
                                  id={`asset${activo.id}`}
                                  onClick={() => {
                                    const dataAsset = dataActivos.find(
                                      (s) => s.id === Number(activo.id)
                                    );

                                    if (
                                      user.currentPortfolioID ===
                                      portfolios.name.particulares
                                    ) {
                                      let auxLists = { ...appLists };
                                      auxLists.auxMunicipalities =
                                        auxLists.appMunicipalities.filter(
                                          (e) =>
                                            e.provinceId ===
                                            dataAsset.provinceId
                                        );

                                      auxLists.auxAssetSubTypes =
                                        auxLists.appAssetSubTypes.filter(
                                          (e) => e.typeId === dataAsset.type
                                        );

                                      dispatch({
                                        type: "UPDATE_APP_LIST",
                                        payload: auxLists,
                                      });
                                    }

                                    setDataSelectedActivo(dataAsset);
                                    setActivoActual(activo.id);
                                  }}
                                  style={
                                    activoActual === activo.id
                                      ? {
                                          color: "#3c8dbc",
                                          cursor: "pointer",
                                          border: "1px solid #ff0e49",
                                          borderRight: "0",
                                          backgroundColor: "#fff",
                                          borderTopRightRadius: "0",
                                          borderBottomRightRadius: "0",
                                          borderTopLeftRadius: "0",
                                          borderBottomLeftRadius: "0",
                                        }
                                      : {
                                          color: "#3c8dbc",
                                          cursor: "pointer",
                                        }
                                  }
                                >
                                  {activo.prinexId}
                                </ListGroup.Item>
                              )}
                            </Fragment>
                          ))}
                        </ListGroup>
                      </Col>
                      <Col xs={10}>
                        <div
                          style={{
                            marginBottom: "1rem",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            style={{ marginRight: "1rem" }}
                            variant="success"
                            hidden={!PERMISOS_POR_ROL.DetalleProducto.Auditoria}
                            onClick={() => handleAuditLink("Asset")}
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faPrint}
                                size="sm"
                                fixedWidth
                              />
                            </span>
                            Auditoría
                          </Button>
                          <Button
                            style={{ marginRight: "1rem" }}
                            variant="success"
                            onClick={() => setModalCambiarActivoPrincipal(true)}
                            disabled={activoActual === Number(activoPrincipal)}
                            hidden={
                              !isEditableCommercialStatus ||
                              !PERMISOS_POR_ROL.DetalleProducto.Guardar
                            }
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faStar}
                                size="sm"
                                fixedWidth
                              />
                            </span>
                            Activo Principal
                          </Button>
                          <Button
                            type="button"
                            form="editAsset"
                            variant="success"
                            hidden={
                              !isEditableCommercialStatus ||
                              !PERMISOS_POR_ROL.DetalleProducto.Guardar
                            }
                            disabled={disabledSaveAsset}
                            onClick={handleSubmitEditAsset}
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faSave}
                                size="sm"
                                fixedWidth
                              />
                            </span>
                            Guardar
                          </Button>
                        </div>
                        {METADATA_ACTIVO.map((field, index) => (
                          <CardForm
                            key={index}
                            metadata={field.metadata}
                            label={field.label}
                            id={field.id}
                            collapsable={field.collapsable}
                            filter={valueFilterAsset}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            lists={appLists}
                            data={dataSelectedActivo}
                            setData={(values) => {
                              if (
                                user.currentPortfolioID ===
                                  portfolios.name.particulares ||
                                user.currentPortfolioID ===
                                  portfolios.name.terceros
                              ) {
                                handleParticularAssetDataChange(values);
                              } else {
                                handleSelectedAssetDataChange(values);
                              }
                            }}
                          />
                        ))}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </S.DetailPage>
  );
};
