import { createContext, useContext, useReducer } from "react";
import DataReducer from "./DataReducer";

const initialState = {
  currentPortfolio: {
    name: "",
    id: "",
  },
  appVersion: "",
  user: null,
  appLists: null,
  regLength: 50,
};

const DataContext = createContext({ state: initialState });
export const useDataContext = () => useContext(DataContext);

const DataState = (props) => {
  const [state, dispatch] = useReducer(DataReducer, initialState);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {props.children}
    </DataContext.Provider>
  );
};

export default DataState;
