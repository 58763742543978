import { useEffect } from "react";
import { useDataContext } from "../../context/DataState";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  buildFormDataEditParticularAsset,
  buildFormDataEditTercerosAsset,
  getMetadataAsset,
  getMetadataNewActivoTerceros,
  validateRequiredFields,
} from "./constants";
import { useLoader } from "../../components/Loading";
import * as API from "../../api";
import {
  disabledFalse,
  disabledTrue,
  hiddenFilter,
  portfolios,
} from "../../constants";
import usePermition from "../../constants/usePermition";

const useCreateAsset = () => {
  const {
    dispatch,
    state: { user, appLists },
  } = useDataContext();
  const navigate = useNavigate();
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [valueFilter, setValueFilter] = useState("");
  const [language, setLanguage] = useState("ES");
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [MDA1, setMDA1] = useState([]);
  const [MDA2, setMDA2] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();
  const { id } = useParams();
  const [opened, setOpened] = useState(false);
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    document.title = id ? "EC-Light-Detalle Activo" : "EC-Light-Crear Activo";
    // redirect to start page if current portfolio isn´t particular
    if (
      user.currentPortfolioID !== portfolios.name.particulares &&
      user.currentPortfolioID !== portfolios.name.terceros
    ) {
      navigate("/");
    }
    if (id) {
      getAsset();
    } else {
      getAllManagmentCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  /**
   * Open tabs when md is loaded
   */
  useEffect(() => {
    if (!opened && !!MDA2.length) {
      let accs = document.getElementsByClassName("accordion-button");
      for (let i = 0, len = accs.length; i < len; i++) {
        accs[i].click();
      }
      setOpened(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MDA2]);

  const createAssetPermition = () => {
    const userRoles = user.roles;
    if (
      userRoles.filter(
        (s) =>
          s.name === "ADMIN" ||
          s.name === "SUPERADMIN" ||
          s.name === "COMMERCIALDELEGATE" ||
          s.name === "CONTENTEDITOR" ||
          s.name === "BUSINESSMANAGER"
      ).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Get Asset filter subtypes by type, filter municipalities by province
   */
  const getAsset = () => {
    let data = {
      iDs: [Number(id)],
    };
    showLoader();
    API.obtenerActivo(data)
      .then((res) => {
        let auxData = res.data[0];
        let auxLists = { ...appLists };
        if (auxData.municipalityId) {
          auxData.provinceId = appLists.appMunicipalities.find(
            (e) => e.id === auxData.municipalityId
          ).provinceId;
          auxLists.auxMunicipalities = auxLists.appMunicipalities.filter(
            (e) => e.provinceId === auxData.provinceId
          );
        }
        auxData.typeId = appLists.appAssetSubTypes.find(
          (e) => e.id === auxData.subTypeId
        ).type;

        let arraySubTypes = auxLists.appAssetSubTypes.filter(
          (e) => e.type === auxData.typeId
        );
        arraySubTypes = arraySubTypes
          .sort((a, b) => a.type - b.type)
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.name === value.name)
          );

        let auxSub = appLists.appAssetSubTypes.find(
          (e) => e.id === auxData.subTypeId
        );

        arraySubTypes = arraySubTypes.filter((e) => e.name !== auxSub.name);

        arraySubTypes.push(auxSub);

        auxLists.auxAssetSubTypes = arraySubTypes;

        //replace . for ,
        if (auxData.crmPrice) {
          auxData.crmPrice = auxData.crmPrice.toString().replace(".", ",");
        }
        if (auxData.heatingEmissions) {
          auxData.heatingEmissions = auxData.heatingEmissions
            .toString()
            .replace(".", ",");
        }
        if (auxData.terraceSurface) {
          auxData.terraceSurface = auxData.terraceSurface
            .toString()
            .replace(".", ",");
        }
        if (auxData?.photoDetails) {
          const formattedText = auxData?.photoDetails.replace(/\|/g, "\n");
          auxData.photoDetails = formattedText;
        }

        getAllUsers(auxLists, auxData);
      })
      .catch((e) => API.DEVELOP && console.log(e))
      .finally(hideLoader);
  };

  /**
   * Get Managment Companies for fill the select
   */
  const getAllManagmentCompanies = (list, auxData) => {
    showLoader();
    API.obtenerSociedadesGestoras()
      .then((res) => {
        let auxList = !!list ? list : { ...appLists };
        const currentCartera = user.currentPortfolioID;
        let auxManagmentCompanyList = res.data.filter(
          (e) => e.portfolioId === currentCartera
        );

        const sociedadesGestorasList = auxManagmentCompanyList.map((item) => ({
          Id: item.prinexId,
          name: item.name,
        }));

        auxList.managmentCompanyList = sociedadesGestorasList || [];

        let auxiliarData = !!auxData ? { ...auxData } : {};

        if (!!!auxData) {
          auxiliarData.businessLine = 3;
          auxiliarData.commercialStatusId = "ECP-1";
        }
        if (currentCartera === portfolios.name.particulares) {
          auxiliarData.managementCompanyId = auxManagmentCompanyList[0]?.id;
          auxiliarData.managementCompanyPrinexId =
            auxManagmentCompanyList[0]?.prinexId;
          auxiliarData.managementCompany = auxManagmentCompanyList[0]?.name;
          auxiliarData.managementCompanyNif = auxManagmentCompanyList[0]?.cif;
          auxiliarData.managementCompanyAddress =
            auxManagmentCompanyList[0]?.address;
        } else if (currentCartera === portfolios.name.terceros) {
          auxiliarData.managementCompanyId =
            auxManagmentCompanyList[0]?.prinexId;
          auxiliarData.managementCompanyPrinexId =
            auxManagmentCompanyList[0]?.prinexId;
          auxiliarData.managementCompany = auxManagmentCompanyList[0]?.name;
          auxiliarData.managementCompanyNif = auxManagmentCompanyList[0]?.cif;
          auxiliarData.managementCompanyAddress =
            auxManagmentCompanyList[0]?.address;
        }

        setData(auxiliarData);
        setInitialData(auxiliarData);
        setHasDataChanged(false);
        if (!id) {
          auxList.auxMunicipalities = appLists.appMunicipalities;
        }
        getAllDelegations(auxList);
      })
      .catch((e) => console.log(e))
      .finally(hideLoader);
  };

  /**
   * Get all delegations && put its on list
   */
  const getAllDelegations = (lists) => {
    showLoader();
    API.obtenerDelegaciones()
      .then((result) => {
        let auxList = { ...lists };
        auxList.delegationList = result.data;

        let listCommercialNT = JSON.parse(
          localStorage.getItem("commercialNetType")
        );

        //put commercial net type list
        auxList.commercialNetType = listCommercialNT.All;
        if (!id) {
          auxList.auxAssetSubTypes = auxList.appAssetSubTypes
            .sort((a, b) => a.type - b.type)
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.name === value.name)
            );
        }

        //put delegations list
        const userDelegations = user.delegations;
        const currentPortfolioId = user.currentPortfolioID;
        let delegationList = result.data.filter(
          (s) =>
            userDelegations.includes(s.id) &&
            currentPortfolioId === s.portfolioId
        );
        auxList.delegationList = delegationList;

        dispatch({
          type: "UPDATE_APP_LIST",
          payload: auxList,
        });

        // Start fields order
        let metadata;
        if (user.currentPortfolioID === "CAR-PARTI") {
          metadata = getMetadataAsset(id);
        } else {
          metadata = getMetadataNewActivoTerceros(id);
        }

        if (!id) {
          metadata = metadata.filter((e) => e.label !== "Auditoría");
        }
        const half = Math.ceil(metadata.length / 2);

        setMDA1(metadata.slice(0, half));
        setMDA2(metadata.slice(half));
        //End fields order
      })
      .catch((e) => toast.error(e))
      .finally(hideLoader);
  };

  /**
   * get application users to put them on lists for show in audit
   * @param {*} lists
   */
  const getAllUsers = (lists, auxData) => {
    showLoader();
    API.obtenerUsuariosList()
      .then((result) => {
        lists.userList = result.data;
        getAllManagmentCompanies(lists, auxData);
      })
      .catch((e) => API.DEVELOP && console.log(e))
      .finally(hideLoader);
  };

  const particularPermition = () => {
    let hasAccess = false;
    const rolesAux = user.roles.filter(
      (s) =>
        s.name === "ADMIN" || s.name === "PRIVATE" || s.name === "SUPERADMIN"
    );
    if (!!rolesAux.length) {
      hasAccess = true;
    } else {
      hasAccess = false;
    }
    return hasAccess;
  };

  /**
   * Asign listener to check if there are unsaved changes before close page
   */
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasDataChanged) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasDataChanged]);

  /**
   * Go back page & verify if there are unsaved changes
   */
  const goBack = () => {
    if (!hasDataChanged) {
      let auxiliarData = {};
      auxiliarData.okPBC = "-Seleccionar-";
      auxiliarData.okPBC = "-Seleccionar-";
      auxiliarData.okRegistral = "-Seleccionar-";
      auxiliarData.okRegistral = "-Seleccionar-";
      auxiliarData.mandateModality = "-Seleccionar-";
      auxiliarData.typeOfRoad = "-Seleccionar-";
      auxiliarData.provinceId = "";
      auxiliarData.municipalityId = "";
      auxiliarData.commercialNetType = "";
      auxiliarData.typeId = "";
      auxiliarData.subTypeId = "";
      auxiliarData.hasStorage = "";
      auxiliarData.hasGarage = "";
      auxiliarData.garageType = "";
      setData(auxiliarData);
      navigate(-1);
    } else {
      if (window.confirm("Existen cambios sin guardar. ¿Desea salir?")) {
        navigate(-1);
      }
    }
  };

  /**
   * Change traducible form fileds language
   * @param {*} param0
   */
  const onLanguageChange = ({ target: { value } }) => {
    setLanguage(value);
  };

  /**
   * Submit form
   * @returns
   */
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    const currentCartera = user.currentPortfolioID;
    if (form.checkValidity() === false) {
      setValidated(true);
      const emptyReguiredFields = validateRequiredFields(data, id);
      const singular = emptyReguiredFields.length === 1;
      const errorMsg = `${singular ? "El" : "Los"} campo${
        singular ? "" : "s "
      }${
        singular
          ? ` ${emptyReguiredFields[0].label} `
          : `[${emptyReguiredFields.map((e) => `${e.label}`)}]`
      }${singular ? " es" : " son"} obligatorio${singular ? "" : "s"}`;
      toast.error(errorMsg.replaceAll(",", ", "));
    } else {
      const emptyReguiredFields = validateRequiredFields(data, id);
      if (!emptyReguiredFields.length) {
        let formData;
        if (currentCartera === "CAR-PARTI") {
          formData = buildFormDataEditParticularAsset(data);
        } else {
          formData = buildFormDataEditTercerosAsset(data);
        }
        if (!id) {
          showLoader();
          const serviceForSaveActivo =
            currentCartera === "CAR-PARTI"
              ? API.crearActivoParticulares
              : API.crearActivoTerceros;
          serviceForSaveActivo(formData)
            .then((res) => {
              toast.success("Activo creado correctamente");
              if (currentCartera === "CAR-PARTI") {
                navigate(`/particular-asset/${res.data.id}`);
              } else {
                navigate(`/terce-asset/${res.data.id}`);
              }
            })
            .catch((error) => API.DEVELOP && console.log(error))
            .finally(() => hideLoader());
        } else {
          if (currentCartera === "CAR-PARTI") {
            updateAssetParticulares(formData);
          } else {
            updateAssetTerceros(formData);
          }
        }
      } else {
        const singular = emptyReguiredFields.length === 1;
        const errorMsg = `${singular ? "El" : "Los"} campo${
          singular ? "" : "s "
        }${
          singular
            ? ` ${emptyReguiredFields[0].label} `
            : `[${emptyReguiredFields.map((e) => `${e.label}`)}]`
        }${singular ? " es" : " son"} obligatorio${singular ? "" : "s"}`;
        toast.error(errorMsg.replaceAll(",", ", "));
      }
    }
    setHasDataChanged(false);
  };

  /**
   * Actualizar el activo
   * @param {*} formdata
   */
  const updateAssetParticulares = (formdata) => {
    showLoader();
    API.editarActivoParticular(formdata)
      .then((res) => {
        getAsset();
        toast.success("Activo guardado correctamente");
      })
      .catch((e) => API.DEVELOP && console.log(e))
      .finally(() => hideLoader());
  };

  const updateAssetTerceros = (formdata) => {
    showLoader();
    API.editarActivoTerceros(formdata)
      .then((res) => {
        getAsset();
        toast.success("Activo guardado correctamente");
      })
      .catch((e) => API.DEVELOP && console.log(e))
      .finally(() => hideLoader());
  };

  const isReadOnly = () => {
    let found = false;
    const userRoles = user.roles;
    if (userRoles.filter((s) => s.name === "READONLY").length > 0) {
      found = true;
    }
    return found;
  };

  /**
   * Navigate to audit
   */
  const handleAuditLink = () => {
    navigate(`/auditing?EntityName=Asset&EntityId=${data.id}`);
  };

  /**
   * Set values from form. Filter municipalities select by selected province
   * @param {*} values
   */
  const handleDataChange = (values) => {
    let auxMD = [...MDA1, ...MDA2];
    let auxLists = { ...appLists };
    let municipalities = appLists.appMunicipalities;
    let auxMunMD = auxMD
      .find((e) => e.label === "Localización")
      .metadata.find((el) => el.name === "municipalityId");
    if (!!values.provinceId) {
      municipalities = municipalities.filter(
        (mun) => mun.provinceId === values.provinceId
      );
      auxMunMD.disabled = disabledFalse;
    } else {
      values.municipalityId = "";
      auxMunMD.disabled = disabledTrue;
    }
    if (
      !!values.road &&
      values.typeOfRoad !== "-Seleccionar-" &&
      !!values.number
    ) {
      values.fullAddress =
        values.typeOfRoad + ": " + values.road + ", " + values.number;
    } else {
      delete values.fullAddress;
    }
    auxLists.auxMunicipalities = municipalities;
    //handle type/subTypeif (!!values.typeId) {
    let mdSubType = auxMD
      .find((header) => header.label === "Características del inmueble")
      .metadata.find((md) => md.name === "subTypeId");
    if (!!values.typeId) {
      mdSubType.disabled = disabledFalse;
      const filteredSubTypes = appLists.appAssetSubTypes.filter(
        (el) => el.type === parseInt(values.typeId)
      );
      auxLists.auxAssetSubTypes = filteredSubTypes
        .sort((a, b) => a.type - b.type)
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.name === value.name)
        );
      mdSubType.hidden = hiddenFilter;
    } else {
      values.subTypeId = "";
      mdSubType.disabled = disabledTrue;
    }

    const regex = /[^0-9,]/g;

    //Replace
    if (!!values.price) {
      const stringPrice = values.price.toString();
      const newValue = stringPrice.replace(regex, "");
      values.price = newValue;
    }
    if (!!values.latitude) {
      const newValue = values.latitude.replace(/[^0-9,-]/g, "");
      values.latitude = newValue;
    }
    if (!!values.longitude) {
      const newValue = values.longitude.replace(/[^0-9,-]/g, "");
      values.longitude = newValue;
    }
    if (!!values.builtM2) {
      const newValue = values.builtM2.replace(regex, "");
      values.builtM2 = newValue;
    }
    if (!!values.terraceSurface) {
      const newValue = values.terraceSurface.toString().replace(regex, "");
      values.terraceSurface = newValue;
    }
    if (!!values.bathrooms) {
      const newValue = values.bathrooms.toString().replace(/[^0-9]/g, "");
      values.bathrooms = newValue;
    }
    if (!!values.bedrooms) {
      const newValue = values.bedrooms.toString().replace(/[^0-9]/g, "");
      values.bedrooms = newValue;
    }
    if (!!values.heatingEmissions) {
      const newNVal = values.heatingEmissions.toString().replace(".", ",");
      const newValue = newNVal.replace(regex, "");
      values.heatingEmissions = newValue;
    }
    if (!!values.crmPrice) {
      const newNVal = values.crmPrice.toString().replace(".", ",");
      const newValue = newNVal.replace(regex, "");
      values.crmPrice = newValue;
    }
    if (!!values.buildingYear) {
      const newValue = values.buildingYear.toString().replace(regex, "");
      if (newValue.length <= 4) {
        values.buildingYear = newValue;
      } else {
        values.buildingYear = newValue.slice(0, 4);
      }
    }
    if (!!values.mandateDuration) {
      const mandateDuration = values.mandateDuration.toString();
      const newValue = mandateDuration.replace(/[^0-9,]/g, "");
      if (newValue.length <= 9) {
        values.mandateDuration = newValue;
      } else {
        values.mandateDuration = newValue.slice(0, 9);
      }
    }
    //Finsh

    if (!!values.postalCode) {
      const newValue = values.postalCode.toString().replace(/[^0-9]$/, "");
      if (newValue.length <= 5) {
        values.postalCode = newValue;
      } else {
        values.postalCode = newValue.slice(0, 5);
      }
    }

    if (!!values.municipalityId && !!values.road) {
      let auxMUN = appLists.appMunicipalities.find(
        (e) => e.id === values.municipalityId
      );
      if (auxMUN) {
        values.name = `${values.road} ${auxMUN.name}`;
      }
    } else {
      values.name = "";
    }

    dispatch({
      type: "UPDATE_APP_LIST",
      payload: auxLists,
    });
    setData(values);

    const half = Math.ceil(auxMD.length / 2);

    setMDA1(auxMD.slice(0, half));
    setMDA2(auxMD.slice(half));
    let auxValues = { ...values };
    let auxInitData = { ...initialData };
    for (const attr in auxInitData) {
      if (!auxInitData[attr]) {
        delete auxInitData[attr];
      }
    }
    for (const attr in auxValues) {
      if (!auxValues[attr]) {
        delete auxValues[attr];
      }
    }
    if (JSON.stringify(auxValues) === JSON.stringify(auxInitData)) {
      setHasDataChanged(false);
    } else {
      setHasDataChanged(true);
    }
  };

  /**
   *
   * @returns If user has super admin role
   */
  const isSuperAdmin = () => {
    const userRoles = user.roles;
    if (userRoles.filter((s) => s.name === "SUPERADMIN").length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return {
    goBack,
    hasDataChanged,
    setValueFilter,
    valueFilter,
    language,
    onLanguageChange,
    handleSubmit,
    validated,
    data,
    handleDataChange,
    MDA1,
    MDA2,
    appLists,
    loader,
    id,
    isSuperAdmin,
    handleAuditLink,
    createAssetPermition,
    isReadOnly,
    particularPermition,
    PERMISOS_POR_ROL,
  };
};

export default useCreateAsset;
