import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { InputModalText } from "../InputModalText";
import { InputModalCheckBox } from "../InputModalCheckBox";
import { Button, Modal, Form } from "react-bootstrap";
import { InputModalList } from "../InputModalList";
import { InputModalDataList } from "../InputModalDataList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDataContext } from "../../context/DataState";
import { InputModalNumber } from "../InputModalNumber/InputModalNumber";

export const ModalForm = ({
  metadata,
  message,
  values,
  setValues,
  confirm,
  cancel,
}) => {
  const [validated, setValidated] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const {
    dispatch,
    state: { appLists },
  } = useDataContext();

  useEffect(() => {
    setInitialValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }

  const handleSubmit = (event) => {
    if (!shallowEqual(values, initialValues)) {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else confirm(values);

      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      cancel();
    }
  };

  const handleChange = (prop, value) => {
    const tmpValues = { ...values };
    tmpValues[prop] = value;
    setValues(tmpValues);
  };

  const handleChangeListWithChildren = (prop, value) => {
    const tmpValues = { ...values };
    const tmpLists = { ...appLists };
    tmpValues[prop] = value;
    setValues(tmpValues);
    tmpLists["territorials"] = tmpLists["territorialsAux"].filter(
      (x) => x.portfolioId === value
    );
    dispatch({
      type: "UPDATE_APP_LIST",
      payload: tmpLists,
    });
  };

  const handleChangeCheckBox = (prop, value) => {
    const tmpValues = { ...values };
    if (value) {
      tmpValues[prop] = true;
    } else {
      tmpValues[prop] = false;
    }
    setValues(tmpValues);
  };

  return (
    <Modal
      show={true}
      onHide={cancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form
            noValidate
            id="formModal"
            validated={validated}
            onSubmit={handleSubmit}
          >
            {metadata.map((field, index) => {
              switch (field.type) {
                case "text":
                  return (
                    <InputModalText
                      key={index}
                      id={field?.name}
                      label={field?.label}
                      value={
                        values[field.name] ? values[field.name].toString() : ""
                      }
                      disabled={field?.disabled}
                      hidden={field?.hidden}
                      handleChange={handleChange}
                      required={field?.required}
                      maxLength={field.maxLength}
                    />
                  );
                case "checkbox":
                  return (
                    <InputModalCheckBox
                      key={index}
                      id={field.name}
                      label={field.label}
                      value={values[field.name] === true || false}
                      disabled={field.disabled}
                      hidden={field.hidden}
                      handleChange={handleChangeCheckBox}
                    />
                  );
                case "list":
                  return (
                    <InputModalList
                      key={index}
                      id={field.name}
                      label={field.label}
                      value={values[field.name] || ""}
                      disabled={field.disabled}
                      hidden={field.hidden}
                      handleChange={handleChange}
                      list={appLists[field.listName]}
                      typeList={field.typeList}
                      required={field.required}
                    />
                  );
                case "listWithChildren":
                  return (
                    <InputModalList
                      key={index}
                      id={field.name}
                      label={field.label}
                      value={values[field.name] || ""}
                      disabled={field.disabled}
                      hidden={field.hidden}
                      handleChange={handleChangeListWithChildren}
                      list={appLists[field.listName]}
                      typeList={field.typeList}
                      required={field.required}
                    />
                  );
                case "datalist":
                  return (
                    <InputModalDataList
                      key={index}
                      id={field.name}
                      label={field.label}
                      value={values[field.name] || ""}
                      disabled={field.disabled}
                      hidden={field.hidden}
                      handleChange={handleChange}
                      list={appLists[field.listName]}
                      required={field.required}
                    />
                  );
                case "number":
                  return (
                    <InputModalNumber
                      key={index}
                      id={field.name}
                      label={field.label}
                      value={values[field.name] || ""}
                      disabled={field.disabled}
                      hidden={field.hidden}
                      handleChange={handleChange}
                      list={appLists[field.listName]}
                      required={field.required}
                      min={field.min}
                    />
                  );
                default:
                  return null;
              }
            })}
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="formModal" variant="success">
          <span>
            <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
          </span>
          Confirmar
        </Button>

        {cancel && (
          <Button onClick={cancel} variant="danger">
            <span>
              <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
            </span>
            Cancelar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalForm.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func,
  metadata: PropTypes.array.isRequired,
  message: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
