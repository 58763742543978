import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoader } from "../../components/Loading";
import {
  faArrowLeft,
  faSave,
  faPrint,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import * as API from "../../api";
import { METADATA_EDICION_ACTIVO_1, buildFormDataEdit } from "./constants";
import { CardForm } from "../../components/CardForm";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as S from "./styles";
import { Dialog } from "../../components/Dialog";
import {
  DEFAULT_HEATING_TYPES,
  disabledFalse,
  disabledTrue,
  hiddenFilter,
  hiddenTrue,
  portfolios,
} from "../../constants";
import { useDataContext } from "../../context/DataState";
import usePermition from "../../constants/usePermition";
import SelectLanguage from "../../components/SelectLanguage/SelectLanguage";

export const DetalleActivoSinProducto = () => {
  const [valueFilter, setValueFilter] = useState("");
  const [language, setLanguage] = useState("ES");
  const [data, setData] = useState({});
  const [validated, setValidated] = useState(false);
  const [isEditableCommercialStatus, setIsEditableCommercialStatus] =
    useState(true);
  const [loader, showLoader, hideLoader] = useLoader();
  const navigate = useNavigate();
  const [modalEnviarTablaTemporal, setModalEnviarTablaTemporal] =
    useState(false);
  const { id } = useParams();
  const [MDAsset1, setMDAsset1] = useState([]);
  const [MDAsset2, setMDAsset2] = useState([]);
  const {
    dispatch,
    state: { appLists, user },
  } = useDataContext();
  const [opened, setOpened] = useState(false);
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    document.title = "EC-Light-Activo";

    let isMounted = true;
    if (isMounted && id) {
      loadAsset();
    }
    return () => (isMounted = false);
  }, [showLoader, hideLoader, id]);

  const loadAsset = () => {
    let listsAux = appLists;
    let budgetLine;
    let data = {
      iDs: [Number(id)],
    };
    showLoader();
    API.obtenerActivo(data)
      .then((res) => {
        if (!!res) {
          let dataAux = res.data[0];
          budgetLine = dataAux.budgetLine;

          if (dataAux.municipalityId) {
            let municipality = listsAux.appMunicipalities.find(
              (s) => s.id === dataAux.municipalityId
            );
            if (municipality) {
              dataAux.provinceId = municipality.provinceId;
            }
          }
          if (dataAux?.photoDetails) {
            const formattedText = dataAux?.photoDetails.replace(/\|/g, "\n");
            dataAux.photoDetails = formattedText;
          }
          let listCommercialType = [];
          let listCommercialNT = JSON.parse(
            localStorage.getItem("commercialNetType")
          );

          listCommercialType = listCommercialNT.All;
          if (dataAux.portfolioId === portfolios.name.anticipa) {
            listCommercialType = [
              ...listCommercialType,
              listCommercialNT[portfolios.name.anticipa],
            ];
          }

          listsAux.commercialNetType = listCommercialType;
          if (dataAux.registralMunicipalityId) {
            let municipality = listsAux.appMunicipalities.find(
              (s) => s.id === dataAux.registralMunicipalityId
            );
            if (municipality) {
              dataAux.registralProvinceId = municipality.provinceId;
            }
          }
          if (dataAux.subTypeId) {
            let type = listsAux.appAssetSubTypes.find(
              (s) => s.id === dataAux.subTypeId
            );
            if (type) {
              dataAux.typeId = type.type;
            }
          }
          if (dataAux.subTypeModId) {
            let type = listsAux.appAssetSubTypes.find(
              (s) => s.id === dataAux.subTypeModId
            );
            if (type) {
              dataAux.typeModId = type.type;
            }
          }
          if (dataAux.commercialStatusId) {
            if (
              listsAux.appNotEditableCommercialStatus.find(
                (s) => s === dataAux.commercialStatusId
              )
            ) {
              setIsEditableCommercialStatus(false);
            } else {
              setIsEditableCommercialStatus(true);
            }
          }

          let currentPort = user.currentPortfolioID;

          // hidde fields by portfolio
          let auxMDA1 = [...METADATA_EDICION_ACTIVO_1];
          let headToRemove = [];
          auxMDA1.forEach((head) => {
            if (
              !!head.visibleFor?.length &&
              !head.visibleFor?.includes(currentPort)
            ) {
              headToRemove.push(head.label);
            } else {
              head.metadata.forEach((field) => {
                if (!!field.visibleFor?.length) {
                  if (!field.visibleFor.includes(currentPort)) {
                    field.hidden = hiddenTrue;
                  }
                }
              });
            }
          });

          let auxMDFloor = auxMDA1.find((e) => e.id === "suelo");
          if (auxMDFloor) {
            let auxSegField = auxMDFloor.metadata.find(
              (md) => md.name === "segmentation"
            );
            if (auxSegField) {
              if (dataAux.businessLine === 1) {
                auxSegField.disabled = disabledFalse;
              } else {
                auxSegField.disabled = disabledTrue;
              }
            }
          }

          // hidde/show anticipaPosesion
          let generalData = auxMDA1.find((e) => e.id === "generalData");
          let posessionField = null;
          if (generalData) {
            posessionField = generalData.metadata.find(
              (e) => e.name === "anticipaPosesion"
            );
          }

          if (user.currentPortfolioID !== portfolios.name.anticipa) {
            if (posessionField) {
              posessionField.hidden = hiddenTrue;
            }
          } else {
            if (posessionField) {
              posessionField.hidden = hiddenFilter;
            }
          }

          // enable/disable delegationId
          if (PERMISOS_POR_ROL.AnyAdmin && generalData) {
            const delegation = generalData.metadata.find(
              (e) => e.name === "delegationId"
            );
            if (delegation) delegation.disabled = disabledFalse;
          }

          // enble/disable hideNumber
          let locSection = auxMDA1.find((e) => e.id === "localization");
          if (PERMISOS_POR_ROL.DetalleProducto.OcultarNumero) {
            if (locSection) {
              locSection.metadata.find(
                (e) => e.name === "hideNumber"
              ).disabled = disabledFalse;
            }
          } else {
            if (locSection) {
              locSection.metadata.find(
                (e) => e.name === "hideNumber"
              ).disabled = disabledTrue;
            }
          }

          const userRoles = user.roles.map((rol) => rol.name.toUpperCase());
          let granularData = auxMDA1.find((e) => e.id === "granular");
          let energyCertificationData = auxMDA1.find(
            (e) => e.id === "energyCertification"
          );
          let graphycMaterialData = auxMDA1.find(
            (e) => e.id === "graphycMaterial"
          );
          let basicFeaturesData = auxMDA1.find((e) => e.id === "basicFeatures");
          if (
            userRoles.includes("CONTENTEDITOR") &&
            (user.currentPortfolioID === portfolios.name.quasar ||
              user.currentPortfolioID === portfolios.name.sareb ||
              user.currentPortfolioID === portfolios.name.anticipa)
          ) {
            if (granularData) {
              let hasBalconyEDC = granularData.metadata.find(
                (e) => e.name === "hasBalconyEDC"
              );
              hasBalconyEDC.disabled = disabledFalse;

              let heatingType = granularData.metadata.find(
                (e) => e.name === "heatingType"
              );
              heatingType.disabled = disabledFalse;

              let buildingYear = granularData.metadata.find(
                (e) => e.name === "buildingYear"
              );
              buildingYear.disabled = disabledFalse;

              let builtInWardrobe = granularData.metadata.find(
                (e) => e.name === "builtInWardrobe"
              );
              builtInWardrobe.disabled = disabledFalse;

              let buildingStatus = granularData.metadata.find(
                (e) => e.name === "buildingStatus"
              );
              buildingStatus.disabled = disabledFalse;

              let buildingOrientation = granularData.metadata.find(
                (e) => e.name === "buildingOrientation"
              );
              buildingOrientation.disabled = disabledFalse;
            }
            if (energyCertificationData) {
              let heatingEmissions = energyCertificationData.metadata.find(
                (e) => e.name === "heatingEmissions"
              );
              heatingEmissions.disabled = disabledFalse;

              let energyCertification = energyCertificationData.metadata.find(
                (e) => e.name === "energyCertification"
              );
              energyCertification.disabled = disabledFalse;

              let energyConsumption = energyCertificationData.metadata.find(
                (e) => e.name === "energyConsumption"
              );
              energyConsumption.disabled = disabledFalse;

              let emissionsCertification =
                energyCertificationData.metadata.find(
                  (e) => e.name === "emissionsCertification"
                );
              emissionsCertification.disabled = disabledFalse;
            }
            if (graphycMaterialData) {
              let areValidPhotos = graphycMaterialData.metadata.find(
                (e) => e.name === "areValidPhotos"
              );
              areValidPhotos.disabled = disabledFalse;

              let photoDetails = graphycMaterialData.metadata.find(
                (e) => e.name === "photoDetails"
              );
              photoDetails.disabled = disabledFalse;

              let photoComments = graphycMaterialData.metadata.find(
                (e) => e.name === "photoComments"
              );
              photoComments.disabled = disabledFalse;

              let photoDetails2 = graphycMaterialData.metadata.find(
                (e) => e.name === "photoDetails2"
              );
              photoDetails2.disabled = disabledFalse;

              let photoValidation2 = graphycMaterialData.metadata.find(
                (e) => e.name === "photoValidation2"
              );
              photoValidation2.disabled = disabledFalse;

              let tourDetails2 = graphycMaterialData.metadata.find(
                (e) => e.name === "tourDetails2"
              );
              tourDetails2.disabled = disabledFalse;

              let tourValidation = graphycMaterialData.metadata.find(
                (e) => e.name === "tourValidation"
              );
              tourValidation.disabled = disabledFalse;

              let descriptionDetails = graphycMaterialData.metadata.find(
                (e) => e.name === "descriptionDetails"
              );
              descriptionDetails.disabled = disabledFalse;

              let descriptionValidation = graphycMaterialData.metadata.find(
                (e) => e.name === "descriptionValidation"
              );
              descriptionValidation.disabled = disabledFalse;

              let agreeTour = graphycMaterialData.metadata.find(
                (e) => e.name === "agreeTour"
              );
              agreeTour.disabled = disabledFalse;
            }
          }

          if (
            userRoles.includes("CONTENTEDITOR") ||
            userRoles.includes("MARKETING") ||
            userRoles.includes("SUPERADMIN") ||
            userRoles.includes("ADMIN")
          ) {
            if (basicFeaturesData) {
              let isNew = basicFeaturesData.metadata.find(
                (e) => e.name === "isNew"
              );
              isNew.disabled = disabledFalse;
            }
          }

          if (!PERMISOS_POR_ROL.DetalleActivo.Auditoria) {
            auxMDA1 = auxMDA1.filter((e) => e.id !== "audit");
          }

          // mostrar/esconder campos de suelo
          if (user.currentPortfolioID === portfolios.name.sareb) {
            let floorSec = auxMDA1.find((e) => e.id === "suelo");
            let basicFeaturesSec = auxMDA1.find(
              (e) => e.id === "basicFeatures"
            );
            if (basicFeaturesSec) {
              let curentUseField = basicFeaturesSec.metadata.find(
                (e) => e.name === "currentUse"
              );
              curentUseField.disabled = disabledTrue;
            }
            if (floorSec) {
              let plotAreaField = floorSec.metadata.find(
                (e) => e.name === "plotArea"
              );
              let buildableVolumeField = floorSec.metadata.find(
                (e) => e.name === "buildableVolume"
              );
              plotAreaField.disabled = disabledTrue;
              buildableVolumeField.disabled = disabledTrue;
            }
          }

          auxMDA1 = auxMDA1.filter((e) => !headToRemove.includes(e.label));

          const half = Math.ceil(auxMDA1.length / 2);

          setMDAsset1(auxMDA1.slice(0, half));
          setMDAsset2(auxMDA1.slice(half));
          setData(dataAux);
          getDelegations(
            dataAux.businessLine,
            listsAux,
            budgetLine,
            dataAux.delegationId
          );
        } else {
          navigate("/404");
        }
      })
      .catch((error) => {
        if (error.code === 401) {
          navigate("/404");
        } else {
          API.DEVELOP && console.log(error);
        }
      })
      .finally(() => hideLoader());
  };

  useEffect(() => {
    // Dependiendo del tipo de negocio se despliega su seccion y se pliegan las de los otros tipos de negocio
    if (!!MDAsset2.length && !opened && !!data) {
      let accs = document.getElementsByClassName("accordion-button");
      for (var i = 0, len = accs.length; i < len; i++) {
        if (accs[i].innerText === "Suelo" && data.businessLine === 1) {
          accs[i].click();
        } else if (
          accs[i].innerText === "Terciario" &&
          data.businessLine === 2
        ) {
          accs[i].click();
        } else if (
          accs[i].innerText === "Granular" &&
          data.businessLine === 3
        ) {
          accs[i].click();
        } else {
          if (
            accs[i].innerText !== "Suelo" &&
            accs[i].innerText !== "Terciario" &&
            accs[i].innerText !== "Granular"
          ) {
            accs[i].click();
          }
        }
      }
      setOpened(true);
    }
  }, [data]);

  const getDelegations = (businessLine, listsAux, budgetLine, delegationId) => {
    if (!opened) {
      showLoader();
      API.obtenerDelegaciones()
        .then((res) => {
          const userDelegations = user.delegations;
          const currentPortfolioId = user.currentPortfolioID;
          const assetDelegation = res.data.find((e) => e.id === delegationId);
          // const businessLine = res.data[0].businessLine;
          let delegationList = res.data
            .filter(
              (s) =>
                userDelegations.includes(s.id) &&
                currentPortfolioId === s.portfolioId &&
                businessLine === s.businessLine
            )
            // se filtra por territorial (excepto para la cartera ANTICIPA)
            .filter(
              (s) =>
                user.currentPortfolioID === "CAR-ANTICI" ||
                s.territorialId === assetDelegation.territorialId
            );
          listsAux.delegationList = delegationList;
          showLoader();
          API.obtenerUsuariosList()
            .then((res) => {
              listsAux.users = res.data;
              listsAux.heatingType = DEFAULT_HEATING_TYPES;
              dispatch({
                type: "UPDATE_APP_LIST",
                payload: listsAux,
              });
            })
            .catch((error) => {
              if (error.code === 401) {
                navigate("/404");
              } else {
                API.DEVELOP && console.log(error);
              }
            })
            .finally(() => hideLoader());
        })
        .catch((error) => {
          if (error.code === 401) {
            navigate("/404");
          } else {
            if (error.code === 401) {
              navigate("/404");
            } else {
              API.DEVELOP && console.log(error);
            }
          }
        })
        .finally(() => hideLoader());
    }
  };

  const handleDataChange = (formData) => {
    setData(formData);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (id) {
        let formData = buildFormDataEdit(data);
        formData.id = id;
        if (formData.delegationId)
          formData.delegationId = parseInt(formData.delegationId);
        else if (formData.delegationId === "") formData.delegationId = null;
        if (formData.commercialNetType)
          formData.commercialNetType = parseInt(formData.commercialNetType);
        else if (formData.commercialNetType === "")
          formData.commercialNetType = null;
        if (formData.garageType)
          formData.garageType = parseInt(formData.garageType);
        else if (formData.garageType === "") formData.garageType = null;
        showLoader();
        API.editarActivo(formData)
          .then((res) => {
            toast.success("Activo editado correctamente");
            loadAsset();
          })
          .catch((error) => {
            if (error.code === 401) {
              navigate("/404");
            } else {
              API.DEVELOP && console.log(error);
            }
          })
          .finally(() => hideLoader());
      }
    }
  };

  const onLanguageChange = ({ target: { value } }) => {
    setLanguage(value);
  };

  const confirmEnviarTablaTemporal = () => {
    showLoader();
    setModalEnviarTablaTemporal(false);
    let data = {};
    API.enviarTablaTemporalActivo(id, data)
      .then((res) => {
        toast.success("Activo enviado a tabla temporal");
      })
      .catch((error) => {
        if (error.code === 401) {
          navigate("/404");
        } else {
          API.DEVELOP && console.log(error);
        }
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleAuditLink = () => {
    navigate(`/auditing?EntityName=Asset&EntityId=${data.id}`);
  };

  return (
    <S.DetailPage>
      {loader}
      {modalEnviarTablaTemporal && (
        <Dialog
          type="noFooter"
          message="Se enviará el activo a una tabla temporal, ¿desea continuar?"
          labelConfirm="Continuar"
          confirm={confirmEnviarTablaTemporal}
          cancel={() => setModalEnviarTablaTemporal(false)}
          value={{}}
        />
      )}
      <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Col>
          <S.Title>Activo {data.prinexId}</S.Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col xs={1}>
                  <Button variant="danger" onClick={() => navigate(-1)}>
                    <span>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        size="lg"
                        fixedWidth
                      />
                    </span>
                  </Button>
                </Col>
                <Col xs={3}>
                  <Form.Control
                    type="text"
                    placeholder="Filtrar campos"
                    onChange={(e) => {
                      setValueFilter(e.target.value);
                    }}
                  />
                </Col>
                <Col
                  xs={8}
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <div
                    key={`inline-radio`}
                    className="mb-1 mt-1"
                    style={{ display: "contents" }}
                  >
                    <div
                      id={
                        PERMISOS_POR_ROL.DetalleActivo.Auditoria ||
                        (PERMISOS_POR_ROL.DetalleActivo.Guardar &&
                          isEditableCommercialStatus)
                          ? "idiomsContainer"
                          : ""
                      }
                    >
                      <SelectLanguage
                        language={language}
                        onLanguageChange={onLanguageChange}
                      />
                    </div>

                    <Button
                      variant="success"
                      hidden={
                        !PERMISOS_POR_ROL.DetalleActivo.EnviarTablaTemporal
                      }
                      onClick={() => setModalEnviarTablaTemporal(true)}
                    >
                      <span>
                        <FontAwesomeIcon
                          icon={faFileImport}
                          size="lg"
                          fixedWidth
                        />
                      </span>
                      Enviar a Tabla Temporal
                    </Button>
                    <Button
                      style={{ marginLeft: "1rem" }}
                      variant="success"
                      hidden={!PERMISOS_POR_ROL.DetalleActivo.Auditoria}
                      onClick={handleAuditLink}
                    >
                      <span>
                        <FontAwesomeIcon icon={faPrint} size="lg" fixedWidth />
                      </span>
                      Auditoría
                    </Button>
                    <Button
                      type="submit"
                      form="editAsset"
                      variant="success"
                      hidden={
                        !PERMISOS_POR_ROL.DetalleActivo.Guardar ||
                        !isEditableCommercialStatus
                      }
                      style={{ marginLeft: "1rem" }}
                    >
                      <span>
                        <FontAwesomeIcon icon={faSave} size="lg" fixedWidth />
                      </span>
                      Guardar
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Form
                noValidate
                id="editAsset"
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col>
                    {MDAsset1.map((field, i) => (
                      <CardForm
                        key={i}
                        metadata={field.metadata}
                        id={field.id}
                        label={field.label}
                        collapsable={field.collapsable}
                        filter={valueFilter}
                        language={language}
                        languageSensitive={field.languageSensitive}
                        lists={appLists}
                        data={data}
                        setData={handleDataChange}
                      />
                    ))}
                  </Col>
                  <Col>
                    {MDAsset2.map((field, i) => (
                      <CardForm
                        key={i}
                        metadata={field.metadata}
                        label={field.label}
                        id={field.id}
                        collapsable={field.collapsable}
                        filter={valueFilter}
                        language={language}
                        languageSensitive={field.languageSensitive}
                        lists={appLists}
                        data={data}
                        setData={handleDataChange}
                      />
                    ))}
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </S.DetailPage>
  );
};
