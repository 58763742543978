import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  width: 96%;
  margin: auto;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  margin-top: 12px;

  table tr {
    height: 10px;
    height: 34px;
  }
`;

export const Dropdown = styled.td`
  cursor: pointer;
`;

export const OldValue = styled.td`
  background-color: ${COLORES.AUDIT_OLD_VALUE};
  padding-left: 0.5rem;
`;

export const NewValue = styled.td`
  background-color: ${COLORES.AUDIT_NEW_VALUE};
  padding-left: 0.5rem;
`;
