import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { RenderInputs } from "./RenderInputs";

export const ModalEditDelegation = ({
  metadata,
  message,
  values,
  setValues,
  confirm,
  cancel,
  metadata2,
  valuesRangePrice,
  setValuesRangePrice,
}) => {
  const [validated, setValidated] = useState(false);
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    setInitialValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }

  const handleSubmit = (event) => {
    if (!shallowEqual(values, initialValues)) {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else confirm(values);

      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      cancel();
    }
  };

  return (
    <Modal
      show={true}
      onHide={cancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "30px" }}>
        <div>
          <Form
            noValidate
            id="formModal"
            validated={validated}
            onSubmit={handleSubmit}
          >
            <RenderInputs
              metadata={metadata}
              numColumns={2}
              values={values}
              setValues={setValues}
            />
          </Form>
          {!values?.isDefault && (
            <>
              <S.TitleRangePrice>Rangos de precio</S.TitleRangePrice>
              {metadata2.map((form, index) => (
                <S.ContainerMetadata key={index}>
                  <Row>
                    <Col md={12}>
                      <RenderInputs
                        metadata={form}
                        numColumns={4}
                        values={valuesRangePrice[index]}
                        setValues={setValuesRangePrice}
                        index={index}
                      />
                    </Col>
                  </Row>
                </S.ContainerMetadata>
              ))}
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="formModal" variant="success">
          <span>
            <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
          </span>
          Confirmar
        </Button>

        {cancel && (
          <Button onClick={cancel} variant="danger">
            <span>
              <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
            </span>
            Cancelar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
