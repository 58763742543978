import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

export const Dialog = ({
  type,
  message,
  messageBody,
  labelConfirm,
  confirm,
  cancel,
  value,
}) => {
  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      {type === "noFooter" && (
        <Modal.Body>
          <Button
            style={{ marginRight: "1rem" }}
            onClick={() => confirm(value)}
            variant="success"
          >
            <span>
              <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
            </span>
            {labelConfirm}
          </Button>

          {cancel && (
            <Button onClick={cancel} variant="danger">
              <span>
                <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
              </span>
              Cancelar
            </Button>
          )}
        </Modal.Body>
      )}
      {type === "footer" && (
        <Fragment>
          <Modal.Body>{messageBody}</Modal.Body>
          <Modal.Footer>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => confirm(value)}
              variant="success"
            >
              <span>
                <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
              </span>
              {labelConfirm}
            </Button>

            {cancel && (
              <Button onClick={cancel} variant="danger">
                <span>
                  <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
                </span>
                Cancelar
              </Button>
            )}
          </Modal.Footer>
        </Fragment>
      )}
    </Modal>
  );
};

Dialog.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  messageBody: PropTypes.string,
  labelConfirm: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  value: PropTypes.string,
};
