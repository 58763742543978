import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";

export const InputModalText = ({
  id,
  label,
  value,
  placeholder,
  handleChange,
  disabled,
  hidden,
  filter,
  required,
  maxLength,
  inputBootstrap = true,
}) => {
  return (
    <Row className="mb-2">
      {inputBootstrap ? (
        <Col>
          <Form.Label hidden={hidden(filter, label)}>{label}</Form.Label>
        </Col>
      ) : (
        <Form.Label hidden={hidden(filter, label)}>{label}</Form.Label>
      )}

      <Col>
        <Form.Control
          required={required}
          type="text"
          placeholder={placeholder}
          value={value}
          id={id}
          disabled={disabled()}
          hidden={hidden(filter, label)}
          onChange={(e) => handleChange(id, e.target.value)}
          maxLength={maxLength}
          autoComplete="off"
        />
        <Form.Control.Feedback type="invalid">
          El {label} es necesario.
        </Form.Control.Feedback>
      </Col>
    </Row>
  );
};

InputModalText.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.func.isRequired,
  hidden: PropTypes.func.isRequired,
  required: PropTypes.bool,
};
