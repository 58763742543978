import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import * as API from "../api";

const useModalAddAsset = ({
  id,
  lists,
  confirm,
  showLoader,
  hideLoader,
  owner,
}) => {
  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState("");
  const [orderDesc, setOrderDesc] = useState(true);
  const [pageTable, setPage] = useState(0);
  const [regsPage, setRegsPage] = useState(owner !== "promotion" ? 50 : 200);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [numRegisters, setNumRegisters] = useState(0);
  const [dialogTooMuchAssets, setDialogTooMuchAssets] = useState(false);

  useEffect(() => {
    if (pageTable > 0) {
      busquedaAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTable, regsPage, order, orderDesc]);

  /**
   * Culumnas de la tabla de busqueda
   */
  const columns = [
    {
      Header: (
        <Form.Check
          onChange={(e) => {
            setSelectedAllAssets(e.target.checked);
          }}
          disabled={numRegisters === 0}
          checked={selected.length === numRegisters}
        />
      ),
      accessor: "select",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Form.Check
          onChange={(e) => {
            handleChangeSelectedAssets(
              parseInt(cell.row.original.id),
              parseInt(cell.row.original.prinexId),
              e.target.checked
            );
          }}
          checked={selected.find((e) => e.id === cell.row.original.id)}
        />
      ),
    },
    {
      Header: "Id Prínex",
      accessor: "prinexId",
      disableFilters: true,
    },
    {
      Header: "Código",
      accessor: "code",
      disableFilters: true,
    },
    {
      Header: "Cartera",
      accessor: "portfolioId",
      disableFilters: true,
    },
    {
      Header: "Negocio",
      accessor: "businessLine",
      disableFilters: true,
    },
    {
      Header: "Expediente",
      accessor: "dossierCode",
      disableFilters: true,
      width: 90,
    },
    {
      Header: "Id Prom. Prinex",
      accessor: "promotionCode",
      disableFilters: true,
    },
    {
      Header: "Tipo",
      accessor: "type",
      disableFilters: true,
    },
    {
      Header: "Principal",
      accessor: "isPrincipal",
      disableFilters: true,
    },
    {
      Header: "Inmueble",
      accessor: "propertyCode",
      disableFilters: true,
    },
  ];

  /**
   * buscar activos por filtros
   */
  const busquedaAssets = () => {
    if (pageTable !== 0) {
      let dataSend = {};
      let collection = [];
      let filtersAux = {};
      if (!!filters.prinexId) filtersAux.prinexId = filters.prinexId;
      if (!!filters.code) filtersAux.code = filters.code;
      if (!!filters.dossierCode) filtersAux.dossierCode = filters.dossierCode;
      if (!!filters.promotionCode)
        filtersAux.promotionCode = filters.promotionCode;
      if (!!filters.propertyCode)
        filtersAux.propertyCode = filters.propertyCode;
      dataSend.page = pageTable;
      dataSend.regsPage = regsPage;
      dataSend.filters = filtersAux;
      if (order !== null && order !== "") {
        dataSend.orderBy = order;
        dataSend.isAsc = orderDesc;
      } else {
        dataSend.orderBy = "prinexId";
        dataSend.isAsc = true;
      }
      switch (owner) {
        case "campaign": {
          showLoader();
          API.busquedaActivosCampanaPaginada(id, dataSend)
            .then((res) => {
              res.data.collection.forEach((e) => {
                let collectionElement = {};
                collectionElement.id = e.id;
                collectionElement.code = e.code;
                collectionElement.prinexId = e.prinexId;
                const portfolio = lists.appPortfolios.find(
                  (s) => s.id === e.portfolioId
                );
                if (portfolio !== null) {
                  collectionElement.portfolioId = portfolio.name;
                }
                const businessLine = lists.businessLines.find(
                  (s) => s.Id === e.businessLine
                );
                if (businessLine !== null) {
                  collectionElement.businessLine = businessLine.translation;
                }
                collectionElement.dossierCode = e.dossierCode;
                collectionElement.promotionCode = e.promotionCode;
                collectionElement.propertyCode = e.propertyCode;
                const assetType = lists.assetType.find((s) => s.Id === e.type);
                if (assetType !== null) {
                  collectionElement.type = assetType.translation;
                }
                if (e.isPrincipal === true) {
                  collectionElement.isPrincipal = "Si";
                } else if (e.isPrincipal === false) {
                  collectionElement.isPrincipal = "No";
                } else {
                  collectionElement.isPrincipal = "";
                }
                collection.push(collectionElement);
              });
              setData(collection);
              setTotalPages(res.data.maxPages);
              setNumRegisters(res.data.numRegisters);
            })
            .catch((error) => API.DEVELOP && console.log(error))
            .finally(() => hideLoader());
          break;
        }
        case "promotion": {
          showLoader();
          API.busquedaActivosPromocionPaginada(id, dataSend)
            .then((res) => {
              res.data.collection.forEach((e) => {
                let collectionElement = {};
                collectionElement.id = e.id;
                collectionElement.prinexId = e.prinexId;
                collectionElement.code = e.code;
                const portfolio = lists.appPortfolios.find(
                  (s) => s.id === e.portfolioId
                );
                if (portfolio !== null) {
                  collectionElement.portfolioId = portfolio.name;
                }
                const businessLine = lists.businessLines.find(
                  (s) => s.Id === e.businessLine
                );
                if (businessLine !== null) {
                  collectionElement.businessLine = businessLine.translation;
                }
                collectionElement.dossierCode = e.dossierCode;
                collectionElement.promotionCode = e.promotionCode;
                collectionElement.propertyCode = e.propertyCode;
                const assetType = lists.assetType.find((s) => s.Id === e.type);
                if (assetType !== null) {
                  collectionElement.type = assetType.translation;
                }
                if (e.isPrincipal === true) {
                  collectionElement.isPrincipal = "Si";
                } else if (e.isPrincipal === false) {
                  collectionElement.isPrincipal = "No";
                } else {
                  collectionElement.isPrincipal = "";
                }
                collection.push(collectionElement);
              });
              setData(collection);
              setTotalPages(res.data.maxPages);
              setNumRegisters(res.data.numRegisters);
            })
            .catch((error) => API.DEVELOP && console.log(error))
            .finally(() => hideLoader());
          break;
        }
        default:
          break;
      }
    }
  };

  /**
   * añadir activos
   */
  const handleConfirm = () => {
    let ids = selected.map((e) => {
      return e.id;
    });
    confirm(ids);
  };

  /**
   * Cambiar orden de columna
   * @param {*} column
   */
  const changeOrder = (column) => {
    if (!column.disableSortBy) {
      if (order === column.id && orderDesc === false) {
        setOrderDesc(!orderDesc);
      } else if (order === column.id && orderDesc === true) {
        setOrder("");
        setOrderDesc(!orderDesc);
      } else {
        setOrder(column.id);
        setOrderDesc(false);
      }
    }
  };

  /**
   * Cambiar orden de las columnas
   * @param {*} prop
   * @param {*} value
   */
  const handleChange = (prop, value) => {
    const tmpFilters = { ...filters };
    tmpFilters[prop] = value;
    setFilters(tmpFilters);
  };

  /**
   * Seleccionar y deseleccionar activos
   * @param {*} id
   * @param {*} prinexId
   * @param {*} checked
   */
  const handleChangeSelectedAssets = (id, prinexId, checked) => {
    let tmpSelected = [...selected];
    if (checked) {
      let tmpSelectedItem = {
        id,
        prinexId,
      };
      tmpSelected.push(tmpSelectedItem);
      setSelected(tmpSelected);
    } else {
      tmpSelected = tmpSelected.filter((e) => e.id !== id);
      setSelected(tmpSelected);
    }
  };

  /**
   * desseleccionar un activo
   * @param {*} id
   */
  const deleteSelected = (id) => {
    let tmpSelected = [...selected];
    tmpSelected = tmpSelected.filter((e) => e.id !== id);
    setSelected(tmpSelected);
  };

  /**
   * Seleccionar todos los activos posibles
   * @param {*} checked
   */
  const setSelectedAllAssets = (checked) => {
    if (checked) {
      if (numRegisters <= 200) {
        getAllIds();
      } else {
        setDialogTooMuchAssets(true);
      }
    } else {
      setSelected([]);
    }
  };

  /**
   * Obtener todos los ids de activos seleccionables para marcar
   */
  const getAllIds = () => {
    if (dialogTooMuchAssets) setDialogTooMuchAssets(false);
    let dataSend = {};
    if (!!filters.prinexid) dataSend.prinexid = filters.prinexId;
    if (!!filters.code) dataSend.code = filters.code;
    if (!!filters.dossierCode) dataSend.dossierCode = filters.dossierCode;
    if (!!filters.promotionCode) dataSend.promotionCode = filters.promotionCode;
    if (!!filters.asset) dataSend.propertyCode = filters.propertyCode;

    switch (owner) {
      case "campaign": {
        showLoader();
        API.busquedaActivosCampanaTodos(id, dataSend)
          .then((res) => {
            let assetIds = [];
            res.data.forEach((element) => {
              let asset = {
                id: element.id,
                prinexId: element.prinexId,
              };
              assetIds.push(asset);
            });
            setSelected(assetIds);
          })
          .catch((error) => API.DEVELOP && console.log(error))
          .finally(() => hideLoader());
        break;
      }
      case "promotion": {
        showLoader();
        API.busquedaActivosPromocionTodos(id, dataSend)
          .then((res) => {
            let assetIds = [];
            res.data.forEach((element) => {
              let asset = {
                id: element.id,
                prinexId: element.prinexId,
              };
              assetIds.push(asset);
            });
            setSelected(assetIds);
          })
          .catch((error) => API.DEVELOP && console.log(error))
          .finally(() => hideLoader());
        break;
      }
      default:
        break;
    }
  };

  /**
   * Limpiar filtros
   */
  const cleanFilters = () => {
    setFilters({});
  };

  return {
    filters,
    order,
    orderDesc,
    pageTable,
    regsPage,
    totalPages,
    data,
    selected,
    numRegisters,
    dialogTooMuchAssets,
    columns,
    busquedaAssets,
    handleConfirm,
    changeOrder,
    handleChange,
    handleChangeSelectedAssets,
    deleteSelected,
    getAllIds,
    cleanFilters,
    setDialogTooMuchAssets,
    setPage,
    setSelected,
    setRegsPage,
  };
};

export default useModalAddAsset;
