import React from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";

export const InputFormTextAreaFromList = ({
  label,
  placeholder,
  id,
  hidden,
  filter,
  list,
  propertyList,
  value,
}) => {
  if (list === undefined) {
    list = [];
  }

  const getValue = () => {
    const valueList = list.find((s) => s.id === value);
    if (valueList) return valueList[propertyList];
    else return "";
  };

  return (
    <Row className="mb-2" hidden={hidden(filter, label)}>
      <Col xs={12} md={5}>
        <Form.Label
          hidden={hidden(filter, label)}
          style={{ fontSize: "0.875rem" }}
        >
          {label}
        </Form.Label>
      </Col>
      <Col xs={12} md={7}>
        <InputGroup>
          <Form.Control
            as="textarea"
            placeholder={placeholder}
            id={id}
            disabled={true}
            value={getValue()}
            title={getValue()}
            size="sm"
          />
        </InputGroup>
      </Col>
    </Row>
  );
};
