import React, { useState, useEffect, Fragment } from "react";
import { useLoader } from "../../components/Loading";
import { toast } from "react-toastify";
import { Tab, Nav, Row, Col, Card, Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSave,
  faEye,
  faTrash,
  faPlus,
  faPrint,
  faFileImport,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  METADATA_EDICION_PROMOCION_1,
  METADATA_EDICION_PROMOCION_2,
  buildFormDataEdit,
  buildFormDataCreate,
} from "./constants";
import { CardForm } from "../../components/CardForm";
import { TableFrontEnd } from "../../components/TableFrontEnd";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Dialog } from "../../components/Dialog";
import * as S from "./styles";
import * as API from "../../api";
import { useDataContext } from "../../context/DataState";
import { ModalAddAssets } from "../../components/ModalAddAsset/ModalAddAssets";
import usePermition from "../../constants/usePermition";
import { disabledFalse, portfolios } from "../../constants";
import SelectLanguage from "../../components/SelectLanguage/SelectLanguage";

export const DetallePromocion = () => {
  const [loader, showLoader, hideLoader] = useLoader();
  const navigate = useNavigate();
  const [valueFilter, setValueFilter] = useState("");
  const [language, setLanguage] = useState("ES");
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [selected, setSelected] = useState([]);
  const [dataActivos, setDataActivos] = useState([]);
  const [modalAnadirActivos, setModalAnadirActivos] = useState(false);
  const [modalEliminarActivos, setModalEliminarActivos] = useState(false);
  const [enabledSaveBtn, setEnabledSaveBtn] = useState(false);
  const [modalEnviarTablaTemporal, setModalEnviarTablaTemporal] =
    useState(false);
  const { id } = useParams();
  const {
    dispatch,
    state: { appLists, user },
  } = useDataContext();
  const [mdPromotion1, setMDPromotion1] = useState(
    METADATA_EDICION_PROMOCION_1
  );
  const [mdPromotion2, setMDPromotion2] = useState(
    METADATA_EDICION_PROMOCION_2
  );
  const { PERMISOS_POR_ROL } = usePermition({ user });

  /**
   * Verificar si hay cambios antes de salir en la vista de crear
   */
  const goBack = () => {
    if (enabledSaveBtn && !id) {
      if (window.confirm("Existen cambios sin guardar. ¿Desea salir?")) {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    document.title = "EC-Light-Promoción";
    let accs = document.getElementsByClassName("accordion-button");

    let isMounted = true;
    let auxMDPromo2 = [...mdPromotion2];
    if (isMounted && id) {
      for (let i = 0, len = accs.length; i < len; i++) {
        accs[i].click();
      }
      auxMDPromo2[3].collapsable = true;
      let listsAux = appLists;

      getPromotion();
      showLoader();
      API.obtenerUsuariosList()
        .then((res) => {
          listsAux.users = res.data;
          dispatch({
            type: "UPDATE_APP_LIST",
            payload: listsAux,
          });
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    } else {
      auxMDPromo2[3].collapsable = false;
      for (let j = 0, len = accs.length - 1; j < len; j++) {
        accs[j].click();
      }
    }
    setMDPromotion2(auxMDPromo2);
    return () => (isMounted = false);
  }, [id, showLoader, hideLoader, navigate]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (enabledSaveBtn && !id) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [enabledSaveBtn]);

  /**
   * Obtener promoción
   */
  const getPromotion = () => {
    showLoader();
    API.obtenerPromocion(id)
      .then((res) => {
        let promo = res.data;
        const municipality = appLists.appMunicipalities.find(
          (e) => e.id === promo.municipalityId
        );
        if (!!municipality) {
          promo.municipalityId = municipality.name;
          const province = appLists.appProvinces.find(
            (element) => element.id === municipality.provinceId
          );
          if (!!province) {
            promo.provinceId = province.name;
          }
        }
        const userRoles = user.roles.map((rol) => rol.name.toUpperCase());
        let auxMDA1 = [...METADATA_EDICION_PROMOCION_1];
        let generalData = auxMDA1.find((e) => e.label === "Datos Generales");
        if (
          userRoles.includes("CONTENTEDITOR") ||
          userRoles.includes("MARKETING") ||
          userRoles.includes("SUPERADMIN") ||
          userRoles.includes("ADMIN")
        ) {
          if (generalData) {
            let isNew = generalData.metadata.find((e) => e.name === "isNew");
            isNew.disabled = disabledFalse;
          }
        }
        setMDPromotion1(auxMDA1);
        setData(promo);
        setInitialData(res.data);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  /**
   * Guarda los cambios si hay id o crea si no hay id
   * @param {*} event
   */
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (id) {
        showLoader();
        let formData = buildFormDataEdit(data);
        API.editarPromocion(formData)
          .then((res) => {
            toast.success("Promoción editada correctamente");
            getPromotion();
          })
          .catch((error) => {
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
      } else {
        let formData = buildFormDataCreate(data);
        showLoader();
        API.crearPromocion(formData)
          .then((res) => {
            toast.success("Promoción creada correctamente");
            navigate("/promotion-detail/" + res.data.id);
          })
          .catch((error) => {
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
      }
    }
  };

  const onLanguageChange = ({ target: { value } }) => {
    setLanguage(value);
  };

  const defaultSortActivos = [
    {
      id: "idActivo",
      desc: false,
    },
  ];

  const defaultSortModalAssets = [
    {
      id: "prinexId",
      desc: false,
    },
  ];

  const columnsActivos = [
    {
      Header: (
        <Form.Check
          onChange={(e) => {
            setSelectedAllAssets(e.target.checked);
          }}
          disabled={dataActivos.map((s) => s.id).length === 0}
          checked={selected.length === dataActivos.map((s) => s.id).length}
        />
      ),
      accessor: "select",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Form.Check
          onChange={(e) => {
            handleChangeSelectedAssets(
              parseInt(cell.row.original.id),
              e.target.checked
            );
          }}
          checked={selected.includes(cell.row.original.id)}
        />
      ),
    },
    {
      Header: "",
      accessor: "detail",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Fragment>
          <Link
            to={
              cell.row.original.productId
                ? "/product-detail/" +
                  cell.row.original.productId +
                  "/" +
                  cell.row.original.id
                : "/asset-detail/" + cell.row.original.id
            }
          >
            <span title="Ver detalle">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEye}
                size="lg"
                fixedWidth
              />
            </span>
          </Link>
        </Fragment>
      ),
    },
    {
      Header: "Id Prínex",
      accessor: "prinexId",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Expediente",
      accessor: "dossierCode",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Id Promoción Prínex",
      accessor: "promotionCode",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Red Comercial",
      accessor: "commercialNet",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Tipo",
      accessor: "type",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Destacado",
      accessor: "isHighlighted",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Principal",
      accessor: "isPrincipal",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Publicado",
      accessor: "isPublishable",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Incidentado",
      accessor: "hasIncidence",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Fragment>{cell.row.original.hasIncidence ? "Si" : "No"}</Fragment>
      ),
    },
  ];

  const obtenerActivos = () => {
    showLoader();
    API.obtenerActivosPromocion(id)
      .then((res) => {
        let dataAuxList = [];
        res.data.forEach((e) => {
          const dataAux = {};
          dataAux.id = e.id;
          dataAux.prinexId = e.prinexId;
          dataAux.productId = e.productId;
          dataAux.dossierCode = e.dossierCode;
          dataAux.commercialNetType = e.commercialNetType;
          dataAux.hasIncidence = e.hasIncidence;
          const CNT = JSON.parse(localStorage.getItem("commercialNetType"));
          const appNetOptions = [
            ...CNT["All"],
            ...CNT[portfolios.name.anticipa],
            ...CNT[portfolios.name.ns],
            ...CNT[portfolios.name.quasar],
            ...CNT[portfolios.name.sareb],
          ];
          dataAux.commercialNet = appNetOptions.find(
            (item) => item.Id === e.commercialNetType
          ).translation;
          dataAux.promotionCode = e.promotionCode;
          const assetType = appLists.assetType.find((s) => s.Id === e.type);
          if (assetType !== null) {
            dataAux.type = assetType.translation;
          }
          if (e.isHighlighted === true) {
            dataAux.isHighlighted = "Si";
          } else if (e.isHighlighted === false) {
            dataAux.isHighlighted = "No";
          } else {
            dataAux.isHighlighted = "";
          }
          if (e.isPrincipal === true) {
            dataAux.isPrincipal = "Si";
          } else if (e.isPrincipal === false) {
            dataAux.isPrincipal = "No";
          } else {
            dataAux.isPrincipal = "";
          }
          if (e.isPublishable === true) {
            dataAux.isPublishable = "Si";
          } else if (e.isPublishable === false) {
            dataAux.isPublishable = "No";
          } else {
            dataAux.isPublishable = "";
          }
          dataAuxList.push(dataAux);
        });
        setDataActivos(dataAuxList);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const confirmEnviarTablaTemporal = () => {
    showLoader();
    setModalEnviarTablaTemporal(false);
    let data = {};
    API.enviarTablaTemporalPromocion(id, data)
      .then((res) => {
        toast.success("Promoción enviado a tabla temporal");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  /**
   * añadir activos a la promoción, si la operación es exitosa, actualiza la promoción y obtiene los activos para llenar la tabla
   * @param {*} value
   */
  const confirmAnadirActivosPromocion = (value) => {
    showLoader();
    setModalAnadirActivos(false);
    let data = {};
    data.isAdd = true;
    data.iDs = value;
    API.cambiarActivosPromocion(id, data)
      .then((res) => {
        toast.success("Activos añadidos correctamente a la promoción");
        getPromotion();
        obtenerActivos();
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
        setSelected([]);
      });
  };

  const confirmEliminarActivosPromocion = () => {
    showLoader();
    setModalEliminarActivos(false);
    let data = {};
    data.isAdd = false;
    data.iDs = selected;
    API.cambiarActivosPromocion(id, data)
      .then((res) => {
        toast.success("Activos eliminados correctamente de la promoción");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
        setSelected([]);
        obtenerActivos();
      });
  };

  const handleChangeSelectedAssets = (id, checked) => {
    let tmpSelected = [...selected];
    if (checked) {
      tmpSelected.push(id);
      setSelected(tmpSelected);
    } else {
      var ic = tmpSelected.indexOf(id);
      tmpSelected.splice(ic, 1);
      if (ic > -1) setSelected(tmpSelected);
    }
  };

  const handleAuditLink = () => {
    navigate(`/auditing?EntityName=CommercialPromotion&EntityId=${data.id}`);
  };

  const deleteSelected = (id) => {
    let tmpSelected = [...selected];
    var ic = tmpSelected.indexOf(id);
    tmpSelected.splice(ic, 1);
    if (ic > -1) setSelected(tmpSelected);
  };

  const setSelectedAllAssets = (checked) => {
    if (checked) {
      setSelected(dataActivos.map((s) => s.id));
    } else {
      setSelected([]);
    }
  };

  /**
   * Habilitar y desabilitar el boton de guardar si hay cambios o si el code esta vacio
   * @param {*} dataValues
   */
  const handleSetData = (dataValues) => {
    setData(dataValues);
    if (JSON.stringify(initialData) === JSON.stringify(dataValues)) {
      setEnabledSaveBtn(false);
    } else {
      if (!!dataValues.code) {
        setEnabledSaveBtn(true);
      } else {
        setEnabledSaveBtn(false);
      }
    }
  };

  return (
    <S.DetailPage>
      {loader}
      {modalEnviarTablaTemporal && (
        <Dialog
          type="noFooter"
          message="Se enviará la promoción a una tabla temporal, ¿desea continuar?"
          labelConfirm="Continuar"
          confirm={confirmEnviarTablaTemporal}
          cancel={() => setModalEnviarTablaTemporal(false)}
          value={{}}
        />
      )}
      {modalAnadirActivos && (
        <ModalAddAssets
          id={id}
          message="Seleccionar activos"
          lists={appLists}
          defaultSort={defaultSortModalAssets}
          hiddenColumns={["id"]}
          labelConfirm="Guardar"
          cancel={() => setModalAnadirActivos(false)}
          confirm={confirmAnadirActivosPromocion}
          showLoader={showLoader}
          hideLoader={hideLoader}
          owner="promotion"
        />
      )}
      {modalEliminarActivos && (
        <Dialog
          type="noFooter"
          message="Se eliminarán los activos de la promoción, ¿desea continuar?"
          labelConfirm="Continuar"
          confirm={confirmEliminarActivosPromocion}
          cancel={() => setModalEliminarActivos(false)}
          value={{}}
        />
      )}
      <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Col>
          <S.HeaderPageWithBack>
            <Button variant="danger" onClick={goBack}>
              <span>
                <FontAwesomeIcon icon={faArrowLeft} size="lg" fixedWidth />
              </span>
            </Button>
            <S.Title>Promoción {data?.code}</S.Title>
          </S.HeaderPageWithBack>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tab.Container
            defaultActiveKey="detallePromocion"
            id="tabsPromocion"
            className="mb-3"
            fill
          >
            <Row style={{ marginLeft: "0rem" }}>
              {id && (
                <Nav variant="pills">
                  <Col sm={6}>
                    <Nav.Item>
                      <Nav.Link eventKey="detallePromocion">
                        Detalle de la Promoción
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col sm={6}>
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => obtenerActivos()}
                        eventKey="activosPromocion"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        Activos de la Promoción
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                </Nav>
              )}
            </Row>
            <Tab.Content>
              <Tab.Pane
                eventKey="detallePromocion"
                title="Detalle de la Promoción"
              >
                <Card className="mt-4">
                  <Card.Header>
                    <Row>
                      <Col xs={4}>
                        <Form.Control
                          type="text"
                          placeholder="Filtrar campos"
                          onChange={(e) => {
                            setValueFilter(e.target.value);
                          }}
                        />
                      </Col>
                      <Col
                        xs={8}
                        style={{ display: "flex", justifyContent: "right" }}
                      >
                        <SelectLanguage
                          language={language}
                          onLanguageChange={onLanguageChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ marginTop: "1rem" }}>
                        {id && (
                          <Fragment>
                            <Button
                              style={{ marginRight: "1rem" }}
                              variant="success"
                              onClick={handleAuditLink}
                              hidden={!PERMISOS_POR_ROL.Promociones.Auditoria}
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faPrint}
                                  size="lg"
                                  fixedWidth
                                />
                              </span>
                              Auditoría
                            </Button>
                            <Button
                              style={{ marginRight: "1rem" }}
                              variant="success"
                              onClick={() => setModalEnviarTablaTemporal(true)}
                              hidden={
                                !PERMISOS_POR_ROL.Promociones
                                  .EnviarTablaTemporal
                              }
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faFileImport}
                                  size="lg"
                                  fixedWidth
                                />
                              </span>
                              Enviar a Tabla Temporal
                            </Button>
                          </Fragment>
                        )}

                        <Button
                          type="submit"
                          form="editPromotion"
                          variant="success"
                          onSubmit={handleSubmit}
                          disabled={!enabledSaveBtn}
                          hidden={!PERMISOS_POR_ROL.Promociones.CrudPromocion}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faSave}
                              size="lg"
                              fixedWidth
                            />
                          </span>
                          Guardar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Form
                      noValidate
                      id="editPromotion"
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row>
                        <Col>
                          {mdPromotion1.map((field, index) => {
                            return (
                              <CardForm
                                key={index}
                                metadata={field.metadata}
                                label={field.label}
                                collapsable={field.collapsable}
                                filter={valueFilter}
                                language={language}
                                languageSensitive={field.languageSensitive}
                                lists={appLists}
                                data={data}
                                setData={handleSetData}
                              />
                            );
                          })}
                        </Col>
                        <Col>
                          {mdPromotion2.map((field, index) => {
                            return (
                              <CardForm
                                key={index}
                                metadata={field.metadata}
                                label={field.label}
                                collapsable={field.collapsable}
                                filter={valueFilter}
                                language={language}
                                languageSensitive={field.languageSensitive}
                                lists={appLists}
                                data={data}
                                setData={handleSetData}
                              />
                            );
                          })}
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane
                eventKey="activosPromocion"
                title="Activos de la Promoción"
              >
                <Card className="mt-4">
                  <Card.Header>
                    <Row>
                      <Col style={{ marginTop: "1rem" }}>
                        {id && (
                          <Fragment>
                            <Button
                              style={{ marginRight: "1rem" }}
                              variant="danger"
                              disabled={selected.length === 0}
                              onClick={() => setModalEliminarActivos(true)}
                              hidden={
                                !PERMISOS_POR_ROL.Promociones.CrudPromocion
                              }
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  size="lg"
                                  fixedWidth
                                />
                              </span>
                              Eliminar Activos
                            </Button>
                            <Button
                              style={{ marginRight: "1rem" }}
                              variant="success"
                              onClick={() => setModalAnadirActivos(true)}
                              hidden={
                                !PERMISOS_POR_ROL.Promociones.CrudPromocion
                              }
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  size="lg"
                                  fixedWidth
                                />
                              </span>
                              Añadir Activos
                            </Button>
                          </Fragment>
                        )}
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xs={2}>
                        <S.AssetsSelected>
                          <Table
                            striped
                            bordered
                            hover
                            style={{
                              border: "thin solid lightgray",
                              whiteSpace: "nowrap",
                              maxHeight: "80vh",
                              overflowY: "auto",
                            }}
                          >
                            <thead className="bg-light sticky-top top-0">
                              <tr>
                                <th
                                  style={{
                                    backgroundColor: "rgb(246, 246, 246)",
                                  }}
                                >
                                  <button
                                    className="tableButton"
                                    onClick={() => setSelected([])}
                                    hidden={
                                      !PERMISOS_POR_ROL.Promociones
                                        .CrudPromocion
                                    }
                                  >
                                    <span>
                                      <FontAwesomeIcon
                                        style={{ color: "black" }}
                                        icon={faTrashAlt}
                                        size="lg"
                                        fixedWidth
                                      />
                                    </span>
                                  </button>
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "rgb(246, 246, 246)",
                                  }}
                                >
                                  Activos ({selected.length})
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {selected.map((a) => {
                                return (
                                  <tr>
                                    <td>
                                      <button
                                        className="tableButton"
                                        onClick={() => deleteSelected(a)}
                                        hidden={
                                          !PERMISOS_POR_ROL.Promociones
                                            .CrudPromocion
                                        }
                                      >
                                        <span>
                                          <FontAwesomeIcon
                                            style={{ color: "black" }}
                                            icon={faTrashAlt}
                                            size="lg"
                                            fixedWidth
                                          />
                                        </span>
                                      </button>
                                    </td>
                                    <td>{a}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </S.AssetsSelected>
                      </Col>
                      <Col xs={10}>
                        <TableFrontEnd
                          columns={columnsActivos}
                          data={dataActivos}
                          defaultSort={defaultSortActivos}
                          hiddenColumns={
                            PERMISOS_POR_ROL.Promociones.CrudPromocion
                              ? ["productId"]
                              : ["select", "productId"]
                          }
                          pagination={false}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </S.DetailPage>
  );
};
