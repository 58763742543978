import { Form } from "react-bootstrap";
import spain_icon from "../../assets/images/spain_icon.png";
import english_icon from "../../assets/images/english_icon.png";
import french_icon from "../../assets/images/french_icon.png";
import { LanguageSelection } from "./styles";

const SelectLanguage = ({ language, onLanguageChange }) => {
  return (
    <LanguageSelection key={`inline-radio`} className="mb-1 mt-1">
      <Form.Check
        inline
        checked={language === "ES"}
        label={
          <img
            title="ES"
            style={{ height: "20px" }}
            src={spain_icon}
            alt="ES"
          />
        }
        value="ES"
        name="group1"
        type="radio"
        id={`inline-radio-1`}
        onChange={onLanguageChange}
      />
      <Form.Check
        inline
        checked={language === "EN"}
        label={
          <img
            title="EN"
            style={{ height: "20px" }}
            src={english_icon}
            alt="EN"
          />
        }
        value="EN"
        name="group1"
        type="radio"
        id={`inline-radio-2`}
        onChange={onLanguageChange}
      />
      <Form.Check
        inline
        checked={language === "FR"}
        label={
          <img
            style={{ height: "20px" }}
            title="FR"
            src={french_icon}
            alt="FR"
          />
        }
        value="FR"
        name="group1"
        type="radio"
        id={`inline-radio-3`}
        onChange={onLanguageChange}
      />
    </LanguageSelection>
  );
};

export default SelectLanguage;
