import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { StyledEmptyData } from "./styles";

const EmptyData = ({ height }) => {
  return (
    <StyledEmptyData height={height}>
      <div id="messageContainer">
        <span>
          <FontAwesomeIcon icon={faDatabase} size="lg" fixedWidth />
        </span>
        <span>Sin registros</span>
      </div>
    </StyledEmptyData>
  );
};

export default EmptyData;
