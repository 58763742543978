import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { NavMenu } from "../../components/NavMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoader } from "../../components/Loading";
import * as API from "../../api";
import {
  priorityFieldValues,
  ribbonBackgroundColorFieldValues,
  ribbonTextColorFieldValues,
} from "../../constants";
import { useMsal } from "@azure/msal-react";
import { useDataContext } from "../../context/DataState";

export const Layout = ({ triggerGetAccess, setTriggerGetAccess, children }) => {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const [loader, showLoader, hideLoader] = useLoader();
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [appVersion, setAppVersion] = useState("");
  const { dispatch } = useDataContext();

  const getUserList = useCallback(
    (appLists) => {
      showLoader();
      API.obtenerUsuariosList()
        .then((res) => {
          let listas = appLists;
          if (listas !== null) {
            listas.userList = res.data;
            dispatch({
              type: "UPDATE_APP_LIST",
              payload: listas,
            });
            if (process.env.NODE_ENV === "development") {
              localStorage.setItem("appLists", JSON.stringify(listas));
            }
          }
        })
        .catch((error) => {
          if (error.code >= 400 && error.code < 500)
            toast.error(error.msg?.message);
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    },
    [dispatch, showLoader, hideLoader]
  );

  const Access = useCallback(() => {
    showLoader();
    API.accesoUsuario({})
      .then(async (res) => {
        if (res?.data.hasAccess) {
          let errors = [];
          //Listas
          var listas = {};
          if (res.data.appAssetSubTypes) {
            listas.appAssetSubTypes = res.data.appAssetSubTypes;
          } else {
            errors.push("Subtipos de activo");
          }
          if (res.data.appCommercialStatus) {
            listas.appCommercialStatus = res.data.appCommercialStatus;
          } else {
            errors.push("Estado comercial");
          }
          if (res.data.appCommercialStrategies) {
            listas.appCommercialStrategies = res.data.appCommercialStrategies;
          } else {
            errors.push("Estrategia aomercial");
          }
          if (res.data.appDimensions) {
            listas.appDimensions = res.data.appDimensions;
          } else {
            errors.push("Dimenciones");
          }
          if (res.data.appMunicipalities) {
            listas.appMunicipalities = res.data.appMunicipalities;
          } else {
            errors.push("Municipios");
          }
          if (res.data.appNotEditableCommercialStatus) {
            listas.appNotEditableCommercialStatus =
              res.data.appNotEditableCommercialStatus;
          } else {
            errors.push("Estado comercial no editable");
          }

          // appParameters
          if (res.data.appParameters) {
            if (res.data.appParameters["AssetType.Options"]) {
              listas["assetType"] = JSON.parse(
                res.data.appParameters["AssetType.Options"]
              );
            } else {
              errors.push("Tipo de activo");
            }
            if (res.data.appParameters["BulkLoadStatus.Options"]) {
              listas["bulkLoadStatus"] = JSON.parse(
                res.data.appParameters["BulkLoadStatus.Options"]
              );
            } else {
              errors.push("Estado de carga masiva");
            }
            if (res.data.appParameters["BulkLoadTypes.Options"]) {
              listas["bulkLoadTypes"] = JSON.parse(
                res.data.appParameters["BulkLoadTypes.Options"]
              );
            } else {
              errors.push("Tipo de carga masiva");
            }
            if (res.data.appParameters["BusinessLine.Options"]) {
              listas["businessLines"] = JSON.parse(
                res.data.appParameters["BusinessLine.Options"]
              );
            } else {
              errors.push("Líneas de negocio");
            }
            if (res.data.appParameters["CommercialInterestScore.Options"]) {
              listas["commercialInterestScore"] = JSON.parse(
                res.data.appParameters["CommercialInterestScore.Options"]
              );
            } else {
              errors.push("Puntuación de interes comercial");
            }
            if (res.data.appParameters["CommercialNetType.Options"]) {
              listas["commercialNetType"] = JSON.parse(
                res.data.appParameters["CommercialNetType.Options"]
              );
              localStorage.setItem(
                "commercialNetType",
                res.data.appParameters["CommercialNetType.Options"]
              );
            } else {
              errors.push("Tipo de red comercial");
            }
            if (res.data.appParameters["EntityChangeType.Options"]) {
              listas["entityChangeType"] = JSON.parse(
                res.data.appParameters["EntityChangeType.Options"]
              );
            } else {
              errors.push("Tipo de cambio de entidad");
            }
            if (res.data.appParameters["GarageType.Options"]) {
              listas["garageType"] = JSON.parse(
                res.data.appParameters["GarageType.Options"]
              );
            } else {
              errors.push("Tipo de garaje");
            }
            if (res.data.appParameters["HidePVP.Options"]) {
              listas["hidePVP"] = JSON.parse(
                res.data.appParameters["HidePVP.Options"]
              );
            } else {
              errors.push("Esconder PVP");
            }
            if (res.data.appParameters["HotelType.Options"]) {
              listas["hotelType"] = JSON.parse(
                res.data.appParameters["HotelType.Options"]
              );
            } else {
              errors.push("Tipo de hotel");
            }
            if (res.data.appParameters["ProductStatus.Options"]) {
              listas["productStatus"] = JSON.parse(
                res.data.appParameters["ProductStatus.Options"]
              );
            } else {
              errors.push("Estado del producto");
            }
            if (res.data.appParameters["QueueType.Options"]) {
              listas["queueType"] = JSON.parse(
                res.data.appParameters["QueueType.Options"]
              );
            } else {
              errors.push("Tipo de cola");
            }
            if (res.data.appParameters["ReasonBlock.Options"]) {
              listas["reasonBlock"] = JSON.parse(
                res.data.appParameters["ReasonBlock.Options"]
              );
            } else {
              errors.push("Razón de bloqueo");
            }
            if (res.data.appParameters["Segmentation.Options"]) {
              listas["segmentation"] = JSON.parse(
                res.data.appParameters["Segmentation.Options"]
              );
            } else {
              errors.push("Segmentación");
            }
            if (res.data.appParameters["StateOfWork.Options"]) {
              listas["stateOfWork"] = JSON.parse(
                res.data.appParameters["StateOfWork.Options"]
              );
            } else {
              errors.push("Estado de trabajo");
            }
            if (res.data.appParameters["BudgetLine.Options"]) {
              listas["budgetLine"] = JSON.parse(
                res.data.appParameters["BudgetLine.Options"]
              );
            } else {
              errors.push("Línea presupuestaria");
            }
            if (res.data.appParameters["TypeOfRoad.Options"]) {
              listas["typeOfRoads"] = JSON.parse(
                res.data.appParameters["TypeOfRoad.Options"]
              );
            } else {
              errors.push("Tipo de vía");
            }
            if (res.data.appParameters["DelegationPriceRanges.Options"]) {
              listas["delegationPriceRangesFrom"] = JSON.parse(
                res.data.appParameters["DelegationPriceRanges.Options"]
              )["From"];
            } else {
              errors.push("Delegation Price From");
            }
            if (res.data.appParameters["DelegationPriceRanges.Options"]) {
              listas["delegationPriceRangesTo"] = JSON.parse(
                res.data.appParameters["DelegationPriceRanges.Options"]
              )["To"];
            } else {
              errors.push("Delegation Price To");
            }
            if (res.data.appParameters["MandateModality.Options"]) {
              listas["mandateModality"] = JSON.parse(
                res.data.appParameters["MandateModality.Options"]
              );
            } else {
              errors.push("Modo de mandato");
            }
          } else {
            errors.push("Opciones");
          }
          if (res.data.appParameters["ReasonBlock.Options"]) {
            const reasons = JSON.parse(
              res.data.appParameters["ReasonBlock.Options"]
            ).All;
            listas.reasonBlockList = reasons;
          } else {
            errors.push("Razón de bloqueo");
          }
          // end appParameters

          if (res.data.appPortfolios) {
            listas.appPortfolios = res.data.appPortfolios;
          } else {
            errors.push("Portafolios");
          }
          if (res.data.appProvinces) {
            listas.appProvinces = res.data.appProvinces;
          } else {
            errors.push("Provincias");
          }
          if (res.data.appRatings) {
            listas.appRatings = res.data.appRatings;
          } else {
            errors.push("Calificaciones");
          }
          if (res.data.appRequestingUsers) {
            listas.appRequestingUsers = res.data.appRequestingUsers;
          } else {
            errors.push("Usuarios solicitantes");
          }
          if (res.data.appResponsibleTertiary) {
            listas.appResponsibleTertiary = res.data.appResponsibleTertiary;
          } else {
            errors.push("Terciario Responsable");
          }
          if (res.data.appRoles) {
            listas["appRoles"] = res.data.appRoles;
          } else {
            errors.push("Roles");
          }
          if (res.data.appSpecialSalesBusinessFields) {
            listas["appSpecialSalesBusinessFields"] =
              res.data.appSpecialSalesBusinessFields;
          } else {
            errors.push("Campos venta especial");
          }

          //constants
          listas.ribbonBackgroundColorFieldValues =
            ribbonBackgroundColorFieldValues;
          listas.ribbonTextColorFieldValues = ribbonTextColorFieldValues;
          listas.priorityFieldValues = priorityFieldValues;
          listas.noList = [];
          //end constants

          listas.nulleableList = [
            {
              value: null,
              name: "",
            },
            {
              value: true,
              name: "Si",
            },
            {
              value: false,
              name: "No",
            },
          ];

          listas.nulleableListWithoutFalse = [
            {
              value: null,
              name: "",
            },
            {
              value: "Si",
              name: "Si",
            },
          ];

          listas.okKoList = [
            {
              value: null,
              name: "",
            },
            {
              value: true,
              name: "OK",
            },
            {
              value: false,
              name: "KO",
            },
          ];

          listas.haveOrNotList = [
            {
              value: "Si",
              name: "Si",
            },
            {
              value: "No",
              name: "No",
            },
            {
              value: "Ya tiene",
              name: "Ya tiene",
            },
          ];

          if (dispatch) {
            dispatch({
              type: "UPDATE_APP_LIST",
              payload: listas,
            });
          }
          if (process.env.NODE_ENV === "development") {
            localStorage.setItem("appLists", JSON.stringify(listas));
          }
          getUserList(listas);

          if (res.data.user) {
            dispatch({
              type: "UPDATE_USER",
              payload: res.data.user,
            });
            localStorage.setItem(
              "userRoles",
              JSON.stringify(res.data.user.roles)
            );
            localStorage.setItem(
              "currentPortfolioId",
              res.data.user.currentPortfolioID
            );
          } else {
            errors.push("Usuario");
          }

          if (res.data.appVersion) {
            const av = localStorage.getItem("appVersion");
            if (av) {
              if (av !== res.data.appVersion) {
                localStorage.setItem("appVersion", res.data.appVersion);
                window.location.reload();
              } else {
                setAppVersion(res.data.appVersion);
              }
            } else {
              localStorage.setItem("appVersion", res.data.appVersion);
              setAppVersion(res.data.appVersion);
            }
          } else {
            errors.push("Versión de aplicación");
          }
          if (errors.length > 0) {
            errors.forEach((e) => {
              toast.error(
                `Error cargando ${e}. Por favor, contacte con soporte.`
              );
            });

            await setTimeout(() => {
              console.log(errors);
            }, 5000);
          }
        } else {
          toast.error("No tiene acceso.");
          setAppVersion("3.0.0");
          navigate("/NoAccess");
        }
      })
      .catch((error) => {
        API.DEVELOP && console.log("accesoUsuario->error", error);
      })
      .finally(() => hideLoader());
  }, [dispatch, getUserList, navigate, showLoader, hideLoader]);

  useEffect(() => {
    const url = window.location.href.split("/").at(-1);
    if (url === "NoAccess") {
      setAppVersion(
        localStorage.getItem("appVersion")
          ? localStorage.getItem("appVersion")
          : "v3.0.0.25"
      );
    }
  }, [instance, accounts, Access]);

  useEffect(() => {
    if (triggerGetAccess) {
      setTriggerGetAccess(false);
      Access();
    }
  }, [triggerGetAccess, setTriggerGetAccess, Access]);

  return (
    <Fragment>
      {loader}
      <NavMenu pathname={pathname} />
      {appVersion ? children : <Fragment></Fragment>}
      <ToastContainer theme="colored" />
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
