import { disabledFalse, disabledTrue, hiddenFalse } from "../../constants";

export const METADATA_EDICION_TERRITORIAL = [
  {
    label: "Id",
    name: "id",
    type: "text",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Nombre",
    name: "name",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Crm Id",
    name: "crmId",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Cartera",
    name: "portfolioId",
    type: "list",
    typeList: "Normal",
    required: true,
    disabled: disabledTrue,
    hidden: hiddenFalse,
    listName: "appPortfolios",
  },
];

export const METADATA_CREACION_TERRITORIAL = [
  {
    label: "Id",
    name: "id",
    type: "text",
    disabled: disabledTrue,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Nombre",
    name: "name",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Crm Id",
    name: "crmId",
    type: "text",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    required: true,
  },
  {
    label: "Cartera",
    name: "portfolioId",
    type: "list",
    typeList: "Normal",
    disabled: disabledFalse,
    hidden: hiddenFalse,
    listName: "appPortfolios",
    required: true,
  },
];

export const METADATA_AUDIT_TERRITORIAL = [
  {
    label: "Usuario Creación",
    name: "creatorId",
  },
  {
    label: "Fecha Creación",
    name: "creationTime",
  },
  {
    label: "Usuario Última Modificación",
    name: "lastModifierId",
  },
  {
    label: "Fecha Última Modificación",
    name: "lastModificationTime",
  },
];
