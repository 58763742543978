import { Button } from "react-bootstrap";
import { SideFilterButtons } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";

const FilterButtons = ({ onSearch, onClean }) => {
  return (
    <SideFilterButtons>
      <Button onClick={onSearch} variant="success">
        <span>
          <FontAwesomeIcon icon={faSearch} size="lg" fixedWidth />
        </span>
        Buscar
      </Button>
      <Button onClick={onClean} variant="danger">
        <span>
          <FontAwesomeIcon icon={faTrash} size="lg" fixedWidth />
        </span>
        Limpiar Filtros
      </Button>
    </SideFilterButtons>
  );
};

export default FilterButtons;
