import {
  disabledFalse,
  disabledTrue,
  hiddenFilter,
  checkEDCFieldsGrants,
} from "../../constants";

const isEditing = () => {
  let id = window.location.href.split("/").pop();
  if (!isNaN(id)) {
    return true;
  } else {
    return false;
  }
};

export const buildFormDataEdit = (data) => {
  let formData = {};
  formData.id = data.id;
  formData.code = data.code;
  formData.promotionName = data.promotionName;
  formData.promotionDescription = data.promotionDescription;
  formData.promotionDescriptionEN = data.promotionDescriptionEN;
  formData.promotionDescriptionFR = data.promotionDescriptionFR;
  formData.isPublishable = data.isPublishable;
  formData.isNew = data.isNew ? true : false;
  formData.typeOfRoad = data.typeOfRoad;
  formData.road = data.road;
  formData.number = data.number;
  formData.situation = data.situation;
  formData.situationEN = data.situationEN;
  formData.situationFR = data.situationFR;
  formData.connections = data.connections;
  formData.connectionsEN = data.connectionsEN;
  formData.connectionsFR = data.connectionsFR;
  formData.facilities = data.facilities;
  formData.facilitiesEN = data.facilitiesEN;
  formData.facilitiesFR = data.facilitiesFR;
  formData.commuterTrain = data.commuterTrain;
  formData.bus = data.bus;
  formData.highway = data.highway;
  formData.plumbing = data.plumbing;
  formData.plumbingEN = data.plumbingEN;
  formData.plumbingFR = data.plumbingFR;
  formData.airConditioning = data.airConditioning;
  formData.airConditioningEN = data.airConditioningEN;
  formData.airConditioningFR = data.airConditioningFR;
  formData.elevators = data.elevators;
  formData.elevatorsEN = data.elevatorsEN;
  formData.elevatorsFR = data.elevatorsFR;
  formData.hasHeating = data.hasHeating;
  formData.hasHeatingFR = data.hasHeatingFR;
  formData.hasHeatingEN = data.hasHeatingEN;
  formData.otherFacilities = data.otherFacilities;
  formData.otherFacilitiesEN = data.otherFacilitiesEN;
  formData.otherFacilitiesFR = data.otherFacilitiesFR;
  formData.bathrooms = data.bathrooms;
  formData.bathroomsEN = data.bathroomsEN;
  formData.bathroomsFR = data.bathroomsFR;
  formData.kitchens = data.kitchens;
  formData.kitchensEN = data.kitchensEN;
  formData.kitchensFR = data.kitchensFR;
  formData.communityEquipment = data.communityEquipment;
  formData.communityEquipmentEN = data.communityEquipmentEN;
  formData.communityEquipmentFR = data.communityEquipmentFR;
  formData.garages = data.garages;
  formData.garagesEN = data.garagesEN;
  formData.garagesFR = data.garagesFR;
  formData.cover = data.cover;
  formData.coverEN = data.coverEN;
  formData.coverFR = data.coverFR;
  formData.outdoorEnclosure = data.outdoorEnclosure;
  formData.outdoorEnclosureEN = data.outdoorEnclosureEN;
  formData.outdoorEnclosureFR = data.outdoorEnclosureFR;
  formData.structure = data.structure;
  formData.structureEN = data.structureEN;
  formData.structureFR = data.structureFR;
  formData.outDoorCarpentry = data.outDoorCarpentry;
  formData.outDoorCarpentryEN = data.outDoorCarpentryEN;
  formData.outDoorCarpentryFR = data.outDoorCarpentryFR;
  formData.insideCarpentry = data.insideCarpentry;
  formData.insideCarpentryEN = data.insideCarpentryEN;
  formData.insideCarpentryFR = data.insideCarpentryFR;
  formData.innerLiner = data.innerLiner;
  formData.innerLinerEN = data.innerLinerEN;
  formData.innerLinerFR = data.innerLinerFR;
  formData.pavements = data.pavements;
  formData.pavementsEN = data.pavementsEN;
  formData.pavementsFR = data.pavementsFR;
  formData.interiorPartitioning = data.interiorPartitioning;
  formData.interiorPartitioningEN = data.interiorPartitioningEN;
  formData.interiorPartitioningFR = data.interiorPartitioningFR;
  formData.telecomunications = data.telecomunications;
  formData.hasStorage = data.hasStorage;
  formData.hasParking = data.hasParking;
  formData.hasTerrace = data.hasTerrace;
  formData.hasCommunityPool = data.hasCommunityPool;
  formData.hasPrivatePool = data.hasPrivatePool;
  formData.hasCommunityGarden = data.hasCommunityGarden;
  formData.isFirstSeaLine = data.isFirstSeaLine;
  formData.hasPrivateGarden = data.hasPrivateGarden;
  formData.hasPadelCourt = data.hasPadelCourt;
  formData.hasAlarm = data.hasAlarm;
  formData.hasEnergyCertificate = data.hasEnergyCertificate;
  formData.hasGym = data.hasGym;
  formData.isNewBuild = data.isNewBuild;
  formData.stateOfWork = data.stateOfWork;
  formData.urlYoutube = data.urlYoutube;
  formData.urlVirtualTour = data.urlVirtualTour;
  formData.sponsored = data.sponsored;
  formData.qualityMemories = data.qualityMemories;
  formData.qualityMemoriesEN = data.qualityMemoriesEN;
  formData.qualityMemoriesFR = data.qualityMemoriesFR;
  formData.brochures = data.brochures;
  formData.brochuresEN = data.brochuresEN;
  formData.brochuresFR = data.brochuresFR;
  return formData;
};

export const buildFormDataCreate = (data) => {
  let formData = {};
  formData.code = data.code;
  formData.promotionName = data.promotionName;
  formData.promotionDescription = data.promotionDescription;
  formData.promotionDescriptionEN = data.promotionDescriptionEN;
  formData.promotionDescriptionFR = data.promotionDescriptionFR;
  formData.isPublishable = data.isPublishable;
  formData.typeOfRoad = data.typeOfRoad;
  formData.road = data.road;
  formData.number = data.number;
  formData.situation = data.situation;
  formData.situationEN = data.situationEN;
  formData.situationFR = data.situationFR;
  formData.connections = data.connections;
  formData.connectionsEN = data.connectionsEN;
  formData.connectionsFR = data.connectionsFR;
  formData.facilities = data.facilities;
  formData.facilitiesEN = data.facilitiesEN;
  formData.facilitiesFR = data.facilitiesFR;
  formData.commuterTrain = data.commuterTrain;
  formData.bus = data.bus;
  formData.highway = data.highway;
  formData.plumbing = data.plumbing;
  formData.plumbingEN = data.plumbingEN;
  formData.plumbingFR = data.plumbingFR;
  formData.airConditioning = data.airConditioning;
  formData.airConditioningEN = data.airConditioningEN;
  formData.airConditioningFR = data.airConditioningFR;
  formData.elevators = data.elevators;
  formData.elevatorsEN = data.elevatorsEN;
  formData.elevatorsFR = data.elevatorsFR;
  formData.hasHeating = data.hasHeating;
  formData.hasHeatingFR = data.hasHeatingFR;
  formData.hasHeatingEN = data.hasHeatingEN;
  formData.otherFacilities = data.otherFacilities;
  formData.otherFacilitiesEN = data.otherFacilitiesEN;
  formData.otherFacilitiesFR = data.otherFacilitiesFR;
  formData.bathrooms = data.bathrooms;
  formData.bathroomsEN = data.bathroomsEN;
  formData.bathroomsFR = data.bathroomsFR;
  formData.kitchens = data.kitchens;
  formData.kitchensEN = data.kitchensEN;
  formData.kitchensFR = data.kitchensFR;
  formData.communityEquipment = data.communityEquipment;
  formData.communityEquipmentEN = data.communityEquipmentEN;
  formData.communityEquipmentFR = data.communityEquipmentFR;
  formData.garages = data.garages;
  formData.garagesEN = data.garagesEN;
  formData.garagesFR = data.garagesFR;
  formData.cover = data.cover;
  formData.coverEN = data.coverEN;
  formData.coverFR = data.coverFR;
  formData.outdoorEnclosure = data.outdoorEnclosure;
  formData.outdoorEnclosureEN = data.outdoorEnclosureEN;
  formData.outdoorEnclosureFR = data.outdoorEnclosureFR;
  formData.structure = data.structure;
  formData.structureEN = data.structureEN;
  formData.structureFR = data.structureFR;
  formData.outDoorCarpentry = data.outDoorCarpentry;
  formData.outDoorCarpentryEN = data.outDoorCarpentryEN;
  formData.outDoorCarpentryFR = data.outDoorCarpentryFR;
  formData.insideCarpentry = data.insideCarpentry;
  formData.insideCarpentryEN = data.insideCarpentryEN;
  formData.insideCarpentryFR = data.insideCarpentryFR;
  formData.innerLiner = data.innerLiner;
  formData.innerLinerEN = data.innerLinerEN;
  formData.innerLinerFR = data.innerLinerFR;
  formData.pavements = data.pavements;
  formData.pavementsEN = data.pavementsEN;
  formData.pavementsFR = data.pavementsFR;
  formData.interiorPartitioning = data.interiorPartitioning;
  formData.interiorPartitioningEN = data.interiorPartitioningEN;
  formData.interiorPartitioningFR = data.interiorPartitioningFR;
  formData.telecomunications = data.telecomunications;
  formData.hasStorage = data.hasStorage;
  formData.hasParking = data.hasParking;
  formData.hasTerrace = data.hasTerrace;
  formData.hasCommunityPool = data.hasCommunityPool;
  formData.hasPrivatePool = data.hasPrivatePool;
  formData.hasCommunityGarden = data.hasCommunityGarden;
  formData.isFirstSeaLine = data.isFirstSeaLine;
  formData.hasPrivateGarden = data.hasPrivateGarden;
  formData.hasPadelCourt = data.hasPadelCourt;
  formData.hasAlarm = data.hasAlarm;
  formData.hasEnergyCertificate = data.hasEnergyCertificate;
  formData.hasGym = data.hasGym;
  formData.isNewBuild = data.isNewBuild;
  formData.stateOfWork = data.stateOfWork;
  formData.urlYoutube = data.urlYoutube;
  formData.urlVirtualTour = data.urlVirtualTour;
  formData.sponsored = data.sponsored;
  formData.qualityMemories = data.qualityMemories;
  formData.qualityMemoriesEN = data.qualityMemoriesEN;
  formData.qualityMemoriesFR = data.qualityMemoriesFR;
  formData.brochures = data.brochures;
  formData.brochuresEN = data.brochuresEN;
  formData.brochuresFR = data.brochuresFR;
  return formData;
};

export const METADATA_EDICION_PROMOCION_1 = [
  {
    label: "Datos Generales",
    collapsable: true,
    metadata: [
      {
        label: "Id Promoción",
        name: "code",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
        required: true,
      },
      {
        label: "Id Promoción Ant",
        name: "anticipaPromotionID",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        required: true,
      },
      {
        label: "Nombre",
        name: "promotionName",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Descripción",
        name: "promotionDescription",
        type: "texteditor",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "URL que te lleve a la web",
        name: "url",
        type: "url",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Publicable",
        name: "isPublishable",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Promociones Prinex",
        name: "assetPromotionCodes",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Perímetro",
        name: "portfolioId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appPortfolios",
        propertyId: "id",
        propertyList: "name",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² desde",
        name: "areaFrom",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Sociedad Propietaria",
        name: "managementCompany",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Precio desde",
        name: "priceFrom",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha de publicación",
        name: "publishDate",
        type: "date",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Dormitorios",
        name: "assetBedroomsEDC",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de activos publicados",
        name: "numPublishedProducts",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "A estrenar",
        name: "isNew",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Localización",
    collapsable: true,
    metadata: [
      {
        label: "Tipo de Vía",
        name: "typeOfRoad",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Calle",
        name: "road",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de calle",
        name: "number",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Código Postal",
        name: "postalCode",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Municipio",
        name: "municipalityId",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Provincia",
        name: "provinceId",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      // {
      //   label: "Dirección completa",
      //   name: "fullAddress",
      //   type: "text",
      //   disabled: disabledTrue,
      //   hidden: hiddenTrue,
      //   languageSensitive: false,
      // },
      {
        label: "Latitud",
        name: "latitude",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Longitud",
        name: "longitude",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Situación",
        name: "situation",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Comunicaciones",
        name: "connections",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Dotaciones",
        name: "facilities",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Cercanías",
        name: "commuterTrain",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Autobús",
        name: "bus",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Carretera",
        name: "highway",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Imágenes",
    collapsable: true,
    metadata: [
      {
        label: "Imágenes",
        name: "urlImages",
        type: "carousel",
        hidden: hiddenFilter,
      },
      {
        label: "Número de fotos",
        name: "numberOfPhotos",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Letras de las fotos",
        name: "photoLetters",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
];

export const METADATA_EDICION_PROMOCION_2 = [
  {
    label: "Características",
    collapsable: true,
    metadata: [
      {
        label: "Fontanería",
        name: "plumbing",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Aire acondicionado",
        name: "airConditioning",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Ascensores",
        name: "elevators",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Calefacción",
        name: "hasHeating",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Otras instalaciones",
        name: "otherFacilities",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Baños",
        name: "bathrooms",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Cocina",
        name: "kitchens",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Equipamiento comunitario",
        name: "communityEquipment",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Garajes",
        name: "garages",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Cubierta",
        name: "cover",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Cerramiento exterior",
        name: "outdoorEnclosure",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Estructura",
        name: "structure",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Carpinteria exterior",
        name: "outDoorCarpentry",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Carpintería interior",
        name: "insideCarpentry",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Revestimiento interior",
        name: "innerLiner",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Pavimentos",
        name: "pavements",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Tabiquería interior",
        name: "interiorPartitioning",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Telecomunicaciones",
        name: "telecomunications",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Trastero",
        name: "hasStorage",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Parking",
        name: "hasParking",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Viviendas con terraza",
        name: "hasTerrace",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Piscina comunitaria",
        name: "hasCommunityPool",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Piscina privada",
        name: "hasPrivatePool",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Jardín comunitario",
        name: "hasCommunityGarden",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Jardín privado",
        name: "hasPrivateGarden",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Primera línea de mar",
        name: "isFirstSeaLine",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Pista pádel",
        name: "hasPadelCourt",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Alarma",
        name: "hasAlarm",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Certificado energético",
        name: "hasEnergyCertificate",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Gimnasio",
        name: "hasGym",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Excent",
        name: "isNewBuild",
        type: "checkbox",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      // {
      //   label: "Datos sociodemográficos",
      //   name: "sociodemographicData",
      //   type: "textarea",
      //   disabled: checkEDCFieldsGrants,
      //   hidden: hiddenTrue,
      //   languageSensitive: false,
      // },
      // {
      //   label: "Servicios de la zona",
      //   name: "areaServices",
      //   type: "textarea",
      //   disabled: checkEDCFieldsGrants,
      //   hidden: hiddenTrue,
      //   languageSensitive: false,
      // },
      {
        label: "Estado de la obra",
        name: "stateOfWork",
        type: "list",
        typeList: "Translation",
        listName: "stateOfWork",
        propertyId: "Id",
        propertyName: "name",
        disabled: checkEDCFieldsGrants,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Marketing",
    collapsable: true,
    metadata: [
      {
        label: "URL Youtube",
        name: "urlYoutube",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "URL Tour Virtual",
        name: "urlVirtualTour",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Metadescripción",
        name: "metadescription",
        type: "textarea",
        disabled: isEditing,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fotos Decorview",
        name: "decorviewImages",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fotos Plano",
        name: "schemeImages",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Patrocinado",
        name: "sponsored",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
    ],
  },
  {
    label: "Documentación",
    collapsable: true,
    metadata: [
      {
        label: "Memoria de calidades",
        name: "qualityMemories",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Folletos",
        name: "brochures",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Certificación energética",
        name: "energeticCertification",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Auditoría",
    collapsable: true,
    metadata: [
      {
        label: "Fecha Creación",
        name: "creationTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Usuario Creación",
        name: "creatorId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Última Modificación",
        name: "lastModificationTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Usuario Modificación",
        name: "lastModifierId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Borrado",
        name: "deletionTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Usuario Borrado",
        name: "deleterId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
];
