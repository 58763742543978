const DataReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_CURRENT_PORTFOLIO": {
      return {
        ...state,
        currentPortfolio: action.payload,
      };
    }
    case "UPDATE_APP_VERSION": {
      return {
        ...state,
        appVersion: action.payload,
      };
    }
    case "UPDATE_USER": {
      return {
        ...state,
        user: action.payload,
      };
    }
    case "UPDATE_APP_LIST": {
      return {
        ...state,
        appLists: action.payload,
      };
    }
    case "UPDATE_REG_LENGTH": {
      return {
        ...state,
        regLength: action.payload,
      };
    }
    default:
      return state;
  }
};

export default DataReducer;
