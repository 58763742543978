import { disabledFalse, disabledTrue, hiddenFilter } from "../../constants";

export const buildFormDataEdit = (data) => {
  let formData = {};
  formData.id = data.id;
  formData.campaignName = data.campaignName;
  formData.campaignNameEN = data.campaignNameEN;
  formData.campaignNameFR = data.campaignNameFR;
  formData.description = data.description;
  formData.startDate = data.startDate;
  formData.endDate = data.endDate;
  formData.priority = data.priority;
  formData.specialCampaign = data.specialCampaign;
  formData.title = data.title;
  formData.subtitle = data.subtitle;
  formData.landingText = data.landingText;
  formData.landingImage = data.landingImage;
  formData.isPublishable = data.isPublishable;
  formData.requestingUserId = data.requestingUserId;
  formData.url = data.url;
  formData.text = data.text;
  formData.imageBannerDesktop = data.imageBannerDesktop;
  formData.imageBannerDesktopEN = data.imageBannerDesktopEN;
  formData.imageBannerDesktopFR = data.imageBannerDesktopFR;
  formData.imageBannerMobile = data.imageBannerMobile;
  formData.imageBannerMobileEN = data.imageBannerMobileEN;
  formData.imageBannerMobileFR = data.imageBannerMobileFR;
  formData.paragraphBannerEN = data.paragraphBannerEN;
  formData.paragraphBannerES = data.paragraphBannerES;
  formData.paragraphBannerFR = data.paragraphBannerFR;
  formData.ribbonBackground = data.ribbonBackground;
  formData.ribbonFileEN = data.ribbonFileEN;
  formData.ribbonFileES = data.ribbonFileES;
  formData.ribbonFileFR = data.ribbonFileFR;
  formData.ribbonListEN = data.ribbonListEN;
  formData.ribbonListFR = data.ribbonListFR;
  formData.ribbonListES = data.ribbonListES;
  formData.ribbonTextColor = data.ribbonTextColor;
  formData.targetButtonBanner1 = data.targetButtonBanner1;
  formData.targetButtonBanner2 = data.targetButtonBanner2;
  formData.textButtonBanner1EN = data.textButtonBanner1EN;
  formData.textButtonBanner1ES = data.textButtonBanner1ES;
  formData.textButtonBanner1FR = data.textButtonBanner1FR;
  formData.textButtonBanner2EN = data.textButtonBanner2EN;
  formData.textButtonBanner2ES = data.textButtonBanner2ES;
  formData.textButtonBanner2FR = data.textButtonBanner2FR;
  formData.textUnderCarrouselEN = data.textUnderCarrouselEN;
  formData.textUnderCarrouselES = data.textUnderCarrouselES;
  formData.textUnderCarrouselFR = data.textUnderCarrouselFR;
  formData.titleBannerEN = data.titleBannerEN;
  formData.titleBannerES = data.titleBannerES;
  formData.titleBannerFR = data.titleBannerFR;
  formData.urlButtonBanner1 = data.urlButtonBanner1;
  formData.urlButtonBanner2 = data.urlButtonBanner2;
  formData.descriptionEN = data.descriptionEN;
  formData.descriptionFR = data.descriptionFR;
  formData.urlEN = data.urlEN;
  formData.urlFR = data.urlFR;
  formData.agregatorExtraDescription = data.agregatorExtraDescription;
  return formData;
};

export const buildFormDataCreate = (data) => {
  let formData = {};
  formData.id = data.id;
  formData.campaignName = data.campaignName;
  formData.campaignNameEN = data.campaignNameEN;
  formData.campaignNameFR = data.campaignNameFR;
  formData.description = data.description;
  formData.startDate = data.startDate;
  formData.endDate = data.endDate;
  formData.priority = data.priority;
  formData.specialCampaign = data.specialCampaign;
  formData.title = data.title;
  formData.subtitle = data.subtitle;
  formData.landingText = data.landingText;
  formData.landingImage = data.landingImage;
  formData.isPublishable = data.isPublishable;
  formData.requestingUserId = data.requestingUserId;
  formData.url = data.url;
  formData.text = data.text;
  formData.imageBannerDesktop = data.imageBannerDesktop;
  formData.imageBannerDesktopEN = data.imageBannerDesktopEN;
  formData.imageBannerDesktopFR = data.imageBannerDesktopFR;
  formData.imageBannerMobile = data.imageBannerMobile;
  formData.imageBannerMobileEN = data.imageBannerMobileEN;
  formData.imageBannerMobileFR = data.imageBannerMobileFR;
  formData.paragraphBannerEN = data.paragraphBannerEN;
  formData.paragraphBannerES = data.paragraphBannerES;
  formData.paragraphBannerFR = data.paragraphBannerFR;
  formData.ribbonBackground = data.ribbonBackground;
  formData.ribbonFileEN = data.ribbonFileEN;
  formData.ribbonFileES = data.ribbonFileES;
  formData.ribbonFileFR = data.ribbonFileFR;
  formData.ribbonListEN = data.ribbonListEN;
  formData.ribbonListFR = data.ribbonListFR;
  formData.ribbonListES = data.ribbonListES;
  formData.ribbonTextColor = data.ribbonTextColor;
  formData.targetButtonBanner1 = data.targetButtonBanner1;
  formData.targetButtonBanner2 = data.targetButtonBanner2;
  formData.textButtonBanner1EN = data.textButtonBanner1EN;
  formData.textButtonBanner1ES = data.textButtonBanner1ES;
  formData.textButtonBanner1FR = data.textButtonBanner1FR;
  formData.textButtonBanner2EN = data.textButtonBanner2EN;
  formData.textButtonBanner2ES = data.textButtonBanner2ES;
  formData.textButtonBanner2FR = data.textButtonBanner2FR;
  formData.textUnderCarrouselEN = data.textUnderCarrouselEN;
  formData.textUnderCarrouselES = data.textUnderCarrouselES;
  formData.textUnderCarrouselFR = data.textUnderCarrouselFR;
  formData.titleBannerEN = data.titleBannerEN;
  formData.titleBannerES = data.titleBannerES;
  formData.titleBannerFR = data.titleBannerFR;
  formData.urlButtonBanner1 = data.urlButtonBanner1;
  formData.urlButtonBanner2 = data.urlButtonBanner2;
  formData.urlButtonBanner1EN = data.urlButtonBanner1EN;
  formData.urlButtonBanner2EN = data.urlButtonBanner2EN;
  formData.urlButtonBanner1FR = data.urlButtonBanner1FR;
  formData.urlButtonBanner2FR = data.urlButtonBanner2FR;
  formData.descriptionEN = data.descriptionEN;
  formData.descriptionFR = data.descriptionFR;
  formData.urlEN = data.urlEN;
  formData.urlFR = data.urlFR;
  formData.agregatorExtraDescription = data.agregatorExtraDescription;
  return formData;
};

export const METADATA_EDICION_CAMPANA_1 = [
  {
    label: "Datos Generales",
    collapsable: true,
    colalpsed: true,
    metadata: [
      {
        label: "Id",
        name: "id",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Nombre",
        name: "campaignName",
        type: "textarea",
        languageSensitive: true,
        disabled: disabledFalse,
        hidden: hiddenFilter,
        required: true,
      },
      {
        label: "Descripción",
        name: "description",
        type: "texteditor",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Fecha de inicio",
        name: "startDate",
        type: "date",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha de fin",
        name: "endDate",
        type: "date",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "URL",
        name: "url",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Solicitada por",
        name: "requestingUserId",
        type: "list",
        typeList: "Normal",
        listName: "appRequestingUsers",
        propertyId: "id",
        propertyName: "name",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Publicable",
        name: "isPublishable",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Prioridad",
        name: "priority",
        type: "list",
        typeList: "Normal",
        listName: "priorityFieldValues",
        propertyId: "id",
        propertyName: "name",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Texto de campaña",
        name: "text",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Título",
        name: "title",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Subtítulo",
        name: "subtitle",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Texto landing",
        name: "landingText",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Imagen landing",
        name: "landingImage",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
];

export const METADATA_EDICION_CAMPANA_2 = [
  {
    label: "Cuerpo banner",
    collapsable: true,
    metadata: [
      {
        label: "Titular banner (120 caracteres max.)",
        name: "titleBanner",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
        ESisIncluded: true,
        maxLength: 120,
      },
      {
        label: "Párrafo banner (170 caracteres max.)",
        name: "paragraphBanner",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
        ESisIncluded: true,
        maxLength: 170,
      },
      {
        label: "Imagen banner desktop",
        name: "imageBannerDesktop",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Imagen banner mobile",
        name: "imageBannerMobile",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Texto botón banner 1",
        name: "textButtonBanner1",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
        ESisIncluded: true,
      },
      {
        label: "URL botón banner 1",
        name: "urlButtonBanner1",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Target botón banner 1",
        name: "targetButtonBanner1",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Texto botón banner 2",
        name: "textButtonBanner2",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
        ESisIncluded: true,
      },
      {
        label: "URL botón banner 2",
        name: "urlButtonBanner2",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Target botón banner 2",
        name: "targetButtonBanner2",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Campaña especial",
    collapsable: true,
    metadata: [
      {
        label: "Campaña especial",
        name: "specialCampaign",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Ribbon Ficha",
        name: "ribbonFile",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
        ESisIncluded: true,
      },
      {
        label: "Ribbon Listado",
        name: "ribbonList",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
        ESisIncluded: true,
      },
      {
        label: "Ribbon Color Fondo",
        name: "ribbonBackground",
        type: "list",
        typeList: "Normal",
        listName: "ribbonBackgroundColorFieldValues",
        propertyId: "id",
        propertyName: "name",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Ribbon Color de Texto",
        name: "ribbonTextColor",
        type: "list",
        typeList: "Normal",
        listName: "ribbonTextColorFieldValues",
        propertyId: "id",
        propertyName: "name",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "HTML Texto bajo carrousel",
        name: "textUnderCarrousel",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: true,
        ESisIncluded: true,
      },
      {
        label: "Descripción Extra Agregadores (200 caracteres max.)",
        name: "agregatorExtraDescription",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
        maxLength: 200,
      },
    ],
  },
  {
    label: "Auditoría",
    collapsable: false,
    metadata: [
      {
        label: "Fecha Creación",
        name: "creationTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Usuario Creación",
        name: "creatorId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Última Modificación",
        name: "lastModificationTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Usuario Modificaión",
        name: "lastModifierId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Borrado",
        name: "deletionTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Usuario Borrado",
        name: "deleterId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
];
