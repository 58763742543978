import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useDataContext } from "../../context/DataState";
import usePermition from "../../constants/usePermition";

export const ModalAudit = ({
  metadata,
  message,
  values,
  cancel,
  entityName,
}) => {
  const navigate = useNavigate();
  const {
    state: { user },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  /**
   * Redirect to auditory with search params in the url
   */
  const handleAuditLink = () => {
    navigate(`/auditing?EntityName=${entityName}&EntityId=${values.id}`);
  };
  return (
    <Modal
      show={true}
      onHide={cancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form id="formModal">
            {metadata.map((field, index) => (
              <Row className="mb-2" key={index}>
                <Col>
                  <Form.Label>{field.label}</Form.Label>
                </Col>
                <Col>
                  <p>{values[field.name]}</p>
                </Col>
              </Row>
            ))}
          </Form>
        </div>
        <Button
          style={{ marginRight: "1rem" }}
          variant="success"
          hidden={!PERMISOS_POR_ROL.Auditoria.Acceso}
          onClick={handleAuditLink}
        >
          <span>
            <FontAwesomeIcon icon={faPrint} size="lg" fixedWidth />
          </span>
          Auditoría
        </Button>
      </Modal.Body>
    </Modal>
  );
};

ModalAudit.propTypes = {
  values: PropTypes.object.isRequired,
  metadata: PropTypes.array.isRequired,
  message: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
};
