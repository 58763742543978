import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import useSpecialSaleDetail from "./useSpecialSaleDetail";
import * as S from "./styles";
import { CardHeaderContainer } from "../Buscador/styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEye,
  faPlus,
  faPrint,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { InputFormList } from "../../components/InputFormList";
import { disabledFalse, disabledTrue, hiddenFalse } from "../../constants";
import { InputFormText } from "../../components/InputFormText";
import { InputFormCheckBox } from "../../components/InputFormCheckBox";
import { InputFormNumber } from "../../components/InputFormNumber";

const SpecialSaleDetail = () => {
  const {
    PERMISOS_POR_ROL,
    portFolioList,
    filtedredManagementCompanies,
    id,
    loader,
    handleDataChange,
    data,
    navigate,
    actualPublication,
    handleActualChange,
    handleAddRule,
    active,
    handleSelectRule,
    appLists,
    managementCompanies,
    removeRule,
    existRule,
    filteredFields,
    handleAddField,
    filterTable,
    goToAudit,
    save,
  } = useSpecialSaleDetail();

  return (
    <S.SpecialSaleDetail>
      {loader}
      <Card>
        <Card.Header>
          <CardHeaderContainer>
            <div style={{ display: "flex" }}>
              <Button
                style={{ marginRight: "1rem" }}
                variant="success"
                onClick={() => navigate(-1)}
              >
                <span>
                  <FontAwesomeIcon icon={faArrowLeft} fixedWidth />
                </span>
              </Button>
              <PageTitle
                title={!id ? "Nueva venta especial" : `Venta especial: ${id}`}
              />
            </div>

            <div>
              <Button
                style={{ marginRight: "1rem" }}
                variant="success"
                hidden={!PERMISOS_POR_ROL.VentaEspecial.Auditoria || !id}
                onClick={goToAudit}
              >
                <span>
                  <FontAwesomeIcon icon={faPrint} fixedWidth />
                </span>
                Auditoría
              </Button>
              <Button
                variant="success"
                hidden={!PERMISOS_POR_ROL.VentaEspecial.Crud}
                disabled={!data.name}
                onClick={save}
              >
                <span>
                  <FontAwesomeIcon icon={faPrint} fixedWidth />
                </span>
                Guardar
              </Button>
            </div>
          </CardHeaderContainer>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={3}>
              <InputFormText
                id="name"
                label="Nombre"
                placeholder="Nombre"
                disabled={disabledFalse}
                hidden={hiddenFalse}
                language={""}
                languageSensitive={false}
                required={true}
                maxLength={250}
                value={data?.name ? data.name : ""}
                handleChange={handleDataChange}
                addon={""}
              />
            </Col>
            <Col xs={12} md={3}>
              <InputFormNumber
                id="id"
                label="Id"
                placeholder="Id"
                disabled={id ? disabledTrue : disabledFalse}
                hidden={hiddenFalse}
                language={""}
                languageSensitive={false}
                required={true}
                maxLength={250}
                value={data?.id ? data.id : ""}
                handleChange={handleDataChange}
                addon={""}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} md={3}>
              {/* <div className="actual-input"> */}
              <InputFormList
                id="portfolioId"
                disabled={
                  !!data.name && !!data.id ? disabledFalse : disabledTrue
                }
                placeholder="Seleccione..."
                label="Cartera"
                hidden={hiddenFalse}
                filter={false}
                propertyId="id"
                propertyName="name"
                language={""}
                languageSensitive={false}
                list={portFolioList}
                required={true}
                value={
                  actualPublication?.portfolioId
                    ? actualPublication.portfolioId
                    : ""
                }
                handleChange={handleActualChange}
              />
              {/* </div> */}
            </Col>
            <Col xs={12} md={3}>
              {/* <div className="actual-input"> */}
              <InputFormList
                id="managementCompanyId"
                disabled={
                  !!data.name && !!actualPublication.portfolioId
                    ? disabledFalse
                    : disabledTrue
                }
                placeholder="Seleccione..."
                label="Sociedad propietaria"
                hidden={hiddenFalse}
                filter={false}
                propertyId="id"
                propertyName="name"
                language={""}
                languageSensitive={false}
                list={filtedredManagementCompanies}
                required={true}
                value={
                  actualPublication?.managementCompanyId
                    ? actualPublication.managementCompanyId
                    : ""
                }
                handleChange={handleActualChange}
              />
              {/* </div> */}
            </Col>
            <Col xs={12} md={3}>
              <div className="check-container">
                <InputFormCheckBox
                  id="isPublishable"
                  label="Publicable"
                  disabled={
                    !!data.name && !!data.id ? disabledFalse : disabledTrue
                  }
                  hidden={hiddenFalse}
                  language={""}
                  languageSensitive={false}
                  required={false}
                  value={
                    actualPublication?.isPublishable
                      ? actualPublication.isPublishable
                      : false
                  }
                  handleChange={handleActualChange}
                />
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="actual-input">
                <Button
                  variant="success"
                  disabled={!!!data?.name || existRule()}
                  hidden={!PERMISOS_POR_ROL.VentaEspecial.Crud}
                  onClick={handleAddRule}
                >
                  <span>
                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                  </span>
                  Añadir
                </Button>
              </div>
            </Col>
          </Row>
          <hr />
          {!!data?.publication?.length && (
            <Row>
              <Col xs={12} md={6}>
                <Table bordered striped>
                  <thead
                    className="bg-light sticky-top top-0"
                    style={{ zIndex: "auto" }}
                  >
                    <tr>
                      <th></th>
                      <th>Cartera</th>
                      <th>Sociedad Gestora</th>
                      <th>Publicable</th>
                      {PERMISOS_POR_ROL.VentaEspecial.Crud && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {data.publication.map((element, index) => (
                      <tr
                        key={index}
                        className={active === element.id ? "active" : ""}
                      >
                        <td>
                          {element.isPublishable && (
                            <FontAwesomeIcon
                              icon={faEye}
                              size="lg"
                              onClick={() => handleSelectRule(element.id)}
                            />
                          )}
                        </td>
                        <td>
                          {element.portfolioId
                            ? appLists.appPortfolios.find(
                                (e) => e.id === element.portfolioId
                              ).name
                            : "-"}
                        </td>
                        <td>
                          {element?.managementCompanyId &&
                          !!managementCompanies.length
                            ? managementCompanies.find(
                                (e) =>
                                  e.id === Number(element.managementCompanyId)
                              )?.name
                            : "-"}
                        </td>
                        <td>{element.isPublishable ? "Si" : "No"}</td>
                        {PERMISOS_POR_ROL.VentaEspecial.Crud && (
                          <td>
                            <span onClick={() => removeRule(element.id)}>
                              <FontAwesomeIcon icon={faTrash} fixedWidth />
                            </span>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
              <Col xs={12} md={6}>
                {!!active && (
                  <Tabs
                    defaultActiveKey={
                      !!filteredFields
                        ? Object.entries(filteredFields)[0][0]
                        : ""
                    }
                    id="fieldsTableRule"
                    className="mb-3"
                  >
                    {!!filteredFields &&
                      !!active &&
                      Object.entries(filteredFields).map((item, index) => (
                        <Tab eventKey={item[0]} title={item[0]} key={index}>
                          <div className="table-container">
                            <Table striped bordered>
                              <thead className="bg-light sticky-top top-0">
                                <tr>
                                  <th>
                                    <Form.Check
                                      onChange={(e) => {
                                        handleAddField(
                                          "all",
                                          e.target.checked,
                                          item[0]
                                        );
                                      }}
                                      checked={
                                        !!data?.publication?.length
                                          ? data.publication
                                              .find((e) => e.id === active)
                                              ?.configuration.filter((e) =>
                                                e.includes(item[0] + ".")
                                              ).length === item[1].length
                                          : false
                                      }
                                    />
                                  </th>
                                  <th className="th-search">
                                    <div>
                                      <span>Campo</span>

                                      <Form.Control
                                        required={false}
                                        type="text"
                                        placeholder="Buscar..."
                                        id={"search" + item[0] + active}
                                        disabled={disabledFalse}
                                        // value={getValue(value)}
                                        onChange={(e) =>
                                          filterTable(e.target.value, item[0])
                                        }
                                        size="sm"
                                      />
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {item[1].map((field, i) => (
                                  <tr key={i}>
                                    <td>
                                      <Form.Check
                                        onChange={(e) =>
                                          handleAddField(
                                            field,
                                            e.target.checked,
                                            item[0]
                                          )
                                        }
                                        checked={
                                          !!data?.publication?.length
                                            ? !!data.publication
                                                .find((e) => e.id === active)
                                                .configuration?.find(
                                                  (e) =>
                                                    e === item[0] + "." + field
                                                )
                                            : false
                                        }
                                      />
                                    </td>
                                    <td>{field}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Tab>
                      ))}
                  </Tabs>
                )}
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </S.SpecialSaleDetail>
  );
};

export default SpecialSaleDetail;
