import { Col, Form, Row } from "react-bootstrap";
import { StyledInputLink } from "./styles";
import { Link } from "react-router-dom";

const InputFormLink = ({ label, id, hidden, filter, showId, navigateId }) => {
  return (
    <Row className="mb-2" hidden={hidden(filter, label)}>
      <Col xs={12} md={5}>
        <Form.Label
          hidden={hidden(filter, label)}
          style={{ fontSize: "0.875rem" }}
        >
          {label}
        </Form.Label>
      </Col>
      <Col xs={12} md={7}>
        <StyledInputLink>
          {!!showId && !!navigateId && (
            <Link to={"/promotion-detail/" + navigateId}>{showId}</Link>
          )}
        </StyledInputLink>
      </Col>
    </Row>
  );
};

export default InputFormLink;
