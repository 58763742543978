import React from "react";
import { Routes, Route } from "react-router-dom";
import { NotFound } from "../pages/NotFound";
import { NoAccess } from "../pages/NoAccess";
import { SociedadesGestoras } from "../pages/SociedadesGestoras";
import { Territoriales } from "../pages/Territoriales";
import { Delegaciones } from "../pages/Delegaciones";
import { Usuarios } from "../pages/Usuarios";
import { Campanas } from "../pages/Campanas";
import { Promociones } from "../pages/Promociones";
import { CargasMasivas } from "../pages/CargasMasivas";
import { Auditoria } from "../pages/Auditoria";
import { DetalleCampana } from "../pages/DetalleCampana";
import { DetallePromocion } from "../pages/DetallePromocion";
import { Buscador } from "../pages/Buscador";
import { Tareas } from "../pages/Tareas";
import { TablaTemporal } from "../pages/TablaTemporal";
import { Inicio } from "../pages/Inicio";
import { DetalleActivoSinProducto } from "../pages/DetalleActivoSinProducto";
import { DetalleDelegacion } from "../pages/DetalleDelegacion";
import { ProductosBorrados } from "../pages/ProductosBorrados";
import { DetalleProducto } from "../pages/DetalleProducto";
import { CrearProducto } from "../pages/CrearProducto";
import AuditingDetail from "../pages/DetalleAuditoría";
import ParticularAsset from "../pages/DetalleActivoParticular";
import SpecialSale from "../pages/VentaEspacial";
import SpecialSaleDetail from "../pages/DetalleVentaEspecial";

export const RoutesApp = ({ access }) => {
  return (
    <Routes>
      <Route path="/" element={<Inicio />}></Route>
      <Route path="search-asset" element={<Buscador />}></Route>
      <Route
        path="asset-detail/:id"
        element={<DetalleActivoSinProducto />}
      ></Route>
      <Route
        path="product-detail/:idProduct/:idAsset"
        element={<DetalleProducto />}
      ></Route>
      <Route
        path="search-deleted-products"
        element={<ProductosBorrados />}
      ></Route>
      <Route path="create-product" element={<CrearProducto />}></Route>
      <Route path="particular-asset" element={<ParticularAsset />}></Route>
      <Route path="particular-asset/:id" element={<ParticularAsset />}></Route>
      <Route path="create-terce-asset" element={<ParticularAsset />}></Route>
      <Route path="terce-asset/:id" element={<ParticularAsset />}></Route>
      <Route path="search-campaign" element={<Campanas />}></Route>
      <Route path="campaign-detail" element={<DetalleCampana />}></Route>
      <Route path="campaign-detail/:id" element={<DetalleCampana />}></Route>
      <Route path="search-promotion" element={<Promociones />}></Route>
      <Route path="promotion-detail" element={<DetallePromocion />}></Route>
      <Route path="promotion-detail/:id" element={<DetallePromocion />}></Route>
      <Route path="bulk-upload" element={<CargasMasivas />}></Route>
      <Route path="view-territorials" element={<Territoriales />}></Route>
      <Route path="view-delegations" element={<Delegaciones />}></Route>
      <Route
        path="delegation-detail/:id"
        element={<DetalleDelegacion />}
      ></Route>
      <Route path="view-managements" element={<SociedadesGestoras />}></Route>
      <Route path="users" element={<Usuarios />}></Route>
      <Route path="queues" element={<Tareas />}></Route>
      <Route path="temp-table" element={<TablaTemporal />}></Route>
      <Route path="auditing" element={<Auditoria />}></Route>
      <Route path="auditing/:id" element={<AuditingDetail />}></Route>
      <Route path="404" element={<NotFound />}></Route>
      <Route path="special-sales" element={<SpecialSale />}></Route>
      <Route path="special-sale/:id" element={<SpecialSaleDetail />}></Route>
      <Route path="special-sale" element={<SpecialSaleDetail />}></Route>
      <Route path="NoAccess" element={<NoAccess />}></Route>
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  );
};
