import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [
      {
        color: [
          "#000000",
          "#7f7f7f",
          "#880015",
          "#ed1c24",
          "#ff7f27",
          "#fff200",
          "#22b14c",
          "#00a2e8",
          "#3f48cc",
          "#a349a4",
          "#ffffff",
          "#c3c3c3",
          "#b97a57",
          "#ffaec9",
          "#ffc90e",
          "#efe4b0",
          "#b5e61d",
          "#99d9ea",
          "#7092be",
          "#c8bfe7",
        ],
      },
      {
        background: [
          "#000000",
          "#7f7f7f",
          "#880015",
          "#ed1c24",
          "#ff7f27",
          "#fff200",
          "#22b14c",
          "#00a2e8",
          "#3f48cc",
          "#a349a4",
          "#ffffff",
          "#c3c3c3",
          "#b97a57",
          "#ffaec9",
          "#ffc90e",
          "#efe4b0",
          "#b5e61d",
          "#99d9ea",
          "#7092be",
          "#c8bfe7",
        ],
      },
    ],
    [{ align: [] }],
  ],
};

const formats = ["bold", "italic", "underline", "color", "background", "align"];

export const ModalTextEditor = ({
  message,
  cancel,
  value,
  setValue,
  confirm,
}) => {
  return (
    <Modal
      show={true}
      onHide={cancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={value}
          onChange={setValue}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginRight: "1rem" }}
          onClick={() => confirm()}
          variant="success"
        >
          <span>
            <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
          </span>
          Actualizar
        </Button>

        {cancel && (
          <Button onClick={cancel} variant="danger">
            <span>
              <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
            </span>
            Cancelar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalTextEditor.propTypes = {
  message: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};
