import React, { useState, useEffect } from "react";
import { useLoader } from "../../components/Loading";
import * as API from "../../api";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { TableFrontEnd } from "../../components/TableFrontEnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faMapMarkerAlt,
  faMapPin,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { ModalAssignDelegation } from "../../components/ModalAssignDelegation";
import { ModalTable } from "../../components/ModalTable";
import { useDataContext } from "../../context/DataState";
import { DetailPage } from "./styles";
import usePermition from "../../constants/usePermition";

export const DetalleDelegacion = () => {
  const [data, setData] = useState([]);
  const [dataMunicipios, setDataMunicipios] = useState([]);
  const [dataProvincias, setDataProvincias] = useState([]);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [loader, showLoader, hideLoader] = useLoader();
  const [showModalMunicipios, setShowModalMunicipios] = useState(false);
  const [showModalProvincias, setShowModalProvincias] = useState(false);
  const [showModalAssignDelegation, setShowModalAssignDelegation] =
    useState(false);
  const [selectedMunicipios, setSelectedMunicipios] = useState([]);
  const [selectedProvincias, setSelectedProvincias] = useState([]);
  const [assetsForced, setAssetsForced] = useState("");
  const [moveForced, setMoveForced] = useState(false);
  const [typeAssign, setTypeAssign] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    state: { appLists, user },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    document.title = "EC-Light-Delegación";
    let isMounted = true;
    if (isMounted && id) {
      let listsAux = appLists;
      let dataAux = [];
      let dataAuxMunicipios = [];
      let dataAuxProvincias = [];
      showLoader();
      API.obtenerDelegacion(id)
        .then((res) => {
          let municipalitiesDelegation = listsAux.appMunicipalities.filter(
            (s) => res.data.municipalities.includes(s.id)
          );
          let municipalitiesNotInDelegation = listsAux.appMunicipalities.filter(
            (s) => !res.data.municipalities.includes(s.id)
          );

          municipalitiesDelegation.forEach((e) => {
            let newRegister = {};
            newRegister.municipality = e.name;
            let province = listsAux.appProvinces.find(
              (s) => s.id === e.provinceId
            );
            if (province) {
              newRegister.province = province.name;
            }
            dataAux.push(newRegister);
          });
          municipalitiesNotInDelegation.forEach((e) => {
            let newRegister = {};
            newRegister.id = e.id;
            newRegister.municipality = e.name;
            let province = listsAux.appProvinces.find(
              (s) => s.id === e.provinceId
            );
            if (province) {
              newRegister.province = province.name;
            }
            dataAuxMunicipios.push(newRegister);
          });
          listsAux.appProvinces.forEach((e) => {
            let newRegister = {};
            newRegister.id = e.id;
            newRegister.name = e.name;
            dataAuxProvincias.push(newRegister);
          });
          setTitle(res.data.name);
          const portfolio = listsAux.appPortfolios.find(
            (s) => s.id === res.data.portfolioId
          );
          const businessLine = listsAux.businessLines.find(
            (s) => s.Id === res.data.businessLine
          );
          if (portfolio !== null && businessLine !== null) {
            setSubtitle(portfolio.name + " " + businessLine.translation);
          }
          setData(dataAux);
          setDataMunicipios(dataAuxMunicipios);
          setDataProvincias(dataAuxProvincias);
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    }
    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoader, hideLoader, id]);

  const obtenerDelegaciones = () => {
    let listsAux = appLists;
    let dataAux = [];
    let dataAuxMunicipios = [];
    let dataAuxProvincias = [];
    showLoader();
    API.obtenerDelegacion(id)
      .then((res) => {
        let municipalitiesDelegation = listsAux.appMunicipalities.filter((s) =>
          res.data.municipalities.includes(s.id)
        );
        let municipalitiesNotInDelegation = listsAux.appMunicipalities.filter(
          (s) => !res.data.municipalities.includes(s.id)
        );

        municipalitiesDelegation.forEach((e) => {
          let newRegister = {};
          newRegister.municipality = e.name;
          let province = listsAux.appProvinces.find(
            (s) => s.id === e.provinceId
          );
          if (province) {
            newRegister.province = province.name;
          }
          dataAux.push(newRegister);
        });
        municipalitiesNotInDelegation.forEach((e) => {
          let newRegister = {};
          newRegister.id = e.id;
          newRegister.municipality = e.name;
          let province = listsAux.appProvinces.find(
            (s) => s.id === e.provinceId
          );
          if (province) {
            newRegister.province = province.name;
          }
          dataAuxMunicipios.push(newRegister);
        });
        listsAux.appProvinces.forEach((e) => {
          let newRegister = {};
          newRegister.id = e.id;
          newRegister.name = e.name;
          dataAuxProvincias.push(newRegister);
        });
        setTitle(res.data.name);
        const portfolio = listsAux.appPortfolios.find(
          (s) => s.id === res.data.portfolioId
        );
        const businessLine = listsAux.businessLines.find(
          (s) => s.Id === res.data.businessLine
        );
        if (portfolio !== null && businessLine !== null) {
          setSubtitle(portfolio.name + " " + businessLine.translation);
        }
        setData(dataAux);
        setDataProvincias(dataAuxProvincias);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const filterForm = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (
      <Form>
        <Form.Group className="mb-2">
          <span>
            <input
              className="form-control"
              style={{ width: "60%", marginTop: "0.25rem", padding: "0" }}
              value={filterValue || ""}
              onClick={(e) => {
                if (!e) e = window.event;
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => setFilter(e.target.value)}
            />
          </span>
        </Form.Group>
      </Form>
    );
  };

  const defaultColumn = {
    // Let's set up our default Filter UI
    Filter: filterForm,
  };

  const defaultSort = [
    {
      id: "province",
      desc: false,
    },
  ];

  const defaultSortMunicipios = [
    {
      id: "id",
      desc: false,
    },
  ];

  const defaultSortProvincias = [
    {
      id: "id",
      desc: false,
    },
  ];

  const columns = [
    {
      Header: "Municipio",
      accessor: "municipality",
      Filter: filterForm,
    },
    {
      Header: "Provincia",
      accessor: "province",
      Filter: filterForm,
    },
  ];

  const columnsMunicipios = [
    {
      Header: "",
      accessor: "selector",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Form.Check
          onChange={(e) => {
            handleChangeMunicipios(cell.row.original.id, e.target.checked);
          }}
          checked={selectedMunicipios.includes(cell.row.original.id)}
        />
      ),
    },
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Nombre",
      accessor: "municipality",
      Filter: filterForm,
    },
    {
      Header: "Provincia",
      accessor: "province",
      Filter: filterForm,
    },
  ];

  const columnsProvincias = [
    {
      Header: "",
      accessor: "selector",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Form.Check
          onChange={(e) => {
            handleChangeProvincias(cell.row.original.id, e.target.checked);
          }}
          checked={selectedProvincias.includes(cell.row.original.id)}
        />
      ),
    },
    {
      Header: "Id",
      accessor: "id",
      Filter: filterForm,
    },
    {
      Header: "Nombre",
      accessor: "name",
      Filter: filterForm,
    },
  ];

  const obtenerActivosForzadosMunicipios = () => {
    showLoader();
    API.activosForzadosMunicipios(id, selectedMunicipios)
      .then((res) => {
        setTypeAssign("Municipios");
        setAssetsForced(res.data.map((s) => s.id).join(","));
        setShowModalMunicipios(false);
        setShowModalAssignDelegation(true);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const obtenerActivosForzadosProvincias = () => {
    showLoader();
    API.activosForzadosProvincias(id, selectedProvincias)
      .then((res) => {
        setTypeAssign("Provincias");
        setAssetsForced(res.data.map((s) => s.id).join(","));
        setShowModalProvincias(false);
        setShowModalAssignDelegation(true);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const handleChangeMunicipios = (idMunicipio, checked) => {
    let tmpSelectedMunicipios = [...selectedMunicipios];
    if (checked) {
      tmpSelectedMunicipios.push(idMunicipio);
      setSelectedMunicipios(tmpSelectedMunicipios);
    } else {
      var ic = tmpSelectedMunicipios.indexOf(idMunicipio);
      tmpSelectedMunicipios.splice(ic, 1);
      if (ic > -1) setSelectedMunicipios(tmpSelectedMunicipios);
    }
  };

  const handleChangeProvincias = (idProvincia, checked) => {
    let tmpSelectedProvincias = [...selectedProvincias];
    if (checked) {
      tmpSelectedProvincias.push(idProvincia);
      setSelectedProvincias(tmpSelectedProvincias);
    } else {
      var ic = tmpSelectedProvincias.indexOf(idProvincia);
      tmpSelectedProvincias.splice(ic, 1);
      if (ic > -1) setSelectedProvincias(tmpSelectedProvincias);
    }
  };

  const cancelAssign = () => {
    if (typeAssign === "Municipios") setShowModalMunicipios(true);
    else if (typeAssign === "Provincias") setShowModalProvincias(true);
    setShowModalAssignDelegation(false);
    setAssetsForced("");
  };

  const confirmAssign = () => {
    showLoader();
    let dataConfirmAssign = {};
    dataConfirmAssign.moveForced = moveForced;
    if (typeAssign === "Municipios") {
      dataConfirmAssign.iDs = selectedMunicipios;
      API.editarDelegacionMunicipios(id, dataConfirmAssign)
        .then((res) => {
          toast.success("Delegación actualizada correctamente");
          setShowModalAssignDelegation(false);
          setMoveForced(false);
          setTypeAssign("");
          setSelectedMunicipios([]);
          setSelectedProvincias([]);
          setAssetsForced("");
          obtenerDelegaciones();
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    } else if (typeAssign === "Provincias") {
      dataConfirmAssign.iDs = selectedProvincias;
      API.editarDelegacionProvincias(id, dataConfirmAssign)
        .then((res) => {
          toast.success("Delegación actualizada correctamente");
          setShowModalAssignDelegation(false);
          setMoveForced(false);
          setTypeAssign("");
          setSelectedMunicipios([]);
          setSelectedProvincias([]);
          setAssetsForced("");
          obtenerDelegaciones();
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    }
  };

  /**
   * Redirect to auditory with search params in the url
   */
  const handleAuditLink = () => {
    navigate(`/auditing?EntityName=Delegation&EntityId=${id}`);
  };

  return (
    <DetailPage>
      {loader}
      {showModalMunicipios && (
        <ModalTable
          message="Añadir municipios a Delegación"
          columns={columnsMunicipios}
          data={dataMunicipios}
          defaultSort={defaultSortMunicipios}
          defaultColumn={defaultColumn}
          hiddenColumns={["id"]}
          labelConfirm="Confirmar"
          cancel={() => setShowModalMunicipios(false)}
          confirm={() => {
            obtenerActivosForzadosMunicipios();
          }}
          pagination={true}
        />
      )}
      {showModalProvincias && (
        <ModalTable
          message="Añadir provincias a Delegación"
          columns={columnsProvincias}
          data={dataProvincias}
          defaultSort={defaultSortProvincias}
          defaultColumn={defaultColumn}
          hiddenColumns={[]}
          labelConfirm="Confirmar"
          cancel={() => setShowModalProvincias(false)}
          confirm={() => {
            obtenerActivosForzadosProvincias();
          }}
          pagination={false}
        />
      )}
      {showModalAssignDelegation && (
        <ModalAssignDelegation
          message="Confirmar acción"
          cancel={() => cancelAssign(typeAssign)}
          assetsForced={assetsForced}
          typeAssign={typeAssign}
          moveForced={moveForced}
          setMoveForced={setMoveForced}
          confirm={() => {
            confirmAssign();
          }}
        />
      )}
      <h4>{title}</h4>
      <h6>{subtitle}</h6>
      <p>Municipios pertenecientes a esta delegación:</p>
      <Card>
        <Card.Header>
          <Row>
            <Col xs={7}>
              <Button onClick={() => navigate("/view-delegations")}>
                <span>
                  <FontAwesomeIcon icon={faArrowLeft} size="lg" fixedWidth />
                </span>
              </Button>
              <Button
                style={{ marginLeft: "1rem" }}
                variant="success"
                hidden={!PERMISOS_POR_ROL.Delegaciones.Auditoria}
                onClick={handleAuditLink}
              >
                <span>
                  <FontAwesomeIcon icon={faPrint} size="lg" fixedWidth />
                </span>
                Auditoría
              </Button>
            </Col>
            <Col xs={3}>
              <Button
                variant="danger"
                onClick={() => setShowModalMunicipios(true)}
                hidden={!PERMISOS_POR_ROL.Delegaciones.Crud}
              >
                <span>
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" fixedWidth />
                </span>
                Añadir municipios
              </Button>
            </Col>
            <Col xs={2}>
              <Button
                variant="danger"
                onClick={() => setShowModalProvincias(true)}
                hidden={!PERMISOS_POR_ROL.Delegaciones.Crud}
              >
                <span>
                  <FontAwesomeIcon icon={faMapPin} size="lg" fixedWidth />
                </span>
                Añadir provincias
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <TableFrontEnd
            columns={columns}
            data={data}
            defaultSort={defaultSort}
            defaultColumn={defaultColumn}
            hiddenColumns={[]}
            pagination={true}
          />
        </Card.Body>
      </Card>
    </DetailPage>
  );
};
