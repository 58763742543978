import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Modal, Table } from "react-bootstrap";
import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export const ModalEntitiesAudit = ({
  message,
  cancel,
  dataEntities,
  entityShow,
  setEntityShow,
}) => {
  const handleChange = (contador) => {
    let tmpEntityShow = [...entityShow];
    if (
      tmpEntityShow[contador] === undefined ||
      tmpEntityShow[contador] === null
    ) {
      tmpEntityShow[contador] = true;
    } else {
      tmpEntityShow[contador] = !tmpEntityShow[contador];
    }
    setEntityShow(tmpEntityShow);
  };

  const formatValue = (value, field) => {
    if (!isNaN(Date.parse(value)) && field !== "Id" && field !== "CrmId") {
      var date = new Date(value);
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() + -offset * 60000);
      let d = date;
      d = [
        "0" + d.getDate(),
        "0" + (d.getMonth() + 1),
        "" + d.getFullYear(),
        "0" + d.getHours(),
        "0" + d.getMinutes(),
        "0" + d.getSeconds(),
      ].map((component) => {
        if (component.length < 4) {
          return component.slice(-2);
        } else {
          return component;
        }
      });

      return d.slice(0, 3).join("/") + " " + d.slice(3).join(":");
    } else {
      return value;
    }
  };

  return (
    <Modal
      size="lg"
      show={true}
      onHide={cancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <S.Container>
          <Table>
            <thead className="bg-light sticky-top top-0">
              <tr>
                <th></th>
                <th>Entidad</th>
                <th>Identificador</th>
                <th>Acción Realizada</th>
              </tr>
            </thead>
            <tbody>
              {dataEntities.map((field, index) => (
                <Fragment>
                  <tr key={index}>
                    <S.Dropdown onClick={() => handleChange(field.contador)}>
                      <FontAwesomeIcon
                        style={{ height: "100%" }}
                        icon={faPlus}
                        size="lg"
                        fixedWidth
                      />
                    </S.Dropdown>
                    <td>{field.entityName}</td>
                    <td>{field.entityId}</td>
                    <td>{field.entityChangeType}</td>
                  </tr>
                  {entityShow[field.contador] && (
                    <tr key={index}>
                      <td colSpan="3">
                        <Table
                          striped
                          bordered
                          hover
                          style={{ width: "100%", marginLeft: "2rem" }}
                        >
                          <tr>
                            <th>Campo</th>
                            <th>Valor Original</th>
                            <th>Valor Modificado</th>
                          </tr>
                          {field.auditEntityChanges.map(
                            (entityChange, index) => (
                              <tr key={index}>
                                <td>{entityChange.fieldName}</td>
                                <S.OldValue>
                                  {entityChange.fieldName === "CreationTime" ||
                                  entityChange.fieldName === "DeletionTime" ||
                                  entityChange.fieldName ===
                                    "LastModificationTime" ||
                                  entityChange.fieldName === "StartDate" ||
                                  entityChange.fieldName === "EndDate" ? (
                                    formatValue(
                                      entityChange.oldValue,
                                      entityChange.fieldName
                                    )
                                  ) : entityChange.fieldName ===
                                    "Description" ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: entityChange.oldValue,
                                      }}
                                    ></div>
                                  ) : (
                                    entityChange.oldValue
                                  )}
                                </S.OldValue>
                                <S.NewValue>
                                  {entityChange.fieldName === "CreationTime" ||
                                  entityChange.fieldName === "DeletionTime" ||
                                  entityChange.fieldName ===
                                    "LastModificationTime" ||
                                  entityChange.fieldName === "StartDate" ||
                                  entityChange.fieldName === "EndDate" ? (
                                    formatValue(
                                      entityChange.newValue,
                                      entityChange.fieldName
                                    )
                                  ) : entityChange.fieldName ===
                                    "Description" ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: entityChange.newValue,
                                      }}
                                    ></div>
                                  ) : (
                                    entityChange.newValue
                                  )}
                                </S.NewValue>
                              </tr>
                            )
                          )}
                        </Table>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </S.Container>
      </Modal.Body>
    </Modal>
  );
};

ModalEntitiesAudit.propTypes = {
  message: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  dataEntities: PropTypes.array.isRequired,
  entityShow: PropTypes.array.isRequired,
  setEntityShow: PropTypes.func.isRequired,
};
