import { Fragment, useEffect, useState } from "react";
import * as S from "./styles";
import * as API from "../../api";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useLoader } from "../../components/Loading";
import logo from "../../assets/images/aliseda_logo.png";
import { LinkContainer } from "react-router-bootstrap";
import { toast } from "react-toastify";
import {
  faUserFriends,
  faUser,
  faSearch,
  faUpload,
  faClone,
  faCrown,
  faGlobeEurope,
  faMapMarkedAlt,
  faPrint,
  faGripLines,
  faUserAlt,
  faStar,
  faBriefcase,
  faDatabase,
  faSignOutAlt,
  faTasks,
  faTable,
  faBullhorn,
  faCog,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import { MENUS_TABLAS, MENUS_ADMIN } from "./constants";
import { useMsal } from "@azure/msal-react";
import { useDataContext } from "../../context/DataState";
import { CustomeTooltip } from "../CustomeTooltip/CustomeTooltip";
import usePermition from "../../constants/usePermition";

export const NavMenu = ({ pathname }) => {
  const [loader, showLoader, hideLoader] = useLoader();
  const { instance, accounts } = useMsal();
  const {
    state: { user },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });
  const [isFixed, setIsFixed] = useState(false);
  const scrollPositionToShowFixed = 200; // Adjust this value based on your needs

  const MENUS_TABLAS_CON_PERMISOS = MENUS_TABLAS.reduce((acc, item) => {
    const permisos =
      item.label === "Promociones"
        ? PERMISOS_POR_ROL.Promociones.Acceso
        : PERMISOS_POR_ROL.Campanas.Acceso;

    if (permisos) {
      acc.push({ ...item, permisos });
    }

    return acc;
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= scrollPositionToShowFixed) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChangeDropdownPortfolio = (id, name) => {
    showLoader();
    API.cambiarCartera(id)
      .then((res) => {
        window.location = process.env.REACT_APP_BASE_URL;
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const getIcon = (icon) => {
    switch (icon) {
      case "search-asset":
        return (
          <span>
            <FontAwesomeIcon icon={faSearch} size="lg" fixedWidth />
          </span>
        );
      case "search-promotion":
        return (
          <span>
            <FontAwesomeIcon icon={faUserFriends} size="lg" fixedWidth />
          </span>
        );
      case "publications":
        return (
          <span>
            <FontAwesomeIcon icon={faBullhorn} size="lg" fixedWidth />
          </span>
        );
      case "search-campaign":
        return (
          <span>
            <FontAwesomeIcon icon={faClone} size="lg" fixedWidth />
          </span>
        );
      case "bulk-upload":
        return (
          <span>
            <FontAwesomeIcon icon={faUpload} size="lg" fixedWidth />
          </span>
        );
      case "configuration":
        return (
          <span>
            <FontAwesomeIcon icon={faCrown} size="lg" fixedWidth />
          </span>
        );
      case "view-territorials":
        return (
          <span>
            <FontAwesomeIcon icon={faGlobeEurope} size="lg" fixedWidth />
          </span>
        );
      case "view-delegations":
        return (
          <span>
            <FontAwesomeIcon icon={faMapMarkedAlt} size="lg" fixedWidth />
          </span>
        );
      case "view-managements":
        return (
          <span>
            <FontAwesomeIcon icon={faGripLines} size="lg" fixedWidth />
          </span>
        );
      case "users":
        return (
          <span>
            <FontAwesomeIcon icon={faUser} size="lg" fixedWidth />
          </span>
        );
      case "queues":
        return (
          <span>
            <FontAwesomeIcon icon={faTasks} size="lg" fixedWidth />
          </span>
        );
      case "temp-table":
        return (
          <span>
            <FontAwesomeIcon icon={faTable} size="lg" fixedWidth />
          </span>
        );
      case "special-sales":
        return (
          <span>
            <FontAwesomeIcon icon={faHandshake} size="lg" fixedWidth />
          </span>
        );
      case "auditing":
        return (
          <span>
            <FontAwesomeIcon icon={faPrint} size="lg" fixedWidth />
          </span>
        );
      case "userMenu":
        return (
          <span>
            <FontAwesomeIcon icon={faUserAlt} size="lg" fixedWidth />
          </span>
        );
      case "tables":
        return (
          <span>
            <FontAwesomeIcon icon={faDatabase} size="lg" fixedWidth />
          </span>
        );
      case "portfolio":
        return (
          <span>
            <FontAwesomeIcon icon={faBriefcase} size="lg" fixedWidth />
          </span>
        );
      case "config":
        return (
          <span>
            <FontAwesomeIcon icon={faCog} size="lg" fixedWidth />
          </span>
        );
      case "role":
        return (
          <span>
            <FontAwesomeIcon icon={faStar} size="lg" fixedWidth />
          </span>
        );
      case "signout":
        return (
          <span>
            <FontAwesomeIcon icon={faSignOutAlt} size="lg" fixedWidth />
          </span>
        );
      default:
        return null;
    }
  };

  const getNameRoles = () => {
    const roles = user.roles;
    if (roles !== null) {
      return roles.map((s) => s.normalizedName);
    } else {
      return [];
    }
  };

  const getCurrentPortfolioName = () => {
    return user.portfolios.find((e) => e.id === user.currentPortfolioID)?.name;
  };

  const handleLogoutRedirect = () => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
      postLogoutRedirectUri: process.env.REACT_APP_BASE_URL,
    };
    instance.logoutRedirect(logoutRequest);
  };

  const noAccessURL =
    window.location.href.split("/").at(-1).toLowerCase() === "noaccess";

  if (noAccessURL) return <Fragment></Fragment>;

  const activeAccount = instance.getActiveAccount();
  const username = activeAccount?.name?.split(" ")[0] || "";
  const usermail = activeAccount?.username || "";

  return (
    <Fragment>
      {loader}
      {user && (
        <S.MenuContainer fixed={isFixed}>
          {loader}
          <Navbar expand="lg">
            <LinkContainer to={"/"}>
              <Navbar.Brand>
                <img
                  style={{ marginRight: "3rem", height: "5vh" }}
                  src={logo}
                  alt="Logo"
                ></img>
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer to="/search-asset">
                  <Nav.Link className="bulkUpload">
                    {getIcon("search-asset")}
                    <S.LabelMenu>Buscador</S.LabelMenu>
                  </Nav.Link>
                </LinkContainer>
                {PERMISOS_POR_ROL.Publicaciones.Acceso && (
                  <NavDropdown
                    title={
                      <div style={{ display: "inline-block" }}>
                        {getIcon("publications")}{" "}
                        <S.LabelMenu>Publicaciones</S.LabelMenu>
                      </div>
                    }
                    id="basic-nav-dropdown-tablas"
                  >
                    {MENUS_TABLAS_CON_PERMISOS.map((menu, index) => (
                      <LinkContainer key={index} to={"/" + menu.key}>
                        <NavDropdown.Item>
                          {getIcon(menu.key)}
                          <S.LabelMenu>{menu.label}</S.LabelMenu>
                        </NavDropdown.Item>
                      </LinkContainer>
                    ))}
                  </NavDropdown>
                )}

                {PERMISOS_POR_ROL.CargasMasivas.Acceso && (
                  <LinkContainer to="/bulk-upload">
                    <Nav.Link className="bulkUpload">
                      {getIcon("bulk-upload")}
                      <S.LabelMenu>Cargas Masivas</S.LabelMenu>
                    </Nav.Link>
                  </LinkContainer>
                )}
                {PERMISOS_POR_ROL.Administracion.Acceso && (
                  <NavDropdown
                    className="dropdown-white"
                    title={
                      <div style={{ display: "inline-block" }}>
                        {getIcon("config")}{" "}
                        <S.LabelMenu>Administración</S.LabelMenu>
                      </div>
                    }
                    id="basic-nav-dropdown-admin"
                  >
                    {MENUS_ADMIN.map((menu, index) => (
                      <LinkContainer key={index} to={"/" + menu.key}>
                        <NavDropdown.Item>
                          {getIcon(menu.key)}
                          <S.LabelMenu>{menu.label}</S.LabelMenu>
                        </NavDropdown.Item>
                      </LinkContainer>
                    ))}
                  </NavDropdown>
                )}
              </Nav>
              <Nav>
                <NavDropdown
                  className="dropdown-white"
                  title={
                    <div style={{ display: "inline-block" }}>
                      {getIcon("portfolio")}{" "}
                      <S.LabelMenu>{getCurrentPortfolioName()}</S.LabelMenu>
                    </div>
                  }
                  id="basic-nav-dropdown-portfolio"
                >
                  {user.portfolios.map((portfolio) => (
                    <NavDropdown.Item
                      key={portfolio.id}
                      onClick={() =>
                        handleChangeDropdownPortfolio(
                          portfolio.id,
                          portfolio.name
                        )
                      }
                    >
                      {getIcon("portfolio")}
                      <S.LabelMenu>{portfolio.name}</S.LabelMenu>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <NavDropdown
                  className="dropdown-white"
                  title={
                    <div style={{ display: "inline-block" }}>
                      {getIcon("userMenu")}{" "}
                      <CustomeTooltip
                        tooltip={usermail}
                        children={
                          <S.LabelMenu>
                            <span>{username}</span>
                          </S.LabelMenu>
                        }
                      />
                    </div>
                  }
                  id="basic-nav-dropdown-user"
                >
                  {getNameRoles().map((rol, index) => (
                    <NavDropdown.Item key={index}>
                      {getIcon("role")}
                      <S.LabelMenu>{rol}</S.LabelMenu>
                    </NavDropdown.Item>
                  ))}

                  <NavDropdown.Item>
                    {getIcon("signout")}
                    <S.LabelMenu onClick={handleLogoutRedirect}>
                      Cerrar Sesión
                    </S.LabelMenu>
                  </NavDropdown.Item>
                </NavDropdown>
                <Navbar.Brand className="version">
                  <S.LabelMenu>
                    {localStorage.getItem("appVersion")}
                    {process.env.REACT_APP_ENV === "pre" &&
                      " - " + process.env.REACT_APP_ENV.toLocaleUpperCase()}
                  </S.LabelMenu>
                </Navbar.Brand>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </S.MenuContainer>
      )}
    </Fragment>
  );
};

NavMenu.propTypes = {
  pathname: PropTypes.string.isRequired,
};
