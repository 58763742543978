import styled from "styled-components/macro";

export const AuditDetail = styled.div`
  padding: 0 25px;
  .detail-header {
    display: inline-flex;
    margin: 20px 0;
    button {
      width: 50px;
    }
  }
  table {
    tbody {
      .enable-select {
        cursor: pointer;
      }
    }
    tr {
      text-align: center;
    }
  }
  #changesTable {
    height: 70vh;
    overflow-y: auto;
  }
`;
