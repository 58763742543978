import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_BASE_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      /**
       * Below you can configure MSAL.js logs. For more information, visit:
       * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
       */
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// export const loginRequest = {
//   scopes: ["User.Read"],
// };

export const protectedResources = {
  apiTodoList: {
    // endpoint: `${process.env.REACT_APP_BASE_API_URL}/Users/Access`,
    scopes: {
      read: [`${process.env.REACT_APP_API_SCOPE}`],
    },
  },
};

export const loginRequest = {
  scopes: [...protectedResources.apiTodoList.scopes.read],
  prompt: "select_account",
};
