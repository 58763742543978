import React from "react";
import { Row, Col, Form } from "react-bootstrap";

export const InputFormCheckBox = ({
  label,
  id,
  disabled,
  hidden,
  filter,
  handleChange,
  value,
}) => {
  return (
    <Row className="mb-2" hidden={hidden(filter, label)}>
      <Col xs={12} md={5}>
        <Form.Label
          hidden={hidden(filter, label)}
          style={{ fontSize: "0.875rem" }}
        >
          {label}
        </Form.Label>
      </Col>
      <Col xs={12} md={7}>
        <Form.Check
          type="checkbox"
          id={id}
          disabled={disabled()}
          hidden={hidden(filter, label)}
          checked={value}
          onChange={(e) => handleChange(id, e.target.checked)}
          size="sm"
          style={{ cursor: "pointer" }}
        />
      </Col>
    </Row>
  );
};
