import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import PageTitle from "../../components/PageTitle/PageTitle";
import * as S from "./styles";
import useAuditDetail from "./useAuditDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { formatDateWithZone } from "../../constants";
import { TableFrontEnd } from "../../components/TableFrontEnd";

const AuditingDetail = () => {
  const {
    id,
    loader,
    audit,
    active,
    getUserName,
    key,
    setKey,
    getAudit,
    navigate,
    regByPage,
    columns,
    defaultSort,
  } = useAuditDetail();

  /**
   * Componente de tabla con datos generales
   * @returns
   */
  const GeneralTable = () => (
    <Container>
      <Table bordered striped>
        <thead
          className="bg-light sticky-top top-0"
          style={{
            zIndex: "auto",
          }}
        >
          <tr>
            <th>Dato</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Inicio</td>
            <td>{audit ? formatDateWithZone(audit?.startDateTime) : ""}</td>
          </tr>
          <tr>
            <td>Duración</td>
            <td>{audit?.duration}</td>
          </tr>
          <tr>
            <td>Fin</td>
            <td>{audit ? formatDateWithZone(audit?.endDateTime) : ""}</td>
          </tr>
          <tr>
            <td>Erróneo</td>
            <td>{audit?.hasError ? "Si" : "No"}</td>
          </tr>
          {audit?.log && (
            <tr>
              <td>Log</td>
              <td>
                <div style={{ maxHeight: 200, overflowY: "auto" }}>
                  {audit.log}
                </div>
              </td>
            </tr>
          )}
          {audit?.error && (
            <tr>
              <td>Error</td>
              <td>
                <div style={{ maxHeight: 150, overflowY: "auto" }}>
                  {audit.error}
                </div>
              </td>
            </tr>
          )}
          {audit?.parentAuditActionId && (
            <tr>
              <td>Acción Padre</td>
              <td onClick={() => getAudit(audit.parentAuditActionId)}>
                <Link to={"/auditing/" + audit.parentAuditActionId}>
                  {audit.parentAuditActionId}
                </Link>
              </td>
            </tr>
          )}
          <tr>
            <td>Usuario</td>
            <td>{getUserName(audit?.userId)}</td>
          </tr>
          {!!audit?.parameters &&
            Object.keys(audit.parameters).map((key, index) => (
              <tr key={index}>
                <td>{key}</td>
                <td>{audit.parameters[key]}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );

  const filterForm = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (
      <Form>
        <Form.Group className="mb-2">
          <input
            className="form-control"
            style={{ marginTop: "0.25rem", padding: "0" }}
            value={filterValue || ""}
            onClick={(e) => {
              if (!e) e = window.event;
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onKeyUp={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Form.Group>
      </Form>
    );
  };

  const defaultColumn = {
    // Let's set up our default Filter UI
    Filter: filterForm,
  };

  return (
    <S.AuditDetail>
      {loader}
      <Container>
        <div className="detail-header">
          <Button
            variant="danger"
            onClick={() => navigate(-1)}
            style={{ marginRight: "1rem" }}
          >
            <span>
              <FontAwesomeIcon icon={faArrowLeft} size="lg" fixedWidth />
            </span>
          </Button>

          <PageTitle title={"Auditoría " + id} />
        </div>
      </Container>

      {!!audit?.auditEntities.length ? (
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
          fill
        >
          <Tab eventKey="general" title="General">
            <GeneralTable />
          </Tab>
          {!!audit?.auditEntities.length && (
            <Tab eventKey="changes" title="Cambios">
              <Row>
                <Col xs={12} md={5}>
                  <TableFrontEnd
                    columns={columns}
                    data={audit?.auditEntities}
                    defaultSort={defaultSort}
                    hiddenColumns={[]}
                    pagination={
                      audit?.auditEntities?.length > regByPage ? true : false
                    }
                    regByPage={10}
                    heightSize={"55vh"}
                    hideReg={true}
                    defaultColumn={defaultColumn}
                  />
                </Col>
                <Col xs={12} md={7}>
                  <div id="changesTable">
                    <Table striped>
                      <thead
                        className="bg-light sticky-top top-0"
                        style={{ zIndex: "auto" }}
                      >
                        <tr>
                          <th>Campo</th>
                          <th>Valor Inicial</th>
                          <th>Valor Modificado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {audit?.auditEntities
                          .find((e) => e.id === active)
                          ?.auditEntityChanges?.map((field, index) => (
                            <tr key={index}>
                              <td>{field.fieldName}</td>
                              <td>
                                {!!field.oldValue
                                  ? field.fieldName === "CreationTime" ||
                                    field.fieldName ===
                                      "LastModificationTime" ||
                                    field.fieldName === "LastPublicationDate"
                                    ? formatDateWithZone(field.oldValue)
                                    : field.oldValue
                                  : ""}
                              </td>
                              <td>
                                {!!field.newValue
                                  ? field.fieldName === "CreationTime" ||
                                    field.fieldName ===
                                      "LastModificationTime" ||
                                    field.fieldName === "DeletionTime" ||
                                    field.fieldName === "LastPublicationDate"
                                    ? formatDateWithZone(field.newValue, true)
                                    : field.fieldName === "DeleterId" ||
                                        field.fieldName === "LastModifierId" ||
                                        field.fieldName === "CreatorId"
                                      ? getUserName(field.newValue)
                                      : field.newValue
                                  : ""}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Tab>
          )}
        </Tabs>
      ) : (
        <GeneralTable />
      )}
    </S.AuditDetail>
  );
};

export default AuditingDetail;
