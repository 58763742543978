import styled from "styled-components/macro";

export const CreateAssetContainer = styled.div`
  margin: 0 15px;
  padding: 1rem 0;
  input[type="radio"],
  input[type="checkbox"] {
    cursor: pointer;
  }
  #idiomsContainer {
    margin-right: 1rem;
  }
`;
