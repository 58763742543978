import {
  disabledFalse,
  disabledTrue,
  hiddenFilter,
  hiddenTrue,
  portfolios,
} from "../../constants";

export const buildFormDataCreateProduct = (data) => {
  let formData = {};
  formData.defaultAssetId = data.defaultAssetId;
  formData.segmentationPrimary = data.segmentationPrimary;
  formData.segmentationSecondary = data.segmentationSecondary;
  formData.segmentationPrimaryType = data.segmentationPrimaryType;
  formData.segmentationSecondaryType = data.segmentationSecondaryType;
  return formData;
};

export const buildFormDataCreateProductParticulars = (data) => {
  let formData = {};
  if (!!data.defaultAssetId) {
    formData.defaultAssetId = data.defaultAssetId;
  } else {
    formData.defaultAssetId = null;
  }
  if (data.isPublishable) {
    formData.isPublishable = true;
  } else {
    formData.isPublishable = false;
  }

  return formData;
};

const notSuperAdmin = () => {
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  if (userRoles.filter((s) => s.name === "SUPERADMIN").length > 0) {
    return false;
  } else {
    return true;
  }
};

export const METADATA_CREACION_PRODUCTO = (showPublishable) => [
  {
    label: "Producto",
    collapsable: true,
    id: "product",
    metadata: [
      {
        label: "Activo Principal",
        name: "defaultAssetId",
        type: "list",
        typeList: "Normal",
        listName: "assets",
        propertyId: "id",
        propertyName: "name",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Publicado",
        name: "isPublishable",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: showPublishable ? hiddenFilter : hiddenTrue,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Suelo",
    id: "floor",
    collapsable: true,
    metadata: [
      {
        label: "Segmentación",
        name: "segmentation",
        type: "segmentation",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
];

export const METADATA_DETALLE_ACTIVO = [
  {
    label: "Datos Generales",
    collapsable: true,
    id: "generalData",
    metadata: [
      {
        label: "Id (interno)",
        name: "id",
        type: "text",
        disabled: disabledTrue,
        hidden: notSuperAdmin,
        languageSensitive: false,
      },
      {
        label: "Id Prínex",
        name: "prinexId",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Id Promoción Prínex",
        name: "promotionCode",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Nombre de Promoción Prínex",
        name: "promotionName",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Activo",
        name: "name",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Cartera",
        name: "portfolioId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appPortfolios",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Expediente",
        name: "dossierCode",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Línea Negocio",
        name: "businessLine",
        type: "textFromList",
        typeList: "Normal",
        listName: "businessLines",
        propertyList: "translation",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Tipo",
        name: "type",
        type: "textFromList",
        typeList: "Normal",
        listName: "assetType",
        propertyList: "translation",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Descripción activo",
        name: "description",
        type: "texteditor",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Estado Comercial",
        name: "commercialStatusId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appCommercialStatus",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Inmueble",
        name: "propertyCode",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Referencia anterior a otros sistemas",
        name: "previousSystemsReference",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Delegación",
        name: "delegationId",
        type: "textFromList",
        typeList: "Normal",
        listName: "delegationList",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
];
