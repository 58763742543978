import React, { useState, useEffect, Fragment } from "react";
import { Tab, Tabs, Card, Row, Col, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoader } from "../../components/Loading";
import { ModalTable } from "../../components/ModalTable";
import { TableBackEnd } from "../../components/TableBackEnd";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import {
  faPaperclip,
  faFileAlt,
  faDownload,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { orderAlphabetic } from "../../constants";
import { useDataContext } from "../../context/DataState";
import PageTitle from "../../components/PageTitle/PageTitle";
import { TableFrontEnd } from "../../components/TableFrontEnd";
import usePermition from "../../constants/usePermition";

export const CargasMasivas = () => {
  const [loader, showLoader, hideLoader] = useLoader();
  const [dataCampanas, setDataCampanas] = useState([]);
  const [dataPromociones, setDataPromociones] = useState([]);
  const [modalTableCampanas, setModalTableCampanas] = useState(false);
  const [modalTablePromociones, setModalTablePromociones] = useState(false);
  const [idsCampanas, setIdsCampanas] = useState([]);
  const [idsPromociones, setIdsPromociones] = useState([]);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [regsPage, setRegsPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [numRegisters, setNumRegisters] = useState(0);
  const [order, setOrder] = useState("");
  const [orderDesc, setOrderDesc] = useState(true);
  const navigate = useNavigate();
  const {
    dispatch,
    state: { appLists, user },
  } = useDataContext();
  const [file, setFile] = useState("");
  const [enqueued, setEnqueued] = useState([]);
  const [listsCargaMasiva, setListsCargaMasiva] = useState({});
  const { PERMISOS_POR_ROL } = usePermition({ user });

  const currentCartera = user?.currentPortfolioID;
  const listCargaMasiva = appLists?.bulkLoadTypes;

  useEffect(() => {
    if (PERMISOS_POR_ROL.CargasMasivas.Acceso) {
      document.title = "EC-Light-Cargas Masivas";
      getUserList();
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoader, hideLoader]);

  useEffect(() => {
    if (currentCartera) {
      const allData = listCargaMasiva["All"] || [];
      const carteraData = listCargaMasiva[currentCartera] || [];
      const combinedMap = new Map();

      allData.forEach((item) => {
        combinedMap.set(item.Id, item);
      });

      carteraData.forEach((item) => {
        combinedMap.set(item.Id, item);
      });

      const uniqueCombined = Array.from(combinedMap.values());

      const dataListCargaMasiva = appLists["bulkLoadTypes"];

      const uniqueOptionsCargaMasiva = new Map();

      for (const key in dataListCargaMasiva) {
        dataListCargaMasiva[key].forEach((option) => {
          if (!uniqueOptionsCargaMasiva.has(option.name)) {
            uniqueOptionsCargaMasiva.set(option.name, {
              Id: option.Id,
              name: option.translation,
            });
          }
        });
      }
      const allOptions = Array.from(uniqueOptionsCargaMasiva.values());

      setListsCargaMasiva({
        TipoCargaCartera: uniqueCombined,
        AllCarterasTypeOptions: allOptions,
      });
    }
  }, [appLists, currentCartera, listCargaMasiva]);

  const getUserList = () => {
    showLoader();
    API.obtenerUsuariosList()
      .then((res) => {
        let listas = appLists;
        listas.userList = orderAlphabetic(res.data, "name");
        dispatch({
          type: "UPDATE_APP_LIST",
          payload: listas,
        });
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const filterForm = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (
      <Form>
        <Form.Group className="mb-2">
          <span>
            <input
              className="form-control"
              style={{ width: "60%", marginTop: "0.25rem", padding: "0" }}
              value={filterValue || ""}
              onClick={(e) => {
                if (!e) e = window.event;
                e.stopPropagation();
              }}
              onChange={(e) => setFilter(e.target.value)}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            />
          </span>
        </Form.Group>
      </Form>
    );
  };

  const defaultColumn = {
    Filter: filterForm,
  };

  const defaultColumnEnqueued = {
    Filter: filterForm,
  };

  const defaultSort = [
    {
      id: "creationTime",
      desc: true,
    },
  ];

  const defaultSortEnqueued = [
    {
      id: "creationTime",
      desc: true,
    },
  ];

  const columns = [
    {
      Header: "",
      accessor: "select",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <S.ButtonDownload
          title="Descargar"
          style={{ outline: "none", border: "none", color: "black" }}
          onClick={() => descargaHistorico(cell.row.original.id)}
        >
          <FontAwesomeIcon icon={faDownload} size="lg" fixedWidth />
        </S.ButtonDownload>
      ),
    },
    {
      Header: "Id",
      accessor: "id",
      disableFilters: true,
    },
    {
      Header: "Tipo de Carga",
      accessor: "type",
      disableFilters: true,
    },
    {
      Header: "Documento",
      accessor: "fileName",
      disableFilters: true,
    },
    {
      Header: "Usuario",
      accessor: "creatorId",
      disableFilters: true,
    },
    {
      Header: "Fecha Creación",
      accessor: "creationTime",
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "Reg. insertados",
      accessor: "insertedRecords",
      disableFilters: true,
    },
    {
      Header: "Reg. actualizados",
      accessor: "updatedRecords",
      disableFilters: true,
    },
    {
      Header: "Reg. borrados",
      accessor: "deletedRecords",
      disableFilters: true,
    },
    {
      Header: "Reg. erróneos",
      accessor: "errorRecords",
      disableFilters: true,
    },
    {
      Header: "Reg. totales",
      accessor: "totalRecords",
      disableFilters: true,
    },
    {
      Header: "Estado",
      accessor: "status",
      disableFilters: true,
    },
  ];

  const defaultSortCampana = [
    {
      id: "id",
      desc: false,
    },
  ];

  const columnsCampana = [
    {
      Header: "",
      accessor: "select",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Form.Check
          onChange={(e) => {
            handleChange(
              parseInt(cell.row.original.id),
              e.target.checked,
              "AssetsCampaigns"
            );
          }}
        />
      ),
      maxWidth: 38,
    },
    {
      Header: "Id",
      accessor: "id",
      maxWidth: 60,
    },
    {
      Header: "Nombre",
      accessor: "name",
      maxWidth: 260,
    },
  ];

  const defaultSortPromocion = [
    {
      id: "id",
      desc: false,
    },
  ];

  const LOAD_TYPES = {
    0: {
      name: "Creación de productos",
    },
    1: {
      name: "Actualización activos-campañas",
    },
    2: {
      name: "Actualización activos-promociones",
    },
    3: {
      name: "Actualización de productos",
    },
    4: {
      name: "Activos detallados con productos",
    },
  };

  /**
   * Columnas de la tabla de cola de cargas
   */
  const columnsEnqueued = [
    {
      Header: "Nombre del fichero",
      accessor: "fileName",
      disableFilters: true,
    },
    {
      Header: "Progreso",
      accessor: "inProgress",
      disableFilters: true,
      Cell: ({ cell }) => (
        <Fragment>
          {cell.row.original.inProgress !== null
            ? !!cell.row.original.inProgress
              ? "En curso"
              : "En espera"
            : "Error"}
        </Fragment>
      ),
    },
    {
      Header: "Error",
      accessor: "errors",
      disableFilters: true,
    },
    {
      Header: "Fecha de creación",
      accessor: "creationTime",
      disableFilters: true,
      Cell: ({ cell }) => (
        <Fragment>{formatDate(cell.row.original.creationTime)}</Fragment>
      ),
    },
    {
      Header: "Tipo de carga",
      accessor: "type",
      disableFilters: true,
      Cell: ({ cell }) => (
        <Fragment>{LOAD_TYPES[cell.row.original.type]?.name}</Fragment>
      ),
    },
  ];

  const columnsPromocion = [
    {
      Header: "",
      accessor: "select",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Form.Check
          onChange={(e) => {
            handleChange(
              parseInt(cell.row.original.id),
              e.target.checked,
              "AssetsPromotions"
            );
          }}
        />
      ),
      maxWidth: 38,
    },
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Code",
      accessor: "code",
      maxWidth: 60,
    },
    {
      Header: "Promoción",
      accessor: "name",
      maxWidth: 260,
    },
  ];

  const handleChange = (id, value, type) => {
    if (type === "AssetsCampaigns") {
      if (value) {
        idsCampanas.push(id);
        setIdsCampanas(idsCampanas);
      } else {
        var ic = idsCampanas.indexOf(id);
        idsCampanas.splice(ic, 1);
        if (ic > -1) setIdsCampanas(idsCampanas);
      }
    } else if (type === "AssetsPromotions") {
      if (value) {
        idsPromociones.push(id);
        setIdsPromociones(idsPromociones);
      } else {
        var ip = idsPromociones.indexOf(id);
        idsPromociones.splice(ip, 1);
        if (ip > -1) setIdsPromociones(idsPromociones);
      }
    }
  };

  const handleChangeFilters = (prop, value) => {
    const tmpFilters = { ...filters };
    tmpFilters[prop] = value;
    setFilters(tmpFilters);
  };

  const generateTemplate = (type) => {
    switch (type) {
      case "ProductCreate":
        showLoader();
        API.generarPlantilla("Create/Products")
          .then((res) => {
            const url = URL.createObjectURL(
              new Blob([res.data], {
                type: res.headers["content-type"],
              })
            );
            const fileName = "Plantilla de creación de producto";
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(anchor.href);
          })
          .catch((error) => {
            if (error.code >= 400 && error.code < 500)
              toast.error(error.msg?.message);
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
        break;

      case "AssetCampaignUpdate":
        showLoader();
        setDataCampanas([]);
        API.obtenerCampanas()
          .then((res) => {
            setDataCampanas(res.data);
            setModalTableCampanas(true);
          })
          .catch((error) => {
            if (error.code >= 400 && error.code < 500)
              toast.error(error.msg?.message);
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
        break;

      case "AssetPromotionUpdate":
        showLoader();
        setDataPromociones([]);
        API.obtenerPromociones()
          .then((res) => {
            setDataPromociones(res.data);
            setModalTablePromociones(true);
          })
          .catch((error) => {
            if (error.code >= 400 && error.code < 500)
              toast.error(error.msg?.message);
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
        break;

      case "ProductUpdate":
        showLoader();
        API.generarPlantilla("Update/Products")
          .then((res) => {
            const url = URL.createObjectURL(
              new Blob([res.data], {
                type: res.headers["content-type"],
              })
            );
            const fileName = "Plantilla de actualización de producto";
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(anchor.href);
          })
          .catch((error) => {
            if (error.code >= 400 && error.code < 500)
              toast.error(error.msg?.message);
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
        break;

      case "AssetProductCreateThirdParties":
        showLoader();
        API.generarPlantilla("Create/Products/portfolios/car-terce")
          .then((res) => {
            const url = URL.createObjectURL(
              new Blob([res.data], {
                type: res.headers["content-type"],
              })
            );
            const fileName = "Plantilla Activos detallados con productos";
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(anchor.href);
          })
          .catch((error) => {
            if (error.code >= 400 && error.code < 500)
              toast.error(error.msg?.message);
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
        break;

      case "AssetUpdate":
        showLoader();
        API.generarPlantilla("Update/Assets")
          .then((res) => {
            const url = URL.createObjectURL(
              new Blob([res.data], {
                type: res.headers["content-type"],
              })
            );
            const fileName = "Plantilla de actualización de activos";
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(anchor.href);
          })
          .catch((error) => {
            if (error.code >= 400 && error.code < 500)
              toast.error(error.msg?.message);
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
        break;

      case "PromotionUpdate":
        showLoader();
        API.generarPlantilla("Update/Promotions")
          .then((res) => {
            const url = URL.createObjectURL(
              new Blob([res.data], {
                type: res.headers["content-type"],
              })
            );
            const fileName = "Plantilla de actualización de promociones";
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(anchor.href);
          })
          .catch((error) => {
            if (error.code >= 400 && error.code < 500)
              toast.error(error.msg?.message);
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
        break;

      default:
        break;
    }
  };

  const envioPlantillaCampanas = () => {
    showLoader();
    setModalTableCampanas(false);
    API.generarPlantilla("Update/AssetsCampaigns", idsCampanas)
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: res.headers["content-type"],
          })
        );

        let fileName = "";

        const disposition = res.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          let name = "";
          if (matches != null && matches[1]) {
            name = matches[1].replace(/(UTF-8)['"]{1,2}/g, "");
          }
          fileName = decodeURIComponent(name);
        }
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const envioPlantillaPromociones = () => {
    showLoader();
    setModalTablePromociones(false);
    API.generarPlantilla("Update/AssetsPromotions", idsPromociones)
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: res.headers["content-type"],
          })
        );

        let fileName = "";
        const disposition = res.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          let name = "";
          if (matches != null && matches[1]) {
            name = matches[1].replace(/(UTF-8)['"]{1,2}/g, "");
          }
          fileName = decodeURIComponent(name);
        }
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const getLoadService = (filterActionsType) => {
    switch (filterActionsType) {
      case "ProductCreate":
        return "Create/Products";

      case "AssetCampaignUpdate":
        return "Update/AssetsCampaigns";

      case "AssetPromotionUpdate":
        return "Update/AssetsPromotions";

      case "ProductUpdate":
        return "Update/Products";

      case "AssetProductCreateThirdParties":
        return "Create/Products/portfolios/car-terce";

      case "AssetUpdate":
        return "Update/Assets";

      case "PromotionUpdate":
        return "Update/Promotions";

      default:
        return null;
    }
  };

  const loadFile = () => {
    const file = document.getElementById("customFile").files[0];
    const loadService = getLoadService(filters.actionsType);
    if (file) {
      if (loadService) {
        showLoader();
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => {
          var formData = new FormData();
          formData.append("files", file);
          API.cargaPlantilla(loadService, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res) => {
              toast.success(
                "Se ha recibido su solicitud de carga masiva correctamente y será procesada en los próximos minutos. Revise el listado de cargas pendientes para comprobar el estado de la carga"
              );
            })
            .catch((error) => {
              if (error.code >= 400 && error.code < 500)
                toast.error(error.msg?.message);
              API.DEVELOP && console.log(error);
            })
            .finally(() => hideLoader());
        };
      }
    }
  };

  const descargaHistorico = (id) => {
    showLoader();
    API.descargaHistorico(id)
      .then((res) => {
        if (res) {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: res.headers["content-type"],
            })
          );
          const anchor = document.createElement("a");

          let fileName = "";

          const disposition = res.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            let name = "";
            if (matches != null && matches[1]) {
              name = matches[1].replace(/(UTF-8)['"]{1,2}/g, "");
            }
            fileName = decodeURIComponent(name);
          }
          anchor.href = url;
          anchor.download = fileName.split(".")[0];
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(anchor.href);
        } else {
          toast.error("El Archivo ya no existe");
        }
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const busquedaHistorico = () => {
    if (page > 0) {
      let data = {};
      let collection = [];
      let filtersAux = {};
      data.page = page;
      data.regsPage = regsPage;
      if (filters["type"] !== null && filters["type"] !== "")
        filtersAux["type"] = Number(filters["type"]);
      if (filters["creatorId"] !== null && filters["creatorId"] !== "")
        filtersAux["creatorId"] = filters["creatorId"];
      if (filters["status"] !== null && filters["status"] !== "")
        filtersAux["status"] = Number(filters["status"]);
      data.filters = filtersAux;
      if (order !== null && order !== "") {
        data.orderBy = order;
        data.isAsc = orderDesc;
      } else {
        data.orderBy = "creationTime";
        data.isAsc = false;
      }
      showLoader();
      API.busquedaHistorico(data)
        .then((res) => {
          res.data.collection.forEach((e) => {
            let collectionElement = {};
            collectionElement.id = e.id;
            const type = listCargaMasiva[currentCartera].find(
              (s) => s.Id === e.type
            );
            if (type !== undefined) {
              collectionElement.type = type.translation;
            }

            collectionElement.fileName = e.fileName;
            const creator = appLists["userList"].find(
              (s) => s.id === e.creatorId
            );
            if (creator !== undefined) {
              collectionElement.creatorId = creator.name;
            }

            collectionElement.creationTime = formatDate(e.creationTime);
            const status = appLists["bulkLoadStatus"].find(
              (s) => s.Id === e.status
            );
            if (status !== undefined) {
              collectionElement.status = status.translation;
            }
            collectionElement.deletedRecords = e.deletedRecords;
            collectionElement.errorRecords = e.errorRecords;
            collectionElement.insertedRecords = e.insertedRecords;
            collectionElement.totalRecords = e.totalRecords;
            collectionElement.updatedRecords = e.updatedRecords;
            collection.push(collectionElement);
          });
          setData(collection);
          setTotalPages(res.data.maxPages);
          setNumRegisters(res.data.numRegisters);
        })
        .catch((error) => API.DEVELOP && console.log(error))
        .finally(() => hideLoader());
    }
  };

  const cleanFilters = () => {
    document.getElementById("type").value = "";
    document.getElementById("creatorId").value = "";
    document.getElementById("status").value = "";
    setFilters({});
  };

  const formatDate = (dateString) => {
    var date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() + -offset * 60000);
    let d = date;
    d = [
      "0" + d.getDate(),
      "0" + (d.getMonth() + 1),
      "" + d.getFullYear(),
      "0" + d.getHours(),
      "0" + d.getMinutes(),
      "0" + d.getSeconds(),
    ].map((component) => {
      if (component.length < 4) {
        return component.slice(-2);
      } else {
        return component;
      }
    });

    return d.slice(0, 3).join("/") + " " + d.slice(3).join(":");
  };

  const search = () => {
    busquedaHistorico();
  };

  const handleSelect = (key) => {
    if (key === "historico") {
      if (!appLists.userList) {
        getUserList();
      }
    } else if (key === "pendiente") {
      getEnqueuedByUser();
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.value);
  };

  const getEnqueuedByUser = () => {
    showLoader();
    API.getEnqueuedUploads()
      .then((res) => {
        setEnqueued(res.data);
      })
      .catch((e) => console.error(e))
      .finally(() => hideLoader());
  };

  return (
    <S.DetailPage>
      <PageTitle title="Cargas Masivas" />
      {loader}
      {modalTableCampanas && (
        <ModalTable
          message="Seleccionar id de campañas"
          columns={columnsCampana}
          data={dataCampanas}
          defaultSort={defaultSortCampana}
          defaultColumn={defaultColumn}
          hiddenColumns={[]}
          labelConfirm="Confirmar"
          cancel={() => setModalTableCampanas(false)}
          confirm={() => envioPlantillaCampanas()}
          pagination={false}
        />
      )}
      {modalTablePromociones && (
        <ModalTable
          message="Seleccionar id de promociones"
          columns={columnsPromocion}
          data={dataPromociones}
          defaultSort={defaultSortPromocion}
          defaultColumn={defaultColumn}
          hiddenColumns={["id"]}
          labelConfirm="Confirmar"
          cancel={() => setModalTablePromociones(false)}
          confirm={() => envioPlantillaPromociones()}
          pagination={false}
        />
      )}
      <Row style={{ marginTop: 20 }}>
        <Col>
          <Tabs
            defaultActiveKey="acciones"
            id="uncontrolled-tab-example"
            className="mb-3"
            fill
            onSelect={handleSelect}
          >
            <Tab eventKey="acciones" title="Acciones">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs lg="4">
                      <Form.Select
                        id="tipoCarga"
                        value={filters.actionsType || ""}
                        onChange={(e) =>
                          handleChangeFilters("actionsType", e.target.value)
                        }
                      >
                        <option value="" disabled>
                          Seleccione tipo de carga
                        </option>
                        {appLists["bulkLoadTypes"] !== undefined &&
                          listsCargaMasiva?.TipoCargaCartera?.filter((e) =>
                            e.name === "AssetCampaignUpdate" ||
                            e.name === "AssetPromotionUpdate"
                              ? PERMISOS_POR_ROL.CargasMasivas.AsociarActivos
                              : true
                          ).map((e) => (
                            <option value={e.name} key={e.name}>
                              {e.translation}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <Form.Control
                        type="file"
                        id="customFile"
                        placeholder="Seleccionar fichero (max 25.000 registros)"
                        onChange={handleFileChange}
                        hidden={!PERMISOS_POR_ROL.CargasMasivas.Upload}
                      />
                    </Col>
                    <Col>
                      <Button
                        onClick={loadFile}
                        style={{ width: "100%" }}
                        variant="success"
                        hidden={!PERMISOS_POR_ROL.CargasMasivas.Upload}
                        disabled={!file || !filters.actionsType}
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faPaperclip}
                            size="lg"
                            fixedWidth
                          />
                        </span>
                        Adjuntar Fichero
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        style={{ width: "100%" }}
                        variant="success"
                        onClick={() => generateTemplate(filters.actionsType)}
                        disabled={!filters.actionsType}
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faFileAlt}
                            size="lg"
                            fixedWidth
                          />
                        </span>
                        Generar Plantilla
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Tab>
            <Tab
              eventKey="pendiente"
              title="Últimas cargas"
              onClick={getEnqueuedByUser}
            >
              <Card>
                <Card.Header>
                  <PageTitle title="Últimas cargas" />
                </Card.Header>
                <Card.Body>
                  <TableFrontEnd
                    columns={columnsEnqueued}
                    data={enqueued}
                    defaultSort={defaultSortEnqueued}
                    defaultColumn={defaultColumnEnqueued}
                    hiddenColumns={[]}
                    pagination={true}
                  />
                </Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="historico" title="Histórico">
              <Card>
                <Card.Header>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Select
                          id="type"
                          value={filters.type || ""}
                          onChange={(e) =>
                            handleChangeFilters("type", e.target.value)
                          }
                        >
                          <option value="" disabled>
                            Tipo
                          </option>
                          {appLists["bulkLoadTypes"] !== undefined &&
                            listsCargaMasiva?.AllCarterasTypeOptions?.map(
                              (e) => (
                                <option value={e.Id} key={e.Id}>
                                  {e.name}
                                </option>
                              )
                            )}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Select
                          id="creatorId"
                          onChange={(e) =>
                            handleChangeFilters("creatorId", e.target.value)
                          }
                        >
                          <option value="">Usuario</option>
                          {appLists["userList"] !== undefined &&
                            appLists["userList"].map((e, i) => (
                              <option value={e.id} key={i}>
                                {e.name}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Select
                          id="status"
                          onChange={(e) =>
                            handleChangeFilters("status", e.target.value)
                          }
                        >
                          <option value="">Estado</option>
                          {appLists["bulkLoadStatus"] !== undefined &&
                            appLists["bulkLoadStatus"].map((e, i) => (
                              <option value={e.Id} key={i}>
                                {e.translation}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        onClick={() => cleanFilters()}
                        style={{ marginRight: "1rem" }}
                        variant="danger"
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="lg"
                            fixedWidth
                          />
                        </span>
                        Limpiar Filtros
                      </Button>
                      <Button
                        onClick={() => {
                          setPage(1);
                          search();
                        }}
                        variant="success"
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faSearch}
                            size="lg"
                            fixedWidth
                          />
                        </span>
                        Buscar
                      </Button>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <TableBackEnd
                    columns={columns}
                    data={data}
                    defaultSort={defaultSort}
                    hiddenColumns={["id"]}
                    pageTable={page}
                    regsPage={regsPage}
                    setPage={setPage}
                    setRegsPage={setRegsPage}
                    search={search}
                    totalPages={totalPages}
                    numRegisters={numRegisters}
                    order={order}
                    setOrder={setOrder}
                    orderDesc={orderDesc}
                    setOrderDesc={setOrderDesc}
                    heightED={"48vh"}
                  />
                </Card.Body>
              </Card>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </S.DetailPage>
  );
};
