import React, { useState, useEffect, Fragment } from "react";
import { TableFrontEnd } from "../../components/TableFrontEnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "../../components/Dialog";
import { ModalForm } from "../../components/ModalForm";
import { useLoader } from "../../components/Loading";
import {
  faTrashAlt,
  faEdit,
  faPlus,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import * as S from "./styles";
import * as API from "../../api";
import {
  METADATA_EDICION_TERRITORIAL,
  METADATA_CREACION_TERRITORIAL,
  METADATA_AUDIT_TERRITORIAL,
} from "./constants";
import { ModalAudit } from "../../components/ModalAudit";
import { useDataContext } from "../../context/DataState";
import { CardHeaderContainer } from "../Buscador/styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePermition from "../../constants/usePermition";

export const Territoriales = () => {
  const [values, setValues] = useState({});
  const [valuesAudit, setValuesAudit] = useState({});
  const [dialogEditarTerritorialVisible, setDialogEditarTerritorialVisible] =
    useState(false);
  const [dialogCrearTerritorialVisible, setDialogCrearTerritorialVisible] =
    useState(false);
  const [dialogBorrarTerritorialVisible, setDialogBorrarTerritorialVisible] =
    useState(false);
  const [dialogAuditTerritorialVisible, setDialogAuditTerritorialVisible] =
    useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [users, setUsers] = useState([]);
  const [valueDelete, setValueDelete] = useState("");
  const {
    state: { appLists, user },
  } = useDataContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    document.title = "EC-Light-Territoriales";
    if (!PERMISOS_POR_ROL.Territoriales.Acceso) {
      navigate("/");
    }
    if (!data.length) {
      showLoader();
      API.obtenerTerritoriales()
        .then((res) => {
          let dataAuxList = [];
          let listas = appLists;
          res.data.forEach((e) => {
            const dataAux = {};
            dataAux.id = e.id;
            dataAux.crmId = e.crmId;
            dataAux.name = e.name;
            dataAux.numDelegations = e.numDelegations;
            const portfolio = listas.appPortfolios.find(
              (s) => s.id === e.portfolioId
            );
            if (portfolio !== null) {
              dataAux.portfolioId = portfolio.id;
              dataAux.portfolioName = portfolio.name;
            }
            dataAux.creatorId = e.creatorId;
            if (e.creationTime !== null) dataAux.creationTime = e.creationTime;
            dataAux.lastModifierId = e.lastModifierId;
            if (e.lastModificationTime !== null)
              dataAux.lastModificationTime = formatDate(e.lastModificationTime);
            dataAuxList.push(dataAux);
          });
          setData(dataAuxList);
          setFilteredData(dataAuxList);
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
      showLoader();
      API.obtenerUsuariosList()
        .then((res) => {
          setUsers(res.data);
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    }
  }, []);

  const formatDate = (dateString) => {
    var date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() + -offset * 60000);
    let d = date;
    d = [
      "0" + d.getDate(),
      "0" + (d.getMonth() + 1),
      "" + d.getFullYear(),
      "0" + d.getHours(),
      "0" + d.getMinutes(),
      "0" + d.getSeconds(),
    ].map((component) => {
      if (component.length < 4) {
        return component.slice(-2);
      } else {
        return component;
      }
    });

    return d.slice(0, 3).join("/") + " " + d.slice(3).join(":");
  };

  const FilterForm = ({ column }) => {
    const { filterValue, setFilter } = column;

    useEffect(() => {
      const value = searchParams.get(
        "filter" + column.Header.split(" ").join("")
      );
      if (value) {
        setFilter(value);
      }
    }, []);

    return (
      <Form>
        <Form.Group className="mb-2">
          <span>
            <input
              id={"filter" + column.Header.split(" ").join("")}
              className="form-control"
              style={{ width: "60%", marginTop: "0.25rem", padding: "0" }}
              value={filterValue || ""}
              onClick={(e) => {
                if (!e) e = window.event;
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => setFilter(e.target.value)}
              autoComplete="off"
            />
          </span>
        </Form.Group>
      </Form>
    );
  };

  const defaultColumn = {
    // Let's set up our default Filter UI
    Filter: FilterForm,
  };

  const defaultSort = [
    {
      id: "id",
      desc: false,
    },
  ];

  const columns = [
    {
      Header: "",
      accessor: "edit",
      disableFilters: true,
      disableSortBy: true,
      width: 60,
      Cell: ({ cell }) => (
        <Fragment>
          <button
            className="tableButton"
            onClick={() => showModalEdit(cell)}
            hidden={!PERMISOS_POR_ROL.Territoriales.Crud}
          >
            <span title="Editar">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEdit}
                size="lg"
                fixedWidth
              />
            </span>
          </button>
          <button
            style={{ marginLeft: "0.5rem" }}
            className="tableButton"
            onClick={() => showModalAudit(cell)}
            hidden={!PERMISOS_POR_ROL.Territoriales.Auditoria}
          >
            <span title="Auditoría">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faPrint}
                size="lg"
                fixedWidth
              />
            </span>
          </button>
        </Fragment>
      ),
    },
    {
      Header: "Id Territorial",
      accessor: "id",
      Filter: FilterForm,
    },
    {
      Header: "Territorial",
      accessor: "name",
      Filter: FilterForm,
    },
    {
      Header: "Cartera",
      accessor: "portfolioId",
    },
    {
      Header: "Cartera",
      accessor: "portfolioName",
      Filter: FilterForm,
    },
    {
      Header: "Nº delegaciones",
      accessor: "numDelegations",
      Filter: FilterForm,
    },
    {
      Header: "CRM ID",
      accessor: "crmId",
    },
    {
      Header: "Usuario Creación",
      accessor: "creatorId",
    },
    {
      Header: "Fecha Creación",
      accessor: "creationTime",
      disableFilters: true,
      Cell: ({ cell }) => (
        <span>{formatDate(cell.row.original.creationTime)}</span>
      ),
    },
    {
      Header: "Usuario Última Modificación",
      accessor: "lastModifierId",
    },
    {
      Header: "Fecha Última Modificación",
      accessor: "lastModificationTime",
    },
    {
      Header: "",
      accessor: "delete",
      disableFilters: true,
      disableSortBy: true,
      width: 50,
      Cell: ({ cell }) => (
        <button
          className="tableButton"
          onClick={() => showModalDelete(cell.row.original.id)}
        >
          <span title="Borrar">
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={faTrashAlt}
              size="lg"
              fixedWidth
            />
          </span>
        </button>
      ),
    },
  ];

  const showModalCreate = () => {
    setValues({});
    setDialogCrearTerritorialVisible(true);
  };

  const showModalEdit = (cell) => {
    setValues(cell.row.values);
    setDialogEditarTerritorialVisible(true);
  };

  const showModalDelete = (id) => {
    setValueDelete(id);
    setDialogBorrarTerritorialVisible(true);
  };

  const showModalAudit = (cell) => {
    let cellValues = { ...cell.row.values };
    const creator = users.find((s) => s.id === cellValues.creatorId);
    if (creator !== undefined) {
      cellValues.creatorId = creator.name;
    }
    const modifier = users.find((s) => s.id === cellValues.lastModifierId);
    if (modifier !== undefined) {
      cellValues.lastModifierId = modifier.name;
    }
    if (!!cellValues.creationTime) {
      cellValues.creationTime = formatDate(cellValues.creationTime);
    }

    const valueIdTerritorial = document.getElementById(
      "filterIdTerritorial"
    ).value;
    const valueTerritorial = document.getElementById("filterTerritorial").value;
    const valueCartera = document.getElementById("filterCartera").value;

    const url = `/view-territorials?${
      valueIdTerritorial !== ""
        ? `filterIdTerritorial=${valueIdTerritorial}`
        : ""
    }${
      valueTerritorial !== ""
        ? valueIdTerritorial !== ""
          ? `&filterTerritorial=${valueTerritorial}`
          : `filterTerritorial=${valueTerritorial}`
        : ""
    }${
      valueCartera !== ""
        ? `${
            valueIdTerritorial !== "" || valueTerritorial !== ""
              ? `&filterCartera=${valueCartera}`
              : `filterCartera=${valueCartera}`
          }`
        : ""
    }`;

    navigate(url);

    setValuesAudit(cellValues);
    setDialogAuditTerritorialVisible(true);
  };

  /**
   * Modificar territorial y actualizar la data correspondiente
   * @param {*} values
   */
  const confirmEdit = (values) => {
    showLoader();
    setDialogEditarTerritorialVisible(false);
    API.editarTerritorial(values)
      .then((res) => {
        let dataAuxList = [...data];
        const dataAux = dataAuxList.find(
          (element) => element.id === res.data.id
        );
        dataAux.crmId = res.data.crmId;
        dataAux.name = res.data.name;
        if (res.data.numDelegations)
          dataAux.numDelegations = res.data.numDelegations;
        const portfolio = appLists["appPortfolios"].find(
          (s) => s.id === res.data.portfolioId
        );
        if (portfolio !== null) {
          dataAux.portfolioId = portfolio.id;
          dataAux.portfolioName = portfolio.name;
        }
        dataAux.creatorId = res.data.creatorId;
        if (res.data.creationTime !== null)
          dataAux.creationTime = res.data.creationTime;
        dataAux.lastModifierId = res.data.lastModifierId;
        if (res.data.lastModificationTime !== null)
          dataAux.lastModificationTime = formatDate(
            res.data.lastModificationTime
          );
        setData(dataAuxList);

        const idTd = document.querySelector(`td[title="${res.data.id}"]`);
        const siblingName = idTd.parentElement.childNodes[2];
        siblingName.setAttribute("title", dataAux.name);

        toast.success("Territorial editada correctamente");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const confirmCreate = (values) => {
    showLoader();
    setDialogCrearTerritorialVisible(false);
    API.crearTerritorial(values)
      .then((res) => {
        let dataAux = res.data;
        dataAux.numDelegations = 0;
        const portfolio = appLists["appPortfolios"].find(
          (s) => s.id === res.data.portfolioId
        );
        if (portfolio !== null) {
          dataAux.portfolioId = portfolio.id;
          dataAux.portfolioName = portfolio.name;
        }
        if (res.data.lastModificationTime !== null)
          dataAux.lastModificationTime = formatDate(res.lastModificationTime);

        setData([...data, dataAux]);
        setFilteredData([...filteredData, dataAux]);
        toast.success("Territorial creada correctamente");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const confirmDelete = (id) => {
    showLoader();
    setDialogBorrarTerritorialVisible(false);
    API.borrarTerritorial(id)
      .then((res) => {
        const td = document.querySelector(`td[title="${id}"]`);
        const row = td.parentElement;
        row.remove();

        const auxData = data.filter((e) => e.id !== id);
        setData(auxData);

        toast.success("Territorial borrada correctamente");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <S.Container>
      {loader}
      {dialogEditarTerritorialVisible && (
        <ModalForm
          metadata={METADATA_EDICION_TERRITORIAL}
          message="Datos de Territorial"
          values={values}
          setValues={setValues}
          cancel={() => setDialogEditarTerritorialVisible(false)}
          confirm={confirmEdit}
        />
      )}
      {dialogCrearTerritorialVisible && (
        <ModalForm
          metadata={METADATA_CREACION_TERRITORIAL}
          message="Datos de Territorial"
          values={values}
          setValues={setValues}
          cancel={() => setDialogCrearTerritorialVisible(false)}
          confirm={confirmCreate}
        />
      )}
      {dialogBorrarTerritorialVisible && (
        <Dialog
          type="noFooter"
          message="¿Desea eliminar la territorial?"
          labelConfirm="Eliminar"
          confirm={confirmDelete}
          cancel={() => setDialogBorrarTerritorialVisible(false)}
          value={valueDelete}
        />
      )}
      {dialogAuditTerritorialVisible && (
        <ModalAudit
          metadata={METADATA_AUDIT_TERRITORIAL}
          message="Auditoría"
          values={valuesAudit}
          cancel={() => setDialogAuditTerritorialVisible(false)}
          entityName="Territorial"
        />
      )}
      <Card>
        <Card.Header
          style={{
            paddingLeft: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <CardHeaderContainer>
            <PageTitle title="Territoriales" />
            <Button
              variant="success"
              onClick={() => showModalCreate()}
              hidden={!PERMISOS_POR_ROL.Territoriales.Crud}
            >
              <span>
                <FontAwesomeIcon icon={faPlus} size="lg" fixedWidth />
              </span>
              Crear Territorial
            </Button>
          </CardHeaderContainer>
        </Card.Header>
        <Card.Body>
          <TableFrontEnd
            columns={columns}
            data={filteredData}
            defaultSort={defaultSort}
            defaultColumn={defaultColumn}
            hiddenColumns={
              PERMISOS_POR_ROL.Territoriales.Crud
                ? [
                    "crmId",
                    "portfolioId",
                    "creatorId",
                    "creationTime",
                    "lastModifierId",
                    "lastModificationTime",
                  ]
                : [
                    "crmId",
                    "portfolioId",
                    "creatorId",
                    "creationTime",
                    "lastModifierId",
                    "lastModificationTime",
                    "delete",
                  ]
            }
            pagination={true}
          />
        </Card.Body>
      </Card>
    </S.Container>
  );
};
