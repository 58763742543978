import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Title } from "../DetalleActivoSinProducto/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPrint,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { CreateAssetContainer } from "./styles";
import useCreateAsset from "./useCreateAsset";
import { CardForm } from "../../components/CardForm";
import SelectLanguage from "../../components/SelectLanguage/SelectLanguage";

const ParticularAsset = () => {
  const {
    goBack,
    hasDataChanged,
    setValueFilter,
    valueFilter,
    language,
    onLanguageChange,
    handleSubmit,
    validated,
    MDA1,
    MDA2,
    handleDataChange,
    data,
    appLists,
    loader,
    id,
    handleAuditLink,
    PERMISOS_POR_ROL,
  } = useCreateAsset();

  return (
    <CreateAssetContainer>
      {loader}
      <Row>
        <Col>
          {!!id ? (
            <Title>Activo: {data.prinexId}</Title>
          ) : (
            <Title>Nuevo Activo</Title>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col xs={1}>
                  <Button variant="danger" onClick={goBack}>
                    <span>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        size="lg"
                        fixedWidth
                      />
                    </span>
                  </Button>
                </Col>
                <Col xs={3}>
                  <Form.Control
                    type="text"
                    placeholder="Filtrar campos"
                    onChange={(e) => {
                      setValueFilter(e.target.value);
                    }}
                  />
                </Col>
                <Col
                  xs={8}
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <div
                    key={`inline-radio`}
                    className="mb-1 mt-1"
                    style={{ display: "contents" }}
                  >
                    <div
                      id={
                        PERMISOS_POR_ROL.DetalleActivoParticular.Auditoria ||
                        PERMISOS_POR_ROL.DetalleActivoParticular.Guardar
                          ? "idiomsContainer"
                          : ""
                      }
                    >
                      <SelectLanguage
                        language={language}
                        onLanguageChange={onLanguageChange}
                      />
                    </div>
                    <Button
                      style={{ marginRight: "1rem" }}
                      variant="success"
                      hidden={
                        !PERMISOS_POR_ROL.DetalleActivoParticular.Auditoria ||
                        !id
                      }
                      onClick={handleAuditLink}
                    >
                      <span>
                        <FontAwesomeIcon icon={faPrint} size="lg" fixedWidth />
                      </span>
                      Auditoría
                    </Button>
                    <Button
                      type="submit"
                      form="editAsset"
                      variant="success"
                      disabled={!hasDataChanged}
                      hidden={!PERMISOS_POR_ROL.DetalleActivoParticular.Guardar}
                    >
                      <span>
                        <FontAwesomeIcon icon={faSave} size="lg" fixedWidth />
                      </span>
                      Guardar
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Form
                noValidate
                id="editAsset"
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col>
                    {MDA1.map((field, i) => (
                      <CardForm
                        key={i}
                        metadata={field.metadata}
                        label={field.label}
                        collapsable={field.collapsable}
                        filter={valueFilter}
                        language={language}
                        languageSensitive={field.languageSensitive}
                        lists={appLists}
                        data={data}
                        setData={handleDataChange}
                      />
                    ))}
                  </Col>
                  <Col>
                    {MDA2.map((field, i) => (
                      <CardForm
                        key={i}
                        metadata={field.metadata}
                        label={field.label}
                        collapsable={field.collapsable}
                        filter={valueFilter}
                        language={language}
                        languageSensitive={field.languageSensitive}
                        lists={appLists}
                        data={data}
                        setData={handleDataChange}
                      />
                    ))}
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </CreateAssetContainer>
  );
};

export default ParticularAsset;
