import React from "react";
import * as S from "./styles";
import { Card, Accordion, Row, Col } from "react-bootstrap";
import { InputFormText } from "../../components/InputFormText";
import { InputFormTextArea } from "../../components/InputFormTextArea";
import { InputFormCheckBox } from "../../components/InputFormCheckBox";
import { InputFormList } from "../InputFormList";
import { InputFormDate } from "../InputFormDate";
import { CarouselField } from "../CarouselField";
import { InputFormTextEditor } from "../InputFormTextEditor";
import { InputFormNumber } from "../InputFormNumber";
import { InputFormUrl } from "../InputFormUrl";
import { InputFormTextFromList } from "../InputFormTextFromList";
import { InputFormTextAreaFromList } from "../InputFormTextAreaFromList";
import { InputFormSegmentation } from "../InputFormSegmentation";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useDataContext } from "../../context/DataState";
import InputFormSampleList from "../InputFormSampleList/InputFormSampleList";
import InputFormLink from "../InputFormLink/InputFormLink";
import { disabledTrue, formatDateWithZone } from "../../constants";

export const CardForm = ({
  label,
  metadata,
  collapsable,
  filter,
  language,
  lists,
  data,
  setData,
}) => {
  const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
  const {
    state: { user },
  } = useDataContext();

  const mapStyle = {
    width: "100%",
    height: "20rem",
    marginTop: "1rem",
    marginBottom: "1rem",
  };

  const formatDate = (date) => {
    let response = "";
    if (!!date && date !== "0001-01-01T00:00:00") {
      response = formatDateWithZone(date).split(" ")[0];
    }
    return response;
  };

  const formatDatePicker = (date) => {
    if (!!date) {
      return date.split("T")[0];
    } else {
      return "";
    }
  };

  const formatDateText = (date) => {
    let response = "";
    if (!!date && date !== "0001-01-01T00:00:00") {
      response = formatDateWithZone(date);
    }
    return response;
  };

  const getValue = (name, language, languageSensitive, ESisIncluded) => {
    if (languageSensitive && !(!ESisIncluded && language === "ES")) {
      return data[name + language];
    } else {
      return data[name];
    }
  };

  const getLabel = (label, name, languageSensitive) => {
    const userRoles = user.roles;
    if (userRoles.filter((s) => s.name === "SUPERADMIN").length > 0) {
      return (
        label +
        (!!languageSensitive ? ` [${language}]` : "") +
        " (" +
        name +
        ") "
      );
    } else {
      const wholeLabel = `${label} ${languageSensitive ? `[${language}]` : ""}`;
      return wholeLabel;
    }
  };

  const getId = (languageSensitive, ESisIncluded, language, name) => {
    if (!languageSensitive) {
      return name;
    } else {
      if (!ESisIncluded && language === "ES") {
        return name;
      } else {
        return name + language;
      }
    }
  };

  const handleChange = (prop, value) => {
    const tmpData = { ...data };
    if (prop === "segmentationPrimary") {
      delete tmpData.segmentationPrimaryType;
    }
    tmpData[prop] = value;
    setData(tmpData);
  };

  const handleChangeCheckBox = (prop, value) => {
    const tmpData = { ...data };
    if (value) {
      tmpData[prop] = true;
    } else {
      tmpData[prop] = false;
    }
    setData(tmpData, prop);
  };

  return (
    <Accordion defaultActiveKey={collapsable ? "" : "0"}>
      <Accordion.Item eventkey="0">
        <Card>
          {collapsable && (
            <S.AccordionCollapsableWrapper>
              <Accordion.Header>{label}</Accordion.Header>
            </S.AccordionCollapsableWrapper>
          )}
          {!collapsable && <Card.Header>{label}</Card.Header>}
          <Accordion.Body eventkey="0">
            <Card.Body>
              <Row>
                {metadata.map((field, index) => {
                  switch (field.type) {
                    case "text":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormText
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            placeholder={field.placeholder}
                            disabled={field.disabled}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            required={field.required}
                            maxLength={field.maxLength}
                            value={getValue(
                              field.name,
                              language,
                              field.languageSensitive,
                              field.ESisIncluded
                            )}
                            handleChange={handleChange}
                            addon={field.addon}
                            tooltip={field.tooltip}
                            numberSeparator={field.numberSeparator}
                          />
                        </Col>
                      );
                    case "dateWithoutMinutes":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormText
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            placeholder={field.placeholder}
                            disabled={disabledTrue}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            required={field.required}
                            maxLength={field.maxLength}
                            value={formatDate(
                              getValue(
                                field.name,
                                language,
                                field.languageSensitive,
                                field.ESisIncluded
                              )
                            )}
                            handleChange={handleChange}
                            addon={field.addon}
                            tooltip={field.tooltip}
                            numberSeparator={field.numberSeparator}
                          />
                        </Col>
                      );
                    case "dateText":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormText
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            placeholder={field.placeholder}
                            disabled={field.disabled}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            required={field.required}
                            maxLength={field.maxLength}
                            value={formatDateText(
                              getValue(
                                field.name,
                                language,
                                field.languageSensitive,
                                field.ESisIncluded
                              )
                            )}
                            handleChange={handleChange}
                            addon={field.addon}
                            tooltip={field.tooltip}
                            numberSeparator={field.numberSeparator}
                          />
                        </Col>
                      );
                    case "textarea":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormTextArea
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            placeholder={field.placeholder}
                            disabled={field.disabled}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            required={field.required}
                            maxLength={field.maxLength}
                            value={getValue(
                              field.name,
                              language,
                              field.languageSensitive,
                              field.ESisIncluded
                            )}
                            handleChange={handleChange}
                          />
                        </Col>
                      );
                    case "texteditor":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormTextEditor
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            placeholder={field.placeholder}
                            disabled={field.disabled}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            required={field.required}
                            maxLength={field.maxLength}
                            value={getValue(
                              field.name,
                              language,
                              field.languageSensitive,
                              field.ESisIncluded
                            )}
                            data={data}
                            setData={setData}
                            handleChange={handleChange}
                          />
                        </Col>
                      );
                    case "number":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormNumber
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            placeholder={field.placeholder}
                            disabled={field.disabled}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            required={field.required}
                            min={field.min}
                            step={field.step}
                            value={
                              getValue(
                                field.name,
                                language,
                                field.languageSensitive,
                                field.ESisIncluded
                              ) || ""
                            }
                            handleChange={handleChange}
                            addon={field.addon}
                          />
                        </Col>
                      );
                    case "url":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormUrl
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            placeholder={field.placeholder}
                            disabled={field.disabled}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            required={field.required}
                            value={getValue(
                              field.name,
                              language,
                              field.languageSensitive,
                              field.ESisIncluded
                            )}
                            handleChange={handleChange}
                          />
                        </Col>
                      );
                    case "checkbox":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormCheckBox
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            disabled={field.disabled}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            required={field.required}
                            value={getValue(
                              field.name,
                              language,
                              field.languageSensitive,
                              field.ESisIncluded
                            )}
                            handleChange={handleChangeCheckBox}
                          />
                        </Col>
                      );
                    case "list":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormList
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            placeholder={field.placeholder}
                            disabled={field.disabled}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            list={lists[field.listName]}
                            propertyId={field.propertyId}
                            propertyName={field.propertyName}
                            required={field.required}
                            value={getValue(
                              field.name,
                              language,
                              field.languageSensitive,
                              field.ESisIncluded
                            )}
                            handleChange={handleChange}
                            isInvalid={field.isInvalid}
                          />
                        </Col>
                      );
                    case "sampleList":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormSampleList
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            placeholder={field.placeholder}
                            disabled={field.disabled}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            list={lists[field.listName]}
                            required={field.required}
                            value={getValue(
                              field.name,
                              language,
                              field.languageSensitive,
                              field.ESisIncluded
                            )}
                            handleChange={handleChange}
                          />
                        </Col>
                      );
                    case "segmentation":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormSegmentation
                            key={index}
                            disabled={field.disabled}
                            hidden={field.hidden}
                            filter={filter}
                            list={lists["segmentation"]}
                            required={field.required}
                            segmentationPrimary={getValue(
                              "segmentationPrimary",
                              field.languageSensitive
                            )}
                            segmentationPrimaryType={getValue(
                              "segmentationPrimaryType",
                              field.languageSensitive
                            )}
                            segmentationSecondary={getValue(
                              "segmentationSecondary",
                              field.languageSensitive
                            )}
                            segmentationSecondaryType={getValue(
                              "segmentationSecondaryType",
                              field.languageSensitive
                            )}
                            handleChange={handleChange}
                          />
                        </Col>
                      );
                    case "navigate":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormLink
                            key={index}
                            hidden={field.hidden}
                            filter={filter}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            showId={data[field.showId]}
                            navigateId={data[field.navigateId]}
                          />
                        </Col>
                      );
                    case "textFromList":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormTextFromList
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            placeholder={field.placeholder}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            list={lists[field.listName]}
                            propertyList={field.propertyList}
                            value={getValue(
                              field.name,
                              language,
                              field.languageSensitive,
                              field.ESisIncluded
                            )}
                          />
                        </Col>
                      );
                    case "textAreaFromList":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormTextAreaFromList
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              )
                            )}
                            placeholder={field.placeholder}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            list={lists[field.listName]}
                            propertyList={field.propertyList}
                            value={getValue(
                              field.name,
                              language,
                              field.languageSensitive,
                              field.ESisIncluded
                            )}
                          />
                        </Col>
                      );
                    case "date":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <InputFormDate
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            placeholder={field.placeholder}
                            disabled={field.disabled}
                            hidden={field.hidden}
                            filter={filter}
                            language={language}
                            languageSensitive={field.languageSensitive}
                            required={field.required}
                            value={formatDatePicker(
                              getValue(
                                field.name,
                                language,
                                field.languageSensitive,
                                field.ESisIncluded
                              )
                            )}
                            handleChange={handleChange}
                            min={field.min}
                          />
                        </Col>
                      );
                    case "carousel":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <CarouselField
                            key={index}
                            id={getId(
                              field.languageSensitive,
                              field.ESisIncluded,
                              language,
                              field.name
                            )}
                            label={getLabel(
                              field.label,
                              getId(
                                field.languageSensitive,
                                field.ESisIncluded,
                                language,
                                field.name
                              ),
                              field.languageSensitive
                            )}
                            hidden={field.hidden}
                            filter={filter}
                            listImages={data[field.name] || []}
                          />
                        </Col>
                      );
                    case "map":
                      return (
                        <Col xs={12} md={12} key={index}>
                          <LoadScript
                            googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
                            key={index}
                          >
                            <GoogleMap
                              mapContainerStyle={mapStyle}
                              options={{
                                fullscreenControl: false,
                                streetViewControl: true,
                              }}
                              center={{
                                lat: data[field.lat]
                                  ? parseFloat(
                                      data[field.lat].replace(/,/, ".")
                                    )
                                  : 0,
                                lng: data[field.lng]
                                  ? parseFloat(
                                      data[field.lng].replace(/,/, ".")
                                    )
                                  : 0,
                              }}
                              zoom={16}
                            >
                              <Marker
                                position={{
                                  lat: data[field.lat]
                                    ? parseFloat(
                                        data[field.lat].replace(/,/, ".")
                                      )
                                    : 0,
                                  lng: data[field.lng]
                                    ? parseFloat(
                                        data[field.lng].replace(/,/, ".")
                                      )
                                    : 0,
                                }}
                              />
                            </GoogleMap>
                          </LoadScript>
                        </Col>
                      );
                    default:
                      return null;
                  }
                })}
              </Row>
            </Card.Body>
          </Accordion.Body>
        </Card>
      </Accordion.Item>
    </Accordion>
  );
};
