import React, { useState, useEffect, Fragment } from "react";
import { TableBackEnd } from "../../components/TableBackEnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "../../components/Dialog";
import { faEye, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Card, Button, Form } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { useLoader } from "../../components/Loading";
import { useDataContext } from "../../context/DataState";
import { CardHeaderContainer } from "../Buscador/styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import { hasSuperAdminRole } from "../../constants";
import usePermition from "../../constants/usePermition";
import FilterButtons from "../../components/SearchButtons/FilterButtons";

export const Promociones = () => {
  const navigate = useNavigate();
  const [dialogBorrarPromocionVisible, setDialogBorrarPromocionVisible] =
    useState(false);
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [showingFilters, setShowingFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [loader, showLoader, hideLoader] = useLoader();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [numRegisters, setNumRegisters] = useState(0);
  const [order, setOrder] = useState("");
  const [orderDesc, setOrderDesc] = useState(true);
  const [valueDelete, setValueDelete] = useState("");
  const [regLength, setRegLength] = useState(50);
  const {
    dispatch,
    state: { appLists, user },
  } = useDataContext();
  const [showMunicipalitiesSelect, setShowMunicipalitiesSelect] =
    useState(false);
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    document.title = "EC-Light-Promociones";

    let isMounted = true;
    if (isMounted) {
      let listas = appLists;
      listas["appMunicipalitiesAux"] = listas.appMunicipalities;
      dispatch({
        type: "UPDATE_APP_LIST",
        payload: listas,
      });
      const {
        id,
        promotionName,
        isPublishable,
        provinceId,
        municipalityId,
        skip,
        take,
        orderField,
        descField,
      } = Object.fromEntries(searchParams);
      if (!!municipalityId || !!provinceId) {
        setShowMunicipalitiesSelect(true);
      }
      if (!!provinceId) {
        listas.appMunicipalitiesAux = appLists.appMunicipalities.filter(
          (e) => e.provinceId === provinceId
        );
        dispatch({
          type: "UPDATE_APP_LIST",
          payload: listas,
        });
      }
      let tmpFilters = { ...filters };
      if (id !== undefined && id !== null) {
        tmpFilters["id"] = id;
      }
      if (promotionName !== undefined && promotionName !== null) {
        tmpFilters["promotionName"] = promotionName;
      }
      if (isPublishable !== undefined && isPublishable !== null) {
        tmpFilters["isPublishable"] = isPublishable;
      }
      if (provinceId !== undefined && provinceId !== null) {
        tmpFilters["provinceId"] = provinceId;
      }
      if (municipalityId !== undefined && municipalityId !== null) {
        tmpFilters["municipalityId"] = municipalityId;
      }
      setFilters(tmpFilters);
      if (
        skip !== undefined &&
        skip !== null &&
        take !== undefined &&
        take !== null
      ) {
        setPage(Number(skip) + 1);
        setRegLength(Number(take));
        if (orderField !== null && orderField !== undefined) {
          setOrder(orderField);
          if (descField !== null && descField !== undefined) {
            setOrderDesc(!Boolean(descField));
          }
        }
        busquedaPromociones();
      }
    }
    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePagination = (value) => {
    setRegLength(value);
  };

  const handleChange = (prop, value) => {
    const tmpFilters = { ...filters };
    if (
      value !== "Publicado: Todos" ||
      value !== "Municipio" ||
      value !== "Provincia"
    ) {
      tmpFilters[prop] = value;
    } else {
      delete tmpFilters[prop];
    }
    setFilters(tmpFilters);
  };

  const handleChangeProvince = (prop, value) => {
    const tmpFilters = { ...filters };
    if (value !== "Provincia") {
      setShowMunicipalitiesSelect(true);
      let tmpLists = { ...appLists };
      tmpFilters[prop] = value;
      tmpLists.appMunicipalitiesAux = tmpLists.appMunicipalities.filter(
        (x) => x.provinceId === value
      );
      dispatch({
        type: "UPDATE_APP_LIST",
        payload: tmpLists,
      });
    } else {
      setShowMunicipalitiesSelect(false);
      delete tmpFilters.municipalityId;
      if (tmpFilters[prop]) delete tmpFilters[prop];
    }
    setFilters(tmpFilters);
  };

  const defaultSort = [
    {
      id: "id",
      desc: false,
    },
  ];

  const busquedaPromociones = () => {
    if (page > 0) {
      let data = {};
      let collection = [];
      let filtersAux = {};
      if (!!filters.code) filtersAux.code = filters.code;
      if (!!filters.promotionName)
        filtersAux.promotionName = filters.promotionName;
      if (!!filters.provinceId) filtersAux.provinceId = filters.provinceId;
      if (!!filters.municipalityId)
        filtersAux.municipalityId = filters.municipalityId;
      if (!!filters.isPublishable) {
        filtersAux.isPublishable = filters.isPublishable === "true";
      }
      if (!!filters.isDeleted) {
        filtersAux.isDeleted = filters.isDeleted === "true";
      }
      data.page = page;
      data.regsPage = regLength;
      data.filters = filtersAux;
      let tmpFilters = { ...filters };
      if (order !== null && order !== "") {
        data.orderBy = order;
        data.isAsc = orderDesc;
        tmpFilters.orderField = order;
        tmpFilters.descField = !orderDesc;
      } else {
        data.orderBy = "id";
        data.isAsc = true;
      }
      tmpFilters.skip = page - 1;
      tmpFilters.take = regLength;
      let newUrl = "/search-promotion?";
      navigate(newUrl + new URLSearchParams(tmpFilters).toString());
      showLoader();
      API.busquedaPromociones(data)
        .then((res) => {
          res.data.collection.forEach((e) => {
            let collectionElement = {};
            collectionElement.id = e.id;
            collectionElement.code = e.code;
            collectionElement.promotionName = e.promotionName;
            collectionElement.municipalityId = e.municipalityId;
            collectionElement.isDeleted = e.isDeleted;
            const province = appLists["appProvinces"].find(
              (s) => s.id === e.provinceId
            );
            if (province !== undefined) {
              collectionElement.provinceId = province.name;
            }
            const municipality = appLists["appMunicipalities"].find(
              (s) => s.id === e.municipalityId
            );
            if (municipality !== undefined) {
              collectionElement.municipalityId = municipality.name;
            }

            collection.push(collectionElement);
          });
          setData(collection);
          setTotalPages(res.data.maxPages);
          setNumRegisters(res.data.numRegisters);
        })
        .catch((error) => API.DEVELOP && console.log(error))
        .finally(() => hideLoader());
    }
  };

  const search = () => {
    busquedaPromociones();
  };

  const columns = [
    {
      Header: "",
      accessor: "detail",
      disableFilters: true,
      disableSortBy: true,
      maxWidth: 40,
      Cell: ({ cell }) => (
        <Fragment>
          <Link to={"/promotion-detail/" + cell.row.original.id}>
            <span title="Ver detalle">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEye}
                size="lg"
                fixedWidth
              />
            </span>
          </Link>
        </Fragment>
      ),
    },
    {
      Header: "Código Promoción",
      accessor: "code",
      maxWidth: 80,
      disableFilters: true,
    },
    {
      Header: "Nombre Promoción",
      accessor: "promotionName",
      disableFilters: true,
      Cell: ({ cell }) => (
        <Fragment>
          {cell.row.original.isDeleted ? (
            <span style={{ textDecoration: "line-through" }}>
              {cell.row.original.promotionName}
            </span>
          ) : (
            cell.row.original.promotionName
          )}
        </Fragment>
      ),
    },
    {
      Header: "Provincia",
      accessor: "provinceId",
      disableFilters: true,
      maxWidth: 150,
    },
    {
      Header: "Municipio",
      accessor: "municipalityId",
      disableFilters: true,
      maxWidth: 150,
    },
    {
      Header: "",
      accessor: "delete",
      disableFilters: true,
      disableSortBy: true,
      maxWidth: 40,
      Cell: ({ cell }) => (
        <Fragment>
          {!cell.row.original.isDeleted && (
            <button
              className="tableButton"
              onClick={() => showModalDelete(cell.row.original.id)}
            >
              <span>
                <FontAwesomeIcon
                  style={{ color: "black" }}
                  icon={faTrashAlt}
                  size="lg"
                  fixedWidth
                />
              </span>
            </button>
          )}
        </Fragment>
      ),
    },
  ];

  const confirmDelete = (id) => {
    showLoader();
    setDialogBorrarPromocionVisible(false);

    API.borrarPromocion(id)
      .then((res) => {
        toast.success("Promoción borrada correctamente");
        const tmpData = [...data];
        const dataDelete = tmpData.find((e) => e.id === id);
        if (!!dataDelete) {
          dataDelete.isDeleted = true;
        }
        setData(tmpData);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const showModalDelete = (id) => {
    setValueDelete(id);
    setDialogBorrarPromocionVisible(true);
  };

  const cleanFilters = () => {
    setFilters({});
    navigate("/search-promotion");
  };

  const showFilters = (showing) => {
    if (showing) {
      document.getElementsByClassName("filters")[0].style.display = "none";
      setShowingFilters(false);
    } else {
      document.getElementsByClassName("filters")[0].style.display = "block";
      setShowingFilters(true);
    }
  };

  return (
    <Fragment>
      {loader}
      {dialogBorrarPromocionVisible && (
        <Dialog
          type="noFooter"
          message="¿Desea eliminar la promoción?"
          labelConfirm="Eliminar"
          confirm={confirmDelete}
          cancel={() => setDialogBorrarPromocionVisible(false)}
          value={valueDelete}
        />
      )}
      <S.Sidebar className="filters">
        <FilterButtons
          onSearch={() => {
            setPage(1);
            search();
          }}
          onClean={cleanFilters}
        />
        <Form.Group className="mb-2">
          <Form.Control
            type="text"
            id="code"
            placeholder="Código Promoción"
            onChange={(e) => handleChange("code", e.target.value)}
            value={filters.code ? filters.code : ""}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Control
            id="promotionName"
            placeholder="Nombre Promoción"
            onChange={(e) => handleChange("promotionName", e.target.value)}
            value={filters.promotionName ? filters.promotionName : ""}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Select
            id="isPublishable"
            onChange={(e) => handleChange("isPublishable", e.target.value)}
            value={filters.isPublishable ? filters.isPublishable : 0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
          >
            <option>Publicado: Todos</option>
            <option value="true">Publicado</option>
            <option value="false">No Publicado</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Select
            id="provinceId"
            onChange={(e) => handleChangeProvince("provinceId", e.target.value)}
            value={filters.provinceId ? filters.provinceId : 0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
          >
            <option>Provincia</option>
            {appLists["appProvinces"] !== undefined &&
              appLists["appProvinces"].map((e, index) => (
                <option value={e.id} key={index}>
                  {e.name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        {showMunicipalitiesSelect && (
          <Form.Group className="mb-2">
            <Form.Select
              id="municipalityId"
              onChange={(e) => handleChange("municipalityId", e.target.value)}
              value={filters.municipalityId ? filters.municipalityId : 0}
              style={{ height: "2rem", fontSize: "0.9rem" }}
            >
              <option>Municipio</option>
              {appLists["appMunicipalitiesAux"] !== undefined &&
                appLists["appMunicipalitiesAux"].map((e, index) => (
                  <option value={e.id} key={index}>
                    {e.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        )}
        {hasSuperAdminRole(user) && (
          <Form.Group className="mb-2">
            <Form.Select
              id="isDeleted"
              onChange={(e) => handleChange("isDeleted", e.target.value)}
              value={filters.isDeleted ? filters.isDeleted : 0}
              style={{ height: "2rem", fontSize: "0.9rem" }}
            >
              <option>Eliminación: Todos</option>
              <option value="true">Eliminados</option>
              <option value="false">No Eliminados</option>
            </Form.Select>
          </Form.Group>
        )}
      </S.Sidebar>
      <S.Content>
        <Card>
          <Card.Header
            style={{
              paddingLeft: "2rem",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <CardHeaderContainer>
              <PageTitle title="Promociones" />
              <div>
                {showingFilters && (
                  <Button
                    className="openFilters"
                    onClick={() => showFilters(showingFilters)}
                    variant="danger"
                  >
                    Esconder Busqueda
                  </Button>
                )}
                {!showingFilters && (
                  <Button
                    className="openFilters"
                    onClick={() => showFilters(showingFilters)}
                    variant="danger"
                  >
                    Desplegar Busqueda
                  </Button>
                )}
                <Button
                  variant="success"
                  onClick={() => navigate("/promotion-detail")}
                  hidden={!PERMISOS_POR_ROL.Promociones.CrudPromocion}
                >
                  <span>
                    <FontAwesomeIcon icon={faPlus} size="lg" fixedWidth />
                  </span>
                  Crear Promoción
                </Button>
              </div>
            </CardHeaderContainer>
          </Card.Header>
          <Card.Body>
            <TableBackEnd
              columns={columns}
              data={data}
              defaultSort={defaultSort}
              hiddenColumns={
                PERMISOS_POR_ROL.Promociones.CrudPromocion ? [] : ["delete"]
              }
              pageTable={page}
              regsPage={regLength}
              setPage={setPage}
              setRegsPage={handleChangePagination}
              search={search}
              totalPages={totalPages}
              numRegisters={numRegisters}
              order={order}
              setOrder={setOrder}
              orderDesc={orderDesc}
              setOrderDesc={setOrderDesc}
            />
          </Card.Body>
        </Card>
      </S.Content>
    </Fragment>
  );
};
