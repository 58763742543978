import styled from "styled-components/macro";

export const AssetsSelected = styled.div`
  max-height: 45vh;
  overflow-y: auto;

  button {
    cursor: pointer;
  }
  .tableButton {
    backgroundcolor: transparent;
    color: #0d6efd;
    textdecoration: underline;
    padding: 0;
    border: none;
    background: none;
  }
  .tableButton:hover {
    color: #0a58ca;
  }
`;
