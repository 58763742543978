import useSpecialSale from "./useSpecialSale";
import * as S from "./styles";
import { Button, Card } from "react-bootstrap";
import { CardHeaderContainer } from "../Buscador/styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { TableFrontEnd } from "../../components/TableFrontEnd";
import { ModalAudit } from "../../components/ModalAudit";

const SpecialSale = () => {
  const {
    loader,
    PERMISOS_POR_ROL,
    data,
    columns,
    defaultSort,
    defaultColumn,
    modalAudit,
    hiddeModalAudit,
    valuesAudit,
    METADATA_AUDIT,
    handleNavDetail,
  } = useSpecialSale();

  return (
    <S.Container>
      {loader}
      {modalAudit && (
        <ModalAudit
          metadata={METADATA_AUDIT}
          message="Auditoría"
          values={valuesAudit}
          cancel={hiddeModalAudit}
          entityName="SpecialSale"
        />
      )}
      <Card>
        <Card.Header
          style={{
            paddingLeft: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <CardHeaderContainer>
            <PageTitle title="Ventas especiales" />
            <Button
              variant="success"
              onClick={() => handleNavDetail(false)}
              hidden={!PERMISOS_POR_ROL.VentaEspecial.Crud}
            >
              <span>
                <FontAwesomeIcon icon={faPlus} size="lg" fixedWidth />
              </span>
              Crear venta especial
            </Button>
          </CardHeaderContainer>
        </Card.Header>
        <Card.Body>
          <TableFrontEnd
            columns={columns}
            data={data}
            defaultSort={defaultSort}
            defaultColumn={defaultColumn}
            hiddenColumns={
              PERMISOS_POR_ROL.Territoriales.Crud
                ? ["creationTime", "delete"]
                : ["config", "delete", "creationTime"]
            }
            pagination={true}
          />
        </Card.Body>
      </Card>
    </S.Container>
  );
};

export default SpecialSale;
