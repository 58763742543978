import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  background-color: ${COLORES.BACKGROUND};
  button {
    cursor: pointer;
  }
  .tableButton {
    backgroundcolor: transparent;
    color: #0d6efd;
    textdecoration: underline;
    padding: 0;
    border: none;
    background: none;
  }
  .tableButton:hover {
    color: #0a58ca;
  }
`;

export const CellModalWrapper = styled.div`
  cursor: pointer;
`;
