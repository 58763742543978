import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Button, Container } from "react-bootstrap";

export const NoAccess = () => {
  const { instance, accounts } = useMsal();

  useEffect(() => {
    document.title = "Sin Acceso";
  }, []);

  const handleLogout = () => {
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: process.env.REACT_APP_BASE_URL,
    };
    instance.logoutRedirect(logoutRequest);
  };

  return (
    <Container>
      <div style={{ textAlign: "center" }}>
        <br />
        <h2>Usted no está autorizado</h2>
        <hr />
        <h5>
          Usuario:
          <b>
            {` ${instance.getActiveAccount()?.name} ( ${
              instance.getActiveAccount()?.username
            } )`}
          </b>
        </h5>
        <hr />
        <h5>
          Contacte con un administrador de la aplicación para gestionar el alta
        </h5>
        {accounts[0]?.localAccountId && (
          <h5>
            y proporcione su código de Azure:{" "}
            <strong>'{accounts[0]?.localAccountId}'</strong>
          </h5>
        )}
        <br />
        <Button variant="danger" onClick={handleLogout}>
          Cerrar sesión
        </Button>
      </div>
    </Container>
  );
};
