import React, { useState, useEffect, Fragment } from "react";
import { TableBackEnd } from "../../components/TableBackEnd";
import { Card, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import * as API from "../../api";
import { useLoader } from "../../components/Loading";
import { useDataContext } from "../../context/DataState";
import PageTitle from "../../components/PageTitle/PageTitle";
import FilterButtons from "../../components/SearchButtons/FilterButtons";

export const ProductosBorrados = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showingFilters, setShowingFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [loader, showLoader, hideLoader] = useLoader();
  const [page, setPage] = useState(0);
  const [regsPage, setRegsPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [numRegisters, setNumRegisters] = useState(0);
  const [order, setOrder] = useState("");
  const [orderDesc, setOrderDesc] = useState(true);
  const [userList, setUserList] = useState([]);
  const {
    state: { appLists },
  } = useDataContext();

  useEffect(() => {
    document.title = "EC-Light-Productos Borrados";
    let isMounted = true;
    if (isMounted) {
      showLoader();
      API.obtenerUsuariosList()
        .then((res) => {
          setUserList(res.data);
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    }
    return () => (isMounted = false);
  }, [showLoader, hideLoader]);

  const handleChange = (prop, value) => {
    const tmpFilters = { ...filters };
    tmpFilters[prop] = value;
    if (prop === "code" || prop === "assetID" || prop === "currentCode") {
      if (value < 1) delete tmpFilters[prop];
    }
    setFilters(tmpFilters);
  };

  const formatDate = (dateString) => {
    var date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() + -offset * 60000);
    let d = date;
    d = [
      "0" + d.getDate(),
      "0" + (d.getMonth() + 1),
      "" + d.getFullYear(),
      "0" + d.getHours(),
      "0" + d.getMinutes(),
      "0" + d.getSeconds(),
    ].map((component) => {
      if (component.length < 4) {
        return component.slice(-2);
      } else {
        return component;
      }
    });

    return d.slice(0, 3).join("/") + " " + d.slice(3).join(":");
  };

  const busquedaProductosBorrados = () => {
    if (page > 0) {
      let data = {};
      let collection = [];
      let filtersAux = {};
      if (!!filters.code) filtersAux.code = parseInt(filters.code);
      if (!!filters.assetID) filtersAux.assetID = parseInt(filters.assetID);
      if (filters.dossierCode !== null && filters.dossierCode !== "")
        filtersAux.dossierCode = filters.dossierCode;
      if (!!filters.currentCode)
        filtersAux.currentCode = parseInt(filters.currentCode);
      data.page = page;
      data.regsPage = regsPage;
      data.filters = filtersAux;
      let tmpFilters = { ...filters };
      if (order !== null && order !== "") {
        data.orderBy = order;
        data.isAsc = orderDesc;
        tmpFilters.orderField = order;
        tmpFilters.descField = !orderDesc;
      } else {
        data.orderBy = "code";
        data.isAsc = true;
      }

      tmpFilters["skip"] = page - 1;
      tmpFilters["take"] = regsPage;
      let newUrl = "/search-deleted-products?";
      navigate(newUrl + new URLSearchParams(tmpFilters).toString());
      showLoader();
      API.busquedaProductosBorrados(data)
        .then((res) => {
          res.data.collection.forEach((e) => {
            let collectionElement = {};
            collectionElement.code = e.code;
            collectionElement.currentCode = e.currentCode;
            collectionElement.dossierCode = e.dossierCode;
            collectionElement.assetID = e.assetID;
            collectionElement.prinexID = e.prinexID;
            const productStatus = appLists["productStatus"].find(
              (s) => s.Id === e.status
            );
            if (productStatus !== undefined) {
              collectionElement.status = productStatus.translation;
            }
            const creator = userList.find((s) => s.id === e.creatorId);
            if (creator !== undefined) {
              collectionElement.creatorId = creator.name;
            }
            if (e.creationTime !== null)
              collectionElement.creationTime = formatDate(e.creationTime);
            const deleter = userList.find((s) => s.id === e.deleterId);
            if (deleter !== undefined) {
              collectionElement.deleterId = deleter.name;
            }
            if (e.deletionTime !== null)
              collectionElement.deletionTime = formatDate(e.deletionTime);
            const lastModifier = userList.find(
              (s) => s.id === e.lastModifierId
            );
            if (lastModifier !== undefined) {
              collectionElement.lastModifierId = lastModifier.name;
            }
            if (e.lastModificationTime !== null)
              collectionElement.lastModificationTime = formatDate(
                e.lastModificationTime
              );
            collection.push(collectionElement);
          });
          setData(collection);
          setTotalPages(res.data.maxPages);
          setNumRegisters(res.data.numRegisters);
        })
        .catch((error) => API.DEVELOP && console.log(error))
        .finally(() => hideLoader());
    }
  };

  const defaultSort = [
    {
      id: "id",
      desc: false,
    },
  ];

  const search = () => {
    busquedaProductosBorrados();
  };

  const columns = [
    {
      Header: "Id Producto",
      accessor: "code",
      disableFilters: true,
    },
    {
      Header: "Código actual",
      accessor: "currentCode",
      disableFilters: true,
    },
    {
      Header: "Expediente",
      accessor: "dossierCode",
      disableFilters: true,
    },
    {
      Header: "Id Prínex",
      accessor: "prinexID",
      disableFilters: true,
    },
    {
      Header: "Estado",
      accessor: "status",
      disableFilters: true,
    },
    {
      Header: "Usuario Creación",
      accessor: "creatorId",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Fecha Creación",
      accessor: "creationTime",
      disableFilters: true,
    },
    {
      Header: "Usuario Borrado",
      accessor: "deleterId",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Fecha Borrado",
      accessor: "deletionTime",
      disableFilters: true,
    },
    {
      Header: "Usuario Última Modificación",
      accessor: "lastModifierId",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Fecha Última Modificación",
      accessor: "lastModificationTime",
      disableFilters: true,
    },
  ];

  const cleanFilters = () => {
    setFilters({});
    navigate("/search-deleted-products");
  };

  const showFilters = (showing) => {
    if (showing) {
      document.getElementsByClassName("filters")[0].style.display = "none";
      setShowingFilters(false);
    } else {
      document.getElementsByClassName("filters")[0].style.display = "block";
      setShowingFilters(true);
    }
  };

  return (
    <Fragment>
      {loader}
      <S.Sidebar className="filters">
        <FilterButtons
          onSearch={() => {
            setPage(1);
            search();
          }}
          onClean={cleanFilters}
        />
        <Form.Group className="mb-3">
          <Form.Control
            type="number"
            onChange={(e) => handleChange("code", e.target.value)}
            id="code"
            placeholder="Id Producto"
            value={filters.code ? filters.code : ""}
            min={0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="number"
            onChange={(e) => handleChange("assetID", e.target.value)}
            id="assetID"
            placeholder="ID Activo"
            value={filters.assetID ? filters.assetID : ""}
            min={0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            onChange={(e) => handleChange("dossierCode", e.target.value)}
            id="dossierCode"
            placeholder="Expediente"
            value={filters.dossierCode ? filters.dossierCode : ""}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="number"
            onChange={(e) => handleChange("currentCode", e.target.value)}
            id="currentCode"
            placeholder="Código Actual"
            value={filters.currentCode ? filters.currentCode : ""}
            min={0}
            style={{ height: "2rem", fontSize: "0.9rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                search();
              }
            }}
            autoComplete="off"
          />
        </Form.Group>
      </S.Sidebar>
      <S.Content>
        <Card>
          <Card.Header
            style={{
              paddingLeft: "2rem",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <PageTitle title="Productos Borrados" />
            {showingFilters && (
              <Button
                className="openFilters"
                onClick={() => showFilters(showingFilters)}
                variant="danger"
              >
                Esconder Busqueda
              </Button>
            )}
            {!showingFilters && (
              <Button
                className="openFilters"
                onClick={() => showFilters(showingFilters)}
                variant="danger"
              >
                Desplegar Busqueda
              </Button>
            )}
          </Card.Header>
          <Card.Body>
            <TableBackEnd
              columns={columns}
              data={data}
              defaultSort={defaultSort}
              hiddenColumns={[]}
              pageTable={page}
              regsPage={regsPage}
              setPage={setPage}
              setRegsPage={setRegsPage}
              search={search}
              totalPages={totalPages}
              numRegisters={numRegisters}
              order={order}
              setOrder={setOrder}
              orderDesc={orderDesc}
              setOrderDesc={setOrderDesc}
            />
          </Card.Body>
        </Card>
      </S.Content>
    </Fragment>
  );
};
