import { portfolios, userRoles } from ".";

const usePermition = ({ user }) => {
  const {
    roleAdmin,
    roleBusinessManager,
    roleCommercialDelegate,
    roleContentEditor,
    roleMarketing,
    roleParticular,
    roleRead,
    roleSuperAdmin,
  } = userRoles.name;

  const hasRole = (roleName) => {
    if (roleName) {
      const auxRoles = user?.roles;
      const role = auxRoles?.find(
        (e) => e.name.toLowerCase() === roleName.toLowerCase()
      );
      if (role) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  /**
   * Para cada vista se establece la visibilidad de los botones dependiendo de la cartera y los roles de usuario
   */
  const PERMISOS_POR_ROL = {
    Inicio: {
      Tabla:
        user?.currentPortfolioID !== portfolios.name.particulares &&
        hasRole(roleContentEditor),
    },
    Buscador: {
      Acceso: true,
      CrearProducto:
        ((user?.currentPortfolioID !== portfolios.name.particulares &&
          (hasRole(roleSuperAdmin) ||
            hasRole(roleAdmin) ||
            hasRole(roleCommercialDelegate) ||
            hasRole(roleBusinessManager))) ||
          (user?.currentPortfolioID === portfolios.name.particulares &&
            (hasRole(roleAdmin) ||
              hasRole(roleSuperAdmin) ||
              hasRole(roleParticular)))) &&
        !hasRole(roleRead),
      ValidarCreacionProducto: true,
      BuscarProductosBorrados: hasRole(roleSuperAdmin),
      CrearActivo:
        !hasRole(roleRead) &&
        (user?.currentPortfolioID === portfolios.name.particulares ||
          user?.currentPortfolioID === portfolios.name.terceros) &&
        (hasRole(roleAdmin) ||
          hasRole(roleSuperAdmin) ||
          hasRole(roleParticular)),
      SuperAdmin: hasRole(roleSuperAdmin),
    },
    Publicaciones: {
      Acceso: user?.currentPortfolioID !== portfolios.name.particulares,
    },
    Administracion: {
      Acceso: hasRole(roleSuperAdmin),
    },
    DetalleActivoParticular: {
      Acceso:
        user?.currentPortfolioID === portfolios.name.particulares ||
        user?.currentPortfolioID === portfolios.name.terceros,
      Auditoria: hasRole(roleSuperAdmin),
      Guardar:
        !hasRole(roleRead) &&
        (hasRole(roleAdmin) ||
          hasRole(roleSuperAdmin) ||
          hasRole(roleParticular)),
    },
    DetalleActivo: {
      Acceso: true,
      EnviarTablaTemporal: !hasRole(roleRead) && hasRole(roleSuperAdmin),
      Auditoria: hasRole(roleSuperAdmin),
      Guardar:
        !hasRole(roleRead) &&
        (hasRole(roleAdmin) ||
          hasRole(roleSuperAdmin) ||
          hasRole(roleCommercialDelegate) ||
          hasRole(roleContentEditor) ||
          hasRole(roleBusinessManager)),
      OcultarNumero:
        !hasRole(roleRead) &&
        (hasRole(roleSuperAdmin) || hasRole(roleContentEditor)),
    },
    DetalleProducto: {
      Acceso: true,
      Auditoria: hasRole(roleSuperAdmin),
      ValidarProducto: true,
      Guardar:
        ((user?.currentPortfolioID !== portfolios.name.particulares &&
          (hasRole(roleSuperAdmin) ||
            hasRole(roleAdmin) ||
            hasRole(roleCommercialDelegate) ||
            hasRole(roleBusinessManager) ||
            hasRole(roleContentEditor))) ||
          (user?.currentPortfolioID === portfolios.name.particulares &&
            (hasRole(roleAdmin) ||
              hasRole(roleSuperAdmin) ||
              hasRole(roleParticular)))) &&
        !hasRole(roleRead),
      EnviarTablaTemporal:
        user?.currentPortfolioID !== portfolios.name.particulares &&
        user?.currentPortfolioID !== portfolios.name.terceros &&
        !hasRole(roleRead) &&
        hasRole(roleSuperAdmin),
      OcultarNumero:
        !hasRole(roleRead) &&
        (hasRole(roleSuperAdmin) || hasRole(roleContentEditor)),
      ProcesoTransparencia:
        !hasRole(roleRead) &&
        (hasRole(roleSuperAdmin) ||
          hasRole(roleAdmin) ||
          hasRole(roleCommercialDelegate)),
    },
    Campanas: {
      Acceso:
        user?.currentPortfolioID !== portfolios.name.particulares &&
        user?.currentPortfolioID !== portfolios.name.terceros,
      CrudCampana:
        !hasRole(roleRead) &&
        (hasRole(roleAdmin) ||
          hasRole(roleSuperAdmin) ||
          hasRole(roleMarketing)),
      Auditoria: hasRole(roleSuperAdmin),
    },
    Promociones: {
      Acceso: user?.currentPortfolioID !== portfolios.name.particulares,
      CrudPromocion:
        !hasRole(roleRead) &&
        (hasRole(roleAdmin) ||
          hasRole(roleSuperAdmin) ||
          hasRole(roleContentEditor)),
      Auditoria: hasRole(roleSuperAdmin),
      EnviarTablaTemporal:
        user?.currentPortfolioID !== portfolios.name.terceros &&
        !hasRole(roleRead) &&
        hasRole(roleSuperAdmin),
    },
    CargasMasivas: {
      Acceso: user?.currentPortfolioID !== portfolios.name.particulares,
      Upload:
        !hasRole(roleRead) &&
        (hasRole(roleAdmin) ||
          hasRole(roleSuperAdmin) ||
          hasRole(roleContentEditor) ||
          hasRole(roleMarketing)),
      AsociarActivos:
        hasRole(roleAdmin) ||
        hasRole(roleSuperAdmin) ||
        hasRole(roleContentEditor) ||
        hasRole(roleMarketing),
    },
    Territoriales: {
      Acceso: hasRole(roleSuperAdmin),
      Crud: !hasRole(roleRead) && hasRole(roleSuperAdmin),
      Auditoria: hasRole(roleSuperAdmin),
    },
    Delegaciones: {
      Acceso: hasRole(roleSuperAdmin),
      Crud: !hasRole(roleRead) && hasRole(roleSuperAdmin),
      Auditoria: hasRole(roleSuperAdmin),
    },
    SociedadesGestoras: {
      Acceso: hasRole(roleSuperAdmin),
      Crud: !hasRole(roleRead) && hasRole(roleSuperAdmin),
      Auditoria: hasRole(roleSuperAdmin),
    },
    Usuarios: {
      Acceso: hasRole(roleSuperAdmin),
      Crud: !hasRole(roleRead) && hasRole(roleSuperAdmin),
      Auditoria: hasRole(roleSuperAdmin),
    },
    Tareas: {
      Acceso: hasRole(roleSuperAdmin),
    },
    TablaTemporal: {
      Acceso: hasRole(roleSuperAdmin),
    },
    VentaEspecial: {
      Acceso: hasRole(roleSuperAdmin),
      Crud: !hasRole(roleRead) && hasRole(roleSuperAdmin),
      Auditoria: hasRole(roleSuperAdmin),
    },
    Auditoria: {
      Acceso: hasRole(roleSuperAdmin),
    },
    SuperAdmin: hasRole(roleSuperAdmin),
    Admin: hasRole(roleAdmin),
    AnyAdmin:
      hasRole(roleSuperAdmin) ||
      hasRole(roleAdmin) ||
      hasRole(roleBusinessManager),
    ComercialDelegate: hasRole(roleCommercialDelegate),
    BusinessManager: hasRole(roleBusinessManager),
    ReadOnly: hasRole(roleRead),
    ContentEditor: hasRole(roleContentEditor),
    Marketing: hasRole(roleMarketing),
    Private: hasRole(roleParticular),
  };

  return {
    PERMISOS_POR_ROL,
  };
};

export default usePermition;
