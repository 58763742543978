import React, { useState, useEffect } from "react";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faTrash,
  faTrashAlt,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import * as API from "../../api";
import * as S from "./styles";
import { Dialog } from "../Dialog";
import { Fragment } from "react";
import EmptyData from "../EmptyTable/EmptyData";

export const ModalAssetProduct = ({
  id,
  message,
  lists,
  labelConfirm,
  confirm,
  cancel,
  defaultSort,
  // defaultColumn,
  showLoader,
  hideLoader,
}) => {
  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState("");
  const [orderDesc, setOrderDesc] = useState(false);
  const [pageTable, setPage] = useState(0);
  const [regsPage, setRegsPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [dataWOProd, setDataWOProd] = useState([]);
  const [selected, setSelected] = useState([]);
  const [numRegisters, setNumRegisters] = useState(0);
  const [dialogTooMuchAssets, setDialogTooMuchAssets] = useState(false);
  const [openProducts, setOpenProducts] = useState([]);
  const [openedAssWOP, setOpenedAssWOP] = useState(true);
  const [allIdsSelected, setAllIdsSelected] = useState(false);

  useEffect(() => {
    if (pageTable > 0) {
      busquedaAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTable, regsPage, order, orderDesc]);

  useEffect(() => {
    areAllSelecteds();
  }, [selected, data]);

  /**
   * tamaños de columnas
   */
  const TABLE_MD = {
    check: {
      width: 40,
    },
    prinexId: {
      width: 100,
    },
    code: {
      width: 80,
    },
    name: {
      width: 120,
    },
    businessLine: {
      width: 100,
    },
    dossierCode: {
      width: 80,
    },
    promotionCode: {
      width: 100,
    },
    propertyCode: {
      width: 20,
    },
  };

  const isSelected = (id) => {
    let found = false;
    let i = 0;
    while (i < selected.length && !found) {
      if (id === selected[i].id) {
        found = true;
      }
      i++;
    }
    return found;
  };

  /**
   * Check if all
   */
  const areAllSelecteds = () => {
    console.log(selected);
    let allSelected = !!data.length ? true : false;
    let i = 0;
    while (i < data.length && allSelected) {
      let auxProd = data[i];
      let auxAssets = auxProd.assets;
      let j = 0;
      while (j < auxAssets.length && allSelected) {
        let auxAss = auxAssets[j];
        if (!isSelected(auxAss.id)) {
          allSelected = false;
        }
        j++;
      }
      i++;
    }
    setAllIdsSelected(allSelected);
  };

  /**
   * Columnas para la tabla
   */
  const columns = [
    {
      Header: (
        <Form.Check
          onChange={(e) => {
            setSelectedAllAssets(e.target.checked);
          }}
          disabled={numRegisters === 0}
          checked={allIdsSelected}
          style={{ cursor: "pointer" }}
        />
      ),
      accessor: "select",
      disableFilters: true,
      disableSortBy: true,
      width: TABLE_MD.check.width,
      Cell: ({ cell }) => (
        <Form.Check
          onChange={(e) => {
            handleChangeSelectedAssets(
              parseInt(cell.row.original.id),
              parseInt(cell.row.original.prinexId),
              e.target.checked
            );
          }}
          checked={selected.find((e) => e.id === cell.row.original.id)}
        />
      ),
    },
    {
      Header: "Id Prínex",
      accessor: "prinexId",
      disableFilters: true,
      width: TABLE_MD.prinexId.width,
      Cell: ({ cell }) => <Fragment>{cell.row.original.prinexId}</Fragment>,
    },
    {
      Header: "Producto",
      accessor: "code",
      disableFilters: true,
      width: TABLE_MD.code.width,
    },
    {
      Header: "Nombre",
      accessor: "name",
      disableFilters: true,
      width: TABLE_MD.name.width,
    },
    {
      Header: "Línea Negocio",
      accessor: "businessLine",
      disableFilters: true,
      width: TABLE_MD.businessLine.width,
    },

    {
      Header: "Expediente",
      accessor: "dossierCode",
      disableFilters: true,
      width: TABLE_MD.dossierCode.width,
    },
    {
      Header: "Prom. Prínex",
      accessor: "promotionCode",
      disableFilters: true,
      width: TABLE_MD.promotionCode.width,
    },
    {
      Header: "Inmueble",
      accessor: "propertyCode",
      disableFilters: true,
      width: TABLE_MD.propertyCode.width,
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: defaultSort,
      },
      autoResetHiddenColumns: false,
      // defaultColumn,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const handleConfirm = () => {
    let ids = selected.map((e) => {
      return e.id;
    });
    confirm(ids);
  };

  /**
   * Cambiar orden de columna
   * @param {*} column
   */
  const changeOrder = (column) => {
    if (!column.disableSortBy) {
      if (order === column.id && orderDesc === false) {
        setOrderDesc(!orderDesc);
      } else if (order === column.id && orderDesc === true) {
        setOrder("");
        setOrderDesc(!orderDesc);
      } else {
        setOrder(column.id);
        setOrderDesc(false);
      }
    }
  };

  const handleChange = (prop, value) => {
    const tmpFilters = { ...filters };
    tmpFilters[prop] = value;
    setFilters(tmpFilters);
  };

  const handleChangeSelectedAssets = (id, prinexId, checked) => {
    let tmpSelected = [...selected];
    if (checked) {
      let tmpSelectedItem = {
        id,
        prinexId,
      };
      tmpSelected.push(tmpSelectedItem);
      setSelected(tmpSelected);
    } else {
      tmpSelected = tmpSelected.filter((e) => e.id !== id);
      setSelected(tmpSelected);
    }
  };

  const deleteSelected = (id) => {
    let tmpSelected = [...selected];
    tmpSelected = tmpSelected.filter((e) => e.id !== id);
    setSelected(tmpSelected);
  };

  const busquedaAssets = () => {
    if (pageTable > 0) {
      let dataSend = {};
      let filtersAux = {};
      if (filters.prinexId !== null && filters.prinexId !== "")
        filtersAux.prinexId = Number(filters.prinexId);
      if (filters.code !== null && filters.code !== "")
        filtersAux.code = Number(filters.code);
      if (filters.dossierCode !== null && filters.dossierCode !== "")
        filtersAux.dossierCode = filters.dossierCode;
      if (filters.promotionCode !== null && filters.promotionCode !== "")
        filtersAux.promotionCode = filters.promotionCode;
      if (filters.propertyCode !== null && filters.propertyCode !== "")
        filtersAux.propertyCode = filters.propertyCode;
      dataSend.page = pageTable;
      dataSend.regsPage = regsPage;
      dataSend.filters = filtersAux;
      if (!!order) {
        dataSend.orderBy = order;
        dataSend.isAsc = !orderDesc;
      } else {
        dataSend.orderBy = "code";
        dataSend.isAsc = true;
      }
      showLoader();
      API.busquedaActivosProductoPaginada(id, dataSend)
        .then((res) => {
          let auxCodes = [];
          let withProd = res.data.collection.filter(
            (element) => !!element.code
          );
          let withOutProd = res.data.collection.filter(
            (element) => !!!element.code
          );
          let storeWOP = [];
          withOutProd.forEach((e) => (storeWOP = [...storeWOP, ...e.assets]));
          withProd.forEach((e) => auxCodes.push(e.code));
          setOpenProducts(auxCodes);
          setData(withProd);
          setDataWOProd(storeWOP);
          setTotalPages(res.data.maxPages);
          // pedir a back que mande la cantidad total de activos no de productos
          setNumRegisters(res.data.numRegisters);
        })
        .catch((error) => API.DEVELOP && console.log(error))
        .finally(() => hideLoader());
    }
  };

  const setSelectedAllAssets = (checked) => {
    if (checked) {
      if (numRegisters <= 200) {
        getAllIds(true);
      } else {
        setDialogTooMuchAssets(true);
      }
    } else {
      // let auxSelected = diferntData();
      // setSelected(auxSelected);
      getAllIds(false);
    }
  };

  const isIncluded = (id, array) => {
    let found = false;
    let i = 0;
    while (i < array.length && !found) {
      if (array[i].id === id) {
        found = true;
      }
      i++;
    }
    return found;
  };

  const getAllIds = (isAdd) => {
    if (dialogTooMuchAssets) setDialogTooMuchAssets(false);
    let dataSend = {};
    if (filters.prinexId !== null && filters.prinexId !== "")
      dataSend.prinexId = filters.prinexId;
    if (filters.code !== null && filters.code !== "")
      dataSend.code = filters.code;
    if (filters.dossierCode !== null && filters.dossierCode !== "")
      dataSend.dossierCode = filters.dossierCode;
    if (filters.promotionCode !== null && filters.promotionCode !== "")
      dataSend.promotionCode = filters.promotionCode;
    if (filters.propertyCode !== null && filters.propertyCode !== "")
      dataSend.propertyCode = filters.propertyCode;
    showLoader();
    API.busquedaActivosProductoTodos(id, dataSend)
      .then((res) => {
        let assetIds = [...selected];

        if (isAdd) {
          res.data.forEach((element) => {
            element.assets.forEach((e) => {
              if (!isIncluded(e.id, selected)) {
                let asset = {
                  id: e.id,
                  prinexId: e.prinexId,
                };
                assetIds.push(asset);
              }
            });
          });
        } else {
          let auxArray = [...assetIds];
          res.data.forEach((e) => {
            e.assets.forEach((ass) => {
              auxArray = auxArray.filter((el) => el.id !== ass.id);
            });
          });
          assetIds = auxArray;
        }
        setSelected(assetIds);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => hideLoader());
  };

  const cleanFilters = () => {
    setFilters({});
  };

  return (
    <Fragment>
      {dialogTooMuchAssets && (
        <Dialog
          type="noFooter"
          message="Se seleccionarán más de 200 activos. Puede que la aplicación no funcione correctamente, ¿desea continuar?"
          labelConfirm="Continuar"
          confirm={() => getAllIds(true)}
          cancel={() => setDialogTooMuchAssets(false)}
          value={{}}
        />
      )}
      {!dialogTooMuchAssets && (
        <Modal show={true} onHide={cancel} size="xl">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {message}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={2}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="prinexId"
                    placeholder="Id Prínex"
                    value={filters.prinexId ? filters.prinexId : ""}
                    onChange={(e) => handleChange("prinexId", e.target.value)}
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="code"
                    placeholder="Código Producto"
                    value={filters.code ? filters.code : ""}
                    onChange={(e) => handleChange("code", e.target.value)}
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="dossierCode"
                    type="text"
                    placeholder="Expediente"
                    value={filters.dossierCode ? filters.dossierCode : ""}
                    onChange={(e) =>
                      handleChange("dossierCode", e.target.value)
                    }
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="promotionCode"
                    type="text"
                    placeholder="Id Promoción Prínex"
                    value={filters.promotionCode ? filters.promotionCode : ""}
                    onChange={(e) =>
                      handleChange("promotionCode", e.target.value)
                    }
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="propertyCode"
                    type="text"
                    placeholder="Inmueble"
                    value={filters.propertyCode ? filters.propertyCode : ""}
                    onChange={(e) =>
                      handleChange("propertyCode", e.target.value)
                    }
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPage(1);
                        busquedaAssets();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={3}>
                <Button onClick={cleanFilters} variant="danger">
                  <span>
                    <FontAwesomeIcon icon={faTrash} size="lg" fixedWidth />
                  </span>
                  Limpiar Filtros
                </Button>
              </Col>
              <Col xs={9}>
                <Button
                  onClick={() => {
                    setPage(1);
                    busquedaAssets();
                  }}
                  variant="success"
                >
                  Buscar
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <S.AssetsSelected>
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      border: "thin solid lightgray",
                      whiteSpace: "nowrap",
                      maxHeight: "80vh",
                      overflowY: "auto",
                    }}
                  >
                    <thead className="bg-light sticky-top top-0">
                      <tr>
                        <th style={{ backgroundColor: "rgb(246, 246, 246)" }}>
                          <button
                            className="tableButton"
                            onClick={() => setSelected([])}
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                size="lg"
                                fixedWidth
                              />
                            </span>
                          </button>
                        </th>
                        <th style={{ backgroundColor: "rgb(246, 246, 246)" }}>
                          Id Prínex ({selected.length})
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selected.map((a, index) => (
                        <tr key={index}>
                          <td>
                            <button
                              className="tableButton"
                              onClick={() => deleteSelected(a.id)}
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  size="lg"
                                  fixedWidth
                                />
                              </span>
                            </button>
                          </td>
                          <td>{a.prinexId}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </S.AssetsSelected>
              </Col>
              <Col xs={9}>
                <div
                  style={{
                    height:
                      !!data.length || !!dataWOProd.length ? "45vh" : "5vh",
                    overflow:
                      !!data.length || !!dataWOProd.length ? "auto" : "hidden",
                  }}
                >
                  <S.DataTableContainer>
                    <Table
                      striped
                      bordered
                      hover
                      {...getTableProps()}
                      style={{
                        border: "thin solid lightgray",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <thead className="bg-light sticky-top top-0">
                        {headerGroups.map((headerGroup, index) => (
                          <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={index}
                          >
                            {headerGroup.headers.map((column, indexHeader) => (
                              <th
                                key={indexHeader}
                                onClick={() => changeOrder(column)}
                                style={{
                                  backgroundColor: "rgb(246, 246, 246)",
                                  color: "black",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                  verticalAlign: "top",
                                  border: "thin solid lightgray",
                                  width: column.width,
                                }}
                              >
                                {column.render("Header")}
                                <span>
                                  {column.id === order ? (
                                    orderDesc ? (
                                      <span> ↓ </span>
                                    ) : (
                                      <span> ↑ </span>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {!!dataWOProd.length && (
                          <Fragment>
                            <tr
                              style={{
                                border: "thin solid lightgray",
                                background: "#e9ecef ",
                                padding: 4,
                              }}
                            >
                              <td colSpan={12}>
                                {openedAssWOP ? (
                                  <Fragment>
                                    <FontAwesomeIcon
                                      onClick={() => setOpenedAssWOP(false)}
                                      style={{ height: "1rem" }}
                                      icon={faMinus}
                                      size="lg"
                                      fixedWidth
                                    />{" "}
                                    Activos sin producto
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <FontAwesomeIcon
                                      onClick={() => setOpenedAssWOP(true)}
                                      style={{ height: "1rem" }}
                                      icon={faPlus}
                                      size="lg"
                                      fixedWidth
                                    />{" "}
                                    Activos sin producto
                                  </Fragment>
                                )}
                              </td>
                            </tr>
                            {!!openedAssWOP &&
                              dataWOProd.map((asset, ind) => (
                                <tr>
                                  <td style={{ width: TABLE_MD.check.width }}>
                                    <Form.Check
                                      onChange={(e) => {
                                        handleChangeSelectedAssets(
                                          parseInt(asset.id),
                                          parseInt(asset.prinexId),
                                          e.target.checked
                                        );
                                      }}
                                      checked={
                                        !!selected.find(
                                          (e) => e.id === asset.id
                                        )
                                      }
                                    />
                                  </td>
                                  <td
                                    style={{ width: TABLE_MD.prinexId.width }}
                                  >
                                    {asset.prinexId}
                                  </td>
                                  <td
                                    style={{ width: TABLE_MD.code.width }}
                                  ></td>
                                  <td style={{ width: TABLE_MD.name.width }}>
                                    {asset.name}
                                  </td>
                                  <td
                                    style={{
                                      width: TABLE_MD.businessLine.width,
                                    }}
                                  >
                                    {
                                      lists.businessLines.find(
                                        (e) => e.Id === asset.businessLine
                                      ).translation
                                    }
                                  </td>
                                  <td
                                    style={{
                                      width: TABLE_MD.dossierCode.width,
                                    }}
                                  >
                                    {asset.dossierCode}
                                  </td>
                                  <td
                                    style={{
                                      width: TABLE_MD.promotionCode.width,
                                    }}
                                  >
                                    {asset.promotionName}
                                  </td>
                                  <td
                                    style={{
                                      width: TABLE_MD.propertyCode.width,
                                    }}
                                  >
                                    {asset.propertyCode}
                                  </td>
                                </tr>
                              ))}
                          </Fragment>
                        )}

                        {page.map((row, i) => {
                          prepareRow(row);
                          return (
                            <Fragment>
                              <tr
                                style={{
                                  border: "thin solid lightgray",
                                  background: "#e9ecef ",
                                  padding: 4,
                                }}
                              >
                                <td
                                  colSpan={12}
                                  style={{ fontWeight: "bolder" }}
                                >
                                  {!!row.original.code ? (
                                    openProducts.includes(row.original.code) ? (
                                      <Fragment>
                                        <FontAwesomeIcon
                                          style={{ height: "1rem" }}
                                          icon={faMinus}
                                          size="lg"
                                          fixedWidth
                                          onClick={() =>
                                            setOpenProducts(
                                              openProducts.filter(
                                                (e) => e !== row.original.code
                                              )
                                            )
                                          }
                                        />{" "}
                                        Código de Producto: {row.original.code}
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        <FontAwesomeIcon
                                          style={{ height: "1rem" }}
                                          onClick={() =>
                                            setOpenProducts([
                                              ...openProducts,
                                              row.original.code,
                                            ])
                                          }
                                          icon={faPlus}
                                          size="lg"
                                          fixedWidth
                                        />{" "}
                                        Código de Producto: {row.original.code}
                                      </Fragment>
                                    )
                                  ) : (
                                    "Código de Producto: Activo sin Producto"
                                  )}
                                </td>
                              </tr>
                              {!!openProducts.includes(row.original.code) &&
                                row.original.assets.map((asset, i) => (
                                  <tr
                                    key={i}
                                    style={{ border: "thin solid lightgray" }}
                                  >
                                    <td style={{ width: TABLE_MD.check.width }}>
                                      <Form.Check
                                        onChange={(e) => {
                                          handleChangeSelectedAssets(
                                            parseInt(asset.id),
                                            parseInt(asset.prinexId),
                                            e.target.checked
                                          );
                                        }}
                                        checked={
                                          !!selected.find(
                                            (e) => e.id === asset.id
                                          )
                                        }
                                      />
                                    </td>
                                    <td
                                      style={{ width: TABLE_MD.prinexId.width }}
                                    >
                                      {asset.prinexId}
                                    </td>
                                    <td style={{ width: TABLE_MD.code.width }}>
                                      {row.original.code}
                                    </td>
                                    <td
                                      style={{ maxWidth: TABLE_MD.name.width }}
                                    >
                                      {asset.name}
                                    </td>
                                    <td
                                      style={{
                                        width: TABLE_MD.businessLine.width,
                                      }}
                                    >
                                      {
                                        lists.businessLines.find(
                                          (e) => e.Id === asset.businessLine
                                        ).translation
                                      }
                                    </td>
                                    <td
                                      style={{
                                        width: TABLE_MD.dossierCode.width,
                                      }}
                                    >
                                      {asset.dossierCode}
                                    </td>
                                    <td
                                      style={{
                                        width: TABLE_MD.promotionCode.width,
                                      }}
                                    >
                                      {asset.promotionName}
                                    </td>
                                    <td
                                      style={{
                                        width: TABLE_MD.propertyCode.width,
                                      }}
                                    >
                                      {asset.propertyCode}
                                    </td>
                                  </tr>
                                ))}
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </S.DataTableContainer>
                </div>
                {!!data.length || !!dataWOProd.length ? (
                  <Fragment>
                    <Row className="mb-2 mt-2">
                      <Col>
                        <ButtonGroup size="sm">
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => {
                              setPage(1);
                            }}
                            disabled={pageTable < 2}
                          >
                            {"<<"}
                          </Button>
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => {
                              setPage(pageTable - 1);
                            }}
                            disabled={pageTable < 2}
                          >
                            {"<"}
                          </Button>
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => {
                              setPage(pageTable + 1);
                            }}
                            disabled={
                              pageTable === totalPages || pageTable === 0
                            }
                          >
                            {">"}
                          </Button>
                          <Button
                            style={{ marginRight: "3px" }}
                            variant="secondary"
                            onClick={() => setPage(totalPages)}
                            disabled={
                              pageTable === totalPages || pageTable === 0
                            }
                          >
                            {">>"}
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>{numRegisters + " regístros"}</Col>
                    </Row>
                    <Row className="mb-2 mt-2">
                      <Col xs={6}>
                        <Form.Select
                          size="sm"
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(Number(e.target.value));
                            setPage(1);
                            setRegsPage(Number(e.target.value));
                          }}
                          style={{ width: "8rem" }}
                        >
                          {[10, 20, 50, 100, 200].map((pageSize, indexPage) => (
                            <option key={indexPage} value={pageSize}>
                              Mostrar {pageSize}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <span>
                          Página{" "}
                          <strong>
                            {pageTable} de {totalPages}
                          </strong>{" "}
                        </span>
                        <span>
                          | Ir a la página:{" "}
                          <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                              const page = e.target.value
                                ? Number(e.target.value)
                                : 0;
                              setPage(page);
                            }}
                            style={{ width: "100px" }}
                          />
                        </span>{" "}
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <div style={{ border: "thin solid lightgray" }}>
                    <EmptyData />
                  </div>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={handleConfirm}
              disabled={selected.length === 0}
              variant="success"
            >
              <span>
                <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
              </span>
              {labelConfirm}
            </Button>

            {cancel && (
              <Button onClick={cancel} variant="danger">
                <span>
                  <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
                </span>
                Cancelar
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
};
