import React, { Fragment } from "react";
import { Row, Col, Form } from "react-bootstrap";

export const InputFormSegmentation = ({
  disabled,
  hidden,
  filter,
  list,
  segmentationPrimary,
  segmentationPrimaryType,
  segmentationSecondary,
  segmentationSecondaryType,
  handleChange,
}) => {
  if (list === undefined) {
    list = {};
  }
  return (
    <Fragment>
      {list && (
        <Fragment>
          <Row
            className="mb-2"
            hidden={hidden(filter, "Segmentación uso principal")}
          >
            <Col xs={12} md={5}>
              <Form.Label
                hidden={hidden(filter, "Segmentación uso principal")}
                style={{ fontSize: "0.875rem" }}
              >
                Segmentación uso principal
              </Form.Label>
            </Col>
            <Col xs={12} md={7}>
              <Form.Select
                placeholder="Segmentación uso principal"
                id="segmentationPrimary"
                disabled={disabled()}
                value={segmentationPrimary}
                hidden={hidden(filter, "Segmentación uso principal")}
                onChange={(e) =>
                  handleChange("segmentationPrimary", e.target.value)
                }
                size="sm"
              >
                <option value=""></option>
                {Object.keys(list).map((field, index) => (
                  <option key={index} value={field}>
                    {field}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          {segmentationPrimary &&
            list[segmentationPrimary] &&
            Object.keys(list[segmentationPrimary]).length > 0 && (
              <Row
                className="mb-2"
                hidden={hidden(filter, "Tipo segmentación uso principal")}
              >
                <Col xs={12} md={5}>
                  <Form.Label
                    hidden={hidden(filter, "Tipo segmentación uso principal")}
                    style={{ fontSize: "0.875rem" }}
                  >
                    Tipo segmentación uso principal
                  </Form.Label>
                </Col>
                <Col xs={12} md={7}>
                  <Form.Select
                    placeholder="Tipo segmentación uso principal"
                    id="segmentationPrimaryType"
                    disabled={disabled()}
                    value={segmentationPrimaryType}
                    hidden={hidden(filter, "Tipo segmentación uso principal")}
                    onChange={(e) =>
                      handleChange("segmentationPrimaryType", e.target.value)
                    }
                    size="sm"
                  >
                    {list[segmentationPrimary][0] !== null && (
                      <option value={null}></option>
                    )}
                    {Object.values(list[segmentationPrimary]).map(
                      (field, index) => (
                        <option key={index} value={field ? field : null}>
                          {field ? field : "Sin rellenar"}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Col>
              </Row>
            )}

          <Row
            className="mb-2"
            hidden={hidden(filter, "Segmentación uso secundario")}
          >
            <Col xs={12} md={5}>
              <Form.Label
                hidden={hidden(filter, "Segmentación uso secundario")}
                style={{ fontSize: "0.875rem" }}
              >
                Segmentación uso secundario
              </Form.Label>
            </Col>
            <Col xs={12} md={7}>
              <Form.Select
                placeholder="Segmentación uso secundario"
                id="segmentationSecondary"
                disabled={disabled()}
                value={segmentationSecondary}
                hidden={hidden(filter, "Segmentación uso secundario")}
                onChange={(e) =>
                  handleChange("segmentationSecondary", e.target.value)
                }
                size="sm"
              >
                <option value=""></option>
                {Object.keys(list).map((field, index) => (
                  <option key={index} value={field}>
                    {field}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          {segmentationSecondary &&
            list[segmentationSecondary] &&
            Object.keys(list[segmentationSecondary]).length > 0 && (
              <Row
                className="mb-2"
                hidden={hidden(filter, "Tipo segmentación uso secundario")}
              >
                <Col xs={12} md={5}>
                  <Form.Label
                    hidden={hidden(filter, "Tipo segmentación uso secundario")}
                    style={{ fontSize: "0.875rem" }}
                  >
                    Tipo segmentación uso secundario
                  </Form.Label>
                </Col>
                <Col xs={12} md={7}>
                  <Form.Select
                    placeholder="Tipo segmentación uso secundario"
                    id="segmentationSecondaryType"
                    disabled={disabled()}
                    value={segmentationSecondaryType}
                    hidden={hidden(filter, "Tipo segmentación uso secundario")}
                    onChange={(e) =>
                      handleChange("segmentationSecondaryType", e.target.value)
                    }
                    size="sm"
                  >
                    {list[segmentationSecondary][0] !== null && (
                      <option value={null}></option>
                    )}
                    {Object.values(list[segmentationSecondary]).map(
                      (field, index) => (
                        <option key={index} value={field ? field : null}>
                          {field ? field : "Sin rellenar"}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Col>
              </Row>
            )}
        </Fragment>
      )}
    </Fragment>
  );
};
