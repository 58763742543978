import styled from "styled-components/macro";
// import { COLORES } from "../../constants";

export const SpecialSaleDetail = styled.div`
  padding: 1rem;
  .actual-input {
    text-align: right;
  }
  .check-container {
    label {
      margin-left: 3;
    }
  }
  .table-container {
    max-height: 300px;
    overflow-y: auto;
  }
  table {
    th,
    td {
      text-align: center;
    }
    thead {
      position: sticky;
      z-index: 1020;
    }
    .th-search div {
      display: flex;
      width: fit-content;
      margin: auto;
      input {
        max-width: 20rem;
        margin-left: 1rem;
      }
    }
    .active {
      td {
        color: #0d6efd;
      }
    }
    svg {
      cursor: pointer;
    }
  }
  input[type="checkbox"] {
    cursor: pointer;
  }
`;
