import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { RenderInputs } from "./RenderInputs";

export const ModalCreateDelegation = ({
  metadata,
  message,
  values,
  setValues,
  confirm,
  cancel,
  metadata2,
  valuesRangePrice,
  setValuesRangePrice,
  formRangePriceRangeRef,
  onClickNewRange,
  setMetadataRangePrice,
  updateHiddenProperty,
}) => {
  const [validated, setValidated] = useState(false);
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    setInitialValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }

  const handleSubmit = (event) => {
    if (!shallowEqual(values, initialValues)) {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else confirm(values);

      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      cancel();
    }
  };

  const handleRemoveRange = (index) => {
    setValuesRangePrice((prevValues) =>
      prevValues.filter((_, i) => i !== index)
    );
    setMetadataRangePrice((prevState) =>
      prevState.filter((_, i) => i !== index)
    );

    formRangePriceRangeRef.current.splice(index, 1);
  };

  return (
    <Modal
      show={true}
      onHide={cancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "30px" }}>
        <div>
          <Form
            noValidate
            id="formModal"
            validated={validated}
            onSubmit={handleSubmit}
          >
            <RenderInputs
              metadata={metadata}
              numColumns={2}
              values={values}
              setValues={setValues}
            />
          </Form>
          {!values?.default && (
            <>
              <Button onClick={onClickNewRange} variant="primary">
                <span>
                  <FontAwesomeIcon icon={faPlus} size="lg" fixedWidth />
                </span>
                Añadir rango precios
              </Button>
              {metadata2.map((form, index) => (
                <S.ContainerMetadata
                  key={index}
                  formRef={formRangePriceRangeRef?.current?.[index]}
                >
                  <Row>
                    <Col md={10}>
                      <RenderInputs
                        metadata={form}
                        numColumns={4}
                        values={valuesRangePrice[index]}
                        setValues={setValuesRangePrice}
                        index={index}
                        updateHiddenProperty={updateHiddenProperty}
                      />
                    </Col>
                    <Col
                      md={2}
                      className="d-flex align-items-center justify-content-end"
                    >
                      {index > 0 && (
                        <Button
                          onClick={() => handleRemoveRange(index)}
                          variant="danger"
                          style={{ marginTop: "1.25rem" }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="lg"
                            fixedWidth
                          />
                          Eliminar rango
                        </Button>
                      )}
                    </Col>
                  </Row>
                </S.ContainerMetadata>
              ))}
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="formModal" variant="success">
          <span>
            <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
          </span>
          Confirmar
        </Button>

        {cancel && (
          <Button onClick={cancel} variant="danger">
            <span>
              <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
            </span>
            Cancelar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
