import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Carousel } from "react-bootstrap";

export const CarouselField = ({ label, id, hidden, filter, listImages }) => {
  return (
    <Row className="mb-5" hidden={hidden(filter, label)}>
      <Col>
        <Carousel>
          {listImages.map((image, index) => (
            <Carousel.Item key={index}>
              <img
                className="w-100"
                src={image}
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/img_not_found.png";
                }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Col>
    </Row>
  );
};

CarouselField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  hidden: PropTypes.func.isRequired,
  filter: PropTypes.string,
  listImages: PropTypes.array.isRequired,
};
