import styled from "styled-components/macro";

export const LanguageSelection = styled.div`
  text-align: right;
  .form-check {
    display: inline-flex;
    margin-left: 1rem;
    align-items: center;
    cursor: pointer;
    margin-right: 0;
    input[type="radio"] {
      margin-right: 0.5rem;
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
  }
`;
