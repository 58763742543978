import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";

export const InputFormDate = ({
  label,
  placeholder,
  id,
  disabled,
  hidden,
  filter,
  value,
  handleChange,
  min,
}) => {
  return (
    <Row className="mb-2" hidden={hidden(filter, label)}>
      <Col xs={12} md={5}>
        <Form.Label
          hidden={hidden(filter, label)}
          style={{ fontSize: "0.875rem" }}
        >
          {label}
        </Form.Label>
      </Col>
      <Col hidden={hidden(filter, label)} xs={12} md={7}>
        <Form.Control
          placeholder={placeholder}
          id={id}
          disabled={disabled()}
          hidden={hidden(filter, label)}
          type="date"
          value={value}
          onChange={(e) => {
            handleChange(id, e.target.value);
          }}
          size="sm"
          min={min}
        />
      </Col>
    </Row>
  );
};

InputFormDate.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.func.isRequired,
  hidden: PropTypes.func.isRequired,
  filter: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
};
