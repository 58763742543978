import React, { useState, useEffect } from "react";
import { TableFrontEnd } from "../../components/TableFrontEnd";
import { ModalForm } from "../../components/ModalForm";
import { useLoader } from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faMapMarkerAlt,
  faPlus,
  faPrint,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import * as S from "./styles";
import * as API from "../../api";
import {
  METADATA_EDICION_USUARIO,
  METADATA_CREACION_USUARIO,
  METADATA_AUDIT_USUARIO,
} from "./constants";
import { ModalTable } from "../../components/ModalTable";
import { ModalAudit } from "../../components/ModalAudit";
import { useDataContext } from "../../context/DataState";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CardHeaderContainer } from "../Buscador/styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import usePermition from "../../constants/usePermition";
import { Fragment } from "react";

export const Usuarios = () => {
  const [values, setValues] = useState({});
  const [valuesAudit, setValuesAudit] = useState({});
  const [dialogEditarUsuarioVisible, setDialogEditarUsuarioVisible] =
    useState(false);
  const [dialogCrearUsuarioVisible, setDialogCrearUsuarioVisible] =
    useState(false);
  const [dialogAuditUsuarioVisible, setDialogAuditUsuarioVisible] =
    useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataDelegaciones, setDataDelegaciones] = useState([]);
  const [dataRoles, setDataRoles] = useState([]);
  const [modalTableDelegaciones, setModalTableDelegaciones] = useState(false);
  const [modalTableRoles, setModalTableRoles] = useState(false);
  const [idsDelegaciones, setIdsDelegaciones] = useState([]);
  const [idsRoles, setIdsRoles] = useState([]);
  const [allDelegaciones, setAllDelegaciones] = useState([]);
  // const [delegationsForTable, setDelegationsForTable] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [loader, showLoader, hideLoader] = useLoader();
  const {
    state: { appLists, user },
  } = useDataContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [allRolesSelected, setAllRolesSelected] = useState(false);
  const [allDelegationsSelected, setAllDelegationsSelected] = useState(false);
  const [typing, setTyping] = useState("Cartera");
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    const fiterPortfolioInput = document.getElementById(
      "filterCarteraDelegations"
    );
    const fiterNameInput = document.getElementById("filterNombreDelegations");

    //focus
    if (typing === "Cartera") {
      fiterPortfolioInput?.focus();
    } else {
      fiterNameInput?.focus();
    }
  }, [typing, allDelegationsSelected]);

  useEffect(() => {
    if (PERMISOS_POR_ROL.Usuarios.Acceso) {
      document.title = "EC-Light-Usuarios";

      //orden memorizado
      const order = searchParams.get("order");
      const desc = searchParams.get("desc") === "true" ? true : false;
      if (!!order) {
        const headerTH = document.getElementById(`${order}Header`);
        headerTH.click();
        if (desc) {
          headerTH.click();
        }
      }

      if (!data.length) {
        obtenerUsuarios();
        showLoader();
        API.obtenerDelegaciones()
          .then((res) => {
            let dataAuxList = [];
            setAllDelegaciones(res.data.map((e) => e.id));
            let rolesAux = [...appLists.appRoles.map((s) => s.id)];
            setAllRoles(rolesAux);
            res.data.forEach((e) => {
              const dataAux = {};
              dataAux.id = e.id;
              dataAux.name = e.name;
              const portfolio = appLists.appPortfolios.find(
                (s) => s.id === e.portfolioId
              );
              if (portfolio !== null) {
                dataAux.portfolio = portfolio.name;
              }
              dataAuxList.push(dataAux);
            });
            setDataDelegaciones(dataAuxList);
          })
          .catch((error) => {
            API.DEVELOP && console.log(error);
          })
          .finally(() => hideLoader());
      }
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (!!modalTableDelegaciones) {
      if (dataDelegaciones.length === idsDelegaciones.length) {
        setAllDelegationsSelected(true);
      }
    } else {
      setAllDelegationsSelected(false);
    }
  }, [modalTableDelegaciones]);

  useEffect(() => {
    if (!!data.length) {
      areAllSelectedDelegations("");
    }
  }, [idsDelegaciones]);

  const obtenerUsuarios = () => {
    showLoader();
    API.obtenerUsuarios()
      .then((res) => {
        let dataAuxList = [];
        res.data.forEach((e) => {
          const dataAux = {};
          dataAux.id = e.id;
          dataAux.email = e.email;
          dataAux.name = e.name;
          dataAux.numRoles = e.roles.length;
          dataAux.delegations = e.delegations;
          dataAux.numDelegations = e.delegations.length;
          dataAux.rolesAux = e.roles;
          dataAux.roles = "";
          e.roles.map(
            (rol) => (dataAux.roles = dataAux.roles + obtenerRol(rol) + "; ")
          );
          dataAux.portfolios = "";
          e.portfolios.map(
            (portfolio) =>
              (dataAux.portfolios =
                dataAux.portfolios + obtenerPortfolio(portfolio) + "; ")
          );
          dataAux.roles = dataAux.roles.slice(0, -2);
          dataAux.portfolios = dataAux.portfolios.slice(0, -2);
          dataAux.isDeleted = e.isDeleted;
          if (dataAux.isDeleted) {
            dataAux.isDeletedAux = "Si";
          } else {
            dataAux.isDeletedAux = "No";
          }
          dataAux.hasAccess = e.hasAccess;
          if (dataAux.hasAccess) {
            dataAux.hasAccessAux = "Si";
          } else {
            dataAux.hasAccessAux = "No";
          }
          dataAux.creatorId = e.creatorId;
          if (e.creationTime !== null) dataAux.creationTime = e.creationTime;
          dataAux.lastModifierId = e.lastModifierId;
          if (e.lastModificationTime !== null)
            dataAux.lastModificationTime = formatDate(e.lastModificationTime);
          if (e.lastAccess !== null)
            dataAux.lastAccess = formatDate(e.lastAccess);
          dataAuxList.push(dataAux);
        });
        setData(dataAuxList);
        if (!!!filteredData.length) {
          setFilteredData(dataAuxList);
        }
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const obtenerRol = (rolId) => {
    if (appLists) {
      const rol = appLists.appRoles.find((s) => s.id === rolId);
      if (rol !== undefined && rol !== null) {
        return rol.normalizedName;
      } else {
        return "";
      }
    }
  };

  const obtenerPortfolio = (portfolioId) => {
    if (appLists) {
      const portfolio = appLists.appPortfolios.find(
        (s) => s.id === portfolioId
      );
      if (portfolio !== undefined && portfolio !== null) {
        return portfolio.name;
      } else {
        return "";
      }
    }
  };

  const formatDate = (dateString) => {
    var date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() + -offset * 60000);
    let d = date;
    d = [
      "0" + d.getDate(),
      "0" + (d.getMonth() + 1),
      "" + d.getFullYear(),
      "0" + d.getHours(),
      "0" + d.getMinutes(),
      "0" + d.getSeconds(),
    ].map((component) => {
      if (component.length < 4) {
        return component.slice(-2);
      } else {
        return component;
      }
    });

    return d.slice(0, 3).join("/") + " " + d.slice(3).join(":");
  };

  const FilterForm = ({ column }) => {
    const { filterValue, setFilter } = column;

    useEffect(() => {
      const value = searchParams.get(
        "filter" + column.Header.split(" ").join("")
      );
      if (value) {
        setFilter(value);
      }
    }, []);
    return (
      <Form>
        <Form.Group className="mb-2" id={`search${column.Header}`}>
          <span>
            <input
              id={"filter" + column.Header.split(" ").join("")}
              className="form-control"
              style={{ width: "60%", marginTop: "0.25rem", padding: "0" }}
              value={filterValue || ""}
              onClick={(e) => {
                if (!e) e = window.event;
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
          </span>
        </Form.Group>
      </Form>
    );
  };

  const FilterFormDelegations = ({ column }) => {
    const { filterValue, setFilter } = column;

    return (
      <Form>
        <Form.Group className="mb-2" id={`search${column.Header}`}>
          <span>
            <input
              id={"filter" + column.Header.split(" ").join("") + "Delegations"}
              className="form-control"
              style={{ width: "60%", marginTop: "0.25rem", padding: "0" }}
              value={filterValue || ""}
              onClick={(e) => {
                if (!e) e = window.event;
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => {
                setFilter(e.target.value);
                areAllSelectedDelegations(column.Header.split(" ").join(""));
              }}
            />
          </span>
        </Form.Group>
      </Form>
    );
  };

  const FilterFormRoles = ({ column }) => {
    const { filterValue, setFilter } = column;
    useEffect(() => {
      areAllSelectedRoles();
    }, [filterValue]);
    return (
      <Form>
        <Form.Group className="mb-2" id={`search${column.Header}`}>
          <span>
            <input
              id={"filter" + column.Header.split(" ").join("") + "Roles"}
              className="form-control"
              style={{ width: "60%", marginTop: "0.25rem", padding: "0" }}
              value={filterValue || ""}
              onClick={(e) => {
                if (!e) e = window.event;
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              autoComplete="off"
            />
          </span>
        </Form.Group>
      </Form>
    );
  };

  const defaultColumn = {
    // Let's set up our default Filter UI
    Filter: FilterForm,
  };

  const defaultColumnDelegations = {
    // Let's set up our default Filter UI
    Filter: FilterFormDelegations,
  };

  const defaultColumntRoles = {
    // Let's set up our default Filter UI
    Filter: FilterFormRoles,
  };

  const defaultSort = [
    {
      id: "id",
      desc: false,
    },
  ];

  const columns = [
    {
      Header: "",
      accessor: "edit",
      disableFilters: true,
      disableSortBy: true,
      width: 40,
      Cell: ({ cell }) => (
        <Fragment>
          <button
            className="tableButton"
            onClick={() => showModalEdit(cell)}
            hidden={!PERMISOS_POR_ROL.Usuarios.Crud}
          >
            <span title="Editar">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEdit}
                size="lg"
                fixedWidth
              />
            </span>
          </button>
          <button
            style={{ marginLeft: "0.5rem" }}
            className="tableButton"
            onClick={() => showModalAudit(cell)}
            hidden={!PERMISOS_POR_ROL.Usuarios.Auditoria}
          >
            <span title="Auditoría">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faPrint}
                size="lg"
                fixedWidth
              />
            </span>
          </button>
        </Fragment>
      ),
    },
    {
      Header: "Código",
      accessor: "id",
      Filter: FilterForm,
    },
    {
      Header: "Nombre",
      accessor: "name",
      Filter: FilterForm,
      Cell: ({ cell }) => (
        <Fragment>
          {data.find((e) => e.id === cell.row.original.id).name}
        </Fragment>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Filter: FilterForm,
      Cell: ({ cell }) => (
        <Fragment>
          {data.find((e) => e.id === cell.row.original.id).email}
        </Fragment>
      ),
    },
    {
      Header: "Delegaciones",
      accessor: "delegations",
      Filter: FilterForm,
    },
    {
      Header: "Nº delegaciones",
      accessor: "numDelegations",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <S.CellModalWrapper
          onClick={() => {
            PERMISOS_POR_ROL.Usuarios.Crud
              ? abrirModalDelegaciones(cell.row.original.id)
              : toast.error("No tienes permiso para gestionar delegaciones.");
          }}
          title="Editar delegaciones"
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" fixedWidth />{" "}
          {data.find((e) => e.id === cell.row.original.id).numDelegations}
        </S.CellModalWrapper>
      ),
    },
    {
      Header: "Nº roles",
      accessor: "numRoles",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <S.CellModalWrapper
          onClick={() => {
            PERMISOS_POR_ROL.Usuarios.Crud
              ? abrirModalRoles(cell.row.original.id)
              : toast.error("No tienes permiso para gestionar roles.");
          }}
          title="Editar roles"
        >
          <FontAwesomeIcon icon={faStar} size="lg" fixedWidth />{" "}
          {data.find((e) => e.id === cell.row.original.id).numRoles}
        </S.CellModalWrapper>
      ),
    },
    {
      Header: "Roles",
      accessor: "roles",
      disableSortBy: true,
      Filter: FilterForm,
      maxWidth: 200,
      Cell: ({ cell }) => (
        <Fragment>
          {!!data.find((e) => e.id === cell.row.original.id).roles.length
            ? data
                .find((e) => e.id === cell.row.original.id)
                .roles.split("; ")
                .map((element, index) => <p key={index}>{element + ";"}</p>)
            : ""}
        </Fragment>
      ),
    },
    {
      Header: "Roles",
      accessor: "rolesAux",
      disableSortBy: true,
      Filter: FilterForm,
      maxWidth: 200,
    },
    {
      Header: "Carteras",
      accessor: "portfolios",
      disableSortBy: true,
      Filter: FilterForm,
      maxWidth: 150,
      Cell: ({ cell }) => (
        <Fragment>
          {!!data.find((e) => e.id === cell.row.original.id).portfolios.length
            ? data
                .find((e) => e.id === cell.row.original.id)
                .portfolios.split("; ")
                .map((element, index) => <p key={index}>{element + ";"}</p>)
            : ""}
        </Fragment>
      ),
    },
    {
      Header: "Usuario Creación",
      accessor: "creatorId",
    },
    {
      Header: "Fecha Creación",
      accessor: "creationTime",
      disableFilters: true,
      Cell: ({ cell }) => (
        <span>{formatDate(cell.row.original.creationTime)}</span>
      ),
    },
    {
      Header: "Usuario Última Modificación",
      accessor: "lastModifierId",
    },
    {
      Header: "Fecha Última Modificación",
      accessor: "lastModificationTime",
    },
    {
      Header: "Último Acceso",
      accessor: "lastAccess",
    },
    {
      Header: "Deshabilitado",
      accessor: "isDeleted",
    },
    {
      Header: "Deshabilitado",
      accessor: "isDeletedAux",
    },
    {
      Header: "Acceso",
      accessor: "hasAccess",
      maxWidth: 60,
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id).hasAccessAux}
        </span>
      ),
    },
    {
      Header: "Acceso",
      accessor: "hasAccessAux",
      maxWidth: 60,
      Cell: ({ cell }) => (
        <span>
          {data.find((e) => e.id === cell.row.original.id).hasAccess
            ? "Si"
            : "No"}
        </span>
      ),
    },
  ];

  const defaultSortDelegacion = [
    {
      id: "select",
      desc: false,
    },
  ];

  const areAllSelectedDelegations = (header) => {
    let allSelected = true;
    setTyping(header);

    const fiterPortfolioInput = document.getElementById(
      "filterCarteraDelegations"
    );
    const fiterNameInput = document.getElementById("filterNombreDelegations");

    const nameValue = fiterNameInput?.value?.toLowerCase();
    const portfolioValue = fiterPortfolioInput?.value?.toLowerCase();

    const filteredDelegations = dataDelegaciones
      .filter(
        (e) =>
          e.name.toLowerCase().includes(nameValue) &&
          e.portfolio.toLowerCase().includes(portfolioValue)
      )
      .map((del) => del.id);

    if (filteredDelegations.length <= idsDelegaciones.length) {
      const selecteds = filteredDelegations.filter((e) =>
        idsDelegaciones.includes(e)
      );
      if (selecteds.length !== filteredDelegations.length) {
        allSelected = false;
      }
    } else {
      allSelected = false;
    }
    setAllDelegationsSelected(allSelected);
  };

  const areAllSelectedRoles = () => {
    let allSelected = true;
    const filterNameElement = document.getElementById("filterNombreRoles");

    if (!!filterNameElement) {
      const filterName = filterNameElement.value.toLowerCase();
      const ids = dataRoles.filter((el) =>
        el.normalizedName.toLowerCase().includes(filterName)
      );
      let filteredArray = ids.map((e) => e.id);

      if (filteredArray.length <= idsRoles.length) {
        const selecteds = filteredArray.filter((e) => idsRoles.includes(e));
        if (selecteds.length !== filteredArray.length) {
          allSelected = false;
        }
      } else {
        allSelected = false;
      }
    }
    setAllRolesSelected(allSelected);
    filterNameElement.focus();
  };

  const columnsDelegacion = [
    {
      Header: (
        <Form.Check
          onChange={(e) => {
            handleChangeAllDelegaciones(e.target.checked);
          }}
          checked={allDelegationsSelected}
        />
      ),
      accessor: "select",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Form.Check
          onChange={(e) => {
            handleChange(
              parseInt(cell.row.original.id),
              e.target.checked,
              "Delegations"
            );
          }}
          checked={idsDelegaciones.includes(cell.row.original.id)}
        />
      ),
      maxWidth: 35,
    },
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Nombre",
      accessor: "name",
      maxWidth: 250,
    },
    {
      Header: "Cartera",
      accessor: "portfolio",
      maxWidth: 80,
    },
  ];

  const defaultSortRol = [
    {
      id: "id",
      desc: false,
    },
  ];

  const columnsRol = [
    {
      Header: (
        <Form.Check
          onChange={(e) => {
            handleChangeAllRoles(e.target.checked);
          }}
          checked={allRolesSelected}
        />
      ),
      accessor: "select",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Form.Check
          onChange={(e) => {
            handleChange(cell.row.original.id, e.target.checked, "Roles");
          }}
          checked={idsRoles.includes(cell.row.original.id)}
        />
      ),
      maxWidth: 25,
    },
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Rol",
      accessor: "name",
    },
    {
      Header: "Nombre",
      accessor: "normalizedName",
    },
  ];

  const handleChange = (id, checked, type) => {
    if (type === "Delegations") {
      if (checked) {
        const tmpValues = [...idsDelegaciones];
        tmpValues.push(id);
        setIdsDelegaciones(tmpValues);
      } else {
        const tmpValues = [...idsDelegaciones];
        var ic = tmpValues.indexOf(id);
        tmpValues.splice(ic, 1);
        if (ic > -1) setIdsDelegaciones(tmpValues);
      }
    } else if (type === "Roles") {
      if (checked) {
        const tmpValues = [...idsRoles];
        tmpValues.push(id);
        setIdsRoles(tmpValues);
      } else {
        const tmpValues = [...idsRoles];
        var ip = tmpValues.indexOf(id);
        tmpValues.splice(ip, 1);
        if (ip > -1) setIdsRoles(tmpValues);
      }
    }
  };

  const handleChangeAllDelegaciones = (checked) => {
    const filterName = document
      .getElementById("filterNombreDelegations")
      .value.toLowerCase();
    const filterPortfolio = document
      .getElementById("filterCarteraDelegations")
      .value.toLowerCase();

    if (checked) {
      const ids = dataDelegaciones.filter(
        (element) =>
          element.name.toLowerCase().includes(filterName) &&
          element.portfolio.toLowerCase().includes(filterPortfolio)
      );
      let delegacionesAux = ids.map((el) => el.id);
      const noCoinsidence = idsDelegaciones.filter(
        (e) => !delegacionesAux.includes(e)
      );
      delegacionesAux = [...delegacionesAux, ...noCoinsidence];
      setIdsDelegaciones(delegacionesAux);
    } else {
      const delgs = dataDelegaciones.filter(
        (element) =>
          element.portfolio.toLowerCase().includes(filterPortfolio) &&
          element.name.toLowerCase().includes(filterName)
      );
      const idsToRemove = delgs.map((e) => e.id);
      const newIds = idsDelegaciones.filter((e) => !idsToRemove.includes(e));
      setIdsDelegaciones(newIds);
    }
  };

  const handleChangeAllRoles = (checked) => {
    const filterName = document
      .getElementById("filterNombreRoles")
      .value.toLowerCase();
    if (checked) {
      const ids = dataRoles.filter((el) =>
        el.normalizedName.toLowerCase().includes(filterName)
      );
      let rolesAux = ids.map((e) => e.id);
      const noCoinsidence = idsRoles.filter((e) => !rolesAux.includes(e));
      rolesAux = [...rolesAux, ...noCoinsidence];
      setIdsRoles(rolesAux);
    } else {
      const roles = dataRoles.filter((role) =>
        role.normalizedName.toLowerCase().includes(filterName)
      );
      const idsToRemove = roles.map((e) => e.id);
      const newIds = idsRoles.filter((e) => !idsToRemove.includes(e));
      setIdsRoles(newIds);
    }
  };

  const abrirModalDelegaciones = (idUser) => {
    const ids = data.find((e) => e.id === idUser).delegations;
    if (PERMISOS_POR_ROL.Usuarios.Crud) {
      let checkedDelegations = dataDelegaciones.filter((e) =>
        ids.includes(e.id)
      );
      let noCheckedDelegations = dataDelegaciones.filter(
        (e) => !ids.includes(e.id)
      );
      setDataDelegaciones([...checkedDelegations, ...noCheckedDelegations]);
      // seguir aqui
      setIdsDelegaciones(ids);
      setCurrentUser(idUser);
      setModalTableDelegaciones(true);
    }
  };

  const abrirModalRoles = (idUser) => {
    const ids = data.find((e) => e.id === idUser).rolesAux;
    if (PERMISOS_POR_ROL.Usuarios.Crud) {
      setIdsRoles(ids);
      setCurrentUser(idUser);
      if (appLists !== null) {
        setDataRoles(appLists.appRoles);
      }
      if (appLists.appRoles.length === idsRoles.length) {
        setAllRolesSelected(true);
      }
      setModalTableRoles(true);
    }
  };

  const showModalCreate = () => {
    setValues({});
    setDialogCrearUsuarioVisible(true);
  };

  const showModalEdit = (cell) => {
    setValues(cell.row.values);
    setDialogEditarUsuarioVisible(true);
  };

  const showModalAudit = (cell) => {
    let cellValues = { ...cell.row.values };
    const creator = data.find((s) => s.id === cellValues.creatorId);
    if (creator !== undefined) {
      cellValues.creatorId = creator.name;
    }
    const modifier = data.find((s) => s.id === cellValues.lastModifierId);
    if (modifier !== undefined) {
      cellValues.lastModifierId = modifier.name;
    }
    cellValues.creationTime = formatDate(cellValues.creationTime);

    const valueCode = document.getElementById("filterCódigo").value;
    const valueName = document.getElementById("filterNombre").value;
    const valueEmail = document.getElementById("filterEmail").value;
    const valueRoles = document.getElementById("filterRoles").value;
    const valuePortfolies = document.getElementById("filterCarteras").value;
    const valueAccess = document.getElementById("filterAcceso").value;

    const sort = document.getElementById("sortedData");
    const sortCol = sort.getAttribute("column");
    const sortDesc = sort.nextElementSibling.innerText === " ↓";

    const url = `/users?${valueCode !== "" ? `filterCódigo=${valueCode}` : ""}${
      valueName !== ""
        ? valueCode !== ""
          ? `&filterNombre=${valueName}`
          : `filterNombre=${valueName}`
        : ""
    }${
      valueEmail !== ""
        ? valueCode !== "" || valueName !== ""
          ? `&filterEmail=${valueEmail}`
          : `filterEmail=${valueEmail}`
        : ""
    }${
      valueRoles !== ""
        ? valueCode !== "" || valueName !== "" || valueEmail !== ""
          ? `&filterRoles=${valueRoles}`
          : `filterRoles=${valueRoles}`
        : ""
    }${
      valuePortfolies !== ""
        ? valueCode !== "" ||
          valueName !== "" ||
          valueEmail !== "" ||
          valueRoles !== ""
          ? `&filterCarteras=${valuePortfolies}`
          : `filterCarteras=${valuePortfolies}`
        : ""
    }${
      valueAccess !== ""
        ? `${
            valueCode !== "" ||
            valueName !== "" ||
            valueEmail !== "" ||
            valueRoles !== "" ||
            valuePortfolies !== ""
              ? `&filterAcceso=${valueAccess}`
              : `filterAcceso=${valueAccess}`
          }`
        : ""
    }${
      valueCode !== "" ||
      valueName !== "" ||
      valueEmail !== "" ||
      valueRoles !== "" ||
      valuePortfolies !== "" ||
      valueAccess !== ""
        ? `&order=${sortCol}&desc=${sortDesc}`
        : `order=${sortCol}&desc=${sortDesc}`
    }`;

    navigate(url);
    setValuesAudit(cellValues);
    setDialogAuditUsuarioVisible(true);
  };

  const confirmCreate = (value) => {
    showLoader();
    setDialogCrearUsuarioVisible(false);
    API.crearUsuario(value)
      .then((res) => {
        toast.success("Usuario creado correctamente");
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
        obtenerUsuarios();
      });
  };

  const confirmEdit = (value) => {
    setDialogEditarUsuarioVisible(false);
    showLoader();
    API.editarUsuario(value)
      .then((res) => {
        toast.success("Usuario editado correctamente");
        obtenerUsuarios();
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const updateDelegaciones = () => {
    showLoader();
    let reqData = {};
    reqData["userId"] = currentUser;
    obtenerUsuarios();
    reqData["delegationIDs"] = idsDelegaciones;
    setModalTableDelegaciones(false);
    API.editarUsuarioDelegaciones(reqData)
      .then((res) => {
        toast.success("Usuario editado correctamente");
        setCurrentUser("");
        setIdsDelegaciones([]);
        obtenerUsuarios();
        //Si el usuario a modificar es el mismo logueado va a la pagina de inicio y llama al Access para actulizar todos los datos del usuario
        if (user.id === currentUser) {
          window.location = process.env.REACT_APP_BASE_URL;
        }
        // let auxUserList = [...data];
        // let auxUser = auxUserList.find((e) => e.id === currentUser);
        // auxUser.numDelegations = idsDelegaciones.length;
        // auxUser.delegations = idsDelegaciones;

        // setData(auxUserList);
        obtenerUsuarios();
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  const updateRoles = () => {
    let reqData = {};
    reqData["userId"] = currentUser;
    reqData["roleIDs"] = idsRoles;
    setModalTableRoles(false);
    showLoader();
    API.editarUsuarioRoles(reqData)
      .then((res) => {
        toast.success("Usuario editado correctamente");
        setCurrentUser("");
        setIdsRoles([]);
        if (user.id === currentUser) {
          window.location = process.env.REACT_APP_BASE_URL;
        }
        obtenerUsuarios();
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  return (
    <S.Container>
      {loader}
      {modalTableDelegaciones && (
        <ModalTable
          message="Seleccionar delegaciones"
          columns={columnsDelegacion}
          data={dataDelegaciones}
          defaultSort={defaultSortDelegacion}
          defaultColumn={defaultColumnDelegations}
          hiddenColumns={["id"]}
          labelConfirm="Confirmar"
          cancel={() => setModalTableDelegaciones(false)}
          confirm={updateDelegaciones}
          pagination={false}
        />
      )}
      {modalTableRoles && (
        <ModalTable
          message="Seleccionar roles"
          columns={columnsRol}
          data={dataRoles}
          defaultSort={defaultSortRol}
          defaultColumn={defaultColumntRoles}
          hiddenColumns={["id", "name"]}
          labelConfirm="Confirmar"
          cancel={() => setModalTableRoles(false)}
          confirm={() => {
            updateRoles();
          }}
          pagination={false}
        />
      )}
      {dialogEditarUsuarioVisible && (
        <ModalForm
          metadata={METADATA_EDICION_USUARIO}
          message="Datos de Usuario"
          values={values}
          setValues={setValues}
          cancel={() => setDialogEditarUsuarioVisible(false)}
          confirm={confirmEdit}
        />
      )}
      {dialogCrearUsuarioVisible && (
        <ModalForm
          metadata={METADATA_CREACION_USUARIO}
          message="Datos de Usuario"
          values={values}
          setValues={setValues}
          cancel={() => setDialogCrearUsuarioVisible(false)}
          confirm={confirmCreate}
        />
      )}
      {dialogAuditUsuarioVisible && (
        <ModalAudit
          metadata={METADATA_AUDIT_USUARIO}
          message="Auditoría"
          values={valuesAudit}
          cancel={() => setDialogAuditUsuarioVisible(false)}
          entityName="User"
        />
      )}
      <Card>
        <Card.Header
          style={{
            paddingLeft: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <CardHeaderContainer>
            <PageTitle title="Usuarios" />
            <Button
              variant="success"
              onClick={() => showModalCreate()}
              hidden={!PERMISOS_POR_ROL.Usuarios.Crud}
            >
              <span>
                <FontAwesomeIcon icon={faPlus} size="lg" fixedWidth />
              </span>
              Crear Usuario
            </Button>
          </CardHeaderContainer>
        </Card.Header>
        <Card.Body>
          <TableFrontEnd
            columns={columns}
            data={filteredData}
            defaultSort={defaultSort}
            defaultColumn={defaultColumn}
            hiddenColumns={[
              "isDeleted",
              "isDeletedAux",
              "delegations",
              "rolesAux",
              "creatorId",
              "creationTime",
              "lastModifierId",
              "lastModificationTime",
              "lastAccess",
              "hasAccess",
            ]}
            pagination={true}
          />
        </Card.Body>
      </Card>
    </S.Container>
  );
};
