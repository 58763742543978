import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ModalAssignDelegation = ({
  message,
  cancel,
  confirm,
  assetsForced,
  moveForced,
  setMoveForced,
  typeAssign,
}) => {
  return (
    <Modal
      show={true}
      onHide={cancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeAssign === "Municipios" && (
          <p>
            Esta acción hará que los municipios seleccionados (y sus activos)
            formen parte de esta Delegación para esta línea de negocio y
            cartera. ¿Confirma esta acción?
          </p>
        )}
        {typeAssign === "Provincias" && (
          <p>
            Esta acción hará que las provincias seleccionadas (y los activos que
            contienen sus municipios) formen parte de esta Delegación para esta
            línea de negocio y cartera. ¿Confirma esta acción?
          </p>
        )}
        {assetsForced && (
          <Fragment>
            <hr />
            <div style={{ marginBottom: "1rem" }}>
              <p>
                La selección contiene activos con una delegación forzada ¿Desea
                mover estos activos?
                <input
                  style={{ marginLeft: "0.5rem" }}
                  type="checkbox"
                  checked={moveForced}
                  onChange={(e) => {
                    setMoveForced(e.target.checked);
                  }}
                />
              </p>
            </div>
            <textarea
              style={{ width: "100%", marginBottom: "1rem" }}
              disabled="true"
            >
              {assetsForced}
            </textarea>
          </Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginRight: "1rem" }}
          onClick={() => confirm()}
          variant="success"
        >
          <span>
            <FontAwesomeIcon icon={faCheck} size="lg" fixedWidth />
          </span>
          Confirmar
        </Button>

        {cancel && (
          <Button onClick={cancel} variant="danger">
            <span>
              <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
            </span>
            Cancelar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalAssignDelegation.propTypes = {
  message: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  assetsForced: PropTypes.string.isRequired,
  moveForced: PropTypes.bool.isRequired,
  setMoveForced: PropTypes.func.isRequired,
  typeAssign: PropTypes.string.isRequired,
};
