import React, { useState, useEffect, Fragment } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { Row, Col, Tabs, Tab, Card } from "react-bootstrap";
import { TableBackEnd } from "../../components/TableBackEnd";
import { useLoader } from "../../components/Loading";
import { useDataContext } from "../../context/DataState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { portfolios } from "../../constants";
import usePermition from "../../constants/usePermition";

export const Inicio = () => {
  const [loader, showLoader, hideLoader] = useLoader();
  const [dataActivos, setDataActivos] = useState([]);
  const [dataProductos, setDataProductos] = useState([]);
  const [pageActivos, setPageActivos] = useState(0);
  const [pageProductos, setPageProductos] = useState(0);
  const [totalPagesActivos, setTotalPagesActivos] = useState(1);
  const [totalPagesProductos, setTotalPagesProductos] = useState(1);
  const [numRegistersActivos, setNumRegistersActivos] = useState(0);
  const [numRegistersProductos, setNumRegistersProductos] = useState(0);
  const [orderActivos, setOrderActivos] = useState("");
  const [orderProductos, setOrderProductos] = useState("");
  const [orderDescActivos, setOrderDescActivos] = useState(true);
  const [orderDescProductos, setOrderDescProductos] = useState(true);
  const {
    dispatch,
    state: { appLists, user, regLength },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    document.title = "EC-Light-Inicio";
    dispatch({
      type: "UPDATE_REG_LENGTH",
      payload: 50,
    });
  }, []);

  useEffect(() => {
    setPageActivos(1);
    setPageProductos(1);
  }, [appLists]);

  const formatDate = (dateString) => {
    var date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() + -offset * 60000);
    let d = date;
    d = [
      "0" + d.getDate(),
      "0" + (d.getMonth() + 1),
      "" + d.getFullYear(),
      "0" + d.getHours(),
      "0" + d.getMinutes(),
      "0" + d.getSeconds(),
    ].map((component) => {
      if (component.length < 4) {
        return component.slice(-2);
      } else {
        return component;
      }
    });

    return d.slice(0, 3).join("/") + " " + d.slice(3).join(":");
  };

  const handleChangePagination = (value) => {
    dispatch({
      type: "UPDATE_REG_LENGTH",
      payload: value,
    });
  };

  const defaultSortActivos = [
    {
      id: "lastPublicationDate",
      desc: true,
    },
  ];

  const columnsActivos = [
    {
      Header: "",
      accessor: "detail",
      disableFilters: true,
      disableSortBy: true,
      width: 40,
      Cell: ({ cell }) => (
        <Fragment>
          <Link
            to={
              cell.row.original.productId
                ? "/product-detail/" +
                  cell.row.original.productId +
                  "/" +
                  cell.row.original.id
                : user.currentPortfolioID !== portfolios.name.particulares
                  ? "/asset-detail/" + cell.row.original.id
                  : "/particular-asset/" + cell.row.original.id
            }
          >
            <span title="Ver detalle">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEye}
                size="lg"
                fixedWidth
              />
            </span>
          </Link>
        </Fragment>
      ),
    },
    {
      Header: "Id",
      accessor: "id",
      disableFilters: true,
    },
    {
      Header: "Producto Id",
      accessor: "productId",
      disableFilters: true,
    },
    {
      Header: "Código",
      accessor: "code",
      disableFilters: true,
      width: 80,
    },
    {
      Header: "Descripción",
      accessor: "description",
      disableFilters: true,
      maxWidth: 400,
    },
    {
      Header: "Última publicación",
      accessor: "lastPublicationDate",
      disableFilters: true,
      maxWidth: 100,
    },
    {
      Header: "Línea de negocio",
      accessor: "businessLine",
      disableFilters: true,
      width: 100,
    },
    {
      Header: "Subtipo",
      accessor: "type",
      disableFilters: true,
      width: 100,
    },
  ];

  const defaultSortProductos = [
    {
      id: "code",
      desc: false,
    },
  ];

  const columnsProductos = [
    {
      Header: "",
      accessor: "detail",
      disableFilters: true,
      disableSortBy: true,
      maxWidth: 80,
      Cell: ({ cell }) => (
        <Fragment>
          <Link
            to={
              "/product-detail/" +
              cell.row.original.id +
              "/" +
              cell.row.original.defaultAssetId
            }
          >
            <span title="Ver detalle">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEye}
                size="lg"
                fixedWidth
              />
            </span>
          </Link>
        </Fragment>
      ),
    },
    {
      Header: "Id",
      accessor: "id",
      disableFilters: true,
    },
    {
      Header: "Activo Id",
      accessor: "defaultAssetId",
      disableFilters: true,
    },
    {
      Header: "Código Producto",
      accessor: "code",
      disableFilters: true,
      maxWidth: 100,
    },
    {
      Header: "Nombre comercial",
      accessor: "tradeName",
      disableFilters: true,
    },
    {
      Header: "Estado",
      accessor: "status",
      disableFilters: true,
      maxWidth: 140,
    },
    {
      Header: "Disponibilidad",
      accessor: "isAvailable",
      disableFilters: true,
      maxWidth: 60,
    },
    {
      Header: "Publicado",
      accessor: "isPublishable",
      disableFilters: true,
      maxWidth: 60,
    },
  ];

  const busquedaProductos = () => {
    if (pageProductos > 0) {
      showLoader();
      let data = {};
      let collection = [];
      data.page = pageProductos;
      data.regsPage = regLength;
      if (orderProductos !== null && orderProductos !== "") {
        data.orderBy = orderProductos;
        data.isAsc = orderDescProductos;
      } else {
        data.orderBy = "id";
        data.isAsc = true;
      }
      API.busquedaEditorContenidoProductos(data)
        .then((res) => {
          res.data.collection.forEach((e) => {
            let collectionElement = {};
            collectionElement.id = e.id;
            collectionElement.defaultAssetId = e.defaultAssetId;
            collectionElement.code = e.code;
            collectionElement.tradeName = e.tradeName;
            const productStatus = appLists["productStatus"].find(
              (s) => s.Id === e.status
            );
            if (productStatus !== undefined) {
              collectionElement.status = productStatus.translation;
            }
            if (e.isAvailable === true) {
              collectionElement.isAvailable = "Si";
            } else if (e.isAvailable === false) {
              collectionElement.isAvailable = "No";
            } else {
              collectionElement.isAvailable = "";
            }
            if (e.isPublishable === true) {
              collectionElement.isPublishable = "Si";
            } else if (e.isPublishable === false) {
              collectionElement.isPublishable = "No";
            } else {
              collectionElement.isPublishable = "";
            }
            collection.push(collectionElement);
          });
          setDataProductos(collection);
          setTotalPagesProductos(res.data.maxPages);
          setNumRegistersProductos(res.data.numRegisters);
        })
        .catch((error) => API.DEVELOP && console.log(error))
        .finally(() => hideLoader());
    }
  };

  const busquedaActivos = () => {
    if (pageActivos > 0) {
      showLoader();
      let data = {};
      let collection = [];
      data.page = pageActivos;
      data.regsPage = regLength;
      if (orderActivos !== null && orderActivos !== "") {
        data.orderBy = orderActivos;
        data.isAsc = orderDescActivos;
      } else {
        data.orderBy = "id";
        data.isAsc = true;
      }
      API.busquedaEditorContenidoActivos(data)
        .then((res) => {
          res.data.collection.forEach((e) => {
            let collectionElement = {};
            collectionElement.id = e.id;
            collectionElement.productId = e.productId;
            collectionElement.code = e.code;
            collectionElement.description = e.description;
            const assetType = appLists["assetType"].find(
              (s) => s.Id === e.type
            );
            if (assetType !== undefined) {
              collectionElement.type = assetType.translation;
            }
            const businessLine = appLists["businessLines"].find(
              (s) => s.Id === e.businessLine
            );
            if (businessLine !== undefined) {
              collectionElement.businessLine = businessLine.translation;
            }
            if (e.lastPublicationDate !== null)
              collectionElement.lastPublicationDate = formatDate(
                e.lastPublicationDate
              );
            collection.push(collectionElement);
          });
          setDataActivos(collection);
          setTotalPagesActivos(res.data.maxPages);
          setNumRegistersActivos(res.data.numRegisters);
        })
        .catch((error) => API.DEVELOP && console.log(error))
        .finally(() => hideLoader());
    }
  };

  return (
    <S.HomePage>
      {loader}
      <Row className="mt-4 mb-4">
        <Col>
          <S.Title>Bienvenido a EC LIGHT</S.Title>
        </Col>
      </Row>
      {PERMISOS_POR_ROL.Inicio.Tabla && (
        <Row>
          <Col>
            <Tabs
              defaultActiveKey="productos"
              id="tabsInicio"
              className="mb-3"
              fill
            >
              <Tab eventKey="productos" title="Productos">
                <Card>
                  <Card.Header>
                    Hay un total de {numRegistersProductos} productos sin
                    descripción. Son los que se muestran en la siguiente tabla:
                  </Card.Header>
                  <Card.Body>
                    <TableBackEnd
                      columns={columnsProductos}
                      data={dataProductos}
                      defaultSort={defaultSortProductos}
                      hiddenColumns={["id", "defaultAssetId"]}
                      pageTable={pageProductos}
                      regsPage={regLength}
                      setPage={setPageProductos}
                      setRegsPage={handleChangePagination}
                      search={busquedaProductos}
                      totalPages={totalPagesProductos}
                      numRegisters={numRegistersProductos}
                      order={orderProductos}
                      setOrder={setOrderProductos}
                      orderDesc={orderDescProductos}
                      setOrderDesc={setOrderDescProductos}
                    />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="activos" title="Activos">
                <Card>
                  <Card.Header>
                    A continuación se muestran los {numRegistersActivos} activos
                    publicados:
                  </Card.Header>
                  <Card.Body>
                    <TableBackEnd
                      columns={columnsActivos}
                      data={dataActivos}
                      defaultSort={defaultSortActivos}
                      hiddenColumns={["id", "productId"]}
                      pageTable={pageActivos}
                      regsPage={regLength}
                      setPage={setPageActivos}
                      setRegsPage={handleChangePagination}
                      search={busquedaActivos}
                      totalPages={totalPagesActivos}
                      numRegisters={numRegistersActivos}
                      order={orderActivos}
                      setOrder={setOrderActivos}
                      orderDesc={orderDescActivos}
                      setOrderDesc={setOrderDescActivos}
                    />
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
    </S.HomePage>
  );
};
