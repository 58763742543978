import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const StyledEmptyData = styled.div`
  height: ${({ height }) => (height ? height : "53.1vh")};
  #messageContainer {
    margin: auto;
    width: fit-content;
    top: 50%;
    bottom: auto;
    position: relative;
    span {
      color: ${COLORES.TITLE_CAMPAIGN_PROMOTION};
      font-size: larger;
      font-weight: 600;
    }
  }
`;
