// FUNCIONES

export const disabledTrue = () => {
  return true;
};

export const disabledFalse = () => {
  return false;
};

export const hiddenTrue = (filter, label) => {
  return true;
};

export const hiddenFalse = (filter, label) => {
  return false;
};

export const hiddenFilter = (filter, label) => {
  if (label.toLowerCase().includes(filter.toLowerCase())) {
    return false;
  } else {
    return true;
  }
};

// LISTAS

export const priorityFieldValues = [
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
];

export const ribbonTextColorFieldValues = [
  { id: "negro", name: "negro" },
  { id: "blanco", name: "blanco" },
];

export const ribbonBackgroundColorFieldValues = [
  { id: "primary", name: "primary" },
  { id: "secondary", name: "secondary" },
  { id: "tertiary", name: "tertiary" },
  { id: "quaternary", name: "quaternary" },
  { id: "quinary", name: "quinary" },
  { id: "senary", name: "senary" },
  { id: "septenary", name: "septenary" },
  { id: "octonary", name: "octonary" },
  { id: "nonary", name: "nonary" },
  { id: "denary", name: "denary" },
  { id: "undenary", name: "undenary" },
  { id: "duodenary", name: "duodenary" },
  { id: "tredenary", name: "tredenary" },
];

// CONSTANTES ROLES

export const userRoles = {
  name: {
    roleSuperAdmin: "superAdmin",
    roleAdmin: "admin",
    roleCommercialDelegate: "commercialDelegate",
    roleBusinessManager: "businessManager",
    roleContentEditor: "contentEditor",
    roleMarketing: "marketing",
    roleRead: "readonly",
    roleParticular: "private",
  },
};

export const portfolios = {
  name: {
    quasar: "CAR-QUASAR",
    ns: "CAR-NS",
    sareb: "CAR-SAREB",
    anticipa: "CAR-ANTICI",
    particulares: "CAR-PARTI",
    terceros: "CAR-TERCE",
  },
};
export const messageType = {
  info: 0,
  warning: 1,
  error: 2,
  success: 3,
};

export const hasSuperAdminRole = (user) => {
  if (!!user) {
    const roles = user.roles;
    if (
      roles.filter(
        (s) => s.name === userRoles.name.roleSuperAdmin.toUpperCase()
      ).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const checkEDCFieldsGrants = () => {
  const item = localStorage.getItem("userRoles");
  if (item === null) {
    return true;
  }
  const rolesArray = JSON.parse(item).map((s) => s.name.toLowerCase());
  return !(
    rolesArray.includes(userRoles.name.roleAdmin.toLowerCase()) ||
    rolesArray.includes(userRoles.name.roleContentEditor.toLowerCase()) ||
    rolesArray.includes(userRoles.name.roleSuperAdmin.toLowerCase())
  );
};

export const DEFAULT_HEATING_TYPES = [
  { name: "" },
  { name: "Individual" },
  { name: "Central" },
  { name: "No dispone" },
];
export const DEFAULT_EMISSIONS_CERTIFICATION = [
  { name: "" },
  { name: "A" },
  { name: "B" },
  { name: "C" },
  { name: "D" },
  { name: "E" },
  { name: "F" },
  { name: "G" },
];

// PALETA DE COLORES
export const COLORES = {
  LOADING_BACKGROUND: "#5558",
  BACKGROUND: "white",
  BORDER: "grey",
  LIGHT_BORDER: "lightgrey",
  MENU_HOVER: "#ffd0d0",
  MENU_ACTIVE: "indianred",
  LOADING_TEXT: "indianred",
  BLACK: "black",
  LIST_ACTIVE: "indianred",
  LIST_HOVER: "#ffd0d0",
  LINK: "blue",
  TEXT_DISABLED: "grey",
  BACKGROUND_DISABLED: "lightgrey",
  HIGHLIGHTED: "#cee7ff",
  DELETED: "#dd0000",
  DIALOG_COMMENT: "royalblue",
  FORWARD: "green",
  BACKWARD: "red",
  EXPORT: "#2588bb",
  BUTTON_TEXT_DEFAULT: "white",
  BUTTON_BACKGROUND_DEFAULT: "#66aaff",
  HEADER_TABLE_FONT: "white",
  HEADER_TABLE_BACKGROUND: "#0770a7",
  SIDEBAR_MENU: "#fff3f3",
  NAV_MENU: "#17a2b8",
  TITLE_CAMPAIGN_PROMOTION: "#00a65a",
  AUDIT_OLD_VALUE: "#ff7F50",
  AUDIT_NEW_VALUE: "#90ee90",
};

export const formatDateWithZone = (dateString, doit) => {
  let date = new Date(dateString);
  const offset = date.getTimezoneOffset();
  if (!!!doit) {
    date = new Date(date.getTime() + -offset * 60000);
  }
  let d = date;
  d = [
    "0" + d.getDate(),
    "0" + (d.getMonth() + 1),
    "" + d.getFullYear(),
    "0" + d.getHours(),
    "0" + d.getMinutes(),
    "0" + d.getSeconds(),
  ].map((component) => {
    if (component.length < 4) {
      return component.slice(-2);
    } else {
      return component;
    }
  });

  return d.slice(0, 3).join("/") + " " + d.slice(3).join(":");
};

/**
 * Ordenar alfabeticamente una arreglo de objetos por un campo
 * @param {*} array
 * @returns
 */
export const orderAlphabetic = (array, filedName) => {
  array.sort((a, b) => {
    let textA = a[filedName].trim().toUpperCase();
    let textB = b[filedName].trim().toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  return array;
};

/**
 * Generar aleatoriamente un id
 * @param {*} length
 * @returns
 */
export const generateRandomId = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
