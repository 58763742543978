import {
  disabledFalse,
  disabledTrue,
  hiddenFilter,
  portfolios,
} from "../../constants";

export const buildFormDataEditAsset = (data) => {
  let formData = {};
  formData.id = data.id;
  formData.delegationId = data.delegationId;
  formData.bathroomsEDC = data.bathroomsEDC;
  formData.bedroomsEDC = data.bedroomsEDC;
  formData.currentUse = data.currentUse;
  formData.plotArea = data.plotArea;
  formData.technicalFloor = data.technicalFloor;
  formData.activityType = data.activityType;
  formData.santanderBankComment = data.santanderBankComment;
  formData.commercialNetType = data.commercialNetType;
  formData.segmentationPrimary = data.segmentationPrimary;
  formData.segmentationPrimaryType = data.segmentationPrimaryType;
  formData.segmentationSecondary = data.segmentationSecondary;
  formData.segmentationSecondaryType = data.segmentationSecondaryType;
  formData.aboveGroundM2 = data.aboveGroundM2;
  formData.lowFlushM2 = data.lowFlushM2;
  formData.conservationStatus = data.conservationStatus;
  formData.floorAmount = data.floorAmount;
  formData.aboveGroundFloorAmount = data.aboveGroundFloorAmount;
  formData.distribution = data.distribution;
  formData.facade = data.facade;
  formData.officeM2 = data.officeM2;
  formData.warehouseM2 = data.warehouseM2;
  formData.groundFloorM2 = data.groundFloorM2;
  formData.firstFloorM2 = data.firstFloorM2;
  formData.secondFloorM2 = data.secondFloorM2;
  formData.hotelType = data.hotelType;
  formData.category = data.category;
  formData.roomsNumber = data.roomsNumber;
  formData.freeHeight = data.freeHeight;
  formData.campa = data.campa;
  formData.piers = data.piers;
  formData.gates = data.gates;
  formData.hasParking = data.hasParking;
  formData.hasRestaurant = data.hasRestaurant;
  formData.hasSmokeOutlet = data.hasSmokeOutlet;
  formData.hasSwimmingPool = data.hasSwimmingPool;
  formData.hasFalseCeiling = data.hasFalseCeiling;
  formData.hasFalseFloor = data.hasFalseFloor;
  formData.parkingPlacesAmount = data.parkingPlacesAmount;
  formData.grossLeasableM2 = data.grossLeasableM2;
  formData.assetStatus = data.assetStatus;
  formData.occupationalStatus = data.occupationalStatus;
  formData.wardrobeAmount = data.wardrobeAmount;
  formData.hasStorage = data.hasStorage;
  formData.hasFurnishedKitchen = data.hasFurnishedKitchen;
  formData.hasElevator = data.hasElevator;
  formData.hasAirConditioning = data.hasAirConditioning;
  formData.hasHeating = data.hasHeating;
  formData.hasTerrace = data.hasTerrace;
  formData.terraceSurface = data.terraceSurface;
  formData.hasCommunityGarden = data.hasCommunityGarden;
  formData.hasOwnGarden = data.hasOwnGarden;
  formData.hasCommunityPool = data.hasCommunityPool;
  formData.hasOwnPool = data.hasOwnPool;
  formData.hasIntercom = data.hasIntercom;
  formData.hasPorter = data.hasPorter;
  formData.hasGarage = data.hasGarage;
  formData.garageType = data.garageType;
  formData.outDoorCarpentry = data.outDoorCarpentry;
  formData.insideCarpentry = data.insideCarpentry;
  formData.furnishingStatus = data.furnishingStatus;
  formData.isExterior = data.isExterior;
  formData.district = data.district;
  formData.hideNumber = data.hideNumber;
  formData.hideAddress = data.hideAddress;
  formData.commuterTrain = data.commuterTrain;
  formData.bus = data.bus;
  formData.highway = data.highway;
  formData.communityExpenses = data.communityExpenses;
  formData.urlYoutube = data.urlYoutube;
  formData.cloudVideo = data.cloudVideo;
  formData.urlVirtualTour = data.urlVirtualTour;
  formData.decorview = data.decorview;
  formData.cluster1 = data.cluster1;
  formData.cluster2 = data.cluster2;
  formData.score = data.score;
  formData.sponsored = data.sponsored;
  formData.isHighlighted = data.isHighlighted;
  return formData;
};

export const buildFormDataEditProduct = (data, defaultAssetId) => {
  let formData = {};
  formData.id = data.id;
  formData.defaultAssetId = defaultAssetId;
  formData.delegationId = data.delegationId;
  formData.tradeName = data.tradeName;
  if (
    data.manualHidePvPAPICRM === "true" ||
    data.manualHidePvPAPICRM === true
  ) {
    formData.manualHidePvPAPICRM = true;
  } else if (
    data.manualHidePvPAPICRM === "false" ||
    data.manualHidePvPAPICRM === false
  ) {
    formData.manualHidePvPAPICRM = false;
  } else {
    formData.manualHidePvPAPICRM = null;
  }

  formData.isBlocked = data.isBlocked;
  if (!data.reasonBlock && data.reasonBlock !== 0) {
    formData.reasonBlock = null;
  } else {
    formData.reasonBlock = Number(data.reasonBlock);
  }
  formData.easyReferenceBusiness = data.easyReferenceBusiness;
  formData.isForced = data.isForced;
  formData.terciaryWebId = data.terciaryWebId;
  formData.checkGiai = data.checkGiai;
  formData.rental = data.rental;
  if (data.garagesAmount) formData.garagesAmount = Number(data.garagesAmount);
  if (data.storagesAmount)
    formData.storagesAmount = Number(data.storagesAmount);
  formData.commercialInterestDescription = data.commercialInterestDescription;
  if (data.commercialInterestScore)
    formData.commercialInterestScore = data.commercialInterestScore;
  formData.segmentationPrimary = data.segmentationPrimary;
  formData.segmentationSecondary = data.segmentationSecondary;
  formData.segmentationPrimaryType = data.segmentationPrimaryType;
  formData.segmentationSecondaryType = data.segmentationSecondaryType;
  formData.webBookingAvailable = data.webBookingAvailable;
  formData.webBookingAvailableURL = data.webBookingAvailableURL;
  formData.ignoreMinimalPriceValidation = data.ignoreMinimalPriceValidation;
  formData.inSarebCompetitiveProcess = data.inSarebCompetitiveProcess;
  return formData;
};

export const METADATA_EDICION_PRODUCTO_1 = [
  {
    label: "Producto",
    id: "product",
    collapsable: true,
    metadata: [
      {
        label: "Nombre Comercial",
        name: "tradeName",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Importe",
        name: "price",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
      },
      {
        label: "Importe Mín.",
        name: "minimalPrice",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
      },
      {
        label: "PVP Mínimo Aprobado BX",
        name: "isBxMinimalPriceApproved",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Importe CRM",
        name: "crmPrice",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
      },
      {
        label: "Importe Mín. CRM",
        name: "minimalCrmPrice",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
      },
      {
        label: "Precio alquiler",
        name: "rentalPrice",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
        min: 0,
        visibleFor: [
          portfolios.name.sareb,
          portfolios.name.quasar,
          portfolios.name.anticipa,
        ],
      },
      {
        label: "Precio alquiler web",
        name: "rentalPriceWeb",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
        min: 0,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Precio alquiler Web CRM",
        name: "rentalPriceCrm",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
        min: 0,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Api asignado",
        name: "isApiAssigned",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Publicado",
        name: "isPublishable",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Venta online",
        name: "onlineSale",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Venta Online Exclusiva",
        name: "webBookingAvailable",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "URL Venta online exclusiva",
        name: "webBookingAvailableURL",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Cita virtual",
        name: "virtualDate",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Cálculo Precio web a consultar",
        name: "manualHidePvPAPICRM",
        type: "list",
        typeList: "Normal",
        listName: "hidePVP",
        propertyId: "Id",
        propertyName: "name",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Precio web a consultar",
        name: "hidePvPAPICRM",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Bloqueo Comercial",
        name: "isBlocked",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Motivo del bloqueo",
        name: "reasonBlock",
        type: "list",
        typeList: "Normal",
        listName: "reasonBlockList",
        propertyId: "Id",
        propertyName: "translation",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        required: false,
      },
      {
        label: "Referencia Negocio Fácil",
        name: "easyReferenceBusiness",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Estado del producto",
        name: "status",
        type: "textFromList",
        typeList: "Normal",
        listName: "productStatus",
        propertyList: "translation",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Disponible",
        name: "isAvailable",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Forzado",
        name: "isForced",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Comentarios del Estado",
        name: "comments",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Id Web Terciario",
        name: "terciaryWebId",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Check GIAI",
        name: "checkGiai",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Alquiler",
        name: "rental",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² Superficie total",
        name: "builtM2Product",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² publicables",
        name: "areaPublishable",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        tooltip:
          "La superficie publicable es el resultado de un algoritmo acordado con BX y Anticipa que se basa en la superficie construida de catastro, pudiendo repercutir en algunos casos zonas comunes. En general no coincide con la superficie que aparece en la nota simple.",
      },
      {
        label: "Número de garajes",
        name: "garagesAmount",
        type: "number",
        min: 0,
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de trasteros",
        name: "storagesAmount",
        type: "number",
        min: 0,
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Quasar",
    id: "quasar",
    collapsable: true,
    metadata: [
      {
        label: "Ignorar validez precio mínimo",
        name: "ignoreMinimalPriceValidation",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Sareb",
    id: "sareb",
    collapsable: true,
    metadata: [
      {
        label: "En proceso de transparencia",
        name: "inSarebCompetitiveProcess",
        type: "checkbox",
        class: "inline-block",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Inicio proceso de transparencia",
        name: "sarebCompetitiveStartDate",
        type: "date",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fin proceso de transparencia",
        name: "sarebCompetitiveEndDate",
        type: "date",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Anticipa",
    id: "anticipa",
    collapsable: true,
    metadata: [
      {
        label: "ID Oportunidad",
        name: "currentOpId",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha de ingreso",
        name: "currentOpDate",
        type: "date",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Imágenes",
    id: "images",
    collapsable: true,
    metadata: [
      {
        label: "Imágenes",
        name: "urlImages",
        type: "carousel",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de fotos",
        name: "defaultAssetPhotosNumber",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Letras de las fotos",
        name: "defaultAssetPhotoLetters",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha modificación fotos",
        name: "lastPhotosModificationDate",
        type: "date",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Interés comercial",
    id: "commercialInterest",
    collapsable: true,
    metadata: [
      {
        label: "Interés comercial",
        name: "commercialInterestScore",
        type: "list",
        typeList: "Normal",
        listName: "commercialInterestScore",
        propertyId: "Id",
        propertyName: "name",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Valoración comercial",
        name: "commercialInterestDescription",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Suelo",
    id: "floor",
    collapsable: true,
    metadata: [
      {
        label: "Segmentación",
        name: "segmentation",
        type: "segmentation",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Auditoría",
    id: "audit",
    collapsable: true,
    metadata: [
      {
        label: "Fecha Creación",
        name: "creationTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Usuario Creación",
        name: "creatorId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Última Modificación",
        name: "lastModificationTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Usuario Modificación",
        name: "lastModifierId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Borrado",
        name: "deletionTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Usuario Borrado",
        name: "deleterId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
];

export const buildFormDataEditProductParticular = (data) => {
  let formData = {};

  formData.id = data.id;

  //Producto
  formData.defaultAssetId = parseInt(data.defaultAssetId);
  if (!!data.tradeName) {
    formData.tradeName = data.tradeName;
  } else {
    formData.tradeName = null;
  }
  if (!!data.isPublishable) {
    formData.isPublishable = data.isPublishable;
  } else {
    formData.isPublishable = false;
  }
  formData.webBookingAvailable = data.webBookingAvailable;
  if (!!data.webBookingAvailableURL) {
    formData.webBookingAvailableURL = data.webBookingAvailableURL;
  } else {
    formData.webBookingAvailableURL = null;
  }
  if (data.manualHidePvPAPICRM === "true") {
    formData.manualHidePvPAPICRM = true;
  } else if (data.manualHidePvPAPICRM === "false") {
    formData.manualHidePvPAPICRM = false;
  } else {
    formData.manualHidePvPAPICRM = null;
  }
  if (!!data.isBlocked) {
    formData.isBlocked = data.isBlocked;
  } else {
    formData.isBlocked = false;
  }
  if (!data.reasonBlock && data.reasonBlock !== 0) {
    formData.reasonBlock = null;
  } else {
    formData.reasonBlock = Number(data.reasonBlock);
  }
  if (!!data.easyReferenceBusiness) {
    formData.easyReferenceBusiness = data.easyReferenceBusiness;
  } else {
    formData.easyReferenceBusiness = null;
  }
  if (!!data.isForced) {
    formData.isForced = data.isForced;
  } else {
    formData.isForced = false;
  }
  if (!!data.terciaryWebId) {
    formData.terciaryWebId = data.terciaryWebId;
  } else {
    formData.terciaryWebId = null;
  }
  if (!!data.checkGiai) {
    formData.checkGiai = data.checkGiai;
  } else {
    formData.checkGiai = false;
  }
  if (!!data.rental) {
    formData.rental = data.rental;
  } else {
    formData.rental = false;
  }
  if (!!data.rental) {
    formData.rental = data.rental;
  } else {
    formData.rental = false;
  }
  if (!!data.garagesAmount) {
    formData.garagesAmount = parseInt(data.garagesAmount);
  } else {
    formData.garagesAmount = 0;
  }
  if (!!data.storagesAmount) {
    formData.storagesAmount = parseInt(data.storagesAmount);
  } else {
    formData.storagesAmount = 0;
  }

  if (!!data.ignoreMinimalPriceValidation) {
    formData.ignoreMinimalPriceValidation = data.ignoreMinimalPriceValidation;
  } else {
    formData.ignoreMinimalPriceValidation = false;
  }

  //Segmentación
  if (!!data.segmentationPrimary) {
    formData.segmentationPrimary = data.segmentationPrimary;
  } else {
    formData.segmentationPrimary = null;
  }
  if (!!data.segmentationSecondary) {
    formData.segmentationSecondary = data.segmentationSecondary;
  } else {
    formData.segmentationSecondary = null;
  }
  if (!!data.segmentationPrimaryType) {
    formData.segmentationPrimaryType = data.segmentationPrimaryType;
  } else {
    formData.segmentationPrimaryType = null;
  }
  if (!!data.segmentationSecondaryType) {
    formData.segmentationSecondaryType = data.segmentationSecondaryType;
  } else {
    formData.segmentationSecondaryType = null;
  }

  //Interes comercial
  if (!!data.commercialInterestScore || data.commercialInterestScore === 0) {
    formData.commercialInterestScore = parseInt(data.commercialInterestScore);
  } else {
    formData.commercialInterestScore = null;
  }
  if (!!data.commercialInterestDescription) {
    formData.commercialInterestDescription = data.commercialInterestDescription;
  } else {
    formData.commercialInterestDescription = null;
  }

  return formData;
};

export const getMetadataParticularProduct = (
  isSuperadmin,
  isAnyAdmin,
  manualHidePvPAPICRMAccess,
  hasRoleCommercialDelegate,
  isBlocked,
  reasonBlockPEP
) => {
  return [
    {
      label: "Producto",
      id: "product",
      collapsable: true,
      metadata: [
        {
          label: "Nombre Comercial",
          name: "tradeName",
          type: "textarea",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "PVP Mínimo Aprobado BX",
          name: "isBxMinimalPriceApproved",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Api asignado",
          name: "isApiAssigned",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Publicado",
          name: "isPublishable",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Venta online",
          name: "onlineSale",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Venta Online Exclusiva",
          name: "webBookingAvailable",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "URL Venta online exclusiva",
          name: "webBookingAvailableURL",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Cita virtual",
          name: "virtualDate",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Cálculo Precio web a consultar",
          name: "manualHidePvPAPICRM",
          type: "list",
          typeList: "Normal",
          listName: "hidePVP",
          propertyId: "Id",
          propertyName: "name",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Precio web a consultar",
          name: "hidePvPAPICRM",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Bloqueo Comercial",
          name: "isBlocked",
          type: "checkbox",
          disabled:
            isAnyAdmin || hasRoleCommercialDelegate
              ? disabledFalse
              : disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Motivo del bloqueo",
          name: "reasonBlock",
          type: "list",
          typeList: "Normal",
          listName: "reasonBlockList",
          propertyId: "Id",
          propertyName: "translation",
          disabled:
            (isAnyAdmin || hasRoleCommercialDelegate) &&
            isBlocked &&
            !reasonBlockPEP
              ? disabledFalse
              : disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
          required: false,
        },
        {
          label: "Referencia Negocio Fácil",
          name: "easyReferenceBusiness",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Estado del producto",
          name: "status",
          type: "textFromList",
          typeList: "Normal",
          listName: "productStatus",
          propertyList: "translation",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Disponible",
          name: "isAvailable",
          type: "checkbox",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Comentarios del Estado",
          name: "comments",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Id Web Terciario",
          name: "terciaryWebId",
          type: "text",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Check GIAI",
          name: "checkGiai",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Alquiler",
          name: "rental",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "m² Superficie total",
          name: "builtM2Product",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "m² publicables",
          name: "areaPublishable",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
          tooltip:
            "La superficie publicable es el resultado de un algoritmo acordado con BX y Anticipa que se basa en la superficie construida de catastro, pudiendo repercutir en algunos casos zonas comunes. En general no coincide con la superficie que aparece en la nota simple.",
        },
        {
          label: "Número de garajes",
          name: "garagesAmount",
          type: "text",
          min: 0,
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Número de trasteros",
          name: "storagesAmount",
          type: "text",
          min: 0,
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Ignorar validez precio mínimo",
          name: "ignoreMinimalPriceValidation",
          type: "checkbox",
          disabled: disabledFalse,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Interés comercial",
      id: "commercialInterest",
      collapsable: true,
      metadata: [
        {
          label: "Interés comercial",
          name: "commercialInterestScore",
          type: "list",
          typeList: "Normal",
          listName: "commercialInterestScore",
          propertyId: "Id",
          propertyName: "name",
          disabled:
            isSuperadmin || hasRoleCommercialDelegate
              ? disabledFalse
              : disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Valoración comercial",
          name: "commercialInterestDescription",
          type: "text",
          disabled:
            isSuperadmin || hasRoleCommercialDelegate
              ? disabledFalse
              : disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Imágenes",
      id: "images",
      collapsable: true,
      metadata: [
        {
          label: "Imágenes",
          name: "urlImages",
          type: "carousel",
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Número de fotos",
          name: "defaultAssetPhotosNumber",
          type: "text",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Letras de las fotos",
          name: "defaultAssetPhotoLetters",
          type: "textarea",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Fecha modificación fotos",
          name: "lastPhotosModificationDate",
          type: "dateWithoutMinutes",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
    {
      label: "Auditoría",
      id: "audit",
      collapsable: true,
      metadata: [
        {
          label: "Fecha Creación",
          name: "creationTime",
          type: "date",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Usuario Creación",
          name: "creatorId",
          type: "textFromList",
          typeList: "Normal",
          listName: "users",
          propertyList: "name",
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Fecha Última Modificación",
          name: "lastModificationTime",
          type: "date",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Usuario Modificación",
          name: "lastModifierId",
          type: "textFromList",
          typeList: "Normal",
          listName: "users",
          propertyList: "name",
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Fecha Borrado",
          name: "deletionTime",
          type: "date",
          disabled: disabledTrue,
          hidden: hiddenFilter,
          languageSensitive: false,
        },
        {
          label: "Usuario Borrado",
          name: "deleterId",
          type: "textFromList",
          typeList: "Normal",
          listName: "users",
          propertyList: "name",
          hidden: hiddenFilter,
          languageSensitive: false,
        },
      ],
    },
  ];
};
