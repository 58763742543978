import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  background-color: ${COLORES.BACKGROUND};
  button {
    cursor: pointer;
  }
  .btn-group-sm {
    margin-top: 10px;
  }
`;

export const TableContainer = styled.div`
  max-height: 58vh;
  overflow: auto;
  .table {
    thead {
      z-index: 1;
    }
  }
`;
