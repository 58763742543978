import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  position: fixed;
  background-color: ${COLORES.LOADING_BACKGROUND};
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    animation: Blinker infinite 1s linear;
  }

  @keyframes Blinker {
    0% {
      opacity: 100%;
    }
    50% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
    }
  }
`;
