import {
  disabledFalse,
  disabledTrue,
  hiddenFilter,
  portfolios,
} from "../../constants";

const notSuperAdmin = () => {
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  if (userRoles.filter((s) => s.name === "SUPERADMIN").length > 0) {
    return false;
  } else {
    return true;
  }
};

const notPermissionFieldsForm = () => {
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  if (
    userRoles.filter(
      (s) =>
        s.name === "ADMIN" ||
        s.name === "SUPERADMIN" ||
        s.name === "CONTENTEDITOR"
    ).length > 0
  ) {
    return false;
  } else {
    return true;
  }
};

const notPermissionSponsored = () => {
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  if (
    userRoles.filter(
      (s) =>
        s.name === "ADMIN" || s.name === "SUPERADMIN" || s.name === "MARKETING"
    ).length > 0
  ) {
    return false;
  } else {
    return true;
  }
};

export const buildFormDataEdit = (data) => {
  let formData = {};
  formData.id = data.id;
  formData.delegationId = data.delegationId;
  formData.bathroomsEDC = data.bathroomsEDC;
  formData.bedroomsEDC = data.bedroomsEDC;
  formData.currentUse = data.currentUse;
  formData.subUse = data.subUse;
  formData.description = data.description;
  formData.descriptionEN = data.descriptionEN;
  formData.descriptionFR = data.descriptionFR;
  formData.plotArea = data.plotArea;
  formData.technicalFloor = data.technicalFloor;
  formData.activityType = data.activityType;
  formData.commercialNetType = data.commercialNetType;
  formData.isNew = data.isNew ? true : false;
  formData.segmentationPrimary = data.segmentationPrimary;
  formData.segmentationPrimaryType = data.segmentationPrimaryType;
  formData.segmentationSecondary = data.segmentationSecondary;
  formData.segmentationSecondaryType = data.segmentationSecondaryType;
  formData.aboveGroundM2 = data.aboveGroundM2;
  formData.lowFlushM2 = data.lowFlushM2;
  formData.conservationStatus = data.conservationStatus;
  formData.floorAmount = data.floorAmount;
  formData.aboveGroundFloorAmount = data.aboveGroundFloorAmount;
  formData.distribution = data.distribution;
  formData.facade = data.facade;
  formData.officeM2 = data.officeM2;
  formData.warehouseM2 = data.warehouseM2;
  formData.groundFloorM2 = data.groundFloorM2;
  formData.firstFloorM2 = data.firstFloorM2;
  formData.secondFloorM2 = data.secondFloorM2;
  formData.hotelType = data.hotelType;
  formData.category = data.category;
  formData.roomsNumber = data.roomsNumber;
  formData.freeHeight = data.freeHeight;
  formData.campa = data.campa;
  formData.piers = data.piers;
  formData.gates = data.gates;
  formData.hasParking = data.hasParking;
  formData.hasRestaurant = data.hasRestaurant;
  formData.hasSmokeOutlet = data.hasSmokeOutlet;
  formData.hasSwimmingPool = data.hasSwimmingPool;
  formData.hasFalseCeiling = data.hasFalseCeiling;
  formData.hasFalseFloor = data.hasFalseFloor;
  formData.parkingPlacesAmount = data.parkingPlacesAmount;
  formData.grossLeasableM2 = data.grossLeasableM2;
  formData.assetStatus = data.assetStatus;
  formData.occupationalStatus = data.occupationalStatus;
  formData.wardrobeAmount = data.wardrobeAmount;
  formData.hasStorage = data.hasStorage;
  formData.hasFurnishedKitchen = data.hasFurnishedKitchen;
  formData.hasElevator = data.hasElevator;
  formData.hasAirConditioning = data.hasAirConditioning;
  formData.hasHeating = data.hasHeating;
  formData.hasTerrace = data.hasTerrace;
  formData.terraceSurface = data.terraceSurface;
  formData.hasCommunityGarden = data.hasCommunityGarden;
  formData.hasOwnGarden = data.hasOwnGarden;
  formData.hasCommunityPool = data.hasCommunityPool;
  formData.hasOwnPool = data.hasOwnPool;
  formData.hasIntercom = data.hasIntercom;
  formData.hasPorter = data.hasPorter;
  formData.hasGarage = data.hasGarage;
  formData.garageType = data.garageType;
  formData.outDoorCarpentry = data.outDoorCarpentry;
  formData.insideCarpentry = data.insideCarpentry;
  formData.furnishingStatus = data.furnishingStatus;
  formData.isExterior = data.isExterior;
  formData.district = data.district;
  formData.hideNumber = data.hideNumber;
  formData.hideAddress = data.hideAddress;
  formData.commuterTrain = data.commuterTrain;
  formData.bus = data.bus;
  formData.highway = data.highway;
  formData.communityExpenses = data.communityExpenses;
  formData.urlYoutube = data.urlYoutube;
  formData.cloudVideo = data.cloudVideo;
  formData.urlVirtualTour = data.urlVirtualTour;
  formData.hasPlans = data.hasPlans;
  formData.decorview = data.decorview;
  formData.score = data.score;
  formData.sponsored = data.sponsored;
  formData.isHighlighted = data.isHighlighted;
  formData.numberOfPhotos = data.numberOfPhotos;
  formData.hasBalconyEDC = data?.hasBalconyEDC ? true : false;
  formData.heatingType = data?.heatingType;
  formData.buildingYear = data?.buildingYear;
  formData.builtInWardrobe = data?.builtInWardrobe ? true : false;
  formData.buildingStatus = data?.buildingStatus;
  formData.buildingOrientation = data?.buildingOrientation;
  formData.heatingEmissions = data?.heatingEmissions;
  formData.energyCertification = data?.energyCertification;
  formData.energyConsumption = data?.energyConsumption;
  formData.emissionsCertification = data?.emissionsCertification;
  formData.areValidPhotos = data?.areValidPhotos ? true : false;
  formData.photoDetails = data?.photoDetails;
  formData.photoComments = data?.photoComments;
  formData.photoDetails2 = data?.photoDetails2;
  formData.tourDetails2 = data?.tourDetails2;
  formData.descriptionDetails = data?.descriptionDetails;
  formData.descriptionValidation = data?.descriptionValidation;
  formData.agreeTour = data?.agreeTour;

  if (
    data.isBrandNewConstruction === "true" ||
    data.isBrandNewConstruction === true
  ) {
    formData.isBrandNewConstruction = true;
  } else if (
    data.isBrandNewConstruction === "false" ||
    data.isBrandNewConstruction === false
  ) {
    formData.isBrandNewConstruction = false;
  } else {
    formData.isBrandNewConstruction = null;
  }

  if (data.photoValidation2 === "true" || data.photoValidation2 === true) {
    formData.photoValidation2 = true;
  } else if (
    data.photoValidation2 === "false" ||
    data.photoValidation2 === false
  ) {
    formData.photoValidation2 = false;
  } else {
    formData.photoValidation2 = null;
  }

  if (data.tourValidation === "true" || data.tourValidation === true) {
    formData.tourValidation = true;
  } else if (data.tourValidation === "false" || data.tourValidation === false) {
    formData.tourValidation = false;
  } else {
    formData.tourValidation = null;
  }

  if (!!data.anticipaPosesion) {
    formData.anticipaPosesion = "Si";
  } else {
    formData.anticipaPosesion = null;
  }
  formData.urlDossier = data.urlDossier;
  return formData;
};

export const METADATA_EDICION_ACTIVO_1 = [
  {
    label: "Datos generales del activo",
    id: "generalData",
    collapsable: true,
    metadata: [
      {
        label: "Id (interno)",
        name: "id",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Id Prínex",
        name: "prinexId",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "ID Anticipa",
        name: "anticipaID",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.sareb],
      },
      {
        label: "Expediente",
        name: "dossierCode",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Referencia anterior a otros sistemas",
        name: "previousSystemsReference",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Activo",
        name: "name",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Inmueble",
        name: "propertyCode",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Id Promoción Prínex",
        name: "promotionCode",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Promoción PX Anticipa",
        name: "anticipaPrinexPromotionID",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.sareb],
      },
      {
        label: "Nombre de Promoción Prínex",
        name: "promotionName",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Línea Negocio",
        name: "businessLine",
        type: "textFromList",
        typeList: "Normal",
        listName: "businessLines",
        propertyList: "translation",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Delegación",
        name: "delegationId",
        type: "list",
        typeList: "Normal",
        listName: "delegationList",
        propertyId: "id",
        propertyName: "name",
        disabled: notSuperAdmin,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Posesiones",
        name: "anticipaPosesion",
        type: "list",
        typeList: "Normal",
        listName: "nulleableListWithoutFalse",
        propertyId: "value",
        propertyName: "name",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Forzado",
        name: "delegationForced",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Cartera",
        name: "portfolioId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appPortfolios",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Sociedad Propietaria Id",
        name: "managementCompanyId",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Sociedad Propietaria Nombre",
        name: "managementCompany",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Sociedad Propietaria NIF",
        name: "managementCompanyNif",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Sociedad Propietaria Domi",
        name: "managementCompanyAddress",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Estado Comercial",
        name: "commercialStatusId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appCommercialStatus",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Proindiviso",
        name: "coownership",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Cuota Indiviso",
        name: "coownershipShareQuote",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Ficticio",
        name: "isFictitiousLease",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Ficticio en Venta",
        name: "isFictitiousLeaseSale",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Id TIER",
        name: "municipalityTierId",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Estrategia Comercial",
        name: "commercialStrategyId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appCommercialStrategies",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [
          portfolios.name.quasar,
          portfolios.name.ns,
          portfolios.name.anticipa,
        ],
      },
      {
        label: "Rating",
        name: "ratingId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appRatings",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Nombre venta especial",
        name: "specialSaleName",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Id venta especial",
        name: "specialSaleId",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Pendiente reprocesar",
        name: "waittingForReprocess",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Localización",
    id: "localization",
    collapsable: true,
    metadata: [
      {
        label: "Tipo de vía",
        name: "typeOfRoad",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Vía",
        name: "road",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número",
        name: "number",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "Piso",
        name: "floor",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Puerta",
        name: "door",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Escalera",
        name: "stair",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Bloque",
        name: "block",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Código postal",
        name: "postalCode",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      // NUEVO
      {
        label: "Dirección completa",
        name: "fullDirection",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Provincia INE",
        name: "provinceId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appProvinces",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Municipio INE",
        name: "municipalityId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appMunicipalities",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Latitud",
        name: "latitude",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Longitud",
        name: "longitude",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Mapa",
        name: "map",
        type: "map",
        lat: "latitude",
        lng: "longitude",
      },
      {
        label: "Distrito",
        name: "district",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Barrio",
        name: "districtEDC",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Ocultar número",
        name: "hideNumber",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Ocultar dirección",
        name: "hideAddress",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Cercanías",
        name: "commuterTrain",
        type: "text",
        disabled: notPermissionFieldsForm,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Autobús",
        name: "bus",
        type: "text",
        disabled: notPermissionFieldsForm,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Carretera",
        name: "highway",
        type: "text",
        disabled: notPermissionFieldsForm,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Datos económicos",
    collapsable: true,
    id: "economicData",
    metadata: [
      {
        label: "Importe",
        name: "price",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
        min: 0,
      },
      {
        label: "Validez Importe",
        name: "validPriceDate",
        type: "dateWithoutMinutes",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Importe Mínimo",
        name: "minimalPrice",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
        min: 0,
        visibleFor: [
          portfolios.name.quasar,
          portfolios.name.ns,
          portfolios.name.anticipa,
        ],
      },
      {
        label: "Validez Importe Mínimo",
        name: "validMinimalPriceDate",
        type: "dateWithoutMinutes",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [
          portfolios.name.quasar,
          portfolios.name.ns,
          portfolios.name.anticipa,
        ],
      },
      {
        label: "Importe CRM",
        name: "crmPrice",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
        min: 0,
      },
      {
        label: "Importe Mín. CRM",
        name: "minimalCrmPrice",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
        min: 0,
        visibleFor: [
          portfolios.name.quasar,
          portfolios.name.ns,
          portfolios.name.anticipa,
        ],
      },
      {
        label: "Precio alquiler",
        name: "rentalPrice",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
        min: 0,
        visibleFor: [
          portfolios.name.sareb,
          portfolios.name.quasar,
          portfolios.name.anticipa,
        ],
      },
      {
        label: "Precio alquiler web",
        name: "rentalPriceWeb",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
        min: 0,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Precio alquiler Web CRM",
        name: "rentalPriceCrm",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
        min: 0,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Importe Mínimo Nombre Archivo Aprobación",
        name: "minimalPriceApprovalFileName",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [
          portfolios.name.quasar,
          portfolios.name.ns,
          portfolios.name.anticipa,
        ],
      },
      {
        label: "Importe Mínimo Aprobado",
        name: "isMinimalPriceApproved",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [
          portfolios.name.quasar,
          portfolios.name.ns,
          portfolios.name.anticipa,
        ],
      },
      {
        label: "Precio óptimo",
        name: "optimalPrice",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "€",
      },
      {
        label: "Fecha fin contrato alquiler",
        name: "rentalEndDate",
        type: "dateWithoutMinutes",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Rentabilidad",
        name: "profitability",
        type: "text",
        numberSeparator: true,
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "%",
        min: 0,
      },
    ],
  },
  {
    label: "Publicación",
    collapsable: true,
    id: "publication",
    metadata: [
      {
        label: "URL Web",
        name: "urlWeb",
        type: "url",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha de publicación",
        name: "publicationDate",
        type: "dateWithoutMinutes",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha de última publicación",
        name: "lastPublicationDate",
        type: "dateWithoutMinutes",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Patrocinado",
        name: "sponsored",
        type: "number",
        disabled: notPermissionSponsored,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
    ],
  },
  {
    label: "Características básicas del inmueble",
    id: "basicFeatures",
    collapsable: true,
    metadata: [
      {
        label: "Descripción activo",
        name: "description",
        type: "texteditor",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: true,
      },
      {
        label: "Tipo de red comercial",
        name: "commercialNetType",
        type: "list",
        typeList: "Normal",
        listName: "commercialNetType",
        propertyId: "Id",
        propertyName: "translation",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Segmentación comercial",
        name: "anticipaCommercialSegmentation",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "A estrenar",
        name: "isNew",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Tipo",
        name: "typeId",
        type: "textFromList",
        typeList: "Normal",
        listName: "assetType",
        propertyList: "translation",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Subtipo",
        name: "subTypeId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appAssetSubTypes",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Tipo modificado",
        name: "typeModId",
        type: "textFromList",
        typeList: "Normal",
        listName: "assetType",
        propertyList: "translation",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Subtipo modificado",
        name: "subTypeModId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appAssetSubTypes",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Uso Actual",
        name: "currentUse",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Subuso",
        name: "subUse",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "A estrenar obra nueva",
        name: "isBrandNewConstruction",
        type: "list",
        typeList: "Normal",
        listName: "nulleableList",
        propertyId: "value",
        propertyName: "name",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Datos Sareb",
    id: "sarebData",
    collapsable: true,
    visibleFor: [portfolios.name.sareb],
    metadata: [
      {
        label: "ID promoción Sareb",
        name: "sarebPromotionID",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Estrategia Comercial",
        name: "sarebCommercialStrategy",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Comercialización PEP",
        name: "commercializationPEP",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Posesión",
        name: "sarebPosesion",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "CFO",
        name: "sarebCFO",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "LPO",
        name: "sarebLPO",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Estado Alquiler",
        name: "rentStatus",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Línea presupuestaria",
        name: "budgetLine",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Publicación PEP",
        name: "publicationPEP",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },

      {
        label: "Pricing PEP",
        name: "pricingPEP",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Saneamiento PEP",
        name: "sanitationPEP",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Cartera venta",
        name: "portfolioSale",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Código agrupación Sareb",
        name: "sarebGroupingCode",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Interés inquilino",
        name: "tenantInterest",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Publicación VAYS",
        name: "pvays",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "Fecha inicio vigencia",
        name: "validityDate",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Explotadora",
        name: "exploiter",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Precomercialización",
        name: "preMarketing",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "VPO",
        name: "vpo",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Exclusión venta online",
        name: "onlineSaleExclusion",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha precomercialización",
        name: "preMarketingDate",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Servicer Comercial",
        name: "commercialServicer",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Vays",
        name: "dvays",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Dimensiones Prinex",
    id: "prinexDimentions",
    collapsable: true,
    metadata: [
      {
        label: "Procedencia",
        name: "dimensionOriginId",
        type: "textAreaFromList",
        typeList: "Normal",
        listName: "appDimensions",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [
          portfolios.name.quasar,
          portfolios.name.ns,
          portfolios.name.sareb,
        ],
      },
      {
        label: "Producto",
        name: "dimensionProductId",
        type: "textAreaFromList",
        typeList: "Normal",
        listName: "appDimensions",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [
          portfolios.name.quasar,
          portfolios.name.ns,
          portfolios.name.sareb,
        ],
      },
      {
        label: "Activo",
        name: "dimensionAssetId",
        type: "textAreaFromList",
        typeList: "Normal",
        listName: "appDimensions",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "E.Técnico Comercial",
        name: "dimensionStatusTechnicalCommercialId",
        type: "textAreaFromList",
        typeList: "Normal",
        listName: "appDimensions",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [
          portfolios.name.quasar,
          portfolios.name.ns,
          portfolios.name.sareb,
        ],
      },
      {
        label: "E.Jurídico Administrativo",
        name: "dimensionStatusLegalAdministrativeId",
        type: "textAreaFromList",
        typeList: "Normal",
        listName: "appDimensions",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [
          portfolios.name.quasar,
          portfolios.name.ns,
          portfolios.name.sareb,
        ],
      },
      {
        label: "Activo BDE",
        name: "dimensionAssetBdeId",
        type: "textAreaFromList",
        typeList: "Normal",
        listName: "appDimensions",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [
          portfolios.name.quasar,
          portfolios.name.ns,
          portfolios.name.sareb,
        ],
      },
      {
        label: "Clasificación Portfolio",
        name: "alocationPortfolio",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Documentación boletines",
        name: "bulletin",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Estado VPO",
        name: "markVpo",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Dim2",
        name: "dim2",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Dim3",
        name: "dim3",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Documentación cédula habitabilidad",
        name: "card",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Documentación certificado eficiencia energética",
        name: "cee",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Estado alquiler",
        name: "rentStatus",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Estado venta",
        name: "saleStatus",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Inmueble agrupado",
        name: "buildingGroup",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Posesión",
        name: "sarebPosesion",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Tipo comercialización",
        name: "sellingType",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Llaves",
        name: "keys",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "M2 totales",
        name: "grossM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Precio máximo venta",
        name: "maxPrice",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Precio máximo venta VPO",
        name: "maxPriceVPO",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Situación análisis VPO",
        name: "rulesVPO",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Clasificación VPO",
        name: "vpo",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Código comunidad autónoma",
        name: "autonomousRegion",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
      {
        label: "Obligatoriedad cédula habitabilidad",
        name: "cardCP",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
    ],
  },
  {
    label: "Granular",
    id: "granular",
    collapsable: true,
    metadata: [
      {
        label: "Número de baños",
        name: "bathrooms",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de baños EDC",
        name: "bathroomsEDC",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "Número de dormitorios",
        name: "bedrooms",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de dormitorios EDC",
        name: "bedroomsEDC",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "m² construídos",
        name: "builtM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² publicables",
        name: "areaPublishable",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de armarios empotrados",
        name: "wardrobeAmount",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "Armarios empotrados",
        name: "builtInWardrobe",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Trastero",
        name: "hasStorage",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Cocina amueblada",
        name: "hasFurnishedKitchen",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Ascensor",
        name: "hasElevator",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Aire acondicionado",
        name: "hasAirConditioning",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Calefacción",
        name: "hasHeating",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Terraza",
        name: "hasTerrace",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Superficie de la terraza",
        name: "terraceSurface",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "Jardín comunitario",
        name: "hasCommunityGarden",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Jardín propio",
        name: "hasOwnGarden",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Piscina comunitaria",
        name: "hasCommunityPool",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Piscina propia",
        name: "hasOwnPool",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Portero automático",
        name: "hasIntercom",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Portero físico",
        name: "hasPorter",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Garaje",
        name: "hasGarage",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Tipo de Garaje",
        name: "garageType",
        type: "list",
        typeList: "Normal",
        listName: "garageType",
        propertyId: "Id",
        propertyName: "translation",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Carpintería exterior",
        name: "outDoorCarpentry",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Carpintería interior",
        name: "insideCarpentry",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Estado de mobiliario (amueblamiento)",
        name: "furnishingStatus",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Exterior",
        name: "isExterior",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Planta",
        name: "floorEDC",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Balcón",
        name: "hasBalconyEDC",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Estado",
        name: "statusEDC",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Estado del activo",
        name: "buildingStatus",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Primera línea de mar",
        name: "isFirstSeaLineEDC",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Centro ciudad",
        name: "isCityCenterEDC",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Tipo de calefacción",
        name: "heatingType",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Año de construcción",
        name: "buildingYear",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "Orientación del activo",
        name: "buildingOrientation",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Letra Energética",
        name: "certificationCategory",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Terciario",
    id: "terciary",
    collapsable: true,
    visibleFor: [
      portfolios.name.quasar,
      portfolios.name.ns,
      portfolios.name.sareb,
    ],
    metadata: [
      {
        label: "Responsable Terciario",
        name: "responsibleTertiaryId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appResponsibleTertiary",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² sobre rasante",
        name: "aboveGroundM2",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² bajo rasante",
        name: "lowFlushM2",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Estado de conservación",
        name: "conservationStatus",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de plantas",
        name: "floorAmount",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "Planta/s rasante",
        name: "aboveGroundFloorAmount",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "Distribución",
        name: "distribution",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fachada m/l",
        name: "facade",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "m² oficinas",
        name: "officeM2",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² almacén",
        name: "warehouseM2",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² planta baja",
        name: "groundFloorM2",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² primera planta",
        name: "firstFloorM2",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² segunda planta",
        name: "secondFloorM2",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Tipo de hotel",
        name: "hotelType",
        type: "sampleList",
        listName: "hotelType",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Categoría",
        name: "category",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "Número de habitaciones",
        name: "roomsNumber",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "Altura Libre",
        name: "freeHeight",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "Campa",
        name: "campa",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de muelles",
        name: "piers",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "Número de portones",
        name: "gates",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "Parking",
        name: "hasParking",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Restaurante",
        name: "hasRestaurant",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Salida de humos",
        name: "hasSmokeOutlet",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Piscina",
        name: "hasSwimmingPool",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Falso techo",
        name: "hasFalseCeiling",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Falso piso",
        name: "hasFalseFloor",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de plazas de parking",
        name: "parkingPlacesAmount",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "m² útiles",
        name: "usefulM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² brutos alquilables",
        name: "grossLeasableM2",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "Estado del activo",
        name: "assetStatus",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Estado ocupacional",
        name: "occupationalStatus",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Suelo",
    id: "suelo",
    collapsable: true,
    visibleFor: [
      portfolios.name.quasar,
      portfolios.name.ns,
      portfolios.name.sareb,
    ],
    metadata: [
      {
        label: "Id de SIGI",
        name: "sigiFloorId",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Estado SIGI",
        name: "sigiStatus",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Comercializable SIGI",
        name: "sigiMarketable",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² Brutos",
        name: "grossM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² Netos",
        name: "betsM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² Edificables sobre rasante",
        name: "buildingAboveGroundM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² Residencial - Libre",
        name: "freeResidentialM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² Residencial - Protegido",
        name: "protectedResidentialM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de viviendas",
        name: "households",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "Número de viviendas libres",
        name: "freeHouseholds",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "Número de viviendas de protección",
        name: "protectedFreeHouseholds",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        min: 0,
        languageSensitive: false,
      },
      {
        label: "m² Terciario",
        name: "terciaryM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² Comercial",
        name: "comercialM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² Industrial",
        name: "industrialM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² Hoteleros",
        name: "hoteliersM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "m² Otros",
        name: "othersM2",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Porcentaje de obra parada",
        name: "wipPercentage",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "%",
      },
      {
        label: "Ámbito",
        name: "scopePercent",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "%",
      },
      {
        label: "Titularidad",
        name: "ownershipPercent",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        addon: "%",
      },
      {
        label: "Perímetro Comercial",
        name: "commercialPerimeter",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Segmentación",
        name: "segmentation",
        type: "segmentation",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Superficie parcela",
        name: "plotArea",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Suelo técnico",
        name: "technicalFloor",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Tipología actividad",
        name: "activityType",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Volumen Edificable",
        name: "buildableVolume",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Nº de viviendas edificables",
        name: "buildableHomes",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Aprovechamiento",
        name: "exploitation",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Suministros",
        name: "supplies",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Edificaciones",
        name: "buildings",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Material gráfico",
    id: "graphycMaterial",
    collapsable: true,
    metadata: [
      {
        label: "URL Youtube",
        name: "urlYoutube",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Video Cloud",
        name: "cloudVideo",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "URL TourVirtual",
        name: "urlVirtualTour",
        type: "textarea",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Plano",
        name: "hasPlans",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Decorview",
        name: "decorview",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número de Fotos",
        name: "numberOfPhotos",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "Fotos Decorview",
        name: "decorviewImages",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Score",
        name: "score",
        type: "number",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "Letras de las fotos",
        name: "photoLetters",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fotos Plano",
        name: "schemeImages",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Dossier",
        name: "urlDossier",
        type: "text",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Validación fotos",
        name: "areValidPhotos",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Observaciones fotos",
        name: "photoDetails",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Comentarios fotos",
        name: "photoComments",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha última modificación Floorfy",
        name: "lastFloorfyModificationDate",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Observaciones fotos Ed. Contenidos",
        name: "photoDetails2",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Validación fotos Ed. Contenidos",
        name: "photoValidation2",
        type: "list",
        typeList: "Normal",
        listName: "okKoList",
        propertyId: "value",
        propertyName: "name",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Observaciones tour Ed. Contenidos",
        name: "tourDetails2",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Validación tour Ed Contenidos",
        name: "tourValidation",
        type: "list",
        typeList: "Normal",
        listName: "okKoList",
        propertyId: "value",
        propertyName: "name",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Observaciones descriptivo",
        name: "descriptionDetails",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Validación descriptivo",
        name: "descriptionValidation",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Corresponde tour",
        name: "agreeTour",
        type: "list",
        typeList: "Normal",
        listName: "haveOrNotList",
        propertyId: "value",
        propertyName: "name",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Marketing",
    id: "marketing",
    collapsable: true,
    metadata: [
      {
        label: "Id Promoción Comercial",
        name: "commercialPromotionCode",
        type: "navigate",
        hidden: hiddenFilter,
        languageSensitive: false,
        showId: "commercialPromotionCode",
        navigateId: "commercialPromotionId",
      },
      {
        label: "Nombre Promoción Comercial",
        name: "commercialPromotionName",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Destacado Promoción",
        name: "isHighlighted",
        type: "checkbox",
        disabled: disabledFalse,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "IDs Campaña",
        name: "campaignIds",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "IDs Campaña Anticipa",
        name: "anticipaCampaignsIds",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        visibleFor: [portfolios.name.anticipa],
      },
    ],
  },
  {
    label: "Destinos publicación Anticipa",
    id: "anticipaPublicationDestinies",
    collapsable: true,
    visibleFor: [portfolios.name.anticipa],
    metadata: [
      {
        label: "Idealista obra nueva",
        name: "anticipaIdealistaIsNewBuild",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Idealista segunda mano",
        name: "anticipaIdealistaIsSecondHand",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fotocasa obra nueva",
        name: "anticipaFotocasaIsNewBuild",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fotocasa segunda mano",
        name: "anticipaFotocasaIsSecondHand",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Inviertis",
        name: "anticipaInviertis",
        type: "checkbox",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Datos Registrales",
    id: "RagistralData",
    collapsable: true,
    metadata: [
      {
        label: "Ref. Catastral",
        name: "cadastralReference",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "IDUFIR",
        name: "registralIdufir",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Nombre",
        name: "registralName",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número",
        name: "registralNumber",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "Tomo",
        name: "registralVolumne",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Libro",
        name: "registralBook",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Sección",
        name: "registralSection",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Folio",
        name: "registralPage",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Finca",
        name: "registralFarm",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Registral",
        name: "registralDate",
        type: "dateWithoutMinutes",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Provincia",
        name: "registralProvinceId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appProvinces",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Municipio",
        name: "registralMunicipalityId",
        type: "textFromList",
        typeList: "Normal",
        listName: "appMunicipalities",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Certificación Energética",
    id: "energyCertification",
    collapsable: true,
    metadata: [
      {
        label: "Letra Energética",
        name: "certificationCategory",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Certificación",
        name: "certificationDate",
        type: "dateWithoutMinutes",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Caducidad",
        name: "cetificationExpirationDate",
        type: "dateWithoutMinutes",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Certificadora",
        name: "certificatorCompanyName",
        type: "textarea",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "NIF Certificadora",
        name: "certificatorCompanyNIF",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Número Registro Certificación Energética",
        name: "energeticCertificationRegisterNumber",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Emisiones co2/m2",
        name: "heatingEmissions",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "Consumo energético mwh/m2",
        name: "energyConsumption",
        type: "number",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
        min: 0,
      },
      {
        label: "Etiqueta de consumo",
        name: "energyCertification",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Etiqueta de emisiones",
        name: "emissionsCertification",
        type: "text",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
  {
    label: "Auditoría",
    id: "audit",
    collapsable: true,
    metadata: [
      {
        label: "Fecha Creación",
        name: "creationTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Usuario Creación",
        name: "creatorId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Última Modificación",
        name: "lastModificationTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Usuario Modificación",
        name: "lastModifierId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Fecha Borrado",
        name: "deletionTime",
        type: "dateText",
        disabled: disabledTrue,
        hidden: hiddenFilter,
        languageSensitive: false,
      },
      {
        label: "Usuario Borrado",
        name: "deleterId",
        type: "textFromList",
        typeList: "Normal",
        listName: "users",
        propertyList: "name",
        hidden: hiddenFilter,
        languageSensitive: false,
      },
    ],
  },
];
