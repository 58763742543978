import React from "react";
// import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";

export const InputModalList = ({
  label,
  placeholder,
  id,
  disabled,
  hidden,
  filter,
  list,
  typeList,
  value,
  handleChange,
  required,
  inputBootstrap = true,
}) => {
  if (list === undefined) {
    list = [];
  }
  return (
    <Row className="mb-2">
      {inputBootstrap ? (
        <Col>
          <Form.Label hidden={hidden(filter, label)}>{label}</Form.Label>
        </Col>
      ) : (
        <Form.Label hidden={hidden(filter, label)}>{label}</Form.Label>
      )}
      <Col>
        {typeList === "Normal" && (
          <Form.Select
            required={required}
            placeholder={placeholder}
            id={id}
            disabled={disabled()}
            hidden={hidden(filter, label)}
            value={value}
            onChange={(e) => handleChange(id, e.target.value)}
          >
            <option value=""></option>

            {list.map((field, index) => (
              <option key={index} value={field.id}>
                {field.name}
              </option>
            ))}
          </Form.Select>
        )}
        {typeList === "Translation" && (
          <Form.Select
            required={required}
            placeholder={placeholder}
            id={id}
            disabled={disabled()}
            hidden={hidden(filter, label)}
            value={value}
            onChange={(e) => handleChange(id, e.target.value)}
          >
            <option value=""></option>

            {list.map((field, index) => (
              <option value={field.Id} key={index}>
                {field.translation}
              </option>
            ))}
          </Form.Select>
        )}
        <Form.Control.Feedback type="invalid">
          El {label} es necesario.
        </Form.Control.Feedback>
      </Col>
    </Row>
  );
};

// InputModalList.propTypes = {
//   label: PropTypes.string.isRequired,
//   id: PropTypes.string.isRequired,
//   placeholder: PropTypes.string,
//   disabled: PropTypes.func.isRequired,
//   hidden: PropTypes.func.isRequired,
//   filter: PropTypes.string,
//   list: PropTypes.object.isRequired,
//   typeList: PropTypes.string.isRequired,
//   value: PropTypes.string,
//   handleChange: PropTypes.func,
//   required: PropTypes.bool,
// };
