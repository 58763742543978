import { Fragment, useEffect, useState } from "react";
import { Table, Button, ButtonGroup, Form, Row, Col } from "react-bootstrap";
import * as S from "./styles";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDataContext } from "../../context/DataState";
import usePermition from "../../constants/usePermition";
import EmptyData from "../EmptyTable/EmptyData";

export const TableBuscador = ({
  columns,
  dataActivosSinProducto,
  dataActivosConProducto,
  pageTable,
  regsPage,
  setPage,
  setRegsPage,
  totalPages,
  numRegisters,
  search,
  order,
  setOrder,
  orderDesc,
  setOrderDesc,
  selectedIds,
  setSelectedIds,
}) => {
  const location = useLocation();
  const [openProducts, setOpenProducts] = useState([]);
  const {
    state: { appLists, user },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  const currentCartera = user?.currentPortfolioID;

  useEffect(() => {
    if (pageTable > 0) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTable, regsPage, order, orderDesc]);

  useEffect(() => {
    let codes = dataActivosConProducto.map((e) => e.code);
    setOpenProducts(codes);
  }, [dataActivosConProducto]);

  const changeOrder = (column) => {
    if (!column.disableSortBy) {
      if (order === column.id && orderDesc === false) {
        setOrderDesc(!orderDesc);
      } else if (order === column.id && orderDesc === true) {
        setOrder("");
        setOrderDesc(!orderDesc);
      } else {
        setOrder(column.id);
        setOrderDesc(false);
      }
    }
  };

  const getBusinessLine = (businessLineId) => {
    const businessLine = appLists.businessLines.find(
      (s) => s.Id === businessLineId
    );
    if (businessLine !== null) {
      return businessLine.translation;
    } else {
      return "";
    }
  };

  const getType = (typeId) => {
    const assetType = appLists.assetType.find((s) => s.Id === typeId);
    if (assetType !== null) {
      return assetType.translation;
    } else {
      return "";
    }
  };

  const getCommercialStatus = (id) => {
    const commercialStatus = appLists.appCommercialStatus.find(
      (e) => e.id === id
    );
    if (commercialStatus) {
      return commercialStatus.name;
    } else {
      return "";
    }
  };

  const canSeeCheck = (commercialStatusId) => {
    if (commercialStatusId) {
      if (
        appLists.appNotEditableCommercialStatus.find(
          (s) => s === commercialStatusId
        )
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleChange = (id, checked) => {
    if (checked) {
      let tmpSelectedIds = [...selectedIds];
      tmpSelectedIds.push(id);
      setSelectedIds(tmpSelectedIds);
    } else {
      let tmpSelectedIds = [...selectedIds];
      var ic = tmpSelectedIds.indexOf(id);
      tmpSelectedIds.splice(ic, 1);
      if (ic > -1) setSelectedIds(tmpSelectedIds);
    }
  };

  const handleChangeOpenProduct = (productId) => {
    if (openProducts.includes(productId)) {
      let tmpOpenProducts = [...openProducts];
      var ic = tmpOpenProducts.indexOf(productId);
      tmpOpenProducts.splice(ic, 1);
      if (ic > -1) setOpenProducts(tmpOpenProducts);
    } else {
      let tmpOpenProducts = [...openProducts];
      tmpOpenProducts.push(productId);
      setOpenProducts(tmpOpenProducts);
    }
  };

  const redirectTo = (asset) => {
    let url;
    if (currentCartera === "CAR-PARTI") {
      url = "/particular-asset/" + asset?.id;
    } else if (currentCartera === "CAR-TERCE") {
      url = "/terce-asset/" + asset?.id;
    } else {
      url = "/asset-detail/" + asset?.id;
    }
    return url;
  };

  return (
    <S.Container>
      <S.TableContainer>
        <Table
          striped
          bordered
          hover
          style={{
            border: "solid 1px black",
            whiteSpace: "nowrap",
          }}
        >
          <thead className="bg-light sticky-top top-0">
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  onClick={() => changeOrder(column)}
                  style={{
                    backgroundColor: "rgb(246, 246, 246)",
                    cursor: "pointer",
                    verticalAlign: "top",
                    border: "thin solid lightgray",
                    width: column.width,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                  }}
                >
                  {column.label}
                  <span>
                    {column.id === order ? (
                      orderDesc ? (
                        <span> ↑ </span>
                      ) : (
                        <span> ↓ </span>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataActivosSinProducto.length > 0 && (
              <tr style={{ border: "thin solid lightgray" }}>
                <td colSpan={12}>Código: Activos sin producto</td>
              </tr>
            )}
            {dataActivosSinProducto.map((row, index) => (
              <Fragment key={index}>
                {row.assets.map((asset, i) => (
                  <tr style={{ border: "thin solid lightgray" }} key={i}>
                    <td></td>
                    <td>
                      <Form.Check
                        onChange={(e) => {
                          handleChange(parseInt(asset.id), e.target.checked);
                        }}
                        hidden={!canSeeCheck(asset.commercialStatusId)}
                        checked={selectedIds.includes(asset.id)}
                      />
                    </td>
                    <td>
                      <Link
                        to={redirectTo(asset)}
                        state={{
                          sourcePath: location.pathname + location.search,
                        }}
                      >
                        <span title="Ver detalle">
                          <FontAwesomeIcon
                            style={{ color: "black" }}
                            icon={faEye}
                            size="lg"
                            fixedWidth
                          />
                        </span>
                      </Link>
                    </td>
                    <td title={row.code}>{row.code}</td>
                    <td title={asset.id}>{asset.id}</td>
                    <td title={asset.promotionCode}>{asset.promotionCode}</td>
                    <td title={asset.name}>{asset.name}</td>
                    <td title={asset.dossierCode}>{asset.dossierCode}</td>
                    {PERMISOS_POR_ROL.Buscador.SuperAdmin && (
                      <td title={getCommercialStatus(asset.commercialStatusId)}>
                        {getCommercialStatus(asset.commercialStatusId)}
                      </td>
                    )}
                    <td title={getBusinessLine(asset.businessLine)}>
                      {getBusinessLine(asset.businessLine)}
                    </td>
                    <td title={getType(asset.type)}>{getType(asset.type)}</td>
                    <td title={asset.description}>{asset.description}</td>
                  </tr>
                ))}
              </Fragment>
            ))}
            {dataActivosConProducto.map((row, i) => (
              <Fragment key={i}>
                <tr style={{ border: "thin solid lightgray" }} key={i}>
                  {openProducts.includes(row.code) && (
                    <td
                      colSpan={12}
                      onClick={() => handleChangeOpenProduct(row.code)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ height: "1rem" }}
                        icon={faMinus}
                        size="lg"
                        fixedWidth
                      />{" "}
                      Código {row.code}
                    </td>
                  )}
                  {!openProducts.includes(row.code) && (
                    <td
                      colSpan={12}
                      onClick={() => handleChangeOpenProduct(row.code)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ height: "1rem" }}
                        icon={faPlus}
                        size="lg"
                        fixedWidth
                      />{" "}
                      Código {row.code}
                    </td>
                  )}
                </tr>
                {openProducts.includes(row.code) &&
                  row.assets.map((asset, indexAsset) => (
                    <tr
                      style={{ border: "thin solid lightgray" }}
                      key={indexAsset}
                    >
                      <td></td>
                      <td>
                        <Form.Check
                          onChange={(e) => {
                            handleChange(parseInt(asset.id), e.target.checked);
                          }}
                          hidden={!canSeeCheck(asset.commercialStatusId)}
                          checked={selectedIds.includes(asset.id)}
                        />
                      </td>
                      <td>
                        <Link
                          to={
                            "/product-detail/" + row.productId + "/" + asset.id
                          }
                          state={{
                            sourcePath: location.pathname + location.search,
                          }}
                        >
                          <span>
                            <FontAwesomeIcon
                              style={{ color: "black" }}
                              icon={faEye}
                              size="lg"
                              fixedWidth
                            />
                          </span>
                        </Link>
                      </td>
                      <td title={row.code}>{row.code}</td>
                      <td title={asset.id}>{asset.id}</td>
                      <td title={asset.promotionCode}>{asset.promotionCode}</td>
                      <td title={asset.name}>{asset.name}</td>
                      <td title={asset.dossierCode}>{asset.dossierCode}</td>
                      {PERMISOS_POR_ROL.Buscador.SuperAdmin && (
                        <td
                          title={getCommercialStatus(asset.commercialStatusId)}
                        >
                          {getCommercialStatus(asset.commercialStatusId)}
                        </td>
                      )}
                      <td title={getBusinessLine(asset.businessLine)}>
                        {getBusinessLine(asset.businessLine)}
                      </td>
                      <td title={getType(asset.type)}>{getType(asset.type)}</td>
                      <td title={asset.description}>{asset.description}</td>
                    </tr>
                  ))}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </S.TableContainer>
      {!dataActivosConProducto.length && !dataActivosSinProducto.length ? (
        <EmptyData />
      ) : (
        <Fragment>
          <Row className="mb-2">
            <Col>
              <ButtonGroup size="sm">
                <Button
                  style={{ marginRight: "3px" }}
                  variant="secondary"
                  onClick={() => {
                    setPage(1);
                  }}
                  disabled={pageTable < 2}
                >
                  {"<<"}
                </Button>
                <Button
                  style={{ marginRight: "3px" }}
                  variant="secondary"
                  onClick={() => {
                    setPage(pageTable - 1);
                  }}
                  disabled={pageTable < 2}
                >
                  {"<"}
                </Button>
                <Button
                  style={{ marginRight: "3px" }}
                  variant="secondary"
                  onClick={() => {
                    setPage(pageTable + 1);
                  }}
                  disabled={pageTable === totalPages || pageTable === 0}
                >
                  {">"}
                </Button>
                <Button
                  style={{ marginRight: "3px" }}
                  variant="secondary"
                  onClick={() => setPage(totalPages)}
                  disabled={pageTable === totalPages || pageTable === 0}
                >
                  {">>"}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={6}>
              <Form.Select
                size="sm"
                value={regsPage}
                onChange={(e) => {
                  setPage(1);
                  setRegsPage(Number(e.target.value));
                }}
                style={{ width: "8rem" }}
              >
                {[10, 20, 50, 100, 200].map((pageSize, index) => (
                  <option key={index} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <span>
                Página{" "}
                <strong>
                  {pageTable} de {totalPages}
                </strong>{" "}
              </span>
              <span>
                | Ir a la página:{" "}
                <input
                  type="number"
                  defaultValue={pageTable + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) : 0;
                    setPage(page);
                  }}
                  style={{ width: "100px" }}
                />
              </span>{" "}
            </Col>
          </Row>
          <Row>
            <Col>{`${numRegisters} registro${
              numRegisters > 1 ? "s" : ""
            }`}</Col>
          </Row>
        </Fragment>
      )}
    </S.Container>
  );
};
