import React, { useCallback, useState } from "react";
import * as S from "./styles";
import logo from "../../assets/images/logo_aliseda_white.svg";

export const Loading = () => {
  return (
    <S.Wrapper>
      <img src={logo} alt="Logo" />
    </S.Wrapper>
  );
};

export const useLoader = () => {
  const [loading, setLoading] = useState(0);
  return [
    loading ? <Loading /> : null,
    useCallback(() => setLoading((prevState) => prevState + 1), []),
    useCallback(() => setLoading((prevState) => prevState - 1), []),
  ];
};
