import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLoader } from "../../components/Loading";
import * as API from "../../api";
import { useState } from "react";
import { useDataContext } from "../../context/DataState";
import usePermition from "../../constants/usePermition";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const useAuditDetail = () => {
  const { id } = useParams(null);
  const [loader, showLoader, hideLoader] = useLoader();
  const [audit, setAudit] = useState();
  const [active, setActive] = useState(null);
  const [key, setKey] = useState("general");
  const {
    dispatch,
    state: { appLists, user },
  } = useDataContext();
  const navigate = useNavigate();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  const regByPage = 10;

  useEffect(() => {
    if (!PERMISOS_POR_ROL.Auditoria.Acceso) {
      navigate("/");
    }
    document.title = `Detalle Auditoría ${id}`;
    getAudit();
    getUserList();
  }, [id]);

  /**
   * Obtener usuarios
   * @param {*} appLists
   */
  const getUserList = () => {
    showLoader();
    API.obtenerUsuariosList()
      .then((res) => {
        let listas = { ...appLists };
        if (listas !== null) {
          listas.userList = res.data;
          dispatch({
            type: "UPDATE_APP_LIST",
            payload: listas,
          });
          if (process.env.NODE_ENV === "development") {
            localStorage.setItem("appLists", JSON.stringify(listas));
          }
        }
      })
      .catch((error) => {
        if (error.code >= 400 && error.code < 500)
          toast.error(error.msg?.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  /**
   * Obtener la auditoría por el id de la url
   */
  const getAudit = () => {
    showLoader();
    API.getAudit(id)
      .then((res) => {
        let item = res.data;
        if (item.parameters !== "") {
          const parsedParameters = JSON.parse(item.parameters);
          if (!!parsedParameters) {
            item.parameters = parsedParameters;
          }
        }
        setAudit(item);
      })
      .catch((e) => console.error(e))
      .finally(() => hideLoader());
  };

  const getUserName = (id) => {
    let userName = "";
    if (id) {
      const userItem = appLists?.userList?.find((e) => e.id === id);
      if (!!userItem) {
        userName = userItem.name;
      }
    }

    return userName;
  };

  /**
   * Obtener los cambios de la auditoría y cambiar activo
   * @param {*} id
   */
  const handleActiveChange = (id) => {
    setActive(id);
    let auxAudit = { ...audit };
    let audiEnt = auxAudit.auditEntities.find((e) => e.id === id);
    let index = auxAudit.auditEntities.findIndex((e) => e.id === id);
    if (!audiEnt?.auditEntityChanges?.length) {
      const entityId = audiEnt.id;
      showLoader();
      API.obtenerCambios(entityId)
        .then((res) => {
          auxAudit.auditEntities[index] = res.data;
          setAudit(auxAudit);
        })
        .catch((e) => API.DEVELOP && console.error(e))
        .finally(hideLoader());
    }
  };

  const columns = [
    {
      Header: "",
      accessor: "select",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <FontAwesomeIcon
          icon={faEye}
          size="lg"
          color={cell.row.original.id === active ? "#0d6efd" : "black"}
          onClick={() => handleActiveChange(cell.row.original.id)}
          style={{ cursor: "pointer" }}
        />
      ),
      width: 10,
    },
    {
      Header: "Entidad",
      accessor: "entityName",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Identificador",
      accessor: "entityId",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Acción Realizada",
      accessor: "entityChangeType",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <span>
          {
            appLists.entityChangeType.find(
              (e) => e.Id === cell.row.original.entityChangeType
            ).translation
          }
        </span>
      ),
    },
  ];

  const defaultSort = [
    {
      id: "entityName",
      desc: false,
    },
  ];

  return {
    id,
    loader,
    audit,
    appLists,
    active,
    getUserName,
    key,
    setKey,
    getAudit,
    navigate,
    regByPage,
    columns,
    defaultSort,
  };
};

export default useAuditDetail;
