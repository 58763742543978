import { Fragment, useEffect, useState } from "react";
import { useDataContext } from "../../context/DataState";
import usePermition from "../../constants/usePermition";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPrint, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useLoader } from "../../components/Loading";
import * as API from "../../api";
import { formatDateWithZone } from "../../constants";

const useSpecialSale = () => {
  const {
    state: { user },
  } = useDataContext();
  const [data, setData] = useState([]);
  const { PERMISOS_POR_ROL } = usePermition({ user });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [modalAudit, setModalAudit] = useState(false);
  const [users, setUsers] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();
  const [valuesAudit, setValuesAudit] = useState({});

  useEffect(() => {
    document.title = "Venta Especial";
    if (!PERMISOS_POR_ROL.VentaEspecial.Acceso) {
      navigate("/");
    } else {
      loadUserList();
      getSpacialSales();
    }
  }, []);

  /**
   * Cargar usuarios para mostrar en las auditorias
   */
  const loadUserList = () => {
    showLoader();
    API.obtenerUsuariosList()
      .then((res) => {
        setUsers(res.data);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => hideLoader());
  };

  /**
   * Cargar ventas especiales
   */
  const getSpacialSales = () => {
    showLoader();
    API.obtenerVentasEspeciales()
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => API.DEVELOP && console.error(e))
      .finally(hideLoader);
  };

  const defaultSort = [
    {
      id: "creationTime",
      desc: true,
    },
  ];

  const FilterForm = ({ column }) => {
    const { filterValue, setFilter } = column;

    useEffect(() => {
      const value = searchParams.get(
        "filter" + column.Header.split(" ").join("")
      );
      if (value) {
        setFilter(value);
      }
    }, []);

    return (
      <Form>
        <Form.Group className="mb-2">
          <span>
            <input
              id={"filter" + column.Header.split(" ").join("")}
              className="form-control"
              style={{ width: "60%", marginTop: "0.25rem", padding: "0" }}
              value={filterValue || ""}
              onClick={(e) => {
                if (!e) e = window.event;
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => setFilter(e.target.value)}
              autoComplete="off"
            />
          </span>
        </Form.Group>
      </Form>
    );
  };

  const defaultColumn = {
    // Let's set up our default Filter UI
    Filter: FilterForm,
  };

  /**
   * Columnas para la tabla
   */
  const columns = [
    {
      Header: "",
      accessor: "edit",
      disableFilters: true,
      disableSortBy: true,
      width: 60,
      Cell: ({ cell }) => (
        <Fragment>
          <button
            style={{ marginLeft: "0.5rem" }}
            className="tableButton"
            onClick={() => showModalAudit(cell)}
            hidden={!PERMISOS_POR_ROL.Territoriales.Auditoria}
          >
            <span title="Auditoría">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faPrint}
                size="lg"
                fixedWidth
              />
            </span>
          </button>
          <button
            className="tableButton"
            onClick={() => handleNavDetail(cell.row.values.id)}
          >
            <span title="Editar">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEye}
                size="lg"
                fixedWidth
              />
            </span>
          </button>
        </Fragment>
      ),
    },
    {
      Header: "Id",
      accessor: "id",
      disableFilters: true,
      disableSortBy: true,
      width: 100,
    },
    {
      Header: "Nombre",
      accessor: "name",
      width: 300,
    },
    {
      Header: "Fecha creación",
      accessor: "creationTime",
    },
    {
      Header: "Cantidad de activos",
      accessor: "numAssets",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "delete",
      disableFilters: true,
      disableSortBy: true,
      width: 60,
      Cell: ({ cell }) => (
        <button
          style={{ marginLeft: "0.5rem" }}
          className="tableButton"
          onClick={() => showModalDelete(cell)}
          hidden={!PERMISOS_POR_ROL.Territoriales.Auditoria}
        >
          <span title="Borrar">
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={faTrash}
              size="lg"
              fixedWidth
            />
          </span>
        </button>
      ),
    },
  ];

  /**
   * Meteadata del modal de auditoria
   */
  const METADATA_AUDIT = [
    {
      label: "Usuario Creación",
      name: "creatorId",
    },
    {
      label: "Fecha Creación",
      name: "creationTime",
    },
    {
      label: "Usuario Última Modificación",
      name: "lastModifierId",
    },
    {
      label: "Fecha Última Modificación",
      name: "lastModificationTime",
    },
  ];

  /**
   * Mostrar modal de auditoria
   */
  const showModalAudit = (cell) => {
    const item = data.find((e) => e.id === cell.row.values.id);
    let cellValues = { ...item };

    const creator = users.find((s) => s.id === cellValues.creatorId);
    if (creator !== undefined) {
      cellValues.creatorId = creator.name;
    }
    const modifier = users.find((s) => s.id === cellValues.lastModifierId);
    if (modifier !== undefined) {
      cellValues.lastModifierId = modifier.name;
    }
    if (!!cellValues.creationTime) {
      cellValues.creationTime = formatDateWithZone(cellValues.creationTime);
    }
    if (!!cellValues.lastModificationTime) {
      cellValues.lastModificationTime = formatDateWithZone(
        cellValues.lastModificationTime
      );
    }

    setValuesAudit(cellValues);
    setModalAudit(true);
  };

  const handleNavDetail = (id) => {
    const url = !!id ? `/special-sale/${id}` : "/special-sale";
    navigate(url);
  };
  const showModalDelete = () => {};

  /**
   * Quitar el modal de auditoria
   */
  const hiddeModalAudit = () => {
    setModalAudit(false);
  };

  return {
    loader,
    PERMISOS_POR_ROL,
    data,
    columns,
    defaultSort,
    defaultColumn,
    modalAudit,
    hiddeModalAudit,
    valuesAudit,
    METADATA_AUDIT,
    handleNavDetail,
  };
};

export default useSpecialSale;
