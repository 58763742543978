import React, { useState, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faSearch,
  faPlusCircle,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import * as S from "./styles";
import * as API from "../../api";
import { useLoader } from "../../components/Loading";
import { Col, Row, Table, Form, Button, Card } from "react-bootstrap";
import { ModalFilterAudit } from "../../components/ModalFilterAudit";
import { TableBackEnd } from "../../components/TableBackEnd";
import { ModalEntitiesAudit } from "../../components/ModalEntitiesAudit";
import { ModalOnlyText } from "../../components/ModalOnlyText";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { formatDateWithZone, orderAlphabetic } from "../../constants";
import { useDataContext } from "../../context/DataState";
import PageTitle from "../../components/PageTitle/PageTitle";
import usePermition from "../../constants/usePermition";

export const Auditoria = () => {
  const [data, setData] = useState([]);
  const [result, setResult] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filtersModal, setFiltersModal] = useState([]);
  const [comparators, setComparators] = useState([]);
  const [values, setValues] = useState({});
  const [entityShow, setEntityShow] = useState([]);
  const [sqlField, setSqlField] = useState("");
  const [showModalFilters, setShowShowingFilters] = useState(false);
  const [showModalEntities, setShowShowingEntities] = useState(false);
  const [showModalLog, setShowModalLog] = useState(false);
  const [page, setPage] = useState(0);
  const [regsPage, setRegsPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [numRegisters, setNumRegisters] = useState(0);
  const [order, setOrder] = useState("");
  const [orderDesc, setOrderDesc] = useState(true);
  const [entities, setEntities] = useState({});
  const [log, setLog] = useState("");
  const [loader, showLoader, hideLoader] = useLoader();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    dispatch,
    state: { appLists, user },
  } = useDataContext();
  const { PERMISOS_POR_ROL } = usePermition({ user });

  useEffect(() => {
    if (PERMISOS_POR_ROL.Auditoria.Acceso) {
      document.title = "EC-Light-Auditoría";
      showLoader();
      API.obtenerFiltrosAuditoria()
        .then((res) => {
          let data = res.data;
          let userFilter = data.filterFields.find(
            (e) => e.sqlField === "UserId"
          );
          if (userFilter) {
            userFilter.values = orderAlphabetic(userFilter.values, "label");
          }
          setData(data);
          API.obtenerUsuariosList()
            .then((res) => {
              let listas = appLists;
              if (listas !== null) {
                let array = orderAlphabetic(res.data, "name");
                listas.userList = array;
                dispatch({
                  type: "UPDATE_APP_LIST",
                  payload: listas,
                });
              }
              if (searchParams?.size > 0) {
                setFiltersFromSearchParams(data);
              }
            })
            .catch((error) => {
              if (error.code >= 400 && error.code < 500)
                toast.error(error.msg?.message);
              API.DEVELOP && console.log(error);
            })
            .finally(() => hideLoader());
        })
        .catch((error) => {
          if (error.code >= 400 && error.code < 500)
            toast.error(error.msg?.message);
          API.DEVELOP && console.log(error);
        });
    } else {
      navigate("/");
    }
  }, [hideLoader, showLoader]);

  /**
   * LLenar los filtros por los parametros de la URL
   */
  const setFiltersFromSearchParams = (dataFields) => {
    const queryParameters = new URLSearchParams(window.location.search);

    let tempModalFilters = [...dataFields.filterFields];

    let tempFilters = [];

    tempModalFilters.forEach((element) => {
      const value = queryParameters.get(element.sqlField);
      if (value) {
        if (element.isRepeteable === false) {
          var ic = tempModalFilters.indexOf(element);
          tempModalFilters.splice(ic, 1);
          setFiltersModal(tempModalFilters);
        }

        let newFilter = {};
        newFilter.campo = element.fieldLabel;
        newFilter.sqlField = element.sqlField;
        newFilter.sqlValue = value;
        newFilter.sqlComparator = element.sqlComparators[0];
        newFilter.auditTableType = element.fieldType.auditTableType;
        newFilter.entityTableType = element.fieldType.entityTableType;

        tempFilters.push(newFilter);

        if (element.values) {
          const newVal = element.values.find((e) => e.value === value);
          if (newVal?.children?.length > 0) {
            newVal.children.forEach((el) => {
              const childVal = queryParameters.get(el.sqlField);
              if (childVal) {
                let newFilterAux = {};
                newFilterAux.campo = el.fieldLabel;
                newFilterAux.sqlField = el.sqlField;
                newFilterAux.sqlValue = childVal;
                newFilterAux.sqlComparator = el.sqlComparators[0];
                newFilterAux.auditTableType = el.fieldType.auditTableType;
                newFilterAux.entityTableType = el.fieldType.entityTableType;

                tempFilters.push(newFilterAux);
              } else {
                tempModalFilters.push(el);
              }
            });

            setFiltersModal(tempModalFilters);
          }
        }
      }
    });

    setFilters(tempFilters);

    setPage(1);
    search();
  };

  const obtenerMilisegundos = (time) => {
    let date = new Date("Thu Jan 01 1970 " + time + " GMT");
    return date.getTime();
  };

  const defaultSort = [
    {
      id: "id",
      desc: true,
    },
  ];

  const search = () => {
    if (page > 0) {
      let dataSearch = {};
      let filterAuxList = [];
      dataSearch.page = page;
      dataSearch.regsPage = regsPage;
      if (order !== null && order !== "") {
        dataSearch.orderBy = order.charAt(0).toUpperCase() + order.slice(1);
        dataSearch.isAsc = orderDesc;
      } else {
        dataSearch.orderBy = "id";
        dataSearch.isAsc = false;
      }
      filters.forEach((e) => {
        let filterAux = {};
        filterAux.sqlField = e.sqlField;
        if (
          e.auditTableType === "System.Int32" ||
          e.auditTableType === "System.Int64"
        ) {
          filterAux.sqlValue = Number(e.sqlValue);
        } else if (e.auditTableType === "System.String") {
          filterAux.sqlValue = e.sqlValue.toString();
        } else if (e.auditTableType === "System.DateTime") {
          filterAux.sqlValue = new Date(e.sqlValue);
        } else {
          filterAux.sqlValue = e.sqlValue;
        }
        filterAux.sqlComparator = e.sqlComparator;
        filterAuxList.push(filterAux);
      });
      dataSearch.filters = filterAuxList;

      //set filter in url
      let tmpFilters = [...filters];
      let url = "/auditing?";
      for (let i = 0; i < tmpFilters.length; i++) {
        const fil = tmpFilters[i];
        url =
          url +
          fil.sqlField +
          "=" +
          fil.sqlValue +
          `${i === tmpFilters.length - 1 ? "" : "&"}`;
      }
      let newUrl = `${url}${!!tmpFilters.length ? "&" : ""}skip=${
        page - 1
      }&take=${regsPage}`;
      navigate(newUrl);

      showLoader();
      API.obtenerAuditorias(dataSearch)
        .then((res) => {
          let auxListResult = [];
          res.data.collection.forEach((e) => {
            let auxResult = {};
            auxResult.id = e.id;
            auxResult.parentAuditActionId = e.parentAuditActionId;
            if (e.startDateTime !== null)
              auxResult.startDateTime = formatDateWithZone(e.startDateTime);
            if (e.endDateTime !== null)
              auxResult.endDateTime = formatDateWithZone(e.endDateTime);
            auxResult.duration = e.duration;
            auxResult.serviceName = e.serviceName;
            let usuario = appLists?.userList?.find((s) => s.id === e.userId);
            if (!!usuario) auxResult.user = usuario.name;
            auxResult.log = e.log;
            auxResult.userId = e.userId;
            auxResult.hasError = e.hasError;
            auxResult.error = e.error;
            auxResult.auditEntities = e.auditEntities;
            auxListResult.push(auxResult);
          });
          setResult(auxListResult);
          setTotalPages(res.data.maxPages);
          setNumRegisters(res.data.numRegisters);
        })
        .catch((error) => {
          if (error.code >= 400 && error.code < 500)
            toast.error(error.msg?.message);
          API.DEVELOP && console.log(error);
        })
        .finally(() => hideLoader());
    }
  };

  const columns = [
    {
      Header: "",
      accessor: "moreInfo",
      disableFilters: true,
      disableSortBy: true,
      width: 40,
      Cell: ({ cell }) => (
        <Fragment>
          <Link to={"/auditing/" + cell.row.original.id}>
            <span title="Ver detalle">
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={faEye}
                size="lg"
                fixedWidth
              />
            </span>
          </Link>
        </Fragment>
      ),
    },
    {
      Header: "Id",
      accessor: "id",
      disableFilters: true,
      maxWidth: 80,
    },
    {
      Header: "Id Padre",
      accessor: "parentAuditActionId",
      disableFilters: true,
      disableSortBy: true,
      maxWidth: 80,
      Cell: ({ cell }) => (
        <Fragment>
          {!!cell.row.original.parentAuditActionId && (
            <Link to={"/auditing/" + cell.row.original.parentAuditActionId}>
              <span title="Ver detalle" style={{ marginRight: 10 }}>
                <FontAwesomeIcon
                  style={{ color: "black" }}
                  icon={faEye}
                  size="lg"
                  fixedWidth
                />
              </span>
            </Link>
          )}
          {cell.row.original.parentAuditActionId}
        </Fragment>
      ),
    },
    {
      Header: "Fecha Inicio",
      accessor: "startDateTime",
      disableFilters: true,
      maxWidth: 140,
    },
    {
      Header: "Fecha Fin",
      accessor: "endDateTime",
      disableFilters: true,
      disableSortBy: true,
      maxWidth: 140,
    },
    {
      Header: "Duración",
      accessor: "duration",
      disableFilters: true,
      disableSortBy: true,
      maxWidth: 60,
      Cell: ({ cell }) => (
        <Fragment>
          {!!cell.row.original.duration
            ? `${obtenerMilisegundos(cell.row.original.duration)} ms`
            : ""}
        </Fragment>
      ),
    },
    {
      Header: "Service Name",
      accessor: "serviceName",
      disableFilters: true,
      with: 70,
    },
    {
      Header: "Usuario",
      accessor: "userId",
      disableFilters: true,
      width: 140,
      Cell: ({ cell }) => <Fragment>{cell.row.original.user}</Fragment>,
    },
    {
      Header: "Log",
      accessor: "log",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Erróneo",
      accessor: "hasError",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Fragment>{cell.row.original.hasError ? "Si" : "No"}</Fragment>
      ),
    },
    {
      Header: "Error",
      accessor: "error",
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const addFilter = (event, values) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.valor === undefined) toast.error("Valor no puede ser vacío");
    else if (form.valor.value === null || form.valor.value === "")
      toast.error("Valor no puede ser vacío");
    else {
      const tmpFilters = [...filters];
      const tmpFiltersModal = [...filtersModal];
      const filterAux = tmpFiltersModal.find(
        (s) =>
          s.sqlField === form.sqlField.value &&
          s.fieldLabel === form.campo.value
      );
      if (filterAux !== null) {
        if (filterAux.isRepeteable === false) {
          var ic = tmpFiltersModal.indexOf(filterAux);
          tmpFiltersModal.splice(ic, 1);
          setFiltersModal(tmpFiltersModal);
        }
      }

      let newFilter = {};
      newFilter.campo = form.campo.value;
      newFilter.sqlField = form.sqlField.value;
      if (values.entityTableType !== "System.Boolean") {
        newFilter.sqlValue = form.valor.value;
      } else {
        if (form.valor.checked) newFilter.sqlValue = "true";
        else newFilter.sqlValue = "false";
      }
      newFilter.sqlComparator = form.comparador.value;
      newFilter.auditTableType = values.auditTableType;
      newFilter.entityTableType = values.entityTableType;
      tmpFilters.push(newFilter);
      setFilters(tmpFilters);
      if (values.values !== null) {
        const newValues = values.values.find(
          (e) => e.value === form.valor.value
        );
        if (!!newValues?.children?.length) {
          newValues.children.forEach((e) => {
            tmpFiltersModal.push(e);
          });

          setFiltersModal(tmpFiltersModal);
        }
      }
      setShowShowingFilters(false);

      let url = "/auditing?";

      for (let i = 0; i < tmpFilters.length; i++) {
        const fil = tmpFilters[i];
        url =
          url +
          fil.sqlField +
          "=" +
          fil.sqlValue +
          `${i === tmpFilters.length - 1 ? "" : "&"}`;
      }
      navigate(url);
    }
  };

  const addFilterModal = () => {
    if (filtersModal.length === 0) setFiltersModal(data.filterFields);
    setShowShowingFilters(true);
  };

  const deleteFilter = (sqlField, sqlComparator, sqlValue) => {
    const tmpFilters = [...filters];
    let tmpFiltersModal = [...filtersModal];
    const filter = filters.find(
      (s) =>
        s.sqlField === sqlField &&
        s.sqlComparator === sqlComparator &&
        s.sqlValue === sqlValue
    );
    if (filter !== null) {
      const filterField = data.filterFields.find(
        (s) => s.fieldLabel === filter.campo && s.sqlField === filter.sqlField
      );
      if ((filterField !== null) & (filterField !== undefined)) {
        if (filterField.isRepeteable === false) {
          if (filterField.values !== null) {
            const valuesFilterField = filterField.values.find(
              (s) => s.value === filter.sqlValue
            );
            if (
              valuesFilterField != null &&
              valuesFilterField.children !== null
            ) {
              valuesFilterField.children.forEach((e) => {
                tmpFiltersModal = tmpFiltersModal.filter((ele) => {
                  return ele.sqlField !== e.sqlField;
                });
              });
            }
          }
          tmpFiltersModal.push(filterField);
          setFiltersModal(tmpFiltersModal);
        }
      }
      var ic = tmpFilters.indexOf(filter);
      tmpFilters.splice(ic, 1);
      let url = "/auditing?";

      for (let i = 0; i < tmpFilters.length; i++) {
        const fil = tmpFilters[i];
        url =
          url +
          fil.sqlField +
          "=" +
          fil.sqlValue +
          `${i === tmpFilters.length - 1 ? "" : "&"}`;
      }
      navigate(url);
      setFilters(tmpFilters);
    }
  };

  const cleanFilters = () => {
    setFilters([]);
    setFiltersModal(data.filterFields);
    navigate("/auditing");
  };

  const handleChangeFilters = (value) => {
    const field = filtersModal.find((e) => e.fieldLabel === value);
    if (field !== undefined && field !== null) {
      setSqlField(field.sqlField);
      setComparators(field.sqlComparators);
      var valuesAux = {};
      valuesAux["auditTableType"] = field.fieldType.auditTableType;
      valuesAux["entityTableType"] = field.fieldType.entityTableType;
      valuesAux["values"] = field.values;
      setValues(valuesAux);
    }
  };

  return (
    <S.Container>
      {loader}
      {showModalFilters && (
        <ModalFilterAudit
          message="Nuevo Filtro"
          labelConfirm="Confirmar"
          confirm={addFilter}
          cancel={() => setShowShowingFilters(false)}
          filtersModal={filtersModal}
          comparators={comparators}
          values={values}
          sqlField={sqlField}
          handleChangeFilters={handleChangeFilters}
        />
      )}
      {showModalEntities && (
        <ModalEntitiesAudit
          message="Entidades"
          cancel={() => setShowShowingEntities(false)}
          dataEntities={entities}
          entityShow={entityShow}
          setEntityShow={setEntityShow}
        />
      )}
      {showModalLog && (
        <ModalOnlyText
          message="Log"
          cancel={() => setShowModalLog(false)}
          text={log}
        />
      )}
      <Card>
        <Card.Header>
          <PageTitle title="Auditoría" />
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Row>
                <Col xs={10}>
                  <Table striped>
                    <thead>
                      <tr>
                        <th>Campo</th>
                        <th>Comparación</th>
                        <th>Valor</th>
                        <th>Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filters.map((filter, index) => (
                        <tr key={index}>
                          <td>{filter.campo}</td>
                          <td>{filter.sqlComparator}</td>
                          <td>{filter.sqlValue}</td>
                          <td>
                            <S.DeleteFilter
                              onClick={() =>
                                deleteFilter(
                                  filter.sqlField,
                                  filter.sqlComparator,
                                  filter.sqlValue
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                size="lg"
                                fixedWidth
                              />
                            </S.DeleteFilter>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                <Col xs={2}>
                  <Form.Group>
                    <Button
                      className="mb-2"
                      onClick={() => addFilterModal()}
                      variant="success"
                    >
                      <span>
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          size="lg"
                          fixedWidth
                        />
                      </span>
                      Añadir Filtro
                    </Button>
                  </Form.Group>
                  <Form.Group>
                    <Button
                      className="mb-2"
                      onClick={() => {
                        setPage(1);
                        search();
                      }}
                      variant="success"
                    >
                      <span>
                        <FontAwesomeIcon icon={faSearch} size="lg" fixedWidth />
                      </span>
                      Buscar
                    </Button>
                  </Form.Group>
                  <Form.Group>
                    <Button
                      className="mb-2"
                      onClick={() => cleanFilters()}
                      variant="danger"
                    >
                      <span>
                        <FontAwesomeIcon icon={faTrash} size="lg" fixedWidth />
                      </span>
                      Limpiar Filtros
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header
          style={{
            paddingLeft: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        ></Card.Header>
        <Card.Body>
          <TableBackEnd
            columns={columns}
            data={result}
            defaultSort={defaultSort}
            hiddenColumns={["log", "error"]}
            pageTable={page}
            regsPage={regsPage}
            setPage={setPage}
            setRegsPage={setRegsPage}
            search={search}
            totalPages={totalPages}
            numRegisters={numRegisters}
            order={order}
            setOrder={setOrder}
            orderDesc={orderDesc}
            setOrderDesc={setOrderDesc}
          />
        </Card.Body>
      </Card>
    </S.Container>
  );
};
