import React from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";

export const InputFormNumber = ({
  label,
  placeholder,
  id,
  disabled,
  hidden,
  filter,
  required,
  min,
  step,
  handleChange,
  value,
  addon,
}) => {
  const handleValueChange = (id, val) => {
    if (val < min) val = min;
    handleChange(id, val);
  };

  return (
    <Row className="mb-2" hidden={hidden(filter, label)}>
      <Col xs={12} md={5}>
        <Form.Label
          hidden={hidden(filter, label)}
          style={{ fontSize: "0.875rem" }}
        >
          {label}
        </Form.Label>
      </Col>
      <Col xs={12} md={7}>
        <InputGroup>
          <Form.Control
            required={required}
            type="number"
            placeholder={placeholder}
            id={id}
            disabled={disabled()}
            value={value || ""}
            min={min}
            step={step}
            onChange={(e) => handleValueChange(id, e.target.value)}
            aria-describedby="addon"
            size="sm"
            autoComplete="off"
          />
          {addon && <InputGroup.Text id="addon">{addon}</InputGroup.Text>}
          <Form.Control.Feedback type="invalid">
            El {label} es necesario.
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  );
};
